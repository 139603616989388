/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import axios from 'axios';
import { getToken, logout } from './auth.service';


const getTenant = () => 
{
  const tenantId = `${localStorage.getItem("tenantId")}`;
  const url = process.env.REACT_APP_API_BASE_URL.replace("{tenantId}",tenantId);

  return url;
}

const api = axios.create({
  baseURL: getTenant(),
});

api.interceptors.request.use((request) => {
  request.baseURL = getTenant();

  if (
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/register' &&
    !window.location.pathname.includes('verify')
  ) {
    const token = getToken();
    if (!token) {
      // logout();
      // window.location = '/login';
    }
    request.headers.authorization = `Bearer ${token}`;
  }
  return request;
});

api.interceptors.response.use(
  (response) => response,
  (err) => {
    if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
      if (!err.response || err.response.status === 401 || err.response.status === 403) {
        // logout();
        // window.location = '/login';
      }
    }
    return Promise.reject(err);
  }
);

export default api;
