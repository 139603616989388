/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import P, { element, shape } from 'prop-types';
import { MainContext } from '../../context/ApiContext';
import { diffTime, msToTime } from '../../utils/timeMath';
import BusinessTime from '../../utils/businessTime';
import UtilsTime from '../../utils/utilsTime';
import MoreIcon from '@material-ui/icons/More';
import { DateTime } from 'luxon';
import useApp from '../../hooks/useApp';
import { listToMatrix } from '../../utils/array';
import { Pages } from '@material-ui/icons';

const ProcessesReportTable = ({ listItem, filters, callResult, mutate }) => {
  const [open, setOpen] = useState(false);

  const [totalList, setTotalList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [pages, setPages] = useState({
    per_page: 10,
    page: 1,
  });

  const handleChangePage = (event, newPage) => {
    setPages({ ...pages, page: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setPages({ ...pages, page: 1, per_page: parseInt(event.target.value, 10) });
    mutate();
  };

  useEffect(() => {    
    if (listItem?.length > 0) {
      const listMatrix = listToMatrix(listItem, pages.per_page);
      setTotalList(listMatrix);
      setTotalPages(listMatrix.length);
    }
  }, [listItem, pages.per_page]);

  const totalTime = (callsDuration) => {
    const total = callsDuration.reduce((total, el) => (total += el.duration), 0);
    return total;
  };

  const statusText = (status) => {
    let response = status
    switch (status) {
      case 0:   
      response = "1 - Ainda Não Atualizado"   
        break;
      case 1:   
      response = "2 - Info de cliente Alterada"   
        break;
      case 2:   
      response = "3 - Lead Agendado"   
        break;
      case 9:   
      response = "X - Renovador não encontrado"   
        break;
    
      default:
        break;
    }
    return response;
  };

  return (
    <>
          <Box m={1} />
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Ano/Mês Ref.</TableCell>
                  <TableCell>Apólice</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell>Cliente / CPF</TableCell>
                  <TableCell>Corretor / ID / RHG</TableCell>
                  <TableCell width='78px'>Nº RHG</TableCell>
                  <TableCell>Lead</TableCell>
                  <TableCell>Canal</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Info</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listItem?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align="left">{item.id}</TableCell>                    
                    <TableCell align="left">{item.reference_month ? (item.reference_month.toString().slice(4,6) + "/" + item.reference_month.toString().slice(0,4)) : ""}</TableCell>                    
                    <TableCell align="left">{item.policy_number}</TableCell>                    
                    <TableCell align="left">{item.product}</TableCell>                    
                    <TableCell align="left">{item.client_info?.client_name} / {item.client_info?.client_cpf}</TableCell>                    
                    <TableCell align="left">{item.broker?.name} / {item.broker?.id} / {item.broker?.renovator_hierarchical_group}</TableCell>                    
                    <TableCell align="left">{item.renovator_hierarchical_group}</TableCell>                    
                    <TableCell align="left">{item?.lead_id || "-"}</TableCell>                    
                    <TableCell align="left">{item.channel?.name}</TableCell>                    
                    <TableCell align="left">{statusText(item.update_status)}</TableCell>                    
                    <TableCell align="left">{item.additional_information}</TableCell>                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
    </>
  );
};

ProcessesReportTable.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
};

ProcessesReportTable.defaultProps = {
  listItem: null,
};

export default ProcessesReportTable;
