import logoV from '../../assets/Logo_Vertical_Dark.png';
import logoH from '../../assets/Logo_Horizontal_Dark.png';
import logoM3tas from '../../assets/logo.png';
import Bandeira from '../../assets/Bandeiras.png';
import { Logo,LogoH,LogoV ,BandeiraCartao} from './styles';

import logoCoruja from '../../assets/CORUJA_LOGO.png';
import logoSampleclient from '../../assets/logo-sampleclient.png';
import logo3ccorretora from '../../assets/logo-3ccorretora.png';
import logo3ccorretoraH from '../../assets/logo-3ccorretora-Horizontal.png';


const logosRequired = {
    logoV,
    logoH,
    logoM3tas,
    Bandeira,
    logoCoruja,
    logoSampleclient,
    logo3ccorretora,
    logo3ccorretoraH
}


export const LogoMetas = () => 
    <Logo src={logoM3tas} 
          layoutId="logo" 
          transition={{ duration: 1 }} />;

// Login
export const LogoVertical = () => 
    <LogoV src={logosRequired[localStorage.getItem("logoVertical")] || logoM3tas} 
           layoutId="logo" 
           transition={{ duration: 1 }} />;

export const LogoHorizontal = () => 
    <LogoH src={logosRequired[localStorage.getItem("logoHorizontal")] || logoM3tas} 
           layoutId="logo" 
           transition={{ duration: 1 }} />;

export const LogoImg = () => 
    <Logo src={logosRequired[localStorage.getItem("logoImg")] || logoM3tas} 
          layoutId="logo" 
          transition={{ duration: 1 }} />;

export const Bandeiras = () => 
    <BandeiraCartao src={logosRequired[localStorage.getItem("logoBandeiras")]} 
                    layoutId="Bandeira" 
                    transition={{ duration: 1 }} />;

