/* eslint-disable */
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DateTime } from 'luxon';
import { useContext, useState } from 'react';
import useSWR from 'swr';
import { MainContext } from '../../../context/ApiContext';
import useApp from '../../../hooks/useApp';
import { Nav, NavbarForm } from './styles';
import { fetcher } from '../../../service/SWRFetcher';

const DatePeriodFilter = ({ corretora, filters, setFilters }) => {
  const { partners, insurances } = useApp();
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [dates, setDates] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
  });

  const { getters } = useContext(MainContext);

  const { data: users } = useSWR(`/users`, fetcher);

  function handleSubmit(event) {
    event.preventDefault();
    let filt = {
      date_ini: event.target.elements.date_ini?.value,
      date_end: event.target.elements.date_end?.value,
      partner_id: partner.map((p) => ({ id: p.id })) || null,
      insurance_id: insurance.map((i) => ({ id: i.id })) || null,
      broker: broker?.id,
      paginate: null,
    };
    setFilters({ ...filters, ...filt });
  }

  const [partner, setPartner] = useState([]);

  const [insurance, setInsurance] = useState([]);

  const [broker, setBroker] = useState();

  return (
    <Nav>
      <div className="filter">Filtros</div>

      <NavbarForm>
        <form className="form" onSubmit={(e) => handleSubmit(e, getters)}>
          <div className="inputs">
            <div className="date_ini">
              <input
                type="date"
                name="date_ini"
                className="input-form"
                placeholder="Data Inicial"
                value={dates.date_ini}
                onChange={(e) => setDates(e)}
              />
            </div>
            <div className="date_end">
              <input
                type="date"
                name="date_end"
                className="input-form"
                placeholder="Data Final"
                value={dates.date_end}
                onChange={(e) => setDates(e)}
              />
            </div>
          </div>

          <Autocomplete
            multiple
            options={partners ? partners : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setPartner(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Empresas" />
            )}
          />

          <Autocomplete
            multiple
            options={insurances ? insurances.filter((i) => i.id <= 3) : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setInsurance(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Tipos de Estoque" />
            )}
          />

          <Autocomplete
            //  multiple
            options={users ? users : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setBroker (value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Corretor" />
            )}
          />

          <div>
            <button className="button" type="submit"> 
              Filtrar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
