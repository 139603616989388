/* eslint-disable camelcase */
/* eslint-disable */

import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import useSWR from 'swr';
import InputMask from 'react-input-mask';
import { IconButton, TablePagination } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { Header, Container } from '../../styles/shared/styles';
import { fetcher } from '../../service/SWRFetcher';
import { useAuth } from '../../hooks/useAuth';
import { excelExport } from '../../service/excel.service';
import { ExportButton } from './styles';
import Filter from '../../components/Filter';
import ProcessesReportTable from '../../components/ProcessesReportTable/index';
import CompTable from '../../components/CompTable';
import { maskCpf, maskDateTime } from '../../utils/masks';
import ModalHistoricoSinistro from '../../components/ClientForm/modalHistoricoSinistro';

const AccidentReport = () => {
  const { user } = useAuth();
  const [openSinistro, setOpenSinistro] = useState(false);
  const [sinistroSelecionado, setSinistroSelecionado] = useState([]);
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [filters, setFilters] = useState({
    cpf: undefined,
    name: undefined,
    leadId: undefined,
    startDate: dateLastMonth,
    endDate: dateNow,
    broker: undefined,
    per_page: 10,
    page: 0,
  });

  const Limpar = () => {
    setFilters({
      ...filters,
      cpf: '',
      name: '',
      leadId: '',
      status: '',
      situation: '',
      startDate: dateLastMonth,
      endDate: dateNow,
      broker: '',
      per_page: 10,
      page: 0,
    });
  };

  const MontarUrl = () => {
    const filtros = Object.keys(filters).map((x) =>
      filters[x] != undefined && filters[x] != '' ? `${x}=${filters[x]}` : ''
    );
    let url = '';
    filtros.forEach((x) => {
      if (url != '') {
        url += '&';
      }
      url += x;
    });
    return url;
  };
  const { data, mutate, isValidating } = useSWR(`/sinisters/list?${MontarUrl()}`, fetcher);

  const AbrirModalHistoricoSinistro = (id) => {
    setOpenSinistro(true);
    const sinistro = data.data.find((x) => id == x.id);
    setSinistroSelecionado([sinistro]);
  };

  const CriarBotao = (id) => (
    <IconButton onClick={() => AbrirModalHistoricoSinistro(id)}>
      <LaunchIcon />
    </IconButton>
  );
  const colums = [
    { titulo: 'ID', variavel: 'id' },
    { titulo: 'Cliente', variavel: 'client.name' },
    { titulo: 'CPF', variavel: 'client.cpf', tratamento: (cpf) => maskCpf(cpf) },
    { titulo: 'Reclamante', variavel: 'complainant' },
    { titulo: 'Data de Abertura', variavel: 'created_at', tratamento: (created_at) => maskDateTime(created_at) },
    { titulo: 'Data de Finalização', variavel: 'finish_date', tratamento: (finish_date) => maskDateTime(finish_date) },
    { titulo: 'Situação', variavel: 'situation', tratamento: (situation) => situation },
    { titulo: 'Status', variavel: 'status', tratamento: (status) => status },
    { titulo: 'Detalhes', variavel: 'id', tratamento: CriarBotao },
  ];

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, page: 0, per_page: parseInt(event.target.value, 10) });
    mutate();
  };
  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, page: newPage });
  };
  return (
    <>
      <ModalHistoricoSinistro
        title="Detalhes Sinistro"
        sinistros={sinistroSelecionado}
        setOpen={setOpenSinistro}
        open={openSinistro}
      />
      <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
        <Container>
          <Header>
            <h1>Relatório de Sinistros</h1>
          </Header>

          <Filter
            clickFilter={() => {
              if (
                filters.name ||
                filters.cpf ||
                filters.broker ||
                filters.startDate ||
                filters.endDate ||
                filters.leadId
              ) {
                mutate();
              }
            }}
            clearFilter={() => {
              Limpar();
            }}>
            <input type="text" placeholder="Nome" name="name" value={filters.name} onChange={handleChange} />
            <InputMask
              maskChar=""
              mask="999.999.999-99"
              type="text"
              name="cpf"
              placeholder="CPF"
              value={filters.cpf}
              onChange={handleChange}
            />
            <input list="select" name="status" placeholder="Situação" value={filters.status} onChange={handleChange} />
            <datalist id="select">
              <option key="1" value="Pendente - Prazo Normal">
                {' '}
              </option>
              <option key="2" value="Pendente - Prazo Anormal">
                {' '}
              </option>
              <option key="3" value="Serviço em andamento">
                {' '}
              </option>
              <option key="4" value="Pagamento de valores em andamento">
                {' '}
              </option>
              <option key="5" value="Finalizado">
                {' '}
              </option>
            </datalist>
            <input
              type="date"
              name="startDate"
              className="input-form"
              placeholder="Data Inicial"
              value={filters.startDate}
              onChange={handleChange}
            />
            <input
              type="date"
              name="endDate"
              className="input-form"
              placeholder="Data Final"
              value={filters.endDate}
              onChange={handleChange}
            />
          </Filter>
          <TablePagination
            component="div"
            count={data ? data.total : 0}
            page={filters.page}
            onPageChange={handleChangePage}
            rowsPerPage={filters.per_page}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <CompTable data={data?.data} colums={colums} />
          <ExportButton
            type="button"
            style={{ float: 'right', marginTop: '20px' }}
            onClick={() => {
              excelExport(
                `/sinisters/export?${MontarUrl()}`,
                `relatorio_de_sinistro${DateTime.now().toFormat('dd_LL_yyyy')}`
              );
            }}>
            Exportar
          </ExportButton>
        </Container>
      </motion.div>
    </>
  );
};

export default AccidentReport;
