/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DateTime } from 'luxon';

import ClientForm from '../../components/ClientForm';
import { Header, Container } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import DatePeriodFilter from '../../components/LeadsVerification/Filter';
import LoadingPage from '../LoadingPage';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/acai.css';
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import api from '../../service/api';
import { createClient } from '../../service/client.service';
import useApp from '../../hooks/useApp';

const LeadsVerification = () => {
  const { setState } = useApp();
  const [open, setOpen] = useState(false);
  const [json_response, setJson_response] = useState([]);

  const [filters, setFilters] = useState({
    partner: null,
    leadNum: null,
  });

  useEffect(() => {
    async function fetchData() {
      setState('loading', true);
      const {data:{data}} = await api.post('/leadVerification', filters);
      setJson_response(data);
      setState('loading', false);
    }
    fetchData();
  }, [filters]);

  const ContainerStyle = {
    marginBottom: '30px',
  };
  const titleStyle = {
    marginBottom: '10px',
    marginLeft: '20px',
    marginTop: '20px',
  };

  return (
    <>
      {
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
          <Container>
            <ClientForm open={open} setOpen={setOpen} action={createClient} title="Clientes" />
            <Header>
              <h1>Verificação de Proposta</h1>
            </Header>

            <DatePeriodFilter setFilters={setFilters} />

            <TableContainer component={Paper} style={ContainerStyle}>
              <Table aria-label="simple table">
                <TableHead>
                  <h2 style={titleStyle}>Resposta:</h2>
                </TableHead>
                <TableRow style={titleStyle}>
                  <TableCell>

                    {json_response != undefined && json_response[1]?.inviter_login == null ? (
                      <JSONPretty
                        id="json-pretty"
                        style={{ background: '#fff' }}
                        data={JSON.stringify(json_response)}
                      />
                    ) : (
                      <JSONPretty
                        id="json-pretty"
                        style={{ background: '#fff' }}
                        data={JSON.stringify(json_response[0])}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>

            {json_response != undefined && json_response[1]?.inviter_login != null && (
              <>
                <TableContainer component={Paper} style={ContainerStyle}>
                  <h2 style={titleStyle}>Registrado na tabela leads eletivo: </h2>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>inviter_login</TableCell>
                        <TableCell>created_at</TableCell>
                        <TableCell>updated_at</TableCell>
                        <TableCell>lead_id</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableRow>
                      <TableCell>{JSON.stringify(json_response[1]?.inviter_login)}</TableCell>
                      <TableCell>
                        {DateTime.fromISO(json_response[1]?.created_at).toFormat('dd/MM/yyyy HH:mm:ss')}
                      </TableCell>
                      <TableCell>
                        {DateTime.fromISO(json_response[1]?.updated_at).toFormat('dd/MM/yyyy HH:mm:ss')}
                      </TableCell>
                      <TableCell>{JSON.stringify(json_response[1]?.lead_id)}</TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>

                {json_response[1]?.inviter_login == null ? (
                  ''
                ) : (
                  <TableContainer component={Paper} style={ContainerStyle}>
                    <h2 style={titleStyle}>Lead associado: </h2>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>id</TableCell>
                          <TableCell>proposal</TableCell>
                          <TableCell>actual_proposal</TableCell>
                          <TableCell>older_proposal</TableCell>
                          <TableCell>created_at</TableCell>
                          <TableCell>proposal_date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableRow>
                        <TableCell>{JSON.stringify(json_response[2]?.id)}</TableCell>
                        <TableCell>{JSON.stringify(json_response[2]?.proposal)}</TableCell>
                        <TableCell>{JSON.stringify(json_response[2]?.actual_proposal)}</TableCell>
                        <TableCell>{JSON.stringify(json_response[2]?.older_proposal)}</TableCell>
                        <TableCell>
                          {DateTime.fromISO(json_response[2]?.created_at).toFormat('dd/MM/yyyy HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          {DateTime.fromSQL(json_response[2]?.proposal_date).toFormat('dd/MM/yyyy HH:mm:ss')}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                )}
              </>
            )}
          </Container>
        </motion.div>
      }
    </>
  );
};

export default LeadsVerification;
