/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { InputAdornment } from '@material-ui/core';
import { FormGroup } from '../styles';
import { TextField, AutocompleteField } from '../../FormFields';
import { formatarParaEstiloPTBR } from '../../../hooks/Utils';

export const InsuranceBailHomeHabitationTypeArray = [
    { name: 'Residencial', id: 1 },
    { name: 'Comercial', id: 2 },
];

export function BailQuestions({
    onChange,
    onChangeAutoComplete,
    insuranceBailHomeHabitationType,
    insuranceBailName,
    insuranceBailRentAmount,
    insuranceBailIptuAmount,
    insuranceBailCondoAmount,
})
{
    return (
        <>
        <FormGroup dark>
            <AutocompleteField
                medium
                value={insuranceBailHomeHabitationType || null}
                onChange={onChangeAutoComplete}
                valueText={insuranceBailHomeHabitationType ? insuranceBailHomeHabitationType.name : ''}
                options={InsuranceBailHomeHabitationTypeArray}
                varibleName="insurance_bail_homeHabitationType"
                className="control m"
                label="Imóvel"
            />
            <TextField
                value={insuranceBailName || ''}
                onChange={onChange}
                varibleName="insurance_bail_name"
                placeholder="Locatário ou Inquilino"
                className="control ms"
                label="Nome"
            />
            <NumericFormat
                label="Valor do aluguel"
                name="insurance_bail_rentAmount"
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                autoComplete="off"
                InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
                value={formatarParaEstiloPTBR(insuranceBailRentAmount)}
                onChange={onChange}
            />
            <NumericFormat
                label="IPTU"
                name="insurance_bail_iptuAmount"
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                autoComplete="off"
                InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
                value={formatarParaEstiloPTBR(insuranceBailIptuAmount)}
                onChange={onChange}
            />
            <NumericFormat
                label="Condomínio"
                name="insurance_bail_condoAmount"
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                autoComplete="off"
                InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
                value={formatarParaEstiloPTBR(insuranceBailCondoAmount)}
                onChange={onChange}
            />
        </FormGroup>
        </>
    )
}

BailQuestions.propTypes = {
    onChange: P.objectOf(P.any).isRequired,
    onChangeAutoComplete: P.objectOf(P.any).isRequired,
    insuranceBailHomeHabitationType: P.objectOf(P.any).isRequired,
    insuranceBailName: P.string.isRequired,
    insuranceBailRentAmount: P.number.isRequired,
    insuranceBailIptuAmount: P.number.isRequired,
    insuranceBailCondoAmount: P.number.isRequired,
}