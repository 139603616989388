/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import P, { element, shape } from 'prop-types';
import { MainContext } from '../../context/ApiContext';
import { diffTime, msToTime } from '../../utils/timeMath';
import BusinessTime from '../../utils/businessTime';
import UtilsTime from '../../utils/utilsTime';
import CallsModal from './CallsModal';
import MoreIcon from '@material-ui/icons/More';
import SummaryReport from './SummaryReport';
import { DateTime } from 'luxon';
import useApp from '../../hooks/useApp';
import { listToMatrix } from '../../utils/array';
import { Pages } from '@material-ui/icons';

const CallsReportTableList = ({ listItem, filters, callResult, mutate }) => {
  const { first_call_on_time, setState } = useApp();
  const [open, setOpen] = useState(false);

  const [totalList, setTotalList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [pages, setPages] = useState({
    per_page: 10,
    page: 0,
  });

  const handleChangePage = (event, newPage) => {
    setPages({ ...pages, page: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setPages({ ...pages, page: 0, per_page: parseInt(event.target.value, 10) });
    mutate();
  };

  useEffect(() => {
    if (listItem?.length > 0) {
      const listMatrix = listToMatrix(listItem, pages.per_page);
      setTotalList(listMatrix);
      setTotalPages(listMatrix.length);
    }
  }, [listItem, pages.per_page]);

  // useEffect(() => {
  //   setState('loading', !totalList.length);
  // }, [totalList])

  const [callsList, setCallsList] = useState(null);

  const totalTime = (callsDuration) => {
    const total = callsDuration.reduce((total, el) => (total += el.duration), 0);
    return total;
  };

  return (
    <>
      <CallsModal calls={callsList} open={open} setOpen={setOpen} callResult={callResult} />
      <SummaryReport leads={listItem} />

      <Box m={5} />

      <TablePagination
        component="div"
        count={listItem?.length}
        page={pages.page}
        onPageChange={handleChangePage}
        rowsPerPage={pages.per_page}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome do Cliente</TableCell>
              <TableCell>Nome do Corretor</TableCell>
              <TableCell>Criação do Lead</TableCell>
              <TableCell>Captura do Lead</TableCell>
              <TableCell>Dentro da meta de {Number(first_call_on_time) * 60} minutos de atendimento</TableCell>
              <TableCell>Primeira ligação</TableCell>
              <TableCell>Status da primeira ligação</TableCell>
              <TableCell>Duração da primeira ligação</TableCell>
              <TableCell>Tempo de atendimento até a primeira ligação</TableCell>
              <TableCell>Total de ligações</TableCell>
              <TableCell>Tempo total das ligações</TableCell>
              <TableCell>Tempo médio das ligações atendidas</TableCell>
              <TableCell>Número do cliente</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalList[pages.page]?.map((item) => (
              <TableRow key={item.id}>
                <TableCell align="left">{item.id}</TableCell>
                <TableCell>{item.client?.name || '-'}</TableCell>
                <TableCell>{item.broker?.name || '-'}</TableCell>
                <TableCell>{item.create_lead_date?.replaceAll('-', '/') || '-'}</TableCell>
                <TableCell>{item.capture_date?.replaceAll('-', '/') || '-'}</TableCell>
                <TableCell>{item.first_call_on_time === 1 ? 'Ligou no prazo' : 'Ligou fora do prazo'}</TableCell>
                <TableCell>{item.first_call_date?.replaceAll('-', '/') || '-'}</TableCell>
                <TableCell>{callResult?.find((e) => e.var_id == item?.first_call_status)?.value || '-'}</TableCell>
                <TableCell>{msToTime(item.first_call_duration * 1000) || '-'}</TableCell>
                <TableCell>{BusinessTime(item.create_lead_date, item.first_call_date).time}</TableCell>
                <TableCell>
                  {item.calls.length}
                  <IconButton
                    onClick={() => {
                      setCallsList(item.calls);
                      setOpen(true);
                    }}>
                    <MoreIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{msToTime(totalTime(item.calls) * 1000) || '-'}</TableCell>
                <TableCell>
                  {item.calls?.length > 0 ? msToTime((totalTime(item.calls) / item.calls?.length) * 1000) : '-'}
                </TableCell>
                <TableCell>{`${item.client.phone} / ${item.client.cellphone}` || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

CallsReportTableList.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
};

CallsReportTableList.defaultProps = {
  listItem: null,
};

export default CallsReportTableList;
