/* eslint-disable */
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Header, Container, ExportReportWrapper } from '../../styles/shared/styles';
import Box from '@material-ui/core/Box';
import IndicationManagerReportTableList from '../../components/IndicationManagerReportTableList';
import DatePeriodFilter from '../../components/IndicationManagerReportTableList/Filter';
import SummaryReport from '../../components/IndicationManagerReportTableList/SummaryReport';
import useApp from '../../hooks/useApp';
import useSWR from 'swr';
import { TablePagination } from '@material-ui/core';
import { DateTime } from 'luxon';
import { listToMatrix } from '../../utils/array';
import { excelExport } from '../../service/excel.service';
import { fetcher } from '../../service/SWRFetcher';

const InidicationManagerReport = () => {
  const { lead_states, lead_sold, lead_status, setState } = useApp();
  const [totalLeads, setTotalLeads] = useState([]);
  const [leads, setLeads] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [summary, setSummary] = useState([]);

  const [filters, setFilters] = useState({
    per_page: 10,
    page: 0,
  });

  const [reportFilter, setReportFilter] = useState({
    date_ini: DateTime.now().plus({ months: -1 }).toISODate(),
    date_end: DateTime.now().toISODate(),
    partner_id: '',
    insurance_id: '',
    broker: '',
    paginate: null,
  });

  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, page: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, page: 0, per_page: parseInt(event.target.value, 10) });
    mutate();
  };

  const partnersFilter = () => {
    if (!reportFilter.partner_id || reportFilter.partner_id.length === 0) return '';

    let stringPartnersFilter = '';
    reportFilter.partner_id.map((p) => (stringPartnersFilter += `&partner_id[]=${p.id}`));
    return stringPartnersFilter;
  };

  const insuranceFilter = () => {
    if (!reportFilter.insurance_id || reportFilter.insurance_id.length === 0) return '';

    let stringInsuranceFilter = '';
    reportFilter.insurance_id.map((i) => (stringInsuranceFilter += `&insurance_id[]=${i.id}`));
    return stringInsuranceFilter;
  };

  const brokerFilter = () => {
    if (!reportFilter.broker || reportFilter.broker.length === 0) return '';

    let stringBrokerFilter = `&broker=${reportFilter.broker}`;
    return stringBrokerFilter;
  };

  const { data, mutate, isValidating } = useSWR(
    `/leads/indicationManagerReport?date_ini=${reportFilter.date_ini}&date_end=${
      reportFilter.date_end
    }${insuranceFilter()}${partnersFilter()}${brokerFilter()}`,
    fetcher
  );

  useEffect(() => {
    if (data?.length > 2 && !isValidating) {
      const leadsMatrix = listToMatrix(data[0], filters.per_page);
      setTotalLeads(leadsMatrix);
      setTotalPages(leadsMatrix?.length);
      setLeads(leadsMatrix[filters.page]);
      setSummary(data[1]);
    }
  }, [data, isValidating]);

  useEffect(() => {
    setLeads(totalLeads[filters.page]);
  }, [filters.page]);

  useEffect(() => {
    setState('loading', isValidating);
  }, [isValidating]);

  return (
    <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
      <Container>
        <Header>
          <h1>Relatório de Indicação (Corretora)</h1>
        </Header>
        {/* {!!leads && !!summary && (
          <> */}
        <DatePeriodFilter corretora filters={reportFilter} setFilters={setReportFilter} />

        <SummaryReport listItem={summary} title="Relatório de Indicação (Gerência) - Resumo" />

        {!!data && data[0]?.length > 0 && (
          <ExportReportWrapper>
            <button
              className="button"
              onClick={() =>
                excelExport(
                  `/leads/summaryReportExport?date_ini=${reportFilter.date_ini}&date_end=${
                    reportFilter.date_end
                  }${insuranceFilter()}${partnersFilter()}${brokerFilter()}`,
                  'resumo_relatorio_indicação_' + DateTime.now().toFormat('dd_LL_yyyy')
                )
              }>
              Exportar
            </button>
          </ExportReportWrapper>
        )}

        <Box m={5} />

        <TablePagination
          component="div"
          count={data ? data[0]?.length : 0}
          page={filters.page}
          onPageChange={handleChangePage}
          rowsPerPage={filters.per_page}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <IndicationManagerReportTableList
          listItem={leads}
          title="Relatório de Indicação (Gerência)"
          states={lead_states}
          sold={lead_sold}
          status={lead_status}
        />

        {!!data && data[0]?.length > 0 && (
          <ExportReportWrapper>
            <button
              className="button"
              onClick={() =>
                excelExport(
                  `/leads/indicationManagerReportExport?date_ini=${reportFilter.date_ini}&date_end=${
                    reportFilter.date_end
                  }${insuranceFilter()}${partnersFilter()}${brokerFilter()}`,
                  'relatorio_indicação_' + DateTime.now().toFormat('dd_LL_yyyy')
                )
              }>
              Exportar
            </button>
          </ExportReportWrapper>
        )}
      </Container>
    </motion.div>
  );
};

export default InidicationManagerReport;
