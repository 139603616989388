/* eslint-disable camelcase */
import api from './api';

export const CepVerify = async (cep) => {
  try {
    const { data:{data} } = await api.post('/cepverify', { cep });
    return data.items;
  } catch (error) {
    return null;
  }
};
