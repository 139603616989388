/* eslint-disable */
import P from 'prop-types';
import { ButtonMenu } from './styles';
export default function MenuItem({count, name, onClick, className}) {
  return (
    <div>
      <ButtonMenu style={{fontFamily: 'system-ui'}} 
        className={className} variant="contained" onClick={onClick}>
        {name} <span className="count">{count}</span>
      </ButtonMenu>
    </div>
  );
}


MenuItem.propTypes = {
  onClick: P.func,
  name: P.string.isRequired,
  count:P.any,
  className: P.string.isRequired
};

MenuItem.defaultProps = {
  onClick: ()=>{},
  count: 0
};
