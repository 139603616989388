/* eslint-disable */
import { useState } from 'react';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { ButtonMenu, SubMenu, Shadow } from './styles';


export const GrupoCarbel = ({ onChangeFlow, onClick, setOpen, setOpenSubMenu, subCount }) => (
<>

    <GrupoVNVD 
        onChangeFlow={(flow) => onChangeFlow(flow)} 
        onClick={onClick}
        setOpen={setOpen}
        setOpenSubMenu={setOpenSubMenu}
        subCount={subCount}
    />

    <li className="item">
    <Button
        fullWidth
        onClick={() => {
        if (onChangeFlow != null) {
            localStorage.setItem('pessego_attendance_flow', JSON.stringify([1, 2, 3, 4, 5, 6, 7]));
            onChangeFlow([1, 2, 3, 4, 5, 6, 7]);
        }
        onClick(1, 3);
        setOpen(false);
        setOpenSubMenu(false);
        }}>
        VD <span className="count">{subCount?.find((c) => c.id == 1)?.vd ?? 0}</span>
    </Button>
    </li>
</>
);

export const GrupoVNVD = ({ onChangeFlow, onClick, setOpen, setOpenSubMenu, subCount, setChildren }) => (
<>
    <li className="item">
    <Button
        style={{fontFamily: 'system-ui'}}
        fullWidth
        onClick={() => {
        if (onChangeFlow != null) {
            localStorage.setItem('pessego_attendance_flow', JSON.stringify([1, 2, 3, 4, 5, 6, 7]));
            onChangeFlow([1, 2, 3, 4, 5, 6, 7]);
        }
        onClick(1, 1, setChildren);
        setOpen(false);
        setOpenSubMenu(false);
        }}>
        VN <span className="count">{subCount?.find((c) => c.id == 1)?.vn ?? 0}</span>
    </Button>
    </li>
    <li className="item">
    <Button
        fullWidth
        onClick={() => {
        if (onChangeFlow != null) {
            localStorage.setItem('pessego_attendance_flow', JSON.stringify([1, 2, 3, 4, 5, 6, 7]));
            onChangeFlow([1, 2, 3, 4, 5, 6, 7]);
        }
        onClick(1, 2, setChildren);
        setOpen(false);
        setOpenSubMenu(false);
        }}>
        VU <span className="count">{subCount?.find((c) => c.id == 1)?.vu ?? 0}</span>
    </Button>
    </li>
</>
);
    
export const SubChannel = ({ subChildren, user, countChannel, onChangeFlow, onClick, setOpen, setOpenSubMenu }) => (
<>
    {subChildren.map(
    (cac) =>
        !user?.technical_team ||
        (user?.technical_team && user?.technical_team.channels.filter((tt) => tt.id == cac.id).length > 0 && (
        <li key={cac.id} className="item">
            <Button
            fullWidth
            onClick={() => {
                if (onChangeFlow != null) {
                localStorage.setItem('pessego_attendance_flow', JSON.stringify(cac.attendance_flow.status_flow));
                onChangeFlow(cac.attendance_flow.status_flow);
                }
                onClick(cac.id, null);
                setOpen(false);
                setOpenSubMenu(false);
            }}>
            {cac.name} <span className="count">{countChannel(cac) ?? 0}</span>
            </Button>
        </li>
        ))
    )}
</>
);

export const SubChannelGroup = ({ 
    subChildren, user, countChannel, onChangeFlow, onClick, setOpen, setOpenSubMenu, subCount 
    }) => {

        const [anchorEl, setAnchorEl] = useState(false);

        const handleClose = () => {
            setAnchorEl(null);
            //setSubMenuAnchorEl(null);
          };

    return (        
    <>
    
        

   
        <div className="sub-menus-content" style={{width: '300px'}}>
        <ul>
        {subChildren.map(
        (cac) =>
            !user?.technical_team ||
            (user?.technical_team && user?.technical_team.channels.filter((tt) => tt.id == cac.id).length > 0 && (
                
            <li key={cac.id} className="item">

                        {cac.name} <span className="count">{countChannel(cac) ?? 0}</span>
                
                    
                    <GrupoVNVD 
                                  onChangeFlow={(flow) => onChangeFlow(flow)} 
                                  onClick={onClick}
                                  setChildren={cac.id}
                                  setOpen={setOpen}
                                  setOpenSubMenu={setOpenSubMenu}
                                  subCount={subCount}
                                  />
                    
                
            </li>
            ))
        )}
        </ul>
        </div>
    
    </>
    )
    };
    