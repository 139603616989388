/* eslint-disable */
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import MenuItem from '../Dashboard/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import {  AutocompleteField } from '../FormFields';
import { diffTime, msToTime } from '../../utils/timeMath';


export default function CallsModal({ calls, open, setOpen, callResult }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            Histórico de ligações
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Data da ligação</TableCell>
              <TableCell>Duração</TableCell>
              <TableCell>Situação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calls !== null &&
              calls.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.date?.replaceAll('-','/') || '-'}</TableCell>
                    <TableCell>{msToTime(item.duration * 1000) || '-'}</TableCell>
                    <TableCell>{callResult?.find((e) => e.var_id == item?.status)?.value || '-' }</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>


      </Dialog>
    </div>
  );
}

CallsModal.propTypes = {
  open: P.bool.isRequired,
  setOpen: P.func.isRequired,
  calls: P.arrayOf(P.any),
};

CallsModal.defaultProps = {
  calls: null,
};
