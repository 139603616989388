/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { FormGroup } from '../styles';
import { TextField, AutocompleteField } from '../../FormFields';
import { CommonResidenceAndBusinessQuestions } from './commonQuestions';

export const InsuranceBusinessPersonTypeArray = [
    { name: 'Física', id: 1 },
    { name: 'Jurídica', id: 2 },
];

export function BusinessQuestions({
    insuranceBusinessPersonType,
    insuranceBusinessActivityPlace,
    insuranceAmount,
    insuranceOwner,
    insuranceNew,
    onChange,
    onChangeAutoComplete,
    onChangeNumeric
})
{
    
    return (
        <FormGroup dark>
            <AutocompleteField
                medium
                value={insuranceBusinessPersonType || null}
                onChange={onChangeAutoComplete}
                valueText={insuranceBusinessPersonType ? insuranceBusinessPersonType.name : ''}
                options={InsuranceBusinessPersonTypeArray}
                varibleName="insurance_business_personType"
                className="control ms"
                label="Pessoa"
            />
            <TextField
                value={insuranceBusinessActivityPlace || ''}
                onChange={onChange}
                varibleName="insurance_business_activityPlace"
                placeholder="Ramo de atividade"
                className="control ms"
                label="Ramo de Atividade"
            />
            <CommonResidenceAndBusinessQuestions
                insuranceAmount={insuranceAmount}
                insuranceOwner={insuranceOwner}
                insuranceNew={insuranceNew}
                onChangeNumeric={onChangeNumeric}
                onChangeAutoComplete={onChangeAutoComplete}
            />
        </FormGroup>
    )
};

BusinessQuestions.propTypes = {
    insuranceBusinessPersonType: P.objectOf(P.any).isRequired,
    insuranceBusinessActivityPlace: P.string.isRequired,
    insuranceAmount: P.number.isRequired,
    insuranceOwner: P.objectOf(P.any).isRequired,
    insuranceNew: P.objectOf(P.any).isRequired,
    onChange: P.objectOf(P.any).isRequired,
    onChangeAutoComplete: P.objectOf(P.any).isRequired,
    onChangeNumeric: P.objectOf(P.any).isRequired,
}

BusinessQuestions.defaultProps = {
    
}