/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Header, Container, ExportReportWrapper } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import Box from '@material-ui/core/Box';
import DatePeriodFilter from '../../components/NewInsurancesReportTableList/Filter';
import SummaryReport from '../../components/NewInsurancesReportTableList/SummaryReport';
import useApp from '../../hooks/useApp';
import api from '../../service/api';
import { DateTime } from 'luxon';
import { excelExport } from '../../service/excel.service';
import { Cancelamento } from '../../context/Channels';

const NewInsurancesReport = () => {
  const { setState } = useApp();
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
    broker_id: '',
    partner_id: '',
    insurance_id: '',
    technical_team: null,
  });

  const brokerFilter = () => {
    if (!filters.broker_id || filters.broker_id.length === 0) return '';

    let stringBrokerFilter = '';
    filters.broker_id.map((b) => (stringBrokerFilter += `&broker_id[]=${b.id}`));

    return stringBrokerFilter;
  };

  const partnerFilter = () => {
    if (!filters.partner_id || filters.partner_id.length === 0) return '';

    let stringPartnerFilter = '';
    filters.partner_id.map((p) => (stringPartnerFilter += `&partner_id[]=${p.id}`));

    return stringPartnerFilter;
  };

  const getChannelFromTechTeam = () => {
    if (!filters.technical_team || filters.technical_team.length === 0) return '';

    const renovationChannels = filters.technical_team.channels.filter((channel) => channel.id > Cancelamento);

    let stringChannelFilter = '';
    renovationChannels.map((c) => (stringChannelFilter += `&channel_id[]=${c.id}`));
    return stringChannelFilter;
  };

  const GetNewInsurancesReport = async () => {
    const response = await api.get(`/newInsurances?start_date=${filters.date_ini}
      &end_date=${filters.date_end}
      ${brokerFilter()}
      ${partnerFilter()}
      ${getChannelFromTechTeam()}`);

    setData(response.data.data);    
  }


  return (
    <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
      <Container>
        <Header>
          <h1>Diagnóstico de Venda de Seguros Novos</h1>
        </Header>


        <DatePeriodFilter 
              filters={filters} 
              setFilters={setFilters} 
              filterClick={() => GetNewInsurancesReport()}
            />

        {data && (
          <>
            <Box m={5} />
            <SummaryReport dataItems={data} />

            <ExportReportWrapper>
              <button
                className="button"
                onClick={() =>
                  excelExport(
                    `newInsurances/newInsurancesReportExport?start_date=${filters.date_ini}&end_date=${filters.date_end}`,
                    'relatorio_de_diagnostico' + DateTime.now().toFormat('dd_LL_yyyy')
                  )
                }>
                Exportar
              </button>
            </ExportReportWrapper>
          </>
        )}
      </Container>
    </motion.div>
  );
};

export default NewInsurancesReport;
