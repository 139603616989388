/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-return-assign */
/* eslint eqeqeq: "off", curly: "error" */
/* eslint no-const-assign: "off", curly: "error" */
/* eslint assignment: "off", curly: "error" */
/* eslint assign: "off", curly: "error" */
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import LeadForm from '../../components/LeadForm';
import { Header } from '../../styles/shared/styles';
import { LogoDiv, Container, Leads, LeadsContainer, ButtonBox } from './styles';
import { MainContext } from '../../context/ApiContext';
import LeadCard from '../../components/CustomerMat/LeadCard';
import Navbar from '../../components/CustomerMat/Navbar';
import {LogoImg} from '../../components/Logo';
import FullScreenDialog from '../../components/FullScreenDialog';
import { Backdrop, Fade, makeStyles, Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import { listToMatrix } from '../../utils/array';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useSWR from 'swr';
import { fetcher } from '../../service/SWRFetcher';
import LeadView from '../../components/LeadView';
import { removeMask } from '../../utils/masks';
import { useAuth } from '../../hooks/useAuth';
import useApp from '../../hooks/useApp';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '60%',
    height: '80%',
    borderRadius: 8,
    minWidth: '500px',
    minHeight: '300px',
    padding: '24px 32px',
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
  },
}));

const CustomerMat = () => {
  const { user } = useAuth();
  const { refresh_lead_seconds, lead_status, insurances, setState } = useApp();
  const {
    // values,
    // getters,
    // auth: { getUserInfo },
  } = useContext(MainContext);
  const { height, width } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [openLeadView, setOpenLeadView] = useState(false);
  const [lead, setLead] = useState();
  const [leadCount, setLeadCount] = useState();
  const [lockTabs, setLockTabs] = useState(false);
  const [convertedLeads, setConvertedLeads] = useState(null);
  const [schedulesLead, setSchedulesLead] = useState({ count: null, expired: false });
  const [leadToEdit, setLeadToEdit] = useState(null);
  const [isSchedulable, setIsSchedulable] = useState(false);
  const [leadsListCount, setLeadsListCount] = useState(false);
  const [leadsList, setLeadsList] = useState(false);
  const [filter, setFilter] = useState({
    lead_id: '',
    status: 1,
    state: 1,
    name: '',
    channel: 1,
    insurance: 1,
    partner: null,
    schedule: false,
    sevenDays: false,
    onlySchedules: false,
    start_date: '',
    end_date: '',
    clientName: '',
    broker: '',
    cpf: '',
    isStatusFilterButton: 0,
  });
  const { data: leadsCount, mutate: mutateCount } = useSWR(`/leads/list-count`, fetcher, {
    refreshInterval: (refresh_lead_seconds || 1) * 1000 ?? 600000,
  });
  const {
    data: leads,
    mutate: mutateList,
    isValidating,
    error,
  } = useSWR(
    `/leads/list-broker?` +
      `channel_id=${filter.channel || ''}` +
      `&client_name=${filter.clientName || ''}` +
      `&client_cpf=${removeMask(filter.cpf) || ''}` +
      `&broker=${filter.broker || ''}` +
      `&status=${filter.status || ''}` +
      `&schedule=${filter.schedule ? 1 : ''}` +
      `&saven_days=${filter.sevenDays ? 1 : ''}` +
      `&only_schedules=${filter.onlySchedules ? 1 : ''}` +
      `&status=${filter.status || ''}` +
      `&state=${filter.state || ''}` +
      `&start_date=${filter.start_date || ''}` +
      `&end_date=${filter.end_date || ''}` +
      `&partner_id=${filter.partner || ''}` +
      `&lead_id=${filter.lead_id || ''}` +
      `&isStatusFilterButton=${filter.isStatusFilterButton || ''}` +
      `&customer_mat=1`,
    fetcher,
    { refreshInterval: (refresh_lead_seconds || 1) * 1000 ?? 600000 }
  );

  function compare(a, b) {
    if (a.modified_at < b.modified_at) {
      return 1;
    }
    if (a.modified_at > b.modified_at) {
      return -1;
    }
    return 0;
  }

  const { data: older, mutate: mutateOlder } = useSWR(`/leads/older`, fetcher, {});
  const [selectedAttendanceFlow, setSelectedAttendanceFlow] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [result, setResults] = useState(null);

  useEffect(() => {
    const attendantFlow = JSON.parse(localStorage.getItem('pessego_attendance_flow'));
    if (attendantFlow) setSelectedAttendanceFlow(attendantFlow);
    else {
      setSelectedAttendanceFlow([1, 2, 3, 4, 5, 6, 7]);
      localStorage.setItem('pessego_attendance_flow', JSON.stringify([1, 2, 3, 4, 5, 6, 7]));
    }
  }, []);

  useEffect(() => {
    if (leadsCount) {
      setLeadsListCount(leadsCount);
    }
  }, [leadsCount]);

  useEffect(() => {
    if (leads) {
      setLeadsList(leads);
    }
  }, [leads]);

  const leadsFilteredCount = () => {
    lead_status?.forEach((status) => {
      setLeadCount((state) => ({ ...state, [status.var_id]: countFilteredStatus(status.var_id) }));
    });
  };

  const handleEditLead = (ld) => {
    mutateList();
    mutateCount();
    setLeadToEdit(ld);
    setOpen(true);
  };
  const handleViewLead = (ld) => {
    setLead(ld);
    setOpenLeadView(true);
  };

  function countStatus(sts) {
    const count = leadsListCount?.leads_status_count?.find((count) => count.status == sts);
    if (sts == 6) return count?.news ?? 0;
    return count?.total ?? 0;
  }

  function countFilteredStatus(sts) {
    let count = 0;
    leadsList?.leads.forEach((lead) => {
      if (lead.status == sts) count++;
    });
    return count;
  }

  const nextSchedulers = () => {
    let expired = false;
    const expiredNum = leadsListCount?.leads_status_count?.reduce(
      (acc, cur) => (acc += Number(cur.late_scheduling)),
      0
    );
    if (expiredNum > 0) expired = true;
    setSchedulesLead({ count: expiredNum, expired });
  };

  useEffect(() => {
    nextSchedulers();
    setLockTabs(true);
  }, [leadsList]);

  const OlderCalc = (ls) => {
    let older = true;
    leadsList?.leads.map((l) => {
      if (l.channel_id != ls.channel_id) return false;
      if (l.state != ls.state) return false;
      if (l.insurance_id != ls.insurance_id) return false;
      if (l.create_lead_date < ls.create_lead_date) {
        older = false;
      }
    });

    return older;
  };

  //#region Transforma o array de Leads filtrado em um array bidimencional e cria um Cell para a virtualizaçao da lead
  useEffect(() => {
    if (leadsList?.leads) {
      let orderedLeads = leadsList?.leads.sort((a, b) => {
        return a.modified_at < b.modified_at ? -1 : 1;
      });
      const matrix = listToMatrix(leadsList?.leads, width > 1366 ? 4 : 3);
      setConvertedLeads(matrix);

      const compareId = leadsList.leads.reduce(function (results, lead) {
        (results[lead.id] = results[lead.id] || []).push(lead);
        return results;
      }, {});

      const compareChannel = Object.values(compareId).sort((a, b) => {
        return a[0].channel_id < b[0].channel_id ? -1 : 1;
      });

      setResults(compareChannel);

      if (lockTabs == false) {
        leadsFilteredCount();
      }
    }
  }, [leadsList, filter]);

  const Cell = ({ item, canWork }) => (
    <>
      <div style={{ width: 400, height: 500 }}>
        <LeadCard
          canWork={canWork}
          older={older}
          lead={item}
          key={item.id}
          onClick={handleEditLead}
          onClickView={handleViewLead}
          user={JSON.stringify(user)}
          mutate={() => {
            mutateCount();
            mutateList();
          }}
        />
      </div>
    </>
  );

  const classes = useStyles();

  return (
    <>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Container>
          <div className="leadBar">
            <Header>
              <h1>Esteira do Cliente</h1>
            </Header>
          </div>
        </Container>
        <FullScreenDialog open={open} setOpen={setOpen}>
          <LeadForm
            type="edit"
            leadCurrent={leadToEdit}
            setOpen={setOpen}
            mutate={() => {
              mutateList();
              mutateCount();
            }}
          />
        </FullScreenDialog>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openLeadView}
          onClose={() => setOpenLeadView(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={openLeadView}>
            <div className={classes.paper}>
              <LeadView lead={lead} />
            </div>
          </Fade>
        </Modal>
        {leadsList && leadsListCount && (
          <>
            <Header>
              <div className="container">
                <div className="content">
                  <LogoDiv>
                    <Link to="/">
                      <LogoImg />
                    </Link>
                  </LogoDiv>
                  <div className="menu"></div>
                </div>
              </div>
            </Header>
            <Container>
              <Navbar
                clickSevenDays={() => setFilter({ ...filter, sevenDays: !filter.sevenDays })}
                clickOnlySchedules={() => setFilter({ ...filter, onlySchedules: !filter.onlySchedules })}
                checkValue={filter.sevenDays}
                checkValueSchedules={filter.onlySchedules}
                clickFilter={(name, cpf, broker, start_date, end_date, lead_id) => {
                  if (name || cpf || broker || start_date || end_date || lead_id) {
                    if (name == '' && lead_id == '' && cpf == '') {
                      return toast.error('O nome, Núm. do Lead ou CPF são obrigatórios!');
                    }
                    if (name != '' && name.length < 5) {
                      return toast.error('O nome deve conter no mínimo 5 caracteres!');
                    }
                    setFilter({
                      ...filter,
                      lead_id,
                      clientName: name,
                      broker,
                      cpf,
                      start_date,
                      end_date,
                      state: null,
                      status: null,
                      channel: null,
                      partner: null,
                      insurance: null,
                      schedule: false,
                      name: 'Resultados',
                      sevenDays: false,
                      onlySchedules: false,
                    });
                    leadsFilteredCount();
                  }
                }}
                clearFilter={() =>
                  setFilter({
                    status: 1,
                    state: 1,
                    name: '',
                    channel: 1,
                    insurance: 1,
                    partner: null,
                    schedule: false,
                    sevenDays: false,
                    onlySchedules: false,
                    start_date: '',
                    end_date: '',
                    clientName: '',
                    broker: '',
                    cpf: '',
                    isStatusFilterButton: 0,
                  })
                }
                clickFilterBtn={(name, value) => {
                  if (value === 0)
                    setFilter({ ...filter, partner: null, insurance: null, channel: null, [name]: null });
                  else setFilter({ ...filter, partner: null, insurance: null, channel: null, [name]: value });
                }}
                clearSelected={() => setIsSelected(false)}
                scheduler={isSchedulable}
                setLockTabs={setLockTabs}
              />

              {result &&
                Object.keys(result).length > 0 &&
                result.map((lead) => (
                  <div style={{ marginBottom: '20px' }}>
                    <Header>
                      <h3>{lead[0].channel.name}</h3>
                    </Header>
                    <ScrollMenu>
                      {lead.map((item) => {
                        const tmp = [...lead];
                        return <Cell item={item} canWork={tmp.sort(compare)[0].modified_at == item.modified_at} />;
                      })}
                    </ScrollMenu>
                  </div>
                ))}
            </Container>
          </>
        )}
      </motion.div>
    </>
  );
};
export default CustomerMat;
