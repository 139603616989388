/* eslint-disable */
import React from 'react';
import { useContext, useState, useEffect } from 'react';
import { MainContext } from '../../../context/ApiContext';
import { Nav, NavbarForm } from './styles';
import { DateTime } from 'luxon';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import useApp from '../../../hooks/useApp';
import useSWR from 'swr';
import { fetcher } from '../../../service/SWRFetcher';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '200px',
  },
}));

const DatePeriodFilter = ({ userId, filters, setFilters }) => {
  const { partners: allPartners, insurances } = useApp();
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [dates, setDates] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
  });
  const [partners, setPartners] = useState([]);
  const [insurance, setInsurance] = useState([]);
  const [brokerId, setBrokerId] = useState();

  const { values, getters, creators } = useContext(MainContext);

  const classes = useStyles();

  var partners_ids = [];

  const { data: users } = useSWR(`/users`, fetcher);

  function handlePartners(e, v) {
    e.preventDefault();
    setPartners([]);
    v.forEach((element) => {
      setPartners([...partners, element.id]);
    });
  }

  function handleSubmit(event, getters) {
    event.preventDefault();

    let filters = {
      date_ini: event.target.elements[0].value,
      date_end: event.target.elements[1].value,
      partner_id: `[${partners.join(',')}]`,
      insurance_id: insurance.map((i) => ({ id: i.id })) || null,
      broker_id: brokerId?.map((b) => ({ id: b.id })) || null,
      paginate: null,
    };
    setFilters(filters);
  }

  return (
    <Nav>
      <div className="filter">Filtros</div>

      <NavbarForm>
        <form className="form" onSubmit={(e) => handleSubmit(e, getters)}>
          <div className="inputs">
            <div className="date_ini">
              <input
                type="date"
                name="date_ini"
                className="input-form"
                placeholder="Data Inicial"
                value={dates.date_ini}
                onChange={(e) => setDates(e)}
              />
            </div>
            <div className="date_end">
              <input
                type="date"
                name="date_end"
                className="input-form"
                placeholder="Data Final"
                value={dates.date_end}
                onChange={(e) => setDates(e)}
              />
            </div>
          </div>

          {/* https://material-ui.com/pt/components/autocomplete/ */}
          <Autocomplete
            multiple
            // className="input-form"
            // id="tags-standard"
            options={allPartners}
            getOptionLabel={(option) => option?.name}
            onChange={(e, v) => handlePartners(e, v)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Empresa" />
            )}
          />

          <Autocomplete
            multiple
            options={insurances ? insurances.filter((i) => i.id <= 3) : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setInsurance(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Tipos de Estoque" />
            )}
          />

          <Autocomplete
            multiple
            options={users ? users : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setBrokerId(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Corretor" />
            )}
          />

          <div>
            <button className="button" type="submit">
              Filtrar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
