/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import styled from 'styled-components';

export const Card = styled.article`
  opacity: ${(props) => (props.schedule ? 0.5 : 1)};
  height: calc(100% - 50px);
  padding: 2px 15px 15px 15px;
  display: flex;
  color: #2c3e50;
  flex-wrap: wrap;
  border-radius: 8px;
  flex-direction: column;
  background-color: #e7e7e7;
  align-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(212, 227, 232, 0.6);
  margin: 6px;
  font-family: system-ui;

  .flex-item {
    flex: 1 1 auto;
  }
  .button {
    background-color: green;
  }
  && button {
    background: rgb(250 156 18);
    color: white;
    transition: all ease-in-out 300ms;
    :hover {
      background: #ff9666;
      color: black;
    }
  }

  .finalizado {
    margin: 8px 0;
  }
`;

export const CardHead = styled.div`
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: space-between;
  .lead-number {
    display: flex;
    color: rgb(250 156 18);
    line-height: 0;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    img {
      width: 24px;
      margin-right: 10px;
    }
  }
  .date-time {
    display: flex;
    align-items: center;

    img {
      font-size: 14px;
      color: #2c3e50;
      line-height: 1;
      box-sizing: border-box;
      border: 0;
      vertical-align: middle;
      height: auto;
      display: block;
      max-width: 100%;
      width: 16px;
      margin-right: 6px;
    }
    .date {
      display: inherit;
      align-items: inherit;
    }
    .time {
      display: inherit;
      align-items: inherit;
      margin-left: 12px;
    }
  }

  .on-time {
    /* background-color: ${(props) => (props.indication ? '#2ecc71' : '#e74c3c')}; */
    background: ${(props) =>
      props.indication ? 'linear-gradient(45deg, #91e02a, #2ecc71)' : 'linear-gradient(45deg, #e74c3c, #e58200)'};
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin-right: 8px;
  }
`;

export const Name = styled.div`
  .name {
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 4px;
    letter-spacing: -1px;
    text-transform: capitalize;
    max-height: 62px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cpf {
    margin-bottom: 0px;
    color: #7f8c8d;
  }
`;

export const ChipStatus = styled.div`
  .status-chip {
    border: none;
    height: auto;
    margin-top: 0px;
    font-size: 13px;
    font-weight: 300;
    padding: 6px 15px;
    border-radius: 4px;
    margin-bottom: 8px;
    font-family: system-ui;
    .MuiChip-label {
      padding: 0;
    }
  }
`;

export const Info = styled.div`
  height: 184px;
  max-width: 100%;
  margin-bottom: 8px;
  overflow: auto;
  .status,
  .chanel,
  .type,
  .seller {
    font-size: 16px;
    strong {
      color: #95a5a6;
      font-weight: 500;
      letter-spacing: -0.3px;
    }
  }
  .type {
    margin-bottom: 4px;
  }
  .partner {
    line-height: 1;
    margin-top: 8px;
    font-size: 13px;
    padding: 4px 8px;
    font-weight: 500;
    border-radius: 4px;
    margin-bottom: 12px;
    display: inline-flex;
    background-color: #eef4f6;
  }
  .agendado {
    color: ${(props) => (props.expired ? 'red' : 'inherit')};
  }
`;

export const Status = styled.div`
  margin-top: 12px;
  strong {
    margin-bottom: 4px;
    display: inline-block;
  }
  select {
    width: 100%;
    color: #333;
    border: none;
    outline: none;
    display: block;
    padding: 6px 12px;
    user-select: none;
    border-radius: 8px;
    border-bottom: none;
    transition: all 0.2s ease;
    background-color: #f1f1f1;
    border: 2px solid transparent;
  }
  .select-style::before {
    display: none;
  }
  .status-chip {
    font-weight: 700;
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  .seller {
    margin-bottom: 8px;
  }
  .button {
    font-weight: 500;
    font-family: system-ui;
    + .button {
      margin-top: 8px;
    }
  }
`;

export const Progress = styled.div`
  width: 100%;
  min-height: 1px;
  position: relative;
  display: flex;
  color: #7f8c8d;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .progress {
    overflow: hidden;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 16px;
    margin-top: 10px;
    margin-bottom: -5px;
    background-color: #f1f1f1;

    .progress-bar {
      float: left;
      height: 100%;
      line-height: 20px;
      color: #fff;
      text-align: center;
      background-color: #337ab7;
      transition: width 0.6s ease;
      font-size: 14px;
      font-weight: 500;
      box-shadow: none;
      /* background-image: linear-gradient(to right, #2ecc71 0%, #f1c40f 50%, #e74c3c 100%); */
      background-image: ${(props) => {
        if (props.Progress < 20) return `linear-gradient(to right, #2ecc71 0%, #91e02a 100%)`;
        if (props.Progress < 50) return `linear-gradient(to right, #2ecc71 0%, #f1c40f 100%)`;
        return `linear-gradient(to right, #2ecc71 0%, #f1c40f 50%, #e74c3c 100%)`;
      }};
    }
  }
`;
