import { DateTime } from "luxon";

export function maskPercent(paramNumber) {
  return new Intl.NumberFormat("pt-BR", {
    style: "percent",
    minimumFractionDigits: 2,
  }).format(paramNumber);
}

export function maskCurrency(paramNumber) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(Number(paramNumber));
}

export function maskDate(date) {
  if(date && date != '') {
    let formatedDate = date;
    if (!date?.includes('T')) {
      if (date?.includes(' '))
        formatedDate = date?.replace(' ', 'T');
    };

    return DateTime.fromISO(formatedDate).setLocale('pt-BR').toLocaleString(DateTime.DATE_SHORT);
  }
  return '';
}

export function maskDateTime(date) {
  if (date && date != '') {
    let formatedDate = date;
    if (!date?.includes('T')) {
      if (date?.includes(' '))
        formatedDate = date?.replace(' ', 'T');
    };

    return DateTime.fromISO(formatedDate).setLocale('pt-BR').toLocaleString(DateTime.DATETIME_SHORT);
  }
  return '';
}

export const removeMask = (value) => (value.length > 0 ? value.replaceAll(/[^0-9]/g, "") : value);

export function maskCpf(cpf) {
  if (cpf) {
    let cpfFormatado = cpf.replace(/\D/g, '');
    cpfFormatado = cpfFormatado.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return cpfFormatado;
  }

  return '';
}