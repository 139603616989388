import styled from 'styled-components';
import { TableCell, TableRow } from '@material-ui/core';

export const TableCommissionReport = styled.table`
    border: 0px;
    background-color: #fff;
`;

export const H3 = styled.h3`
    color: #444;
    width: 'max-content'
`;

export const H4 = styled.h4`
    color: #444;
    text-align: center;
`;

export const H5 = styled.h5`
    color: #000;
`;

export const H6 = styled.h6`
    color: #444;
`;

export const TableRowHead = styled(TableRow)`
    background-color: #ccc;
    height: 50px;
`;

export const TableCellCR = styled(TableCell)`
    background-color: #ccc;
`;

export const StyledTableCell = styled(TableCell)`

    .backSilver {
        background-color: #ccc;
    }
`;