import { useState } from 'react';
import { Checkbox, FormControlLabel, IconButton, TextField, Dialog,
  Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import P from 'prop-types';
import { createSchema } from '../../service/commissionSchema.service';


export default function CommissionSchemaAdd({ open, setOpen, mutate }) {
    const [values, setValues] = useState({
        name: '',
        description: '',
        status: false
    });

    const handleClose = () => {
        setOpen(false);
    };
          
    const submit = async (e) => {
      e?.preventDefault();
    
      const addCommission = {
        name: values.name,
        description: values.description,
        status: values.status,
      };

      try {
        await createSchema(addCommission);
  
        toast.success('Nova tabela adicionada!');
        mutate();
      } catch (error) {
        toast.error('Erro ao adicionar a nova tabela');
      }
  
      handleClose();
    };

      
    return (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="md">
            <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Nova tabela de comissão
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle >
            <form onSubmit={submit}>
              <DialogContent>

              <TextField
                autoFocus
                margin="dense"
                label="Tabela"
                type="name"
                value={values.name}
                fullWidth
                required
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />

              <TextField
                margin="dense"
                label="Descrição"
                type="text"
                value={values.description}
                fullWidth
                onChange={(e) => setValues({ ...values, description: e.target.value })}
              />              

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.status}
                    onChange={(e) => setValues({ ...values, status: e.target.checked })}
                    color="default"
                  />
                }
                label="Tabela ativa"
              />

              </DialogContent>
              <DialogActions style={{position: 'sticky', bottom: 0, backgroundColor:'white', width:'100%',}}>
                <Button onClick={handleClose} color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Adicionar
                </Button>
              </DialogActions>
    
            </form>
          </Dialog>
        
        </div>
      );
}
    
CommissionSchemaAdd.propTypes = {
    open: P.bool.isRequired,
    setOpen: P.func.isRequired,
    mutate: P.func.isRequired,
  };