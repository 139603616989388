/* eslint-disable */
import { createContext, useReducer } from 'react';
import P from 'prop-types';
import { reducer } from './reducer';
import { GlobalState } from './data';
import { dataAll, dataCreate, dataDelete, dataEdit, dataFilter } from '../../service/data.service';
import api from '../../service/api';

export const MainContext = createContext();

export const AppContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, GlobalState);
  const {
    user,
    users,
    clients,
    channels,
    insurances,
    partners,
    leads,
    leads_summary,
    lead_states,
    lead_sold,
    lead_status,
    lead_lost_sale,
    lead_calls_result,
    lead_contact_type,
    lead_service_status,
    competitor_brokers,
    technical_teams,
    lost_sale_report,
    time_to_capture_lead,
    time_to_finish_lead,
    indication_on_time,
    first_call_on_time,
    finished_leads_days_to_show,
    endorsement_leads_days_to_show,
    lost_leads_last_year_days_to_show,
    json_response,
    refresh_lead_seconds,
    lead_broker_configurations,
    leadInsurances
  } = state;

  // GETTERS
  const getUsers = () => {
    dataAll('/users').then((data) => dispatch({ type: 'GET_USERS', payload: data }));
  };
  const getChannels = () => {
    dataAll('/channels').then((data) => dispatch({ type: 'GET_CHANNELS', payload: data }));
  };
  const getInsurances = () => {
    dataAll('/insurancesNoAuth').then((data) => dispatch({ type: 'GET_INSURANCES', payload: data }));
  };
  const getLeadInsurances = () => {
    dataAll('/lead-insurances').then((data) => dispatch({ type: 'GET_LEAD_INSURANCES', payload: data }));
  };
  const getPartners = () => {
    dataAll('/partners').then((data) => dispatch({ type: 'GET_PARTNERS', payload: data }));
  };
  const getCallsResult = () => {
    dataAll('/callsResult').then((data) => dispatch({ type: 'GET_CALLSRESULT', payload: data }));
  };
  const getLeadVerification = async (filter) => {
    const {
      data: { data },
    } = await api.post('/leadVerification', filter);
    dispatch({ type: 'GET_LEADVERIFICATION', payload: data });
  };
  const getClients = () => {
    dataAll('/clients').then((data) => dispatch({ type: 'GET_CLIENTS', payload: data }));
  };
  const getLeads = async (filter) => {
    const {
      data: { data },
    } = await api.post('/leads/list', filter);
    dispatch({ type: 'GET_LEADS', payload: data });
  };
  const getDashboard = async () => {
    const { data } = await api.get('/dashboard');
    dispatch({ type: 'GET_ALL', payload: data });
  };

  const getAll = () => {
    getDashboard();
    getLeads();
  };
  const getIndicationReportLeads = async (filters) => {
    const { data } = await getUserInfo();
    var myData = [];
    await dataAll(`/leads/indicationReport/${data.id}`, { params: filters }).then((data) => (myData = data));
    dispatch({ type: 'GET_LEADS', payload: myData[0] });
    dispatch({ type: 'GET_LEAD_STATES', payload: myData[1] });
    dispatch({ type: 'GET_LEAD_STATUS', payload: myData[2] });
    dispatch({ type: 'GET_LEAD_SOLD', payload: myData[3] });
    dispatch({ type: 'GET_LEAD_LOST_SALE', payload: myData[4] });
  };

  const getIndicationManagerReportLeads = async (filters) => {
    const { data } = await getUserInfo();
    var myData = [];
    await dataAll(`/leads/indicationManagerReport`, { params: filters }).then((data) => (myData = data));
    dispatch({ type: 'GET_LEADS', payload: myData[0] });
    dispatch({ type: 'GET_LEADS_SUMMARY', payload: myData[1] });
    dispatch({ type: 'GET_LEAD_STATES', payload: myData[2] });
    dispatch({ type: 'GET_LEAD_STATUS', payload: myData[3] });
    dispatch({ type: 'GET_LEAD_SOLD', payload: myData[4] });
    dispatch({ type: 'GET_LEAD_LOST_SALE', payload: myData[5] });
  };

  const getIndicationReportGerVeic = async (filters) => {
    const { data } = await getUserInfo();
    var myData = [];
    await dataAll(`/leads/indicationReportGerVeic`, { params: filters }).then((data) => (myData = data));
    dispatch({ type: 'GET_LEADS', payload: myData[0] });
    dispatch({ type: 'GET_LEADS_SUMMARY', payload: myData[1] });
    dispatch({ type: 'GET_LEAD_STATES', payload: myData[2] });
    dispatch({ type: 'GET_LEAD_STATUS', payload: myData[3] });
    dispatch({ type: 'GET_LEAD_SOLD', payload: myData[4] });
    dispatch({ type: 'GET_LEAD_LOST_SALE', payload: myData[5] });
  };

  const getLostSaleReport = async (filters) => {
    const { data } = await getUserInfo();
    var myData = [];
    await dataAll(`/leads/lostSaleReport`, { params: filters }).then((data) => (myData = data));

    dispatch({ type: 'GET_LOST_SALE_REPORT', payload: myData[0] });
    dispatch({ type: 'GET_LEAD_LOST_SALE', payload: myData[1] });
    dispatch({ type: 'GET_COMPETITOR_BROKERS', payload: myData[2] });
  };

  // CREATORS
  const createUser = async (newData) => {
    const response = await dataCreate('/users', newData);
    getUsers();
    return response;
  };
  const createChanel = async (newData) => {
    const response = await dataCreate('/channels', newData);
    getChannels();
    return response;
  };
  const createInsurance = async (newData) => {
    const response = await dataCreate('/insurances', newData);
    getInsurances();
    return response;
  };
  const createPartner = async (newData) => {
    const response = await dataCreate('/partners', newData);
    getPartners();
    return response;
  };
  const createClient = async (newData) => {
    const response = await dataCreate('/clients', newData);
    // getClients();
    return response;
  };
  const createLead = async (newData) => {
    const response = await dataCreate('/leads', newData);
    return response;
  };
  const createTechnicalTeam = async (newData) => {
    const response = await dataCreate('/technical-team', newData);
    getAll();
    return response;
  };
  const createLeadBrokerConfig = async (newData) => {
    const response = await dataCreate('/lead-broker-config', newData);
    return response;
  };

  // EDITORS
  const editUser = async (newData, dataID) => {
    const response = await dataEdit('/users', newData, dataID);
    getUsers();
    return response;
  };
  const editChanel = async (newData, dataID) => {
    const response = await dataEdit('/channels', newData, dataID);
    getChannels();
    return response;
  };
  const editInsurance = async (newData, dataID) => {
    const response = await dataEdit('/insurances', newData, dataID);
    getInsurances();
    return response;
  };
  const editPartner = async (newData, dataID) => {
    const response = await dataEdit('/partners', newData, dataID);
    getPartners();
    return response;
  };
  const editClient = async (newData, dataID) => {
    const response = await dataEdit('/clients', newData, dataID);
    // getClients();
    return response;
  };
  const editLead = async (newData, dataID) => {
    const response = await dataEdit('/leads', newData, dataID);
    // getLeads();
    return response;
  };
  const editTechnicalTeam = async (newData, dataID) => {
    const response = await dataEdit('/technical-team', newData, dataID);
    getAll();
    return response;
  };
  const editLeadBrokerConfig = async (newData, dataID) => {
    const response = await dataEdit('/lead-broker-config', newData, dataID);
    return response;
  };

  // DELETERS
  const deleteUser = (dataID) => {
    dataDelete('/users', dataID).then(getUsers());
  };
  const deleteChanel = (dataID) => {
    dataDelete('/channels', dataID).then(getChannels());
  };
  const deleteInsurance = (dataID) => {
    dataDelete('/insurances', dataID).then(getInsurances());
  };
  const deletePartner = (dataID) => {
    dataDelete('/partners', dataID).then(getPartners());
  };
  const deleteClient = (dataID) => {
    dataDelete('/clients', dataID).then(getClients());
  };
  const deleteLead = (dataID) => {
    dataDelete('/leads', dataID).then(getLeads());
  };
  const deleteTechnicalTeam = (dataID) => {
    dataDelete('/technical-team', dataID).then(getAll());
  };
  const deleteLeadBrokerConfig = (dataID) => {
    dataDelete('/lead-broker-config', dataID).then(getAll());
  };

  // AUTH
  const register = (newUser) => api.post(`/register`, newUser);
  const getUserInfo = () => {
    const tenantId = localStorage.getItem("tenantId");
    return api.get(`/tokenconfirm?tenantId=${tenantId}`)
  };

  // Mail
  const sendMailVerify = (mailData) => api.post(`/email/verify`, mailData);
  const sendMailRedefine = (mailData) => api.post(`/email/redefine`, mailData);

  // Verify
  const verify = (verifyData) => api.post(`/verify`, verifyData);
  const getVerifyUser = () => api.get(`/verifyuser`);

  return (
    <MainContext.Provider
      value={{
        values: {
          users,
          clients,
          channels,
          insurances,
          partners,
          leads,
          leads_summary,
          lead_states,
          lead_sold,
          lead_status,
          lead_lost_sale,
          lead_calls_result,
          lead_contact_type,
          lead_service_status,
          competitor_brokers,
          technical_teams,
          lost_sale_report,
          time_to_capture_lead,
          time_to_finish_lead,
          indication_on_time,
          first_call_on_time,
          finished_leads_days_to_show,
          endorsement_leads_days_to_show,
          lost_leads_last_year_days_to_show,
          json_response,
          refresh_lead_seconds,
          lead_broker_configurations,
          leadInsurances
        },
        getters: {
          getUsers,
          getChannels,
          getInsurances,
          getPartners,
          getCallsResult,
          getClients,
          getLeads,
          getAll,
          getDashboard,
          getIndicationReportLeads,
          getIndicationManagerReportLeads,
          getIndicationReportGerVeic,
          getLostSaleReport,
          getLeadVerification,
          getLeadInsurances,
        },
        creators: {
          createUser,
          createChanel,
          createInsurance,
          createPartner,
          createClient,
          createLead,
          createTechnicalTeam,
          createLeadBrokerConfig,
        },
        editors: {
          editUser,
          editChanel,
          editInsurance,
          editPartner,
          editClient,
          editLead,
          editTechnicalTeam,
          editLeadBrokerConfig,
        },
        deleters: {
          deleteUser,
          deleteChanel,
          deleteInsurance,
          deletePartner,
          deleteClient,
          deleteLead,
          deleteTechnicalTeam,
          deleteLeadBrokerConfig,
        },
        auth: {
          user,
          register,
          getUserInfo,
        },
        mail: {
          sendMailVerify,
          sendMailRedefine,
        },
        verify: {
          verify,
          getVerifyUser,
        },
      }}>
      {children}
    </MainContext.Provider>
  );
};

AppContext.propTypes = {
  children: P.node.isRequired,
};
