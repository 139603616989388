import styled from 'styled-components';
import { AppBar } from '@material-ui/core';

export const StyledAppBar = styled(AppBar)`
  && {
    position: fixed;
    background-color: #f26522;
  }
  h4 {
    margin-left: 15px;
    flex: 1;
  }
  button {
    font-family: inherit;
    font-size: 14.08px;
  }
  .leadId {
    position: absolute;
    margin: 5px 0px 0px 10px;
    color: #333;
  }
`;
