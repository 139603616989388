/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Paper } from '@material-ui/core';

import ClientForm from '../../components/ClientForm';
import { Header, Container } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import BaseForm from '../../components/ExternalIndication/BaseForm';
import { useLocation } from 'react-router-dom';
import LoadingPage from '../LoadingPage';
import useSWR from 'swr';
import { fetcher } from '../../service/SWRFetcher';
import { decriptURLSearchParams } from '../../service/utils.service';

const ExternalIndicationNoAuth = () => {
  const [open, setOpen] = useState(false);
  const [hideThanks, setHideThanks] = useState();
  const { values, getters, creators } = useContext(MainContext);

  useEffect(() => {
    getters.getInsurances();
    setHideThanks(true);
  }, [open]);

  const [filters, setFilters] = useState({
    partner: null,
    leadNum: null,
  });

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const fullParams = decodeURIComponent(searchParams.toString());
  const paramsDecript = decriptURLSearchParams(fullParams).split('&');

  const inviter_id = paramsDecript[0].split('=')[1];
  localStorage.setItem("tenantId", paramsDecript[1].split('=')[1]);

  const { data: inviter } = useSWR('/externalInit?inviter_id=' + inviter_id, fetcher);

  return (
    <>
      {!values.insurances || !inviter_id ? (
        <LoadingPage />
      ) : (
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
          <Container>
            {hideThanks ? (
              <>
                <ClientForm open={open} setOpen={setOpen} action={creators.createClient} title="Clientes" />
                <Header>
                  <h1>Indicação externa</h1>
                </Header>
                <Header>
                  <h4>Indicado por {inviter?.name}</h4>
                </Header>
                <BaseForm setFilters={setFilters} inviter_id={inviter_id} setHideThanks={setHideThanks} />
              </>
            ) : (
              <Header>
                <h2 className="tab-title">Obrigado!</h2>
              </Header>
            )}
            <Paper elevation={3}></Paper>
          </Container>
        </motion.div>
      )}
      {!inviter_id && <h2>Não conseguimos achar quem indicou. Confira se o link do email está correto</h2>}
    </>
  );
};

export default ExternalIndicationNoAuth;
