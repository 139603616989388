import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/auth';
import Routes from './router/routes';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter basename={process.env.REACT_APP_SUBDIRECTORY}>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
        
        <Routes />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
