import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import SaveIcon from '@material-ui/icons/Save';
import BasicFormRegister from '../../components/BasicForm';
import TableList from '../../components/TableListBase';
import { Header, Container, AddButton } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';

const Insurances = () => {
  const [open, setOpen] = useState(false);
  const {
    values: { insurances },
    getters: { getInsurances },
    creators: { createInsurance },
    deleters: { deleteInsurance },
    editors: { editInsurance },
  } = useContext(MainContext);

  useEffect(() => {
    getInsurances();
  }, [open]);

  return (
    <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
      <Container>
        <BasicFormRegister open={open} setOpen={setOpen} action={createInsurance} title="Seguro" fields={['name']} />
        <Header>
          <h1>Seguros</h1>
          <div>
            <AddButton tvariant="contained" size="large" startIcon={<SaveIcon />} onClick={() => setOpen(true)}>
              Adicionar
            </AddButton>
          </div>
        </Header>
        <TableList listItem={insurances} deleteData={deleteInsurance} editData={editInsurance} title="Seguro" />
      </Container>
    </motion.div>
  );
};

export default Insurances;
