import { useContext, useEffect, useState } from 'react';
import ProfileForm from '../../components/ProfileForm';
import { Container } from './style';
import { Header } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';

const Profile = () => {
  const {
    auth: { getUserInfo },
  } = useContext(MainContext);
  const [user, setUser] = useState(null)
  useEffect(() => {
    (async()=>{
      const temp = await getUserInfo();
      setUser(temp.data);
    })()
  }, []);

  return (
    <Container>
      <Header>
        <h1>Profile</h1>
      </Header>
      <ProfileForm user={user} />
    </Container>
  );
};

export default Profile;
