import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 98wh;
  height: 98vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled(Paper)`
  padding: 24px;
  width: 100%;
  margin: auto;

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 10px;
    }

    .entrar {
      background-color: rgb(250 156 18);
      margin-right: 24px;

      :hover {
        background-color: rgb(203 123 6);
      }
    }
  }
`;