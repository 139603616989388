import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content:space-between ;
  &&>div{
    width:100%;
    display: flex;
    padding:10px;
  }
  &&>div:first-child{
  width:90%;
  height: 100%;
  flex-direction:column;
  align-items: center;
  justify-content:space-between;
}
  img{
    margin:30px 0;
    height:70px;
  }
  p{
    font-size:1.3em;
    margin-bottom:50px;
  }
`;
export const PaymentForm = styled.div
  `
  padding:10px;
  width:100%;
    span
    {
      display: flex;
      justify-content: space-between;        
    }
    &&>div
    {
      margin-top:20px;
    }
    &&>button{
      margin-top:30px;
      background:#4cec72;
      float:right;
      color:#fff;
      font-size:1em;
      font-weight: 700;
      whidth:fit-content;
    }
    &&>button:hover{
      background:#034e5d;
    }
`
export const Expired = styled.div`
  width: 100%;
  height: 100%;    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  text-align: center;
  gap:30px;
  h1
  {
    color:#f26522;
    font-size: 4em;
    text-transform: uppercase;
  }
  h4
  {
    width: 70%;
    color:#f26522;
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 900;
  }
`;

export const Paid = styled.div`
  width: 100%;
  height: 70%;    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  text-align: center;
  gap:30px;
  h1
  {
    color: rgb(4, 104, 117);
    font-size: 4em;
    text-transform: uppercase;
  }
  h5
  {
    width: 70%;
    color:green;
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 900;
  }
  h6
  {
    width: 70%;
    color: #c6c6c6;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 900;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    label.Mui-focused {
      color: #f26522;
    }
    .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #f26522;
      }
    }
  }
`;
export const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;

  .checkbox {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: blue;
  }

  .control {
    flex: 1 1 auto;
    margin: 0 5px;
  }
  .s {
    flex: 0.2 1 auto;
  }
  .ms {
    flex: 0.5 1 auto;
  }
  .m {
    flex: 2 1 auto;
  }
  .g {
    flex: 3 1 auto;
  }
  .gg {
    flex: 3 1 auto;
  }
  .x {
    flex: 4 1 auto;
  }
  .xl {
    flex: 5 1 auto;
  }
`;
export const LoadingDiv = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;        
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #ffffffaf;
  z-index: 9999;
`;
export const ClientData = styled.div`
  width: 70%;
  border-right: solid #cbcbcb 1px;
  padding:10px;
  &&>div 
  {
    margin-top:50px;
    div
    {
        gap: 30px;
        display: flex;
        flex-direction: row;
        margin-bottom:20px;
    }
  }
`;

export const DataContainer = styled.div`
  width: 100%;
  display:flex;
  flex-direction:row;
`;