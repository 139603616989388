// /* eslint-disable */
import React from 'react';
import P from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import { Button } from '@material-ui/core';
import { MiniMenu } from './styles';

const ItemSubMenu = ({ item, action, count, openSubMenu, setOpenSubMenu, children, clickMenu }) => (
    <>

      <Button fullWidth onClick={item.custom === 1 ? (() => {setOpenSubMenu((prev) => !prev)}) : action}>
        {item.name} <span className="count">{count}</span>
      </Button>
      {item.custom === 1 && (
        <AnimatePresence>
          {openSubMenu && (
            <MiniMenu
            clickMenu={clickMenu}
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
              transition={{
                type: 'tween',
                duration: 0.2,
              }}>
              {item.id == clickMenu ?(
                  <>
                <div className="sub-menus-content">
                  <ul>
                    {children}
                  </ul>
                </div>
                </>)
                : ""
              }
            </MiniMenu>
          )}
        </AnimatePresence>
      )}
    </>
  );

export default ItemSubMenu;

ItemSubMenu.propTypes = {
  item: P.oneOfType([P.any]).isRequired,
  action: P.func,
  children: P.node,
  count: P.oneOfType([P.any]).isRequired,
  openSubMenu: P.bool.isRequired,
  setOpenSubMenu: P.func.isRequired,
  clickMenu: P.number,
};
ItemSubMenu.defaultProps = {
  action: null,
  children: null,
  clickMenu: null
};

/* <li className="item">
  <Button
    fullWidth
    onClick={() => {
      onClick(1, 1);
      setOpen(false);
      setOpenSubMenu(false);
    }}>
    VN <span className="count">{countSubChannel(1)}</span>
  </Button>
</li>
<li className="item">
  <Button
    fullWidth
    onClick={() => {
      onClick(1, 2);
      setOpen(false);
      setOpenSubMenu(false);
    }}>
    VU <span className="count">{countSubChannel(2)}</span>
  </Button>
</li>
<li className="item">
  <Button
    fullWidth
    onClick={() => {
      onClick(1, 3);
      setOpen(false);
      setOpenSubMenu(false);
    }}>
    VD <span className="count">{countSubChannel(3)}</span>
  </Button>
</li> */
