/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';
import P, { shape } from 'prop-types';
import ClientForm from '../ClientForm';
import { PhoneMask } from '../../utils/phoneMask';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { DateTime } from 'luxon';

const DetailsTable = ({ item, stringValues, title, setLead, user, mutate }) => {
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState(null);

  const handleEdit = async (item) => {
    setClient(item);
    setOpen(true);
  };

  const handleOpenDetails = async (item) => {
    setOpenDetails(!openDetails);
  };

  const [openDetails, setOpenDetails] = React.useState(false);

  const isActive = () => {
    let active = false;
    const now = DateTime.now();
    const oneYearAgo = DateTime.now().plus({ years: 1 });
    item.leads.map((l) => {
      if (l.sold == 1 && l.end_lead_date && DateTime.fromISO(l.end_lead_date.replace(' ', 'T')) < oneYearAgo) active = true;
      if (l.channel_id == 6 && l.create_lead_date && DateTime.fromISO(l.create_lead_date.replace(' ', 'T')) > now.minus({ days: 1 })) active = true;
    });
    return active;
  };

  return (
    <>
      <ClientForm open={open} setOpen={setOpen} title={title} client={client} mutate={mutate} />
      <TableRow key={item.id}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleOpenDetails}>
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{item.id}</TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell>{item.mail}</TableCell>
        <TableCell>{PhoneMask(item.phone)}</TableCell>
        <TableCell>{PhoneMask(item.cellphone)}</TableCell>
        <TableCell>{isActive() ? 'Ativo' : 'Inativo'}</TableCell>
        <TableCell align="right">
          <IconButton onClick={() => handleEdit(item)}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Informações adicionais
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Lead Num.</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Corretor</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Vendido ou não</TableCell>
                    {user?.roles[0]?.id < 2 && <TableCell>Abrir lead</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item &&
                    item.leads.map((lead) => (
                      <TableRow>
                        <TableCell>{lead?.id || '-'}</TableCell>
                        <TableCell>{lead?.product || '-'}</TableCell>
                        <TableCell>{lead?.broker?.name || '-'}</TableCell>
                        <TableCell>{stringValues[0]?.find((e) => e.var_id == lead?.state)?.value || '-'}</TableCell>
                        <TableCell>{stringValues[1]?.find((e) => e.var_id == lead?.status)?.value || '-'}</TableCell>
                        <TableCell>
                          {(lead.sold == '1'
                            ? stringValues[2]?.find((e) => e.var_id == lead?.sold)?.value
                            : stringValues[3]?.find((e) => e.var_id == lead?.lost_sale)?.value)}
                        </TableCell>
                        {user?.roles[0]?.id < 2 && (
                          <TableCell>
                            <IconButton onClick={() => setLead(lead?.id)}>
                              <LaunchIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

DetailsTable.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
  title: P.string.isRequired,
};

DetailsTable.defaultProps = {
  listItem: null,
};

export default DetailsTable;
