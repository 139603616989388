/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Header, Container } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import { DateTime } from "luxon";
import SalesReportTable from '../../components/SalesReportTable';
import DatePeriodFilter from '../../components/SalesReportTable/Filter';
import LoadingPage from '../LoadingPage';
import useSWR from 'swr';
import axios from 'axios'

const SalesReport = () => {
  const [user, setUser] = useState(null);
  const { values, getters, creators } = useContext(MainContext);
  const {
    auth: { getUserInfo },
  } = useContext(MainContext);
  const [userId, setUserId] = useState();
  const [authTokens, setAuthTokens] = useState(null);
  const [filters, setFilters] = useState({
    date_ini: DateTime.now().minus({ days: 7 }).toISODate(),
    date_end: DateTime.now().toISODate(),
    product: '',
  });


    const info = {
      "email" : process.env.REACT_APP_PESSEGO_COM_EMAIL,
      "password" : process.env.REACT_APP_PESSEGO_COM_PASSWORD,
      "auth_url" : process.env.REACT_APP_PESSEGO_COM_AUTH_URL,
      "url" : process.env.REACT_APP_PESSEGO_COM_API_URL
     }


  useEffect(() => {(
    async () => {
       axios.post(info?.auth_url,
       {email: info?.email, password: info?.password, returnSecureToken:"true"}
       ).then(res => setAuthTokens(res.data))

    })()
  }, []);


  const fetcher = (url) => axios.get(url,{
    headers: {
      authorization: `Bearer ${authTokens.idToken}`,
      uid: `${authTokens.localId}`
    }
  }).then((res) => res.data);

  const simpleFetcher = (url) => axios.get(url).then((res) => res.data);
  

  const { data, error,  isValidating} = useSWR(authTokens && filters.date_end && filters.date_ini ? `${info?.url}reportInsurance?to=${filters.date_end}&from=${filters.date_ini}&product=${filters.product}` : null, fetcher);
  
  const { data : products, error : errorProducts,  isValidating : isValidatingProducts} = useSWR(`${info?.url}getProducts`, simpleFetcher);

  return (
    <>
      {authTokens === null ? (
        <LoadingPage />
      ) : (
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
          <Container>
            <Header>
              <h1>Relatório de Vendas - Quiver</h1>
            </Header>
            <DatePeriodFilter setFilters={setFilters} filters={filters} isValidating={isValidating} error={error} products={products} isValidatingProducts={isValidatingProducts}/>
            <SalesReportTable
              listItem={data?.data}
            />
          </Container>
        </motion.div>
      )}
    </>
  );
};

export default SalesReport;
