import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Container, Expired, PaymentForm, FormGroup, LoadingDiv, ClientData, DataContainer, Paid } from './style';
import { AutocompleteField, MaskedTextField, TextField } from '../../components/FormFields';
import { instituicaoFinanceira } from '../../mock/leadInfo.com';
import { LogoHorizontal, Bandeiras } from '../../components/Logo';
import { RemoveMask } from '../../utils/phoneMask';
import api from '../../service/api';
import { Loading } from '../../components/Loading';
import { DataParaEstiloPTBR, floatParaEstiloPTBR, formatarParaEstiloPTBR } from '../../hooks/Utils';

const ReplaceCard = () => {
  const { hash } = useParams();

  const hashParts = hash.split(';');
  const tenantId = hashParts[2];
  localStorage.setItem('tenantId', tenantId);

  const [values, setValues] = useState({});
  const [lead, setLead] = useState({});
  const [expired, setExpired] = useState({});
  const [loading, setLoading] = useState(true);
  const [paid, setPaid] = useState(false);

  const Pagar = async () => {
    const dataAtual = new Date();
    const [dateini, time] = new Date(dataAtual.setDate(dataAtual.getDate() + 3)).toISOString().split('T');
    if (!(!!values.creditCardNumber && !!values.securitycode && !!values.expirationYear && !!values.expirationMonth)) {
      toast.warning('Preencha todos os campos corretamente');
      return;
    }

    try {
      setLoading(true);
      const paymentData = {
        cartao: {
          numero: values.creditCardNumber,
          cvv: values.securitycode,
          validade: `${values.expirationYear}-${values.expirationMonth}`,
        },
      };

      const resp = await api.post(`changeCard/${hash}`, paymentData);
      if (resp.status == 200 || resp.status == 201) {
        toast.success(resp.data.message);
        window.location.reload();
      } else if (resp.status == 400) {
        toast.error(resp.data.message);
      } else {
        toast.error('Não foi possivel realizar a troca do cartão.');
      }
    } catch (error) {
      if (error.response.status == 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Não foi possivel realizar a troca do cartão.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e, newValue, variable) => {
    if (!newValue) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    } else {
      setValues({
        ...values,
        [variable]: newValue,
      });
    }
  };
  useEffect(async () => {
    try {
      const resp = await api.get(`changeCard/${hash}`);
      if (resp.status == 200) {
        setExpired(false);
        setLead(resp.data);
      } else if (resp.status == 400 && resp.data?.message == 'Cartão já foi redefinido.') {
        setPaid(true);
      } else {
        setExpired(true);
      }
    } catch (error) {
      if (error.response.status == 400 && error.response.data?.message == 'Cartão já foi redefinido.') {
        setPaid(true);
      } else {
        setExpired(true);
      }
    }
    setLoading(false);
  }, []);

  return (
    <>
      {loading && (
        <LoadingDiv>
          <Loading />
        </LoadingDiv>
      )}

      <Container>
        {paid ? (
          <Paid>
            <h1>Sucesso!</h1>
            <h5>Foi realizado com sucesso a troca do cartão de crédito vinculado a sua assinatura.</h5>
            <h6>Tudo certo por aqui, você já pode realizar o fechamento dessa página.</h6>
          </Paid>
        ) : (
          <div>
            <LogoHorizontal />
            {!expired ? (
              <DataContainer>
                <ClientData>
                  <span>
                    <h2>Informações de cliente:</h2>
                  </span>
                  <div>
                    <div>
                      <span>
                        <b>Nome do cliente: </b>
                        {lead?.nomeCliente}
                      </span>
                    </div>
                    <div>
                      <span>
                        <b>CPF: </b>
                        {lead?.cpf}
                      </span>
                      <span>
                        <b>RG: </b>MG {lead?.rg}
                      </span>
                    </div>
                    <div>
                      <span>
                        <b>Cidade: </b>
                        {lead?.cidade}
                      </span>
                      <span>
                        <b>Estado: </b>
                        {lead?.estado}
                      </span>
                    </div>
                    <div>
                      <span>
                        <b>Plano Contratado: </b>
                        {lead?.plano}
                      </span>
                      <span>
                        <b>Valor da Parcela: </b>R$ {formatarParaEstiloPTBR(lead?.valorParcela)}
                      </span>
                    </div>
                  </div>
                </ClientData>
                <PaymentForm>
                  <span>
                    <h2>Informações de cobrança:</h2>
                  </span>
                  <div>
                    <FormGroup>
                      <MaskedTextField
                        mask="9999 9999 9999 9999"
                        value={values?.creditCardNumber || ''}
                        minDate={0}
                        onChange={handleChange}
                        varibleName="creditCardNumber"
                        label="Numero do Cartão"
                        maxChars={16}
                      />
                      <TextField
                        medium
                        valueText="Cartão de Crédito"
                        value="Cartão de Crédito"
                        varibleName="paymentType"
                        onChange={() => {}}
                        label="Forma de Pagamento"
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        value={values?.expirationMonth || ''}
                        minDate={0}
                        onChange={handleChange}
                        varibleName="expirationMonth"
                        label="Mês Validade"
                        maxChars={2}
                        minChars={2}
                      />
                      <TextField
                        value={values?.expirationYear || ''}
                        minDate={0}
                        onChange={handleChange}
                        varibleName="expirationYear"
                        label="Ano Validade"
                        maxChars={4}
                        minChars={4}
                      />
                      <TextField
                        value={values?.securitycode || ''}
                        minDate={0}
                        onChange={handleChange}
                        varibleName="securitycode"
                        label="Código de segurança"
                        maxChars={3}
                      />
                    </FormGroup>
                  </div>
                  <Button onClick={() => Pagar()} component="button" variant="contained" color="#f26522">
                    Realizar troca cartão
                  </Button>
                </PaymentForm>
              </DataContainer>
            ) : (
              <Expired>
                <h4>FAVOR ENTRAR EM CONTATO COM O CORRETOR PARA GERAR UM NOVO LINK DE TROCA DE CARTÃO.</h4>
              </Expired>
            )}
          </div>
        )}

        <div style={{ backgroundColor: '#046875' }}>
          <Bandeiras />
        </div>
      </Container>
    </>
  );
};

export default ReplaceCard;
