/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Header, Container, ExportReportWrapper } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import Box from '@material-ui/core/Box';
import IndicationManagerReportTableList from '../../components/InidicationReportGerVeicTableList';
import DatePeriodFilter from '../../components/InidicationReportGerVeicTableList/Filter';
import SummaryReport from '../../components/InidicationReportGerVeicTableList/SummaryReport';
import LoadingPage from '../LoadingPage';
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import useApp from '../../hooks/useApp';
import { DateTime } from 'luxon';
import { TablePagination } from '@material-ui/core';
import { listToMatrix } from '../../utils/array';
import { excelExport } from '../../service/excel.service';

const InidicationReportGerVeic = () => {
  const { lead_states, lead_sold, lead_status, setState } = useApp();
  const [userId, setUserId] = useState();

  const [totalLeads, setTotalLeads] = useState([]);
  const [leads, setLeads] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [summary, setSummary] = useState([]);

  const [filters, setFilters] = useState({
    per_page: 10,
    page: 0,
  });

  const [reportFilter, setReportFilter] = useState({
    date_ini: DateTime.now().plus({ months: -1 }).toISODate(),
    date_end: DateTime.now().toISODate(),
    partner_id: '',
    insurance_id: '',
    client_id: '',
    paginate: null,
  });

  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, page: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, page: 0, per_page: parseInt(event.target.value) });
    mutate();
  };

  const partnersFilter = () => {
    if (!reportFilter.partner_id || reportFilter.partner_id.length === 0) return '';

    let stringPartnersFilter = '';
    reportFilter.partner_id.map((p) => (stringPartnersFilter += `&partner_id[]=${p.id}`));
    return stringPartnersFilter;
  };

  const insuranceFilter = () => {
    if (!reportFilter.insurance_id || reportFilter.insurance_id.length === 0) return '';

    let stringInsuranceFilter = '';
    reportFilter.insurance_id.map((i) => (stringInsuranceFilter += `&insurance_id[]=${i.id}`));
    return stringInsuranceFilter;
  };

  const inviterFilter = () => {
    if (!reportFilter.inviter_id || reportFilter.inviter_id.length === 0) return '';

    let stringInviterFilter = `&inviter_id=${reportFilter.inviter_id}`;
    return stringInviterFilter;
  };

  const clientFilter = () => {
    if (!reportFilter.client_id || reportFilter.client_id.length === 0) return '';

    let stringClientFilter = `&client_id=${reportFilter.client_id}`;
    return stringClientFilter;
  };

  const { data, mutate, isValidating } = useSWR(
    `/leads/indicationReportGerVeic?date_ini=${reportFilter.date_ini}&date_end=${
      reportFilter.date_end
    }${insuranceFilter()}${partnersFilter()}${inviterFilter()}${clientFilter()}`,
    fetcher
  );

  useEffect(() => {
    if (data?.length > 2 && !isValidating) {
      const leadsMatrix = listToMatrix(data[0], filters.per_page);
      setTotalLeads(leadsMatrix);
      setTotalPages(leadsMatrix.length);
      setLeads(leadsMatrix[filters.page]);
      setSummary(data[1]);
    }
  }, [data, isValidating]);

  useEffect(() => {
    setLeads(totalLeads[filters.page]);
  }, [filters.page]);

  useEffect(() => {
    setState('loading', isValidating);
  }, [isValidating]);

  return (
    <>
      <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
        <Container>
          <Header>
            <h1>Relatório de Indicação (Concessionária)</h1>
          </Header>
          <DatePeriodFilter id={userId} filters={reportFilter} setFilters={setReportFilter} />
          <SummaryReport listItem={summary} title="Relatório de Indicação (Gerência) - Resumo" />

          {!!data && data[0]?.length > 0 && (
            <ExportReportWrapper>
              <button
                className="button"
                onClick={() =>
                  excelExport(
                    `/leads/summaryReportGerVeicExport?date_ini=${reportFilter.date_ini}&date_end=${
                      reportFilter.date_end
                    }${insuranceFilter()}${partnersFilter()}${inviterFilter()}${clientFilter()}`,
                    'resumo_relatorio_indicação_concessionaria' + DateTime.now().toFormat('dd_LL_yyyy')
                  )
                }>
                Exportar
              </button>
            </ExportReportWrapper>
          )}

          <Box m={5} />
          <TablePagination
            component="div"
            count={data ? data[0]?.length : 0}
            page={filters.page}
            onPageChange={handleChangePage}
            rowsPerPage={filters.per_page}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <IndicationManagerReportTableList
            listItem={leads}
            title="Relatório de Indicação (Gerência)"
            states={lead_states}
            sold={lead_sold}
            status={lead_status}
          />

          {!!data && data[0]?.length > 0 && (
            <ExportReportWrapper>
              <button
                className="button"
                onClick={() =>
                  excelExport(
                    `/leads/indicationReportGerVeicExport?date_ini=${reportFilter.date_ini}&date_end=${
                      reportFilter.date_end
                    }${insuranceFilter()}${partnersFilter()}${inviterFilter()}${clientFilter()}`,
                    'relatorio_indicação_concessionaria' + DateTime.now().toFormat('dd_LL_yyyy')
                  )
                }>
                Exportar
              </button>
            </ExportReportWrapper>
          )}
        </Container>
      </motion.div>
    </>
  );
};

export default InidicationReportGerVeic;
