import { dataCreate, dataDelete, dataEdit } from "./data.service";

const createTechnicalTeam = async (newData) => {
    const response = await dataCreate('/technical-team', newData);
    return response;
  };

  const editTechnicalTeam = async (newData, dataID) => {
    const response = await dataEdit('/technical-team', newData, dataID);
    return response;
  };

  const deleteTechnicalTeam = async (dataID) => {
    const response = await dataDelete('/technical-team', dataID);
    return response;
  };

  export { createTechnicalTeam, editTechnicalTeam, deleteTechnicalTeam };