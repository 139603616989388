/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import api from "./api";
import { editLead, goToNextStatus } from './lead.service';
  
  export const handleQuotationQuiverOnResidential = async (values, setValues, lead) => {
    if (!values?.client_Cpf) {
      toast.warning('Preencha o CPF do cliente');
      return;
    }
    if (!values?.client_name) {
      toast.warning('Preencha o nome do cliente');
      return;
    }
    if (!values?.client_phone && !values?.client_cellphone) {
      toast.warning('Preencha o telefone/celular do cliente');
      return;
    }
    if (!values?.client_Email) {
      toast.warning('Preencha o e-mail do cliente');
      return;
    }
    if (!values?.client_CEP) {
      toast.warning('Preencha o CEP do cliente');
      return;
    }
    if (!values?.client_birth_date) {
      toast.warning('Preencha a data de aniversário do cliente');
      return;
    }
    const {
      data: { param_ws_residential },
    } = await api.get('/quiverconfig');
    const url =
      'https://www.corretor-online.com.br/canalcliente/indexRes.htm?' +
      `?ParamWS=${param_ws_residential}` +
      `&Padrao3000_Nome=${values?.client_name}` +
      `&Padrao3000_TelefoneMod1=${values?.client_phone?.replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3')}` +
      `&Padrao3000_Telefone_celMod1=${values?.client_cellphone?.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')}` +
      `&Padrao3000_E_mail=${values?.client_Email}` +
      `&Padrao3000_Cep=${values?.client_CEP}` +
      `&Padrao3000_Endereco=${values?.client_address_street}` +
      `&Padrao3000_Numero=${values?.client_address_number}` +
      `&Padrao3000_Complemento=${values?.client_address_comp}` +
      `&Padrao3000_Bairro=${values?.client_address_district}` +
      `&Padrao3000_Cidade=${values?.client_address_city}` +
      `&Padrao3000_Cgc_cpf=${values?.client_Cpf}` +
      `&Padrao3000_Datanascimento=${values?.client_birth_date}` 
      ;
    window.open(url.replaceAll(' ', '%20'), '_blank');
    const newStatus = await goToNextStatus(lead?.id);
  
    setValues({ ...values, status: { id: newStatus.var_id, name: newStatus.value } });
    await editLead({ quoted: 1 }, lead?.id);

  };

  export const handleQuotationQuiverMultiResidential = async (values, setValues, lead) => {
    if (!values?.client_Cpf) {
      toast.warning('Preencha o CPF do cliente');
      return;
    }
    if (!values?.client_name) {
      toast.warning('Preencha o nome do cliente');
      return;
    }
    if (!values?.client_phone && !values?.client_cellphone) {
      toast.warning('Preencha o telefone/celular do cliente');
      return;
    }
    if (!values?.client_Email) {
      toast.warning('Preencha o e-mail do cliente');
      return;
    }
    if (!values?.client_CEP) {
      toast.warning('Preencha o CEP do cliente');
      return;
    }
    if (!values?.client_birth_date) {
      toast.warning('Preencha a data de aniversário do cliente');
      return;
    }
    const url =
      'https://www.quiverpro.net.br/link.Aspx?' +
      `Corretor=BONSUCESSO` +
      `&Usuario=${values?.broker?.login}` + 
      `&layoutnovo=S&NomeFrm=FrmAjax&pagina=MultCalculo3000&modulo=AcompanhamentoVendas&Calculo=0&CotacaoExterna=1234&Padrao=22&MultCalculo_Tipo=R&MultCalculo_PadraoLider=3000` +
      `&Padrao3000_Nome=${values?.client_name}` +
      `&Padrao3000_TelefoneMod1=${values?.client_phone?.replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3')}` +
      `&Padrao3000_Telefone_celMod1=${values?.client_cellphone?.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')}` +
      `&Padrao3000_E_mail=${values?.client_Email}` +
      `&Padrao3000_Cep=${values?.client_CEP}` +
      `&Padrao3000_Endereco=${values?.client_address_street}` +
      `&Padrao3000_Numero=${values?.client_address_number}` +
      `&Padrao3000_Complemento=${values?.client_address_comp}` +
      `&Padrao3000_Bairro=${values?.client_address_district}` +
      `&Padrao3000_Cidade=${values?.client_address_city}` +
      `&Padrao3000_Cgc_cpf=${values?.client_Cpf}` +
      `&Padrao3000_Datanascimento=${values?.client_birth_date}` 
      ;
    window.open(url.replaceAll(' ', '%20'), '_blank');

    const newStatus = await goToNextStatus(lead?.id);
  
    setValues({ ...values, status: { id: newStatus.var_id, name: newStatus.value } });
    await editLead({ quoted: 1 }, lead?.id);
  };
  
  export const handleQuotationQuiverMulti = async (values, setValues, lead) => {
    if (!values?.client_Cpf) {
      toast.warning('Preencha o CPF do cliente');
      return;
    }
    if (!values?.client_name) {
      toast.warning('Preencha o nome do cliente');
      return;
    }
    if (!values?.client_phone && !values?.client_cellphone) {
      toast.warning('Preencha o telefone/celular do cliente');
      return;
    }
    if (!values?.client_Email) {
      toast.warning('Preencha o e-mail do cliente');
      return;
    }
    if (!values?.client_CEP) {
      toast.warning('Preencha o CEP do cliente');
      return;
    }
    if (!values?.client_birth_date) {
      toast.warning('Preencha a data de aniversário do cliente');
      return;
    }
    const url =
      `http://www.quiverpro.net.br/link.Aspx?Corretor=BONSUCESSO` +
      `&Usuario=${values?.broker?.login}` +
      `&layoutnovo=S&NomeFrm=FrmAjax&pagina=MultCalculo&modulo=AcompanhamentoVendas&Calculo=0&Padrao=15&MultCalculo_Tipo=A&MultCalculo_PadraoLider=2000` +
      `&Padrao2000_Nome=${values?.client_name}` +
      `&Padrao2000_Cgc_cpf=${values?.client_Cpf}` +
      `&Padrao2000_Datanascimento=${values?.client_birth_date}` +
      `&Padrao2000_Ddd=${values?.client_phone[0]}${values?.client_phone[1]}` +
      `&Padrao2000_Telefone=${values?.client_phone.slice(-8)}` +
      `&Padrao2000_Ddd_cel=${values?.client_cellphone[0]}${values?.client_cellphone[1]}` +
      `&Padrao2000_Telefone_cel=${values?.client_cellphone.slice(-9)}` +
      `&Padrao2000_E_mail=${values?.client_Email}` +
      `&Padrao2000_Chassi=${values?.product_chassis ? values?.product_chassis : ''}` +
      `&Padrao2000_Placa=${values?.license_plate ? values?.license_plate : ''}` +
      `&Padrao2000_Cobertura1851=${values?.client_CEP}` +
      `&Padrao2000_Cep=${values?.client_CEP}` +
      `&Padrao2000_Ceppernoite=${values?.client_CEP}`
      ;
    window.open(url.replaceAll(' ', '%20'), '_blank');
    const newStatus = await goToNextStatus(lead?.id);
  
    setValues({ ...values, status: { id: newStatus.var_id, name: newStatus.value } });
    await editLead({ quoted: 1 }, lead?.id);
  };
  
  export const handleQuotationQuiverOn = async (values, setValues, lead) => {
    if (!values?.client_Cpf) {
      toast.warning('Preencha o CPF do cliente');
      return;
    }
    if (!values?.client_name) {
      toast.warning('Preencha o nome do cliente');
      return;
    }
    if (!values?.client_phone && !values?.client_cellphone) {
      toast.warning('Preencha o telefone/celular do cliente');
      return;
    }
    if (!values?.client_Email) {
      toast.warning('Preencha o e-mail do cliente');
      return;
    }
    if (!values?.client_CEP) {
      toast.warning('Preencha o CEP do cliente');
      return;
    }
    if (!values?.client_birth_date) {
      toast.warning('Preencha a data de aniversário do cliente');
      return;
    }
    const {
      data: { param_ws_car },
    } = await api.get('/quiverconfig');
    const url =
      'https://www.corretor-online.com.br/canalcliente/index.htm' +
      `?ParamWS=${param_ws_car}` +
      `&Padrao2000_Nome=${values?.client_name}` +
      `&Padrao2000_TelefoneMod1=${values?.client_phone?.replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3')}` +
      `&Padrao2000_Telefone_celMod1=${values?.client_cellphone?.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')}` +
      `&Padrao2000_E_mail=${values?.client_Email}` +
      `&Padrao2000_ChassiMod3=${values?.product_chassis || ''}` +
      `&Padrao2000_Cobertura1851=${values?.client_CEP}` +
      `&Padrao2000_Cep=` +
      `&Padrao2000_Ceppernoite=${values?.client_CEP}` +
      `&Padrao2000_Cgc_cpf=${values?.client_Cpf}` +
      `&Padrao2000_Datanascimento=${values?.client_birth_date}` 
      ;
    window.open(url.replaceAll(' ', '%20'), '_blank');
    const newStatus = await goToNextStatus(lead?.id);
  
    setValues({ ...values, status: { id: newStatus.var_id, name: newStatus.value } });
    await editLead({ quoted: 1 }, lead?.id);
  };
  