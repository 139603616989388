import { dataCreate, dataDelete, dataEdit } from './data.service';

const createSchema = async (newData) => {
  const response = await dataCreate('/commissions-schema', newData);
  return response;
};

const editSchema = async (newData, dataID) => {
  const response = await dataEdit('/commissions-schema', newData, dataID);
  return response;
};

const deleteSchema = async (dataID) => {
  const response = await dataDelete('/commissions-schema', dataID);
  return response;
};

const createPercents = async (newData) => {
    const response = await dataCreate('/commission-percents', newData);
    return response;
};

const editPercents = async (newData, dataID) => {
    const response = await dataEdit('/commission-percents', newData, dataID);
    return response;
};

const deletePercents = async (dataID) => {
    const response = await dataDelete('/commission-percents', dataID);
    return response;
};
  

export { createSchema, editSchema, deleteSchema, createPercents, editPercents, deletePercents };