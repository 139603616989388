/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { useContext, useState, useEffect } from 'react';
import { MainContext } from '../../../context/ApiContext'
import SimpleModal from '../../SimpleModal';


export function SelectInsuranceOptions({
    open,
    setOpen,
    values,
    setValues,
    next
}){
    const { values: { leadInsurances } } = useContext(MainContext);

    return (    
        <SimpleModal
        title="Selecionar Seguros"
        fieldName="OBSERVAÇÃO"
        listItems={leadInsurances}
        open={open}
        setOpen={setOpen}
        value={values.additional_information}
        onChange={(e) => setValues({ ...values, additional_information: e.target.value })}
        next={next}
        />
    )
};

SelectInsuranceOptions.propTypes = {
    open: P.objectOf(P.any).isRequired,
    setOpen: P.objectOf(P.any).isRequired,
    values: P.objectOf(P.any).isRequired,
    setValues: P.objectOf(P.any).isRequired,
    next: P.objectOf(P.any).isRequired
};