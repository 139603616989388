import { useEffect } from 'react';
import { Loading } from '../../components/Loading';
import { Container } from './style';

const LoadingPage = () => (
    <Container>
      <Loading />
    </Container>
  );

export default LoadingPage;

