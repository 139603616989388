import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const StyledTextField = styled(TextField)`
  && {
    label.Mui-focused {
      color: #f26522;
    }
    .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #f26522;
      }
    }
  }
`;
