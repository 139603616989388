import { useEffect, useState } from 'react';
import { TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, 
    TableRow, TableFooter, Button } from '@material-ui/core';
import { AddCircleRounded } from '@material-ui/icons';
import { motion } from 'framer-motion';
import useSWR from 'swr';
import useApp from '../../hooks/useApp';
import { Header, Container } from '../../styles/shared/styles';
import { variantFetcher } from '../../service/SWRFetcher';
import CommissionsSchemaTableList from '../../components/CommissionComponents/CommissionsSchemaTableList';
import { createSchema, editSchema, deleteSchema } from '../../service/commissionSchema.service';
import CommissionSchemaAdd from '../../components/CommissionComponents/CommissionSchemaAdd';

const CommissionsSchema = () => {
    const {
        data: listCommissionsSchema,
        mutate,
        isValidating,
    } = useSWR(
        `/commissions-schema`,
        variantFetcher
    );

    const { setState } = useApp();
    const [getListCommissionsSchema, setListCommissionsSchema] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [openAdd, setOpenAdd] = useState(false);


    const handleChangePage = (event, newPage) => {
        // setFilters({ ...filters, page: newPage });
    };

    const handleChangeRowsPerPage = (event) => {
        // setFilters({ ...filters, page: 0, per_page: parseInt(event.target.value, 10) });
    };

    const handleAdd = () => {
        setOpenAdd(true);
    }
    

    useEffect(() => {
        if (listCommissionsSchema?.data) {
            setListCommissionsSchema([...listCommissionsSchema?.data]);
          // setTotalPages(users?.meta?.total);
        }
      }, [listCommissionsSchema]);    


    return (
        <>
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
            <Container>
            <Header>
                <h1>Tabela de Comissões</h1>
            </Header>
            
            {getListCommissionsSchema.length > 0 && (
                <>
                <TablePagination
                    component="div"
                    count={totalPages}
                    page={1}
                    onPageChange={handleChangePage}
                    rowsPerPage={10}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <CommissionsSchemaTableList
                    listItem={getListCommissionsSchema}
                    deleteSchema={deleteSchema}
                    editData={editSchema}
                    title="Tabela"
                    mutate={mutate}
                />
                </>
            )}

            <CommissionSchemaAdd 
                open={openAdd} 
                setOpen={setOpenAdd} 
                mutate={mutate}
            />

            <Table>
            <TableFooter>
                <br/>
                <TableRow>
                <TableCell>
                    <Button variant="contained" onClick={handleAdd}>
                    <AddCircleRounded style={{marginRight: '10px'}}/>
                    Nova Tabela 
                    </Button>
                </TableCell>
                </TableRow>
            </TableFooter>
            </Table>
            </Container>
        </motion.div>
        
        </>
    )
}

export default CommissionsSchema;