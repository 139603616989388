export function listToMatrix(list, elementsPerSubArray) {
    const matrix = []
    let i = 0
    let k = -1;

    for (i = 0, k = -1; i < list.length; i += 1) {
        if (i % elementsPerSubArray === 0) {
            k += 1;
            matrix[k] = [];
        }

        matrix[k].push(list[i]);
    }

    return matrix;
}

export function monthArray() {
    return [
        {name:'Janeiro', value:'01'},
        {name:'Fevereiro', value:'02'},
        {name:'Março', value:'03'},
        {name:'Abril', value:'04'},
        {name:'Maio', value:'05'},
        {name:'Junho', value:'06'},
        {name:'Julho', value:'07'},
        {name:'Agosto', value:'08'},
        {name:'Setembro', value:'09'},
        {name:'Outubro', value:'10'},
        {name:'Novembro', value:'11'},
        {name:'Dezembro', value:'12'},
      ]
}