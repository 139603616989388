/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';
import P from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { AutocompleteField } from '../FormFields';
import { FormGroup } from '../LeadForm/styles';
import api from '../../service/api';
import { formatarParaEstiloAmericano, formatarParaEstiloPTBR } from '../../hooks/Utils';
import { CustomFileUpload } from '../LeadForm/services';

export default function Sinistro({ sinistro, segurosAtivos, disabledField, cliente }) {
  const [sinistroEmAberto, setSinistro] = useState(sinistro);

  const dataAtual = DateTime.local().toFormat('dd/LL/yyyy HH:mm:ss');

  const StyledBoxesHeader = {
    marginTop: 16,
    marginBottom: 8,
    marginRight: 6,
    marginLeft: 12,
  };

  const StyledBoxes = {
    marginTop: 16,
    marginBottom: 8,
    marginRight: 6,
    marginLeft: 6,
  };

  const StyledBoxesWidth = {
    marginTop: 16,
    marginBottom: 8,
    marginRight: 6,
    marginLeft: 6,
    width: 280,
  };

  const optionTipoIndenizacao = [
    {
      id: '',
      name: '',
    },
    {
      id: 1,
      name: 'Indenização total c/ cancelamento de apólice',
    },
    {
      id: 2,
      name: 'Indenização total s/ cancelamento de apólice',
    },
    {
      id: 3,
      name: 'Sem indenização',
    },
    {
      id: 4,
      name: 'Reembolso',
    },
    {
      id: 5,
      name: 'Indenização parcial, não reduzir classe de bônus',
    },
    {
      id: 6,
      name: 'Indenização parcial, Indenização total do item segurado',
    },
  ];

  const optionSituacaoSinistro = [
    {
      id: '',
      name: '',
    },
    {
      id: 1,
      name: 'Pendente - Prazo Normal',
    },
    {
      id: 2,
      name: 'Pendente - Prazo Anormal',
    },
    {
      id: 3,
      name: 'Serviço em andamento',
    },
    {
      id: 4,
      name: 'Pagamento de valores em andamento',
    },
    {
      id: 5,
      name: 'Finalizado',
    },
  ];

  const optionBoletimOcorrencia = [
    {
      id: '',
      name: '',
    },
    {
      id: 1,
      name: 'Sim',
    },
    {
      id: 2,
      name: 'Não',
    },
    {
      id: 3,
      name: 'Não informado',
    },
  ];

  const optionReclamante = [
    {
      id: '',
      name: '',
    },
    {
      id: 1,
      name: 'Segurado',
    },
    {
      id: 2,
      name: 'Terceiro',
    },
    {
      id: 3,
      name: 'Dependente',
    },
    {
      id: 4,
      name: 'Segurado e terceiro',
    },
    {
      id: 5,
      name: 'Beneficiário',
    },
  ];
  const [file, setFile] = useState(false); 
  const [seguroAtivoSelecionado, setSeguroAtivoSelecionado] = useState({
    id: '',
    name: '',
    lead_id: '',
  });

  const [optionTipoIndenizacaoSelecionado, setTipoIndenizacao] = useState({
    id: '',
    name: '',
  });

  const [optionSituacaoSinistroSelecionado, setSituacaoSinistro] = useState({
    id: '',
    name: '',
  });

  const [optionBoletimOcorrenciaSelecionado, setBoletimOcorrencia] = useState({
    id: '',
    name: '',
  });

  const [optionReclamanteSelecionado, setReclamante] = useState({
    id: '',
    name: '',
  });

  const setArrayValue = (nameValue, arr, set) => {
    const defaultValue = { id: 0, name: '', lead_id: 0 };
    if (!nameValue || nameValue != '') {
      const value = arr.find((item) => item.name == nameValue);
      if (!value) {
        set(defaultValue);
        return defaultValue;
      }
      set(value);
      return value;
    }
    return defaultValue;
  };

  useEffect(() => {
    if (sinistro) {
      setSinistro(sinistro);
      setArrayValue(sinistro.type_compensation, optionTipoIndenizacao, setTipoIndenizacao);
      setArrayValue(sinistro.incident_report, optionBoletimOcorrencia, setBoletimOcorrencia);
      setArrayValue(sinistro.complainant, optionReclamante, setReclamante);
      setArrayValue(sinistro.situation, optionSituacaoSinistro, setSituacaoSinistro);
      setArrayValue(sinistro.insurance.name, segurosAtivos, setSeguroAtivoSelecionado);
    } else {
      setArrayValue(segurosAtivos[0]?.name, segurosAtivos, setSeguroAtivoSelecionado);
      setSinistro({ ...sinistroEmAberto, insurance_id: segurosAtivos[0]?.id, lead_id: segurosAtivos[0]?.lead_id });
    }
  }, [sinistro]);

  const gravarSinistro = async () => {
    if (!sinistroEmAberto || !sinistroEmAberto.insurance_id || sinistroEmAberto.insurance_id == 0) {
      toast.error('Selecione um seguro para o sinistro.');
      return;
    }
    const request = { ...sinistroEmAberto, client_id: cliente.id };

    request.franchise_value = formatarParaEstiloAmericano(request.franchise_value);
    request.amount_paid_insurancecompany = formatarParaEstiloAmericano(request.amount_paid_insurancecompany);

    try {
      const response = await api.post(`sinisters/save`, request);

      if (response.status === 200) {
        toast.success('Sinistro gravado com sucesso');
      }
    } catch (error) {
      if (error.response) {
        console.log('Status do Erro:', error.response.status);
        console.log('Dados do Erro:', error.response.data);
      } else if (error.request) {
        console.log('Erro de Resposta:', error.request);
      } else {
        console.log('Erro ao Configurar a Solicitação:', error.message);
      }

      toast.error('Erro ao gravar sinistro, favor sinalizar o suporte caso persista.');
    }
  };

  const atualizarSinistro = async () => {
    if (!sinistroEmAberto || !sinistroEmAberto.insurance_id || sinistroEmAberto.insurance_id == 0) {
      toast.error('Selecione um seguro para o sinistro.');
      return;
    }

    sinistroEmAberto.franchise_value = formatarParaEstiloAmericano(sinistroEmAberto.franchise_value);
    sinistroEmAberto.amount_paid_insurancecompany = formatarParaEstiloAmericano(
      sinistroEmAberto.amount_paid_insurancecompany
    );
    try {
      const response = await api.put(`sinisters/updateInfo/${sinistroEmAberto.id}`, sinistroEmAberto);

      if (response.status === 200) {
        toast.success('Sinistro atualizado com sucesso');
      }
    } catch (error) {
      if (error.response) {
        console.log('Status do Erro:', error.response.status);
        console.log('Dados do Erro:', error.response.data);
      } else if (error.request) {
        console.log('Erro de Resposta:', error.request);
      } else {
        console.log('Erro ao Configurar a Solicitação:', error.message);
      }

      toast.error('Erro ao gravar sinistro, favor sinalizar o suporte caso persista.');
    }
  };

  const btnFinalizarClick = async () => {
    try {
      sinistroEmAberto.amount_paid_insurancecompany = formatarParaEstiloAmericano(
        sinistroEmAberto.amount_paid_insurancecompany
      );
      sinistroEmAberto.franchise_value = formatarParaEstiloAmericano(sinistroEmAberto.franchise_value);
      const response = await api.put(`sinisters/updateInfo/${sinistroEmAberto.id}?finish=true`, sinistroEmAberto);

      if (response.status === 200) {
        toast.success('Sinistro atualizado com sucesso');
        setSinistro({});
      }
    } catch (error) {
      if (error.response) {
        console.log('Status do Erro:', error.response.status);
        console.log('Dados do Erro:', error.response.data);
      } else if (error.request) {
        console.log('Erro de Resposta:', error.request);
      } else {
        console.log('Erro ao Configurar a Solicitação:', error.message);
      }

      toast.error('Erro ao gravar sinistro, favor sinalizar o suporte caso persista.');
    }
  };

  const btnGravarClick = () => {
    if (!sinistro || !sinistro.id || sinistro.id == '') gravarSinistro();
    else atualizarSinistro();
  };

  return (
    <>
      <FormGroup>
        <AutocompleteField
          medium
          varibleName="seguro_ativo"
          onChange={(e, value) => {
            const resultado = { id: 0, name: '', lead_id: 0 };
            if (value && value != undefined) {
              setSeguroAtivoSelecionado(value);
              setSinistro({ ...sinistroEmAberto, insurance_id: value.id, lead_id: value.lead_id });
            } else {
              setSeguroAtivoSelecionado(resultado);
              setSinistro({ ...sinistroEmAberto, insurance_id: resultado.id, lead_id: resultado.lead_id });
            }
          }}
          label="Sinistro em qual seguro ativo?"
          style={{
            width: '30%',
            marginTop: 26,
          }}
          getOptionLabel={(option) => option?.name || ''}
          value={seguroAtivoSelecionado}
          options={segurosAtivos}
          disabled={disabledField}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          variant="outlined"
          value={sinistroEmAberto?.sinister_date ? sinistroEmAberto?.sinister_date : undefined}
          varibleName="data_sinistro"
          label="Data do Sinistro:"
          type="datetime-local"
          style={{
            width: '33.333%',
            marginTop: 16,
            marginBottom: 8,
            marginRight: 6,
            marginLeft: 12,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSinistro({ ...sinistroEmAberto, sinister_date: e.target.value });
          }}
        />
        <TextField
          variant="outlined"
          value={sinistroEmAberto?.sinister_alert_date ? sinistroEmAberto?.sinister_alert_date : undefined}
          varibleName="data_aviso_sinistro"
          label="Data do aviso do sinistro:"
          type="datetime-local"
          style={{
            width: '33.333%',
            marginTop: 16,
            marginBottom: 8,
            marginRight: 6,
            marginLeft: 12,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSinistro({ ...sinistroEmAberto, sinister_alert_date: e.target.value });
          }}
          disabled={disabledField}
        />
        <TextField
          variant="outlined"
          value={sinistroEmAberto?.sinister_documents_date ? sinistroEmAberto?.sinister_documents_date : undefined}
          varibleName="data_envio_documentos_seguradora"
          label="Data envio documentos Seguradora:"
          type="datetime-local"
          style={{
            width: '33.333%',
            marginTop: 16,
            marginBottom: 8,
            marginRight: 6,
            marginLeft: 12,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSinistro({ ...sinistroEmAberto, sinister_documents_date: e.target.value });
          }}
          disabled={disabledField}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          variant="outlined"
          varibleName="explicacao_sinistro"
          value={sinistroEmAberto?.explanation ? sinistroEmAberto?.explanation : undefined}
          label="Explicação sobre o Sinistro:"
          multiline
          rows={5}
          style={{
            width: '98.5%',
            marginLeft: 12,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabledField}
          onChange={(e) => {
            setSinistro({ ...sinistroEmAberto, explanation: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <AutocompleteField
          medium
          varibleName="tipo_indenizacao"
          label="Tipo de indenização:"
          value={optionTipoIndenizacaoSelecionado}
          onChange={(e, value) => {
            const resultado = { id: 0, name: '' };
            if (value && value != undefined) {
              setTipoIndenizacao(value);
              setSinistro({ ...sinistroEmAberto, type_compensation: value.name });
            } else {
              setTipoIndenizacao(resultado);
              setSinistro({ ...sinistroEmAberto, type_compensation: resultado.name });
            }
          }}
          style={StyledBoxesHeader}
          getOptionLabel={(option) => option?.name || ''}
          options={optionTipoIndenizacao}
          disabled={disabledField}
        />
        <AutocompleteField
          medium
          varibleName="boletim_ocorrencia"
          label="Tem boletim de ocorrência:"
          value={optionBoletimOcorrenciaSelecionado}
          onChange={(e, value) => {
            const resultado = { id: 0, name: '' };
            if (value && value != undefined) {
              setBoletimOcorrencia(value);
              setSinistro({ ...sinistroEmAberto, incident_report: value.name });
            } else {
              setBoletimOcorrencia(resultado);
              setSinistro({ ...sinistroEmAberto, incident_report: resultado.name });
            }
          }}
          style={StyledBoxesHeader}
          getOptionLabel={(option) => option?.name || ''}
          options={optionBoletimOcorrencia}
          disabled={disabledField}
        />
        <AutocompleteField
          medium
          varibleName="reclamante"
          label="Reclamante:"
          value={optionReclamanteSelecionado}
          onChange={(e, value) => {
            const resultado = { id: 0, name: '' };
            if (value && value != undefined) {
              setReclamante(value);
              setSinistro({ ...sinistroEmAberto, complainant: value.name });
            } else {
              setReclamante(resultado);
              setSinistro({ ...sinistroEmAberto, complainant: resultado.name });
            }
          }}
          style={StyledBoxesHeader}
          getOptionLabel={(option) => option?.name || ''}
          options={optionReclamante}
          disabled={disabledField}
        />
      </FormGroup>
      <FormGroup style={{ marginTop: -10 }}>
        <AutocompleteField
          medium
          varibleName="situacao_sinistro"
          label="Situação do Sinistro:"
          value={optionSituacaoSinistroSelecionado}
          onChange={(e, value) => {
            const resultado = { id: 0, name: '' };
            if (value && value != undefined) {
              setSituacaoSinistro(value);
              setSinistro({ ...sinistroEmAberto, situation: value.name });
            } else {
              setSituacaoSinistro(resultado);
              setSinistro({ ...sinistroEmAberto, situation: resultado.name });
            }
          }}
          style={StyledBoxesHeader}
          getOptionLabel={(option) => option?.name || ''}
          options={optionSituacaoSinistro}
          disabled={disabledField}
        />
        <NumericFormat
          variant="outlined"
          customInput={TextField}
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          value={
            sinistroEmAberto?.franchise_value ? formatarParaEstiloPTBR(sinistroEmAberto?.franchise_value) : undefined
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
          }}
          varibleName="valor_franquia"
          label="Valor da franquia"
          style={{
            width: '30.3333%',
            marginTop: 32,
            marginBottom: 8,
            marginRight: 16,
            marginLeft: 6,
          }}
          onChange={(e) => {
            setSinistro({ ...sinistroEmAberto, franchise_value: formatarParaEstiloAmericano(e.target.value) });
          }}
          disabled={disabledField}
        />

        <NumericFormat
          variant="outlined"
          customInput={TextField}
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          value={
            sinistroEmAberto?.amount_paid_insurancecompany
              ? formatarParaEstiloPTBR(sinistroEmAberto?.amount_paid_insurancecompany)
              : undefined
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
          }}
          varibleName="valor_pago_seguradora"
          label="Valor a ser pago pela seguradora:"
          style={{
            width: '30.3333%',
            marginTop: 32,
            marginBottom: 8,
            marginRight: 6,
            marginLeft: 6,
          }}
          onChange={(e) => {
            console.log(sinistroEmAberto?.amount_paid_insurancecompany);
            console.log(e.target.value);
            setSinistro({
              ...sinistroEmAberto,
              amount_paid_insurancecompany: formatarParaEstiloAmericano(e.target.value),
            });
          }}
          disabled={disabledField}
        />
      </FormGroup>
      <FormGroup style={{ justifyContent: 'start', gap: 18 }}>
        <TextField
          variant="outlined"
          varibleName="data_liberacao_valor_cliente"
          label="Data da liberação do valor ao cliente:"
          value={sinistroEmAberto?.release_date ? sinistroEmAberto?.release_date : undefined}
          type="date"
          style={{
            width: '33.333%',
            marginTop: 16,
            marginBottom: 8,
            marginRight: 6,
            marginLeft: 12,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSinistro({ ...sinistroEmAberto, release_date: e.target.value });
          }}
          disabled={disabledField}
        />
        <TextField
          variant="outlined"
          varibleName="data_fechamento_sinistro"
          label="Data fechamento do Sinistro:"
          value={sinistroEmAberto?.finish_date ? sinistroEmAberto?.finish_date : undefined}
          type="datetime-local"
          style={{
            width: '33.333%',
            marginTop: 16,
            marginBottom: 8,
            marginRight: 6,
            marginLeft: 12,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSinistro({ ...sinistroEmAberto, finish_date: e.target.value });
          }}
          disabled
        />
      </FormGroup>
      <FormGroup style={{ padding: 12 }}>
        <Button
          className="botoes-cliente"
          onClick={btnGravarClick}
          variant="contained"
          color="default"
          style={{
            height: 52,
            marginTop: 11,
          }}>
          Gravar
        </Button>
                <Button
                disable={!seguroAtivoSelecionado?.lead_id}
            onChange={async (event) => {
               await CustomFileUpload(
                  event,
                  setFile,
                  seguroAtivoSelecionado?.lead_id,
                  'Sinistro',
                  'Sinistro Anexado com sucesso!',
                  '/sinisters/file'
                );
            }}
            component="label"
            variant="contained"
            color="default"
            className="of-file"
            startIcon={<CloudUploadIcon />}>
            Anexar Sinistro
            <input type="file" accept="application/pdf" hidden />
          </Button>
        {sinistro && (
          <Button
            className="botoes-cliente"
            onClick={btnFinalizarClick}
            variant="contained"
            color="default"
            style={{
              height: 52,
              marginTop: 11,
            }}>
            Finalizar Sinistro
          </Button>
        )}
      </FormGroup>
    </>
  );
}

Sinistro.propTypes = {
  sinistro: P.objectOf(P.string),
  segurosAtivos: P.objectOf(P.string),
  cliente: P.objectOf(P.string),
  disabledField: P.bool,
};

Sinistro.defaultProps = {
  sinistro: null,
  segurosAtivos: null,
  disabledField: false,
  cliente: null,
};
