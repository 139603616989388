/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { FormGroup } from '../styles';
import { CommonInvoiceInsuranceQuestions,
    CommonYearValueDateInsuranceQuestions
} from './commonQuestions';


export function MobileQuestions({
    onChange,
    insuranceInvoice,
    insuranceBrand,
    insuranceModel,
    insuranceManufactureYear,
    insuranceBuyAmount,
    insuranceBuyDate,
})
{
    return (
        <>
        <FormGroup dark>
            <CommonInvoiceInsuranceQuestions
                onChange={onChange}
                insuranceInvoice={insuranceInvoice}
                insuranceBrand={insuranceBrand}
                insuranceModel={insuranceModel}
            />
        </FormGroup>

        <FormGroup>
            <CommonYearValueDateInsuranceQuestions
                onChange={onChange}
                insuranceManufactureYear={insuranceManufactureYear}
                insuranceBuyAmount={insuranceBuyAmount}
                insuranceBuyDate={insuranceBuyDate}
            />
        </FormGroup>
        </>
    )
}

MobileQuestions.propTypes = {
    onChange: P.objectOf(P.any).isRequired,
    insuranceInvoice: P.string.isRequired,
    insuranceModel: P.string.isRequired,
    insuranceBrand: P.string.isRequired,
    insuranceManufactureYear: P.number.isRequired,
    insuranceBuyAmount: P.number.isRequired,
    insuranceBuyDate: P.string.isRequired,
}