import React, { useEffect, useRef, useState } from 'react';
import P from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
    Box, IconButton, Paper,
    Table, TableBody,
    TableCell,
    TableContainer, TableHead, TableRow, makeStyles,
    Collapse,
    Typography, Dialog, DialogTitle, Divider

} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { DivSeparacao } from './styles';
import { maskDateTime, maskCurrency } from '../../utils/masks';
import { AutocompleteField, DateField, MaskedTextField, TextFieldAdorned } from '../FormFields';
import { Container, FormGroup, LoadingContainer, LoadingDiv } from '../LeadForm/styles';
import { Beneficiary } from '../LeadForm/beneficiary';
import api from '../../service/api';




function Row(props) {

    const StyledBoxesHeader = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 12,
    };

    const StyledBoxes = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 6,
    };
    const StyledBoxesWidth = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 6,
        width: 280,
    };

    const { row } = props;
    const [open, setOpen] = useState(false);

    const verificarSeIdEstaNaLista = (id) => {
        const arrayOptions = row.observation.split(',').map(item => Number(item));
        if (arrayOptions.length > 0) {
            const verificacao = arrayOptions.some(option => option === id);
            return verificacao;
        }
        return false;
    }

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        tableCell: {
            fontSize: '14px', // Defina o tamanho da fonte conforme necessário
            whiteSpace: 'nowrap', // Impede a quebra de linha
        },
    });

    const classes = useStyles();

    const handleChange = (key, event, obj) => {


    }


    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.tableCell} >{row.insurance ? row.insurance.name : ''}</TableCell>
                <TableCell className={classes.tableCell} >{maskDateTime(row.sinister_date)}</TableCell>
                <TableCell className={classes.tableCell} >{maskDateTime(row.finish_date || '')}</TableCell>
                <TableCell className={classes.tableCell} >{row.situation}</TableCell>
                <TableCell className={classes.tableCell} >{row.status}</TableCell>



            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                    
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}>Data do aviso do sinistro</TableCell>
                                        <TableCell className={classes.tableCell} >Data envio documentos seguradora</TableCell>
                                        <TableCell className={classes.tableCell}>Tipo de indenização</TableCell>
                                        <TableCell className={classes.tableCell}>Tem boletim de ocorrência:</TableCell>
                                        <TableCell className={classes.tableCell}>Reclamante</TableCell>
                                        <TableCell className={classes.tableCell}>Valor da franquia</TableCell>
                                        <TableCell className={classes.tableCell}>Valor a ser pago pela seguradora</TableCell>
                                        <TableCell className={classes.tableCell}>Data liberação valor ao cliente</TableCell>
                                        <TableCell className={classes.tableCell}>Explicação sobre o sinistro:</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableCell>{maskDateTime(row.sinister_alert_date || '')}</TableCell>
                                    <TableCell>{maskDateTime(row.sinister_documents_date || '')}</TableCell>
                                    <TableCell>{row.type_compensation || ''}</TableCell>
                                    <TableCell>{row.incident_report || ''}</TableCell>
                                    <TableCell>{row.complainant || ''}</TableCell>
                                    <TableCell>{maskCurrency(row.franchise_value || '')}</TableCell>
                                    <TableCell>{maskCurrency(row.amount_paid_insurancecompany || '')}</TableCell>
                                    <TableCell>{maskDateTime(row.release_date || '')}</TableCell>
                                    <TableCell>{row.explanation || ''}</TableCell>
                                </TableBody>
                            </Table>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: P.objectOf(P.string).isRequired,
};





export default function ModalHistoricoSinistro({ title, open, setOpen, sinistros }) {

    const handleClose = () => {
        setOpen(false);
    };


    const [openRow, setOpenRow] = useState(false);

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        tableCell: {
            fontSize: '18px', // Defina o tamanho da fonte conforme necessário
            whiteSpace: 'nowrap', // Impede a quebra de linha
        },
    });

    const classes = useStyles();


    return (
        <>
            <Dialog onClose={handleClose}
                aria-labelledby="form-dialog-title"
                open={open}
                maxWidth="md"
                fullWidth

                style={{ zIndex: 15615 }}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <h3>{title}</h3>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>

                </DialogTitle>
                <DivSeparacao>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell className={classes.tableCell}>Seguro</TableCell>
                                    <TableCell className={classes.tableCell}>Data do Sinistro</TableCell>
                                    <TableCell className={classes.tableCell}>Data Finalização</TableCell>
                                    <TableCell className={classes.tableCell} >Situação</TableCell>
                                    <TableCell className={classes.tableCell} >Status</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sinistros.map((row) => (
                                    <Row key={row.id} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DivSeparacao>

            </Dialog>
        </>
    )
}

ModalHistoricoSinistro.propTypes = {
    open: P.bool.isRequired,
    title: P.string.isRequired,
    setOpen: P.func.isRequired,
    sinistros: P.objectOf(P.string),
};

ModalHistoricoSinistro.defaultProps = {
    sinistros: null,
};

