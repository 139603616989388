import { dataCreate, dataDelete, dataEdit } from "./data.service";

const createLeadBrokerConfig = async (newData) => {
    const response = await dataCreate('/lead-broker-config', newData);
    return response;
  };

  const editLeadBrokerConfig = async (newData, dataID) => {
    const response = await dataEdit('/lead-broker-config', newData, dataID);
    return response;
  };

  const deleteLeadBrokerConfig = async (dataID) => {
    const response = await dataDelete('/lead-broker-config', dataID);
    return response;
  };

  export { createLeadBrokerConfig, editLeadBrokerConfig, deleteLeadBrokerConfig };