/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Header, Container, ExportReportWrapper } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import Box from '@material-ui/core/Box';
import DatePeriodFilter from '../../components/LostSaleReportTableList/Filter';
import SummaryReport from '../../components/LostSaleReportTableList/SummaryReport';
import LoadingPage from '../LoadingPage';
import useApp from '../../hooks/useApp';
import useSWR from 'swr';
import { fetcher } from '../../service/SWRFetcher';
import { DateTime } from 'luxon';
import { TablePagination } from '@material-ui/core';
import { listToMatrix } from '../../utils/array';
import { excelExport } from '../../service/excel.service';

const LostSaleReport = () => {
  const { setState } = useApp();
  const [lostSaleReport, setLostSaleReport] = useState();
  const [leadLostSale, setLeadLostSale] = useState();
  const [competitorBrokers, setCompetitorBrokers] = useState();
  const [totalLeads, setTotalLeads] = useState([]);
  const [leads, setLeads] = useState([]);
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [filters, setFilters] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
    partner_id: '',
    insurance_id: '',
    broker_id: '',
  });

  const [pagination, setPagination] = useState({
    per_page: 10,
    page: 0,
  });

  const brokerFilter = () => {
    if (!filters.broker_id || filters.broker_id.length === 0) return '';

    let stringBrokerFilter = '';
    filters.broker_id.map((b) => (stringBrokerFilter += `&broker_id[]=${b.id}`));
    return stringBrokerFilter;
  };

  const insuranceFilter = () => {
    if (!filters.insurance_id || filters.insurance_id.length === 0) return '';

    let stringInsuranceFilter = '';
    filters.insurance_id.map((i) => (stringInsuranceFilter += `&insurance_id[]=${i.id}`));
    return stringInsuranceFilter;
  };

  const { data, mutate, isValidating } = useSWR(
    `/leads/lostSaleReport?date_ini=${filters.date_ini}&date_end=${filters.date_end}&partner_id=${
      filters.partner_id
    }${insuranceFilter()}${brokerFilter()}`,
    fetcher
  );
  useEffect(() => {
    if (data?.length > 0) {
      setLostSaleReport(data[0]);
      setLeadLostSale(data[1]);
      setCompetitorBrokers(data[2]);
    }
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setPagination({ ...pagination, page: 0, per_page: parseInt(event.target.value) });
    mutate();
  };

  useEffect(() => {
    if (data && data[3]?.length > 2 && !isValidating) {
      const leadsMatrix = listToMatrix(data[3], pagination.per_page);
      setTotalLeads(leadsMatrix);
      setLeads(leadsMatrix[pagination.page]);
    }
  }, [data, isValidating]);

  useEffect(() => {
    setLeads(totalLeads[pagination.page]);
  }, [pagination.page]);

  useEffect(() => {
    setState('loading', isValidating);
  }, [isValidating]);

  return (
    <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
      <Container>
        <Header>
          <h1>Relatório de Perdas</h1>
        </Header>

        {lostSaleReport && leadLostSale && competitorBrokers && (
          <>
            <DatePeriodFilter filters={filters} setFilters={setFilters} />
            <SummaryReport
              listItem={lostSaleReport[0] || []}
              title="Motivo da perda"
              c1="Motivo"
              c2="Percentual"
              c3="Quantidade"
              name_id={leadLostSale}
            />
            <Box m={5} />
            <SummaryReport
              listItem={lostSaleReport[1] || []}
              title="Ranking Corretor"
              c1="Corretor"
              c2="Percentual"
              c3="Quantidade"
              withName={true}
            />
            <Box m={5} />
            <SummaryReport
              listItem={lostSaleReport[2] || []}
              title="Ranking Seguradora Concorrente"
              c1="Seguradora"
              c2="Percentual"
              c3="Quantidade"
              name_id={competitorBrokers}
            />
            <Box m={5} />
            <SummaryReport
              listItem={lostSaleReport[3] || []}
              title="Ranking Indicador"
              c1="Indicador"
              c2="Percentual"
              c3="Quantidade"
              withName={true}
            />
            <Box m={5} />
            <SummaryReport
              listItem={lostSaleReport[4] || []}
              title="Ranking Concessionária"
              c1="Concessionária"
              c2="Percentual"
              c3="Quantidade"
              withName={true}
            />
            <Box m={5} />
            <SummaryReport
              listItem={lostSaleReport[5] || []}
              title="Eficiência"
              c1=""
              c2="Percentual"
              c3="Quantidade"
              withName={true}
              noTotal
            />

            <Box m={5} />
            <TablePagination
              component="div"
              count={data && data[3] ? data[3]?.length : 0}
              page={pagination.page}
              onPageChange={handleChangePage}
              rowsPerPage={pagination.per_page}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <SummaryReport
              listItem={leads || []}
              title="Clientes"
              c1="Cliente"
              c2="Corretor"
              c3="Motivo"
              withName={true}
              defaultTableBody={false}
            />
            <Box m={5} />
          </>
        )}

        {lostSaleReport && leadLostSale && competitorBrokers && (
          <ExportReportWrapper>
            <button
              className="button"
              onClick={() =>
                excelExport(
                  `/leads/lostSaleReportExport?date_ini=${filters.date_ini}&date_end=${filters.date_end}&partner_id=${
                    filters.partner_id
                  }${insuranceFilter()}${brokerFilter()}`,
                  'relatorio_perdas_' + DateTime.now().toFormat('dd_LL_yyyy')
                )
              }>
              Exportar
            </button>
          </ExportReportWrapper>
        )}
      </Container>
    </motion.div>
  );
};

export default LostSaleReport;
