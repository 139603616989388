/* eslint-disable */
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { DateTime } from 'luxon';
import { diffTime, msToTime } from '../../utils/timeMath';
import BusinessTimeMs from '../../utils/businessTime';
import { useMemo } from 'react';


const SummaryReport = ({ leads }) => {

  const captureOnTime = (type) => {
    let captureMilliseconds = 0;
    leads?.map((l) => {
      let firstTime = DateTime.now();
      let lastTime = DateTime.now();
      switch (type) {
        case 1:
          firstTime = l.create_lead_date;
          lastTime = l.capture_date;
          break;

        case 2:
          firstTime = l.capture_date
          lastTime = l.first_call_date;
          break;

        case 3:
          firstTime = l.create_lead_date;
          lastTime = l.first_call_date;
          break;

        default:
          break;
      }
       captureMilliseconds += BusinessTimeMs(firstTime, lastTime).ms
    });
    if(captureMilliseconds == 0) return null;
    return msToTime(captureMilliseconds / leads.length);
  };



  const statusCalls = () => {
    let answeredCalls = 0;
    let noAnsweredCalls = 0;
    let totalCalls = 0;
    leads?.map(l => {
      l.calls?.map(c => {
        totalCalls++;
        if(c.status == 2) answeredCalls++;
        else noAnsweredCalls++
      })
    })
    answeredCalls = answeredCalls * 100 / totalCalls;
    noAnsweredCalls = noAnsweredCalls * 100 / totalCalls;
    

    return {answered: `${isNaN(answeredCalls)? '- ' :  answeredCalls.toFixed(2)}%`, noAnswered: `${isNaN(noAnsweredCalls)? '- ' :  noAnsweredCalls.toFixed(2)}%`}
  };

  const callsOnTime = () => {
    let onTime = 0;
    let offTime = 0;
    leads?.map(l => {
      if(l.first_call_on_time == '1') onTime++;
      else offTime++
    })
    onTime = onTime * 100 / leads?.length;
    offTime = offTime * 100 / leads?.length;
    return {onTime: `${isNaN(onTime)? '- ' : onTime.toFixed(2)}%`, offTime: `${isNaN(offTime)? '- ' : offTime.toFixed(2)}%`}
  };

  const teste1 = useMemo(() => captureOnTime(1), [leads])
const teste2 = useMemo(() => captureOnTime(2), [leads])
const teste3 = useMemo(() => captureOnTime(3), [leads])
const onTime = useMemo(() => callsOnTime(), [leads])
const status = useMemo(() => statusCalls(), [leads])

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell width="30%" style={{minWidth:'380px'}}>Media de tempo de captura do lead desde a criação do lead:</TableCell>
              <TableCell>{teste1 || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%">Media de tempo da primeira ligação desde a captura do lead:</TableCell>
              <TableCell>{teste2 || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%">Media de tempo da primeira ligação desde a criação do lead:</TableCell>
              <TableCell>{teste3 || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%">% dentro da meta:</TableCell>
              <TableCell>{onTime.onTime || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%">% fora da meta:</TableCell>
              <TableCell>{onTime.offTime || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%">% de Ligações Atendidas:</TableCell>
              <TableCell>{status.answered || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%">% de Ligações Não Atendidas:</TableCell>
              <TableCell>{status.noAnswered || '-'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SummaryReport;
