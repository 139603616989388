/* eslint-disable */
import useSWR from 'swr';
import { useState } from 'react';
import { Nav, NavbarForm } from './styles';
import { fetcher } from '../../../service/SWRFetcher';

const DatePeriodFilter = ({ setFilters, filters }) => {
  const { data: users, isValidating} = useSWR(`/users`, fetcher);

  function handleSubmit(event) {
    event.preventDefault();
    setFilters((f) => ({ ...f, ...filt }));
  }

  const handleChange = (e) => {
    setFilt({ ...filters, [e.target.name]: e.target.value });
  };

  const [filt, setFilt] = useState({
    name: '',
    login: '',
    status: '',
  });

  return (
    <Nav>
      <div className="filter">Filtros</div>
      <NavbarForm>
        <form className="form" onSubmit={handleSubmit}>
          <div className="client">
            <input
              list="select"
              name="name"
              className="select-form"
              placeholder="Usuário"
              value={filt.name}
              onChange={handleChange}
              disabled={isValidating}
            />
            <datalist id="select">
              {users?.map((item) => (
                <option key={item.id} value={item.name}>
                  {' '}
                </option>
              ))}
            </datalist>
          </div>
          <div className="login">
            <input
              name="login"
              className="select-form"
              placeholder="Login"
              value={filt.login}
              onChange={handleChange}
            />

          </div>
          <div className="login">
            <select
              name="status"
              className="select-form"
              placeholder="Ativo?"
              value={filt.status}
              onChange={handleChange}
            >
            <option defaultChecked value="">Status</option>
            <option value="1">Ativos</option>
            <option value="0">Inativos</option>
            </select>
          </div>

          <div>
            <button className="button" onClick={() => setFilt({ name: '', login: '' })}>
              Limpar
            </button>
            <button className="button" type="submit">
              Filtrar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
