/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Toolbar, Typography, Divider, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { MainListItems } from './listItems';
import { Bar, SideMenu, Container } from './styles';
import LogoUser from '../../../components/LogoUser';
import MainRoutes from '../../../router/MainRoutes';
import { MainContext } from '../../../context/ApiContext';
import { useAuth } from '../../../hooks/useAuth';
import useApp from '../../../hooks/useApp';
import { FullPageLoading } from '../../../components/FullPageLoading';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    width: 56,
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function MainLayout() {
  const { user } = useAuth();
  const {loading} = useApp();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState();
  const [newUsers, setNewUsers] = useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Bar className={open ? 'shift' : ''}>
        <Toolbar className="toolbar">
          <IconButton
            edge="start"
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={('menuButton', open ? 'menuButtonHidden' : '')}>
            <MenuIcon />
          </IconButton>
          <Typography noWrap style={{ flexGrow: 1 }} />
          <LogoUser user={user?.data}>
            {user && `${user.name}`}
          </LogoUser>
        </Toolbar>
      </Bar>
      <SideMenu
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}>
        <div className="menu-icon">
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {user?.id && <MainListItems newUsers={newUsers} menuClick={handleDrawerClose}/>}
      </SideMenu>
      <main className={classes.main}>
        <div id='mainLayout' style={{ width: '100%', height: 30, backgroundColor: '#cdcdcd' }} />
        {loading && <FullPageLoading transparent/>}
        <MainRoutes />
      {/* <FullPageLoading transparent/>

        <MainRoutes /> */}
      </main>
    </Container>
  );
}
