import { useEffect } from 'react';
import RegisterForm from '../../components/RegisterForm';
import { Container } from './style';

const Register = () => (
    <Container>
      <RegisterForm />
    </Container>
  );

export default Register;
