/* eslint-disable import/no-cycle */
import api from './api';

export const dataAll = async (uri, filter = {params: {}}) => {
    const {
      data: { data: resp },
    } = await api.get(uri, filter);
    return resp;
};

export const dataDelete = async (uri, id) => {
    const {
      data: { success },
    } = await api.delete(`${uri}/${id}`);
    return success;
};

export const dataCreate = async (uri, createdData) => {
    const {
      data: { data },
    } = await api.post(uri, createdData);
    return data;
};

export const dataEdit = async (uri, editedData, id) => {
    const {
      data: { data },
    } = await api.put(`${uri}/${id}`, editedData);
    return data;
};
