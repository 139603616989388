/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Box } from '@material-ui/core';
import P from 'prop-types';
import { FunnilConnectRows, FunnilRow } from './styled';
import { FunnelData } from './FunnelData';

const dataStyled = {
  rest: {
    x: 0,
  },
  hover: {
    x: -40,
  },
};

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

export const FunnelCharts = ({ size, data, onClick }) => {
  const { width: wid, height: hei, ref } = useResizeDetector();
  const [hData, setHData] = useState();
  const [hConnector, setHConnector] = useState();
  const [proporcao, setProporcao] = useState();

  useEffect(() => {
    setProporcao((wid - (hei > 200 ? 100 : 50)) / (data.length - 1));
  }, [wid]);

  useEffect(() => {
    setHData((parseFloat(size.replace('px', '')) / data.length) * 0.77);
    setHConnector((parseFloat(size.replace('px', '')) / data.length) * 0.23);
  }, [hei]);

  return (
    <Box
      ref={ref}
      width={size}
      height={size}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      overflow="hidden"
      onClick={onClick}
      >
      {data.map((d, i) => (
        <Container initial="rest" whileHover="hover" animate="rest">
          <FunnilRow width={wid - proporcao * i} height={hData}>
            <FunnelData data={d} hData={hData} wData={wid - proporcao * i} />
          </FunnilRow>

          {i < data.length - 1 && (
            <FunnilConnectRows
              bg="lightgray"
              height={hConnector}
              topWidth={wid - proporcao * i}
              bottomWidth={wid - proporcao * (i + 1)}
            />
          )}
        </Container>
      ))}
    </Box>
  );
};

FunnelCharts.propTypes = {
  size: P.string.isRequired,
  data: P.arrayOf(
    P.shape({
      title: P.string.isRequired,
      values: P.arrayOf(
        P.shape({
          title: P.string,
          value: P.number,
        })
      ),
      total: P.number,
    })
  ).isRequired,
  onClick: P.func,
};

FunnelCharts.defaultProps = {
  onClick: ()=> {},
}
