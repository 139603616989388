import { Badge, ListItem, ListItemIcon, ListItemText, Tooltip, useMediaQuery, withStyles } from '@material-ui/core';
import { forwardRef, useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import P from 'prop-types';
import { GetMenuItemsToHide } from '../../../service/visibilityConfig.service.ts';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 40,
    top: '80%',
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: '50%',
    fontSize: '0.8rem',
    backgroundColor:'#f26522',
    color: '#fff',
    zIndex: 100,
    padding: '5px',
  },
}))(Badge);
export default function ListItemLink({ icon, text, to, count, disable, customLink }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const CustomLink = useMemo(() => 
    forwardRef((linkProps, ref) =>  
      ( 
        <>
          <Link ref={ref} to={!disable ? to : "/"} {...linkProps} /> 
        </>
      )
    ), [to]
  );
  const matchesH = useMediaQuery('(max-height:900px)');
  const [menuItemsToHide, setMenuItemsToHide] = useState([]);

  useEffect(() => {
    setMenuItemsToHide(GetMenuItemsToHide());
  }, []);
  

  return (
    <>
    
    { !menuItemsToHide.includes(to) &&
    ( 
      <ListItem style={{paddingTop: matchesH ? '5px' : '8px'}} 
        button component={CustomLink} >
        <StyledBadge badgeContent={count}>
          <Tooltip title={text} placement="top-start" arrow>
            <ListItemIcon>{icon}</ListItemIcon>
          </Tooltip>
        </StyledBadge>
        <ListItemText 
          primaryTypographyProps={{
            style: {fontFamily: 'system-ui', 
                    lineHeight: 1, 
                    fontSize: '1.15rem', 
                    letterSpacing: 0}
          }} 
          primary={text} />
      </ListItem>
    )}
    </>
  );
}

ListItemLink.propTypes = {
  icon: P.element.isRequired,
  text: P.string.isRequired,
  to: P.string.isRequired,
  count: P.number,
  disable: P.bool,
  customLink: P.func,
};

ListItemLink.defaultProps = {
  count: 0,
  disable: false,
  customLink: null,
};
