/* eslint-disable react/prop-types */
import { useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, TableFooter, Button } from '@material-ui/core';
import { AddCircleRounded, EditTwoTone, DeleteForever } from '@material-ui/icons';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import P, { shape } from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import CommissionPercents from './CommissionPercents';

const CommissionsSchemaTableList = ({ listItem, deleteSchema, title, mutate }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [schemaName, setSchemaName] = useState(null);
  const [commissionSchema, setCommissionSchema] = useState(null);
  const [dialog, setDialog] = useState({
    msg: '',
    title: '',
  });

  const handleEdit = (item) => {
    setCommissionSchema(item)
    setSchemaName(item.name);
    setOpenEdit(true);
  };

  const handleDelete = (item) => {
    if(window.confirm('Confirma Remover essa Tabela?')) {
      deleteSchema(item.id);
    }
  };


  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        msg={dialog.msg.length > 0 ? dialog.msg : 'Dialog'}
        title={dialog.title.length > 0 ? dialog.title : 'Dialog'}
      />
      
      <CommissionPercents 
        open={openEdit} 
        setOpen={setOpenEdit} 
        commissionSchema={commissionSchema} 
        schemaName={schemaName} 
        mutate={mutate}
      />
      
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="50%">Tabela</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItem !== null &&
              listItem.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell>
                    {item.name}
                  </TableCell>
                  <TableCell>
                    {item.description}
                  </TableCell>
                  <TableCell>
                    {item.status == "1" ? 'Ativo' : 'Inativo'}
                  </TableCell>
                  <TableCell align="right">
                  {item.verify?.verified === 0 && (
                      <IconButton onClick={() => handleEdit(item)}>
                        <FiberNewIcon color="action" />
                      </IconButton>
                    )}
                    <IconButton onClick={() => handleEdit(item)}>
                      <EditTwoTone />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(item)}>
                      <DeleteForever />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          
        </Table>
      </TableContainer>
    </>
  );
};

CommissionsSchemaTableList.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
  title: P.string.isRequired,
};

CommissionsSchemaTableList.defaultProps = {
  listItem: null,
};

export default CommissionsSchemaTableList;
