/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import useSWR from 'swr';
import useApp from '../../hooks/useApp';
import { createLeadBrokerConfig, editLeadBrokerConfig } from '../../service/leadBrokerConfig.service';
import { fetcher } from '../../service/SWRFetcher';


export default function LeadBrokerConfigForm ({ open, setOpen, title, leadBrokerConfig, mutate }) {
  const {channels, partners, insurances} = useApp();
  
  const [values, setValues] = useState({
    channels: "",
    partners: "",
    insurances: "",
    users: "",
    status: false,
  });

  const { data: users } = useSWR(`/users`, fetcher);

  const entering = () => {
    setValues({
      channels: leadBrokerConfig?.channel || '',
      partners: leadBrokerConfig?.partner || '',
      insurances: leadBrokerConfig?.insurance || '',
      users: leadBrokerConfig?.broker || '',
      status: leadBrokerConfig?.status || false,
    });
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    const editedLeadConfigBroker = {
      channel_id: values.channels.id,
      partner_id: values.partners.id,
      insurance_id: values.insurances.id,
      broker_id: values.users.id,
      status: values.status,
    };
    
    try {
      if(leadBrokerConfig){
        await editLeadBrokerConfig(editedLeadConfigBroker, leadBrokerConfig.id);
        toast.success('Lead modificada com sucesso');
        mutate();
      }
      else{
        await createLeadBrokerConfig(editedLeadConfigBroker);
        toast.success('Lead criada com sucesso');
        mutate();
      }
    } catch (error) {
      toast.error('Ocorreu um erro');
    }
    handleClose();
  };

  const handleChangeAutoComplete = (event, newValue, variable) => {
    if (newValue) {
      setValues({
        ...values,
        [variable]: newValue,
      });
    }
  };

  return (
    <div>
      <Dialog
        onEntering={entering}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {title}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={submit}>
          <DialogContent>
            <Autocomplete
              // multiple
              options={channels.filter((i) => i.id <= 1)}
              value={values.channels}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name || ""}
              onChange={(event, newValue) => {
                handleChangeAutoComplete(event, newValue, 'channels');
              }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Canal" required />}
            />
            <Autocomplete
                // multiple
                options={partners.filter((i) => i.code_wf != null)}
                value={values.partners}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name || ""}
                onChange={(event, newValue) => {
                    handleChangeAutoComplete(event, newValue, 'partners');
                }}
                renderInput={(params) => <TextField {...params} variant="standard" label="Empresa" required />}
            />
            <Autocomplete
                // multiple
                options={insurances.filter((i) => i.id <= 3)}
                value={values.insurances}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name || ""}
                onChange={(event, newValue) => {
                    handleChangeAutoComplete(event, newValue, 'insurances');
                }}
                renderInput={(params) => <TextField {...params} variant="standard" label="Tipo de Estoque" required />}
            />
            <Autocomplete
                //  multiple
                options={users?.filter((u) => u.roles[0]?.id == 3)}
                value={values.users}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name || ""}
                onChange={(event, newValue) => {
                    handleChangeAutoComplete(event, newValue, 'users');
                }}
                renderInput={(params) => <TextField {...params} variant="standard" label="Corretor" required />}
            />
            {leadBrokerConfig && (
            <FormControlLabel
                control={
                  <Checkbox
                    checked={values.status}
                    onChange={(e) => setValues({ ...values, status: e.target.checked })}
                    color="default"
                  />
                }
                label="Configuração ativa"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Salvar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

LeadBrokerConfigForm .propTypes = {
    open: P.bool.isRequired,
    title: P.string.isRequired,
    setOpen: P.func.isRequired,
    leadBrokerConfig: P.objectOf(P.string),
};

LeadBrokerConfigForm .defaultProps = {
    leadBrokerConfig : null,
};
