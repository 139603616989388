/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { FormGroup } from '../styles';
import { TextField, AutocompleteField } from '../../FormFields';
import { CommonResidenceAndBusinessQuestions } from './commonQuestions';


export const InsuranceResidenceHomeHabitationArray = [
    { name: 'Habitual', id: 1 },
    { name: 'Veraneio', id: 2 },
];

export function ResidenceQuestions({
    insuranceResidenceHomeType,
    insuranceResidenceHomeHabitation,
    insuranceResidenceAmount,
    insuranceResidenceOwner,
    insuranceResidenceNew,
    onChange,
    onChangeAutoComplete,
    onChangeNumeric
})
{
    
    return (
        <FormGroup dark>
            <TextField
                value={insuranceResidenceHomeType || ''}
                onChange={onChange}
                varibleName="insurance_residence_homeType"
                placeholder="Casa ou Apartamento"
                className="control ms"
                label="Tipo do imóvel"
            />
            <AutocompleteField
                medium
                value={insuranceResidenceHomeHabitation || null}
                onChange={onChangeAutoComplete}
                valueText={insuranceResidenceHomeHabitation ? insuranceResidenceHomeHabitation.name : ''}
                options={InsuranceResidenceHomeHabitationArray}
                varibleName="insurance_residence_homeHabitation"
                className="control m"
                label="Moradia"
            />
            <CommonResidenceAndBusinessQuestions
                insuranceAmount={insuranceResidenceAmount}
                insuranceOwner={insuranceResidenceOwner}
                insuranceNew={insuranceResidenceNew}
                onChangeNumeric={onChangeNumeric}
                onChangeAutoComplete={onChangeAutoComplete}
            />
        </FormGroup>
    )
};

ResidenceQuestions.propTypes = {
    insuranceResidenceHomeType: P.string.isRequired,
    insuranceResidenceHomeHabitation: P.objectOf(P.any).isRequired,
    insuranceResidenceAmount: P.string.isRequired,
    insuranceResidenceOwner: P.objectOf(P.any).isRequired,
    insuranceResidenceNew: P.objectOf(P.any).isRequired,
    onChange: P.objectOf(P.any).isRequired,
    onChangeAutoComplete: P.objectOf(P.any).isRequired,
    onChangeNumeric: P.objectOf(P.any).isRequired,
}

ResidenceQuestions.defaultProps = {
    
}