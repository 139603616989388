import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;

  /* max-width: 800px; */
`;

export const NavbarForm = styled.div`
  display: flex;
  align-items: center;

  .date_ini,
  .date_end {
    margin-left: 4px;
  }
  .broker{
    margin-left: 4px;
    input,
    select {
      font: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      width: 100%;
      color: #333;
      border: none;
      height: 34px;
      outline: none;
      display: block;
      padding: 6px 12px;
      border-radius: 8px;
      transition: all 0.2s ease;
      background-color: #ecf0f1;
      border-bottom: 2px solid transparent;

      :hover {
        border-color: #f26522;
      }
    }
  }
  .filter {
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    color: #777;
  }
  .filter-btn {
    display: flex;
    margin-left: 20px;
    button {
      height: 34px;
      white-space: nowrap;
      padding: 5px 15px;
    }
  }

  .name {
    margin-right: 5px;
  }
  .lead_id {
    margin-right: 5px;
  }

  input {
    font: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 100%;
    color: #333;
    border: none;
    height: 34px;
    outline: none;
    display: block;
    padding: 6px 12px;
    border-radius: 8px;
    transition: all 0.2s ease;
    background-color: #ecf0f1;
    border-bottom: 2px solid transparent;

    :hover {
      border-color: #f26522;
    }
  }
`;

export const Links = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fields {
    display: flex;
  }

  button {
    white-space: nowrap;
    list-style: none;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #777;
    + button {
      margin-left: 10px;
    }
  }
`;
