
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import {motion} from 'framer-motion'

export const ButtonMenu = styled.button`
  && {
    border: none;
    display: flex;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    line-height: 1.3;
    min-width: 120px;
    padding: 6px 12px;
    user-select: none;
    text-align: center;
    border-radius: 6px;
    white-space: normal;
    align-items: center;
    text-decoration: none;
    background-color: #e7e7e7;
    flex-direction: column-reverse;
    transition: all .15s ease-in-out;
    font-family: 'DM Sans', sans-serif;
    .count {
      font-size: 18px;
      font-weight: 700;
    }
    &.blue {
      color: #2980b9;
      &.active,
      &:hover {
        background-color: #2980b9;
      }
    }
    &.purple {
      color: #8e44ad;
      &.active,
      &:hover {
        background-color: #8e44ad;
      }
    }
    &.green {
      color: #27ae60;
      &.active,
      &:hover {
        background-color: #27ae60;
      }
    }
    &.sea {
      color: #16a085;
      &.active,
      &:hover {
        background-color: #16a085;
      }
    }
    &.yellow {
      color: #f39c12;
      &.active,
      &:hover {
        background-color: #f39c12;
      }
    }
    &.gray {
      color: #7f8c8d;
      &.active,
      &:hover {
        background-color: #7f8c8d;
      }
    }
    &.orange {
      color: #d35400;
      &.active,
      &:hover {
        background-color: #d35400;
      }
    }
    &.active,
    &:hover {
      color: #fff;
      z-index: 100;
      position: relative;
      text-decoration: none;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 6px 12px;
    border-radius: 6px;
    transition: all 0.15s ease-in-out;
    .MuiListItemText-root { margin: 0; }
    .MuiListItemIcon-root,
    .MuiListItemText-primary {
      color: #333;
      display: flex;
      font-size: 16px;
      font-weight: 500;
      align-items: center;
      justify-content: space-between;
      font-family: 'DM Sans', sans-serif;
    }
    :focus {
      background-color: #f1f1f1;
    }
    .count {
      width: 20px;
      color: #999;
      font-weight: 400;
      text-align: center;
    }
  }
`;

// export const SubMenu = styled.nav`
  // left: 230px;
  // z-index: 100;
  // padding: 16px;
  // margin-top: 16px;
  // min-width: 260px;
  // position: absolute;
  // border-radius: 6px;
  // background-color: #fff;
  // box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
//   ol {
//     li {
//       color: #333;
//       display: flex;
//       font-size: 16px;
//       cursor: pointer;
//       font-weight: 500;
//       padding: 6px 12px;
//       border-radius: 6px;
//       align-items: center;
//       text-decoration: none;
//       justify-content: space-between;
//       transition: all 0.15s ease-in-out;
//       + li { margin-left: 0 !important; }
//       :hover {
//         background-color: #f1f1f1;
//       }
//     }
//   }
// `;

export const SubMenu = styled(motion.div)`
  top: 63px;
  z-index: 300;
  display: block;
  position: absolute;

  top: 60px;
  left: -50%;

  .sub-menus-content {
    padding: 16px;
    min-width: 400px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      display: flex;
      flex-direction: column;

      .item {
        margin-left: 0;

        & + .item{
          margin-left: 0;

        }
      }

      button {
        text-align: left;
        color: #333;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        padding: 6px 12px;
        border-radius: 6px;
        align-items: center;
        text-decoration: none;
        justify-content: space-between;
        transition: all 0.15s ease-in-out;
        :hover {
          background-color: #f1f1f1;
        }
        small {
          color: #ccc;
          display: block;
        }
        .count {
          width: 20px;
          color: #999;
          font-weight: 400;
          text-align: center;
          margin-left: 2px;
        }
      }
    }
  }
`;

export const MiniMenu = styled(motion.div)`
    left: 100%;
    top: -15px;
    z-index: 100;
    padding: 16px;
    min-width: 260px;
    position: absolute;
    display: block;

    .sub-menus-content{
      min-width: 200px;

      button {
        text-align: left;
        color: #333;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        padding: 6px 12px;
        border-radius: 6px;
        align-items: center;
        text-decoration: none;
        justify-content: space-between;
        transition: all 0.15s ease-in-out;
        :hover {
          background-color: #f1f1f1;
        }
        small {
          color: #ccc;
          display: block;
        }
        .count {
          width: 20px;
          color: #999;
          font-weight: 400;
          text-align: center;
          margin-left: 2px;
        }
      }
    }
`;

export const Shadow = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${props => props.open ? 1 : 0};
  pointer-events: ${props => props.open ? 'auto' : 'none'};
  position: fixed;
  transition: all 0.15s ease-in-out;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 200;
`;
