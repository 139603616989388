/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable */
import React, { useContext, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import P, { shape } from 'prop-types';
import DetailsTable from './DetailsTable';
import { useAuth } from '../../hooks/useAuth';

const ClientTableList = ({ listItem, title, stringValues, filters, setLead, mutate }) => {
  const{ user } = useAuth();

  const clientFilter = (l) => {
    if (l.length === 0) {
      return false;
    }
    if (filters.client && l.id != filters.client) return false;
    return true;
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell width="5%"></TableCell>
              <TableCell width="5%">ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Celular</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItem &&
              listItem
                .filter((l) => clientFilter(l))
                .map((item) => (
                  <DetailsTable
                    item={item}
                    stringValues={stringValues}
                    title={title}
                    setLead={setLead}
                    user={user.data}
                    mutate={mutate}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

ClientTableList.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
  title: P.string.isRequired,
};

ClientTableList.defaultProps = {
  listItem: null,
};

export default ClientTableList;
