/* eslint-disable */
import { Header } from '../../styles/shared/styles';
import { ExposeCharts } from '../../components/ExposeCharts';
import { Box, useMediaQuery } from '@material-ui/core';

const FunnelChart = () => {
  const matchesW = useMediaQuery('(max-width:1100px)');
  return (
  <>
    <div initial={{ opacity: 0}} animate={{ opacity: 1,}} exit={{ opacity: 0}}>
      {/* <Container> */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems={matchesW ? 'flex-start' : 'center'} height="92%">
        <Header style={{marginBottom: '20px'}} >
          <h1>Funil de vendas</h1>
        </Header>
          <ExposeCharts />
        </Box>
      {/* </Container> */}
    </div>
  </>
)};

export default FunnelChart;
