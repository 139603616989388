import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { IconButton, InputAdornment, Input, InputLabel, Button, FormHelperText } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import EmailIcon from '@material-ui/icons/Email';
import { FormContainer, InputControl, Container } from './styles';
import {LogoVertical} from '../Logo';
import { auth } from '../../service/auth.service';
import { useAuth } from '../../hooks/useAuth';

const LoginForm = () => {
  const {updateUser} = useAuth();
  const history = useHistory();
  const [values, setValues] = useState({
    login: '',
    password: '',
    showPassword: false,
    redirect: false,
    errStatus: null,
    errMsg: '',
  });

  useEffect(() => {
    if (values.errStatus === 500) toast.error(values.errMsg);
    if (values.errStatus === 404) toast.warning(values.errMsg);
    setValues({ ...values, errStatus: '' })
  }, [values.errStatus]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handlerLogin = async (e) => {
    e.preventDefault();
    const { success, msg, status } = await auth(values.login, values.password);
    if (success) {
      updateUser();
      history.push('/')
    }
    else setValues({ ...values, errStatus: status, errMsg: msg });
  };

  return (
    <Container>
      {values.redirect && <Redirect to="/" />}
      <LogoVertical />
      <FormContainer elevation={1}>
        <form onSubmit={handlerLogin}>
          <InputControl fullWidth error={!!values.errMsg}>
            <InputLabel htmlFor="email">Login</InputLabel>
            <Input
              id="email"
              required
              value={values.login}
              onChange={(e) => setValues({ ...values, login: e.target.value })}
              endAdornment={
                <InputAdornment position="end">
                  <EmailIcon style={{ color: '#707070', marginRight: 12 }} />
                </InputAdornment>
              }
            />
            {values.errMsg && values.login === '' && (
              <FormHelperText id="component-error-text">Campo Obrigatório</FormHelperText>
            )}
          </InputControl>
          <InputControl fullWidth error={!!values.errMsg}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {values.errMsg && values.password === '' && <FormHelperText>Campo Obrigatório</FormHelperText>}
            {values.errMsg && <FormHelperText>{values.errMsg}</FormHelperText>}
          </InputControl>
          <div className="footer">
            <Button className="entrar" variant="contained" type="submit">
              Entrar
            </Button>
            <Link to="/register" style={{fontSize: '11px', textDecoration: 'none'}}>
              Registro de Usuário
            </Link>
          </div>
        </form>
      </FormContainer>
    </Container>
  );
};

export default LoginForm;
