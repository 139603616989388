/* eslint-disable import/no-cycle */
import api from './api';

export const TOKEN_KEY = 'token';

export const auth = async (login, password) => {
  try 
  {
    const tenantId = localStorage.getItem("tenantId");

    const 
    {
      data: { data },
    } = await api.post('/login', 
      { 
        login, 
        password,
        tenantId
      });

    localStorage.setItem(TOKEN_KEY, data.token);
    return { success: true, status: 200, msg: 'Usuário logado' };
  } 
  catch (error) {
    if (error.response) {
      const { status } = error.response;
      if (status === 404)
        return { success: false, status, msg: 'Login/senha inválidos, verifique suas credenciais.' };
    }
    return { success: false, status: 500, msg: 'Houve um problema com o servidor, tente novamente mais tarde.' };
  }
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem('login-role-5-redirect');
};

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getUserInfo = async () => {
  const tenantId = localStorage.getItem("tenantId");
  const {data} = await api.get(`/tokenconfirm?tenantId=${tenantId}`)
  return data;
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
