/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { MainContext } from '../../../context/ApiContext';
import { Nav, NavbarForm } from './styles';
import { DateTime } from "luxon";
import { AutocompleteField } from '../../FormFields';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import useSWR from 'swr';
import { fetcher } from '../../../service/SWRFetcher';
import { monthArray } from '../../../utils/array';
 
const statusList =[
  {name:'Lead Agendado', value:'2'},
  {name:'Renovador não encontrado', value:'9'},
]

const DatePeriodFilter = ({setFilters, setCheckFilter, checkFilter}) => {
  const { data: users } = useSWR(`/users`, fetcher);

  const channels = [
    {id: 6, name: 'Renovação'},
    {id: 10, name: 'Renovações perdidas ano anterior'},
    {id: 9, name: 'Cross-selling carteira'},
  ]

  function handleSubmit (event)  {
    event.preventDefault()    
    let filters = {
      reference_month: (year || "") + "" + (month?.value || "") ,
      broker_id: brokerId?.id || '',
      channel_id: channelId || [],
      client: client || '',
      status: status?.value || '',
    }
    setFilters(f=>({...f, ...filters}))
  }

  const months = monthArray();
  const [brokerId, setBrokerId] = useState("");
  const [channelId, setChannelId] = useState("");
  const [status, setStatus] = useState("");
  const [client, setClient] = useState("");
  const [year, setYear] = useState(DateTime.now().year);
  const [month, setMonth] = useState("");

  const handleChangeChannels = (event, newValue, variable) => {
    if (newValue) {
      let result = newValue.map(a => a.id);
      setChannelId( result);
    }
  };


  useEffect(() => {
    let startMonth = DateTime.now().toFormat("LL")
    let startMonthFind = months.find( element => element.value == startMonth )
    startMonthFind && setMonth(startMonthFind)
  },[])

  return (
    <Nav>    

      <NavbarForm>
        <form className="form" onSubmit={handleSubmit} style={{alignItems: "center"}}>

          <div className="filter">Ano:</div>
          <TextField
            type="number"
            className="autocomplete"
            variant="standard"
            placeholder="Ano Referência"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            style={{width : "80px"}}
          />

          <div className="filter">Mês:</div>

          <Autocomplete
            options={months}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setMonth(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Mês" />
            )}
            value={month}
            style={{width : "180px"}}
          />     

          <Autocomplete
            className="input-form"
            label="Corretor"
            options={users?.filter((u) => u.roles[0]?.id <= 3)}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setBrokerId(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Corretor" />
            )}
          />

          <TextField
            className="autocomplete"
            variant="standard"
            placeholder="Cliente"
            value={client}
            onChange={(e) => setClient(e.target.value)}
          />

          <Autocomplete
            multiple
            options={channels}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => {handleChangeChannels(e, value, 'channel');}}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Canal" />
            )}
          />     
          <Autocomplete
            options={statusList}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setStatus(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Status" />
            )}
          />     

          <div>
            <button className="button" type="submit">
              Filtrar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
