import api from './api';
import { dataCreate, dataDelete, dataEdit } from './data.service';

const getClientByCpf = async (cpf) => {
  const {
    data: { data },
  } = await api.post('/client-by-cpf', { cpf });
  if (data) return data;
  return null;
};

const getClientByName = async (name) => {
  const {
    data: { data },
  } = await api.get(`/client-by-name/${name}`);
  if (data) return data;
  return null;
};

const createClient = async (newData) => {
  const response = await dataCreate('/clients', newData);
  return response;
};

const editClient = async (newData, dataID) => {
  const response = await dataEdit('/clients', newData, dataID);
  return response;
};

const deleteClient = async (dataID) => {
  const response = await dataDelete('/clients', dataID);
  return response;
};

export { getClientByCpf, createClient, editClient, deleteClient, getClientByName };
