/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import P from 'prop-types';
import { isAuthenticated } from '../service/auth.service';
import { GetTenantRoute } from './tenantRoutes';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={({ location, ...props }) => {
      if (location.pathname.includes('/verify') || location.pathname.includes('/redefine') ) return <Component {...props} />;
      return isAuthenticated() ? <Component {...props} /> : <Redirect to={GetTenantRoute()} />;
    }}
  />
);

PrivateRoute.propTypes = {
  component: P.func.isRequired,
};

export default PrivateRoute;
