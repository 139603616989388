/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import { FormGroup } from '../styles';
import { TextField, AutocompleteField } from '../../FormFields';
import { CommonPraticeSportInsuranceQuestions } from './commonQuestions';
import { formatarParaEstiloPTBR } from '../../../hooks/Utils';

export function LifeQuestions({
    onChange,
    onChangeAutoComplete,
    onChangeNumericMonthlySalary,
    onChangeNumericCovidVaccineAmout,
    onChangeNumericDeathCoverAmout,
    insuranceLifeWork,
    insuranceLifeCivilStatus,
    insuranceLifeMonthlySalary,
    insurancePracticesSports,
    insuranceLifeSmoker,
    insuranceLifeCovidVaccineAmout,
    insuranceLifeDeathCoverAmout,
}) {
    return (
        <>
            <FormGroup dark>
                <NumericFormat
                    label="Renda Mensal"
                    customInput={TextField}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    autoComplete="off"
                    className="control ms"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    value={insuranceLifeMonthlySalary}
                    onChange={onChangeNumericMonthlySalary}
                />
                <CommonPraticeSportInsuranceQuestions
                    onChange={onChange}
                    insurancePracticesSports={insurancePracticesSports}
                />
                <FormControlLabel
                    style={{ margin: '10 10 10 10' }}
                    control={
                        <Checkbox
                            color="default"
                            onChange={onChange}
                            checked={insuranceLifeSmoker}
                            name="insurance_life_smoker"
                        />}
                    label="Fumante?"
                />
            </FormGroup>

            <FormGroup dark>
                <NumericFormat
                    label="Doses da vacina de COVID"
                    customInput={TextField}
                    decimalScale={0}
                    autoComplete="off"
                    value={insuranceLifeCovidVaccineAmout || ''}
                    onChange={onChangeNumericCovidVaccineAmout}
                />
                <NumericFormat
                    label="Valor da cobertura de morte a contratar"
                    customInput={TextField}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    autoComplete="off"
                    className="control ms"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    value={insuranceLifeDeathCoverAmout}
                    onChange={onChangeNumericDeathCoverAmout}
                />
            </FormGroup>
        </>
    )
}

LifeQuestions.propTypes = {
    onChange: P.objectOf(P.any).isRequired,
    onChangeAutoComplete: P.objectOf(P.any).isRequired,
    onChangeNumericMonthlySalary: P.objectOf(P.any).isRequired,
    onChangeNumericCovidVaccineAmout: P.objectOf(P.any).isRequired,
    onChangeNumericDeathCoverAmout: P.objectOf(P.any).isRequired,
    insuranceLifeWork: P.string.isRequired,
    insuranceLifeCivilStatus: P.string.isRequired,
    insuranceLifeMonthlySalary: P.number.isRequired,
    insurancePracticesSports: P.bool.isRequired,
    insuranceLifeSmoker: P.bool.isRequired,
    insuranceLifeCovidVaccineAmout: P.number.isRequired,
    insuranceLifeDeathCoverAmout: P.number.isRequired,
}
