import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;

  /* max-width: 800px; */

  .filter {
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    color: #777;
  }
`;

export const NavbarForm = styled.div`
  .form {
    display: flex;
    > div {
      padding-left: 4px;
      padding-right: 4px;
    }

    .name {
      margin-right: 5px;
    }

    .inputs {
      display: flex;
      input,
      select {
        font: inherit;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        width: 100%;
        color: #333;
        border: none;
        height: 34px;
        outline: none;
        display: block;
        padding: 6px 12px;
        border-radius: 8px;
        transition: all 0.2s ease;
        background-color: #ecf0f1;
        border-bottom: 2px solid transparent;

        :hover {
          border-color: #f26522;
        }
      }
    }

    input,
    select {
      width: 100%;
      color: #333;
      border: none;
      outline: none;
      border-radius: 8px;
      transition: all 0.2s ease;
      border-bottom: 2px solid transparent;

    }
    .autocomplete > div {
      background-color: #ecf0f1;
      border-radius: 8px;
      ::before{
        border-radius: 8px;
      }
      ::after{
        border-bottom: none;
      }
      :hover {
        ::before{
          border-color: #f26522;
        }
      }
    }

    .button {
      cursor: pointer;
      color: #fff;
      border: none;
      font-weight: 700;
      padding: 8px 16px;
      text-align: center;
      border-radius: 8px;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      background-color: #f26522;
      text-transform: uppercase;
      font-family: 'DM Sans', sans-serif;
    }
  }
`;

export const Links = styled.div`
  display: flex;
  a {
    list-style: none;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #777;
  }
`;

export const Form = styled.div`
  color: #222;
  max-width: 500px;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .form-title {
    margin-bottom: 16px;
  }
  .subtitle {
    color: #555;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .description {
    color: #333;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: justify;
  }
  .grid-flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
    .form-group {
      padding-left: 8px;
      padding-right: 8px;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .form-group {
    margin-bottom: 12px;
    label {
      color: #333;
      font-size: 13px;
      padding-left: 6px;
      display: inline-block;
      vertical-align: middle;
      sup { color: red; }
    }
    input[type="text"],
    input[type="number"],
    input[type="date"],
    input[type="email"],
    select {
      width: 100%;
      color: #222;
      height: 34px;
      border: none;
      outline: none;
      display: block;
      padding: 6px 12px;
      border-radius: 8px;
      background-color: #fff;
      border: 2px solid #ddd;
      transition: all .15s ease-in-out;
      font-family: 'DM Sans', sans-serif;
      &:hover { border-color: #ccc; }
      &:focus { border-color: #aaa; }
    }
  }
  .button {
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    min-width: 120px;
    padding: 8px 16px;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: .4px;
    display: inline-block;
    vertical-align: middle;
    background-color: #f26522;
    text-transform: uppercase;
    transition: all .15s ease-in-out;
    font-family: 'DM Sans', sans-serif;
    &:hover { background-color: #d54d0d; }
    &-area {
      margin-top: 24px;
    }
  }
`;

export const Return = styled.div`
  .button {
    border: none;
    color: #f26522;
    cursor: pointer;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 16px;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    transition: all .15s ease-in-out;
    font-family: 'DM Sans', sans-serif;
    &::before {
      line-height: 0;
      font-size: 30px;
      font-weight: 700;
      content: "\\2190";
      margin-right: 4px;
      display: inline-block;
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px -8px;
  > div {
    width: 100%;
    position: relative;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    .button {
      color: #666;
      margin: 8px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 700;
      min-width: 120px;
      border: 1px solid;
      padding: 8px 16px;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
      letter-spacing: .4px;
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      background-color: transparent;
      transition: all .15s ease-in-out;
      font-family: 'DM Sans', sans-serif;
      &:hover,
      &.button-selected {
        color: #f26522;
      }
    }
  }
`;
