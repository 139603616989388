import { motion } from 'framer-motion';
import LeadForm from '../../components/LeadForm'

const Lead = () => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <LeadForm type="createAdmin" />
  </motion.div>
);

export default Lead
