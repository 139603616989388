/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Header, Container } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import Box from '@material-ui/core/Box';
import DatePeriodFilter from '../../components/ProcessesReportTable/Filter';
import LoadingPage from '../LoadingPage';
import CallsReportTableList from '../../components/CallsReportTableList';
import { DateTime } from 'luxon';
import { useAuth } from '../../hooks/useAuth';
import useApp from '../../hooks/useApp'
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import { TablePagination } from '@material-ui/core';
import ProcessesReportTable from '../../components/ProcessesReportTable/index';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';


const ProcessesReport = () => {
  const { setState } = useApp();
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [checkFilter, setCheckFilter] = useState(true);
  const [filters, setFilters] = useState({
    reference_month:"",
    broker_id: '',
    channel_id: [],
    client: '',
    page: 0,
    status: '',
    per_page: 10
  });

  const { values, getters } = useContext(MainContext);

  const { data, mutate, isValidating } = useSWR(
    `/lead-temps?per_page=${filters.per_page}&page=${filters.page + 1}` +
    `&reference_month=${filters.reference_month}` +
    `&broker_id=${filters.broker_id}` +
    `&channel_id=${filters.channel_id}` +
    `&status=${filters.status}` +
    `&client=${filters.client}`
    ,
    fetcher
  );

  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, page: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, page: 0, per_page: parseInt(event.target.value) });
    mutate();
  };

  useEffect(() => {
    setState('loading', (isValidating) )
  }, [isValidating])

  return (
    <>
      <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
        <Container>
          <Header>
            <h1>Relatório de Processamentos</h1>
          </Header>
          <DatePeriodFilter filters={filters} setFilters={setFilters} />

            <Box m={1} />
            <TablePagination
              component="div"
              count={data ? data.total : 0}
              page={filters.page}
              onPageChange={handleChangePage}
              rowsPerPage={filters.per_page}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <ProcessesReportTable
              listItem={data?.data}
              title="Relatório dos Processamentos"
            />
        </Container>
      </motion.div>
    </>
  );
};

export default ProcessesReport;
