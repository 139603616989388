import styled from "styled-components";

export const DivBotoes = styled.div`
background-color: #fc986a;
width: 15%;
display: flex;
flex-direction: column;
align-items: center;
gap: 0.8em;

.botoes-cliente{
    width: 90%;

}

@media (max-width: 1024px){
    flex-direction: row;
    width: 100%;
    align-items: initial;
    padding: 2%;

    .botoes-cliente{
        width: 100%;
        font-size: 10px;   
    }
}

@media (max-width: 768){


    .botoes-cliente{
        width: 100%;
        font-size: 10px;   
        flex-direction: column;
    }
}
`;

export const DivSeparacao = styled.div`
display: flex;
flex-direction: row;

@media (max-width: 1024px){
    flex-direction: column ;
}
`;