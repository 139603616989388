import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import P, { shape } from 'prop-types';
import LoadingPage from '../../pages/LoadingPage';
import { maskPercent } from '../../utils/masks';

const AttendanceReportTableList = ({ listItem }) => (
  <>
    {listItem && (
      <>
        <Box m={5} />

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome do Corretor</TableCell>
                <TableCell>Total cap. no prazo</TableCell>
                <TableCell>Total cap. fora do prazo</TableCell>
                <TableCell>Total geral cap. no período</TableCell>
                <TableCell>Vendidos nesse período</TableCell>
                <TableCell>Não vendidos nesse período</TableCell>
                <TableCell>Conversão</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listItem !== null &&
                listItem.map((item) => (
                  <TableRow key={item.broker}>
                    <TableCell align="left">{item.broker}</TableCell>
                    <TableCell>{item.on_time}</TableCell>
                    <TableCell>{item.out_time}</TableCell>
                    <TableCell>{item.total}</TableCell>
                    <TableCell>{item.sold}</TableCell>
                    <TableCell>{item.not_sold}</TableCell>
                    <TableCell>{maskPercent(item.conversion)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )}
  </>
);

AttendanceReportTableList.propTypes = {
  listItem: P.arrayOf(
    shape({
      broker: P.string,
      on_time: P.number,
      out_time: P.number,
      total: P.number,
      sold: P.number,
      not_sold: P.number,
      conversion: P.number,
    })
  ),
};

AttendanceReportTableList.defaultProps = {
  listItem: null,
};

export default AttendanceReportTableList;
