import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  max-height: 100%;
  padding-right: 20px;
  &::-webkit-scrollbar { width: 16px; }
  &::-webkit-scrollbar-track { background-color: transparent; }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 5px solid #fff;
    background-color: #ddd;
  }
  p {
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    + p {
      margin-left: 24px
    }
    strong {
      color: #777;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .block-title {
    padding: 5px;
    display: flex;
    color: #f26522;
    margin-bottom: 8px;
    letter-spacing: -0.3px;
    border-bottom: 1px solid;
    justify-content: space-between;
    span { color: #aaa; }
  }
`;
