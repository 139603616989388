/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { DateTime } from 'luxon';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import api from '../../../service/api';
import { fetcher, variantFetcher } from '../../../service/SWRFetcher';
import Filter from '../../../components/Filter';
import { Container, Header } from '../../../styles/shared/styles';
import useApp from '../../../hooks/useApp';
import CssStyles from '../css.styles';
import CommissionReportGroupTable from '../../../components/CommissionComponents/CommissionReportGroupTable';

const CommissionReport = () => {
    const { data: users } = useSWR('/users', fetcher);
    const { insurances, competitor_brokers } = useApp();
    const layout = [{id: 1, name: 'Corretor'}, {id : 2, name: 'Vendedor'}];
    const consumers = [{id: 1, name: 'Ativos'}, {id : 2, name: 'Inativos'}];
    const origin = [{id: 1, name: 'Renovação'}, {id : 2, name: 'Seguros novos'}];

    const [getCompetitor_broker, setCompetitor_broker] = useState(null);
    const [data, setData] = useState();
    const [getColumns, setColumns] = useState();
    const dateNow = DateTime.now().toISODate();
    const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  
    const [filters, setFilters] = useState({
        level: undefined,
        periodOf: dateLastMonth,
        periodUntil: dateNow,
        broker: null,
        insurance: null,
        competitorBrokers: null,
        consumers: null,
        origin: null,
        layout: layout[0],
      });

      const colums = [
        { titulo: 'Cliente', variavel: 'Cliente' },
        { titulo: 'Data In. Vigência', variavel: 'term_begin' },
        { titulo: 'Proposta', variavel: 'propostal_number' },
        { titulo: 'Apólice', variavel: 'certificate_number' },
        { titulo: 'Endosso', variavel: '' },
        { titulo: 'Produto', variavel: 'product' },
        { titulo: 'Seguro', variavel: 'Seguro' },
        { titulo: 'Tipo de Negócio', variavel: 'channel.name' },
        { titulo: 'Itens', variavel: '' },
        { titulo: 'Vigência', variavel: 'term_begin' },
        { titulo: 'Valor', variavel: 'sales_value' },
        { titulo: 'Parcelas', variavel: 'assinatura_mensal_num_parcelas' },
        { titulo: '%', variavel: 'commission_percentage' },
        { titulo: 'Comissão', variavel: 'commission_value' },
      ];  

    const MontarUrl = () => {
      let brokerIDs = '';
      filters.broker?.map((i) => (brokerIDs += `&broker[]=${i.id}`));

      let competitorBrokersIDs = '';
      filters.competitorBrokers?.map((i) => (competitorBrokersIDs += `&competitorBrokers[]=${i.id}`));
      
      let insurancesIDs = '';
      filters.insurance?.map((i) => (insurancesIDs += `&insurance[]=${i.id}`));
      
      return `periodOf=${filters.periodOf}&` +
        `periodUntil=${filters.periodUntil}&` +
        `${brokerIDs}&` +
        `${insurancesIDs}&` +
        `${competitorBrokersIDs}&` +
        `consumer=${filters.consumers?.name}&` +
        `origin=${filters.origin?.name}&`;
    }
    
    
    const getCommissionData = async () => {
      if (filters.layout?.id == 2) {
        colums.push({ titulo: '%', variavel: 'commission' });
        colums.push({ titulo: 'Comissão do Vendedor', variavel: 'sellerCommissionValue' });
      }
      setColumns(colums);

      const response = await api.get(`/reports/commission/seller?${MontarUrl()}`);
      setData(response.data.data);
    }

    const handleChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const handleChangeAutoComplete = (event, newValue, variable) => {
      setFilters({
        ...filters,
        [variable]: newValue,
      });
    };      

    const Limpar = () => {
      setFilters({
        level: null,
        periodOf: dateLastMonth,
        periodUntil: dateNow,
        broker: null,
        insurance: null,
        competitorBrokers: null,
        consumers: null,
        origin: null,
        layout: layout[0],
      });
    };    
    
    function getPlaceholderText(value) {
      return (
        (value == null || value.length == 0) ? "TODOS" : ''
      )
    }

    useEffect(() => {
      setCompetitor_broker(competitor_brokers?.map((st) => ({ id: st.var_id, name: st.value })) || '');
    }, [filters]);

      
    return (
        <>
        <motion.div initial={CssStyles.motionDivInitial} animate={CssStyles.motionDivAnimate} exit={CssStyles.motionDivExit}>
        <Container>
          <Header>
            <h1>Relatório de Comissão</h1>
          </Header>

          <Filter
            buttonStyle={{"height": "fit-content"}}
            clickFilter={() => {
              if (
                filters.level ||
                filters.periodOf ||
                filters.periodUntil ||
                filters.broker
              ) {
                getCommissionData();
              }
            }}
            clearFilter={() => {
              Limpar();
            }}>

            <Grid container spacing={2}>              
            <label>
                Vigência de:
                <input
                type="date"
                name="periodOf"
                className="input-form"
                title='De'
                value={filters.periodOf}
                onChange={handleChange}
                />
            </label>
              
            
            <label>
                Até
                <input
                type="date"
                name="periodUntil"
                className="input-form"
                title='Até'
                value={filters.periodUntil}
                onChange={handleChange}
                />
            </label>

            <Autocomplete
              xs={4}
              disableClearable
              options={layout || []}
              getOptionLabel={(option) => option?.name || ''}
              value={filters.layout || [1]}
              onChange={(e, value) => setFilters({...filters, layout: value})}
              renderInput={(params) => (
                <TextField {...params} 
                  className="autocomplete" 
                  variant="filled" 
                  label="Layout" 
                />
              )}
              style={{
                width: '200px'
              }}
            />

            <Autocomplete
              xs={4}
              options={origin || []}
              getOptionLabel={(option) => option?.name || ''}
              value={filters.origin || [0]}
              onChange={(e, value) => setFilters({...filters, origin: value})}
              renderInput={(params) => (
                <TextField {...params} 
                  className="autocomplete" 
                  variant="filled" 
                  placeholder='TODOS'
                  label='Origem'
                />
              )}
              style={{
                width: '200px'
              }}
            />

            <Autocomplete
              xs={4}
              options={consumers || [0]}
              getOptionLabel={(option) => option?.name || ''}
              value={filters.consumers || [0]}
              onChange={(e, value) => setFilters({...filters, consumers: value})}
              renderInput={(params) => (
                <TextField {...params} 
                  className="autocomplete" 
                  variant="filled" 
                  placeholder='TODOS'
                  label='Clientes'
                />
              )}
              style={{
                width: '200px'
              }}
            />

            <Autocomplete
              multiple
              xs={4}
              options={users || [0]}
              getOptionLabel={(option) => option?.name || ''}
              value={filters.broker || [0]}
              onChange={(e, value) => setFilters({...filters, broker: value})}
              renderInput={(params) => (
                <TextField {...params} 
                  className="autocomplete" 
                  variant="filled" 
                  placeholder={getPlaceholderText(filters.broker)}
                  label='Corretor'
                />
              )}
              style={{
                width: '300px'
              }}
            />

            <Autocomplete
              multiple
              xs={4}
              options={insurances || []}
              getOptionLabel={(option) => option?.name || ''}
              value={filters.insurance || [0]}
              onChange={(e, value) => setFilters({...filters, insurance: value})}
              renderInput={(params) => (
                <TextField {...params} 
                  className="autocomplete" 
                  variant="filled" 
                  placeholder={getPlaceholderText(filters.insurance)} 
                  label='Tipo de Seguro'
                />
              )}
              style={{
                width: '300px'
              }}
            />

            <Autocomplete
              multiple
              xs={4}
              options={getCompetitor_broker || []}
              getOptionLabel={(option) => option?.name || ''}
              value={filters.competitorBrokers || [0]}
              onChange={(e, value) => setFilters({...filters, competitorBrokers: value})}
              renderInput={(params) => (
                <TextField {...params} 
                  className="autocomplete" 
                  variant="filled" 
                  placeholder={getPlaceholderText(filters.competitorBrokers)}
                  label='Seguradora'
                />
              )}
              style={{
                width: '300px'
              }}
            />
            </Grid>
            </Filter>
          
          <br />
          <Divider />
          <CommissionReportGroupTable data={data} colums={getColumns} layout={filters.layout?.id} />

        </Container>
        </motion.div>
        </>
    )
}

export default CommissionReport;