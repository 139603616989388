import { useEffect } from 'react';
import VerifyForm from '../../components/VerifyForm';
import { Container } from './style';

const Verify = () => (
    <Container>
      <VerifyForm />
    </Container>
  );


export default Verify;
