/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import P from 'prop-types';
import { isAuthenticated } from '../service/auth.service';

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (isAuthenticated() ? <Redirect to="/" /> : <Component {...props} />)} />
);

LoginRoute.propTypes = {
  component: P.func.isRequired,
};


export default LoginRoute;
