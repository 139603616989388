/* eslint-disable */
import { useContext, useState, useEffect } from 'react';
import { MainContext } from '../../context/ApiContext';
import { Form, Return, Tabs } from './styles';
import { DateTime } from 'luxon';
import CurrencyInput from 'react-currency-input-field';
import { Grow } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { maskCurrency } from '../../utils/masks';
import { TextField } from '../FormFields';
import { LoadingButton } from '../Loading';

const DetailsForms = ({ createLead, details, setDetails, insurance, pjTXT }) => {
  const {
    values: { partners },
  } = useContext(MainContext);
  const [getLoadingButton_finalizar, setLoadingButton_finalizar] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (getLoadingButton_finalizar) return;

    setLoadingButton_finalizar(true);
    await createLead();
    setLoadingButton_finalizar(false);
  }

  const [partner, setPartners] = useState();

  //const { values, getters } = useContext(MainContext);
  const [values, setValues] = useState({
    client_address_state : ''
  });

  const getFinalizarText = () => {
    return (
      <>
        {getLoadingButton_finalizar ? 
          (<LoadingButton />) : 
          (<></>) 
        }
        Finalizar      
      </>
    )    
  }

  const changeDetails = (e) => {
    setDetails((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  return (
    <>
      {insurance && (
        <>
          {(insurance.id == 1 || insurance.id == 2 || insurance.id == 3) && ( //automovel
            <Grow in={true}>
              <Form>
                <form className="form" onSubmit={handleSubmit}>
                  <h2>Automóvel</h2>
                  <h3 className="description">O Seguro Automóvel garante proteção contra perdas e danos ocorridos com automóveis em caso de
                    acidentes, roubo ou furto, danos materiais ou corporais causados a terceiros e morte/invalidez dos
                    passageiros.</h3>

                  <div className="grid-flex">
                    <div className="form-group">
                      <label for="model">Modelo <sup>*</sup></label>
                      <input type="text" id="model" name="model" value={details?.model} onChange={(e) => changeDetails(e)} required/>
                    </div>

                    <div className="form-group">
                      <label for="type"> Tipo de Veículo <sup>*</sup></label>
                      <select type="text" id="type" name="type" onChange={(e) => changeDetails(e)} required>
                        <option disabled selected value={''}> Selecione... </option>
                        <option> Usado </option>
                        <option> Novo </option>
                      </select>
                    </div>
                  </div>

                  <div className="grid-flex">
                    <div className="form-group">
                      <label for="chassi">Chassi: {details?.type == 'Usado' ? '* ' : ''}</label>
                      <input type="text" id="chassi" name="chassi" value={details?.chassi} onChange={(e) => changeDetails(e)} required={details?.type == 'Usado' ? true : false}/>
                    </div>

                    <div className="form-group">
                      <label for="renavam">Renavam: {details?.type == 'Usado' ? '* ' : ''}</label>
                      <input type="text" id="renavam" name="renavam" value={details?.renavam} onChange={(e) => changeDetails(e)} required={details?.type == 'Usado' ? true : false}/>
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="address">Endereço Completo <sup>*</sup></label>
                    <input type="text" id="address" name="address" value={details?.address} required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="button-area">
                    <button className="button" type="submit">
                      {getFinalizarText()}
                    </button>
                  </div>
                </form>
              </Form>
            </Grow>
          )}

          {(insurance.id == 26) && ( //Proteção veicular
            <Grow in={true}>
              <Form>
                <form className="form" onSubmit={handleSubmit}>
                  <h2>{insurance.name}</h2>
                  <h3 className="description">A ppv.</h3>

                  <div className="grid-flex">
                    <div className="form-group">
                      <label for="model">Modelo <sup>*</sup></label>
                      <input type="text" id="model" name="model" value={details?.model} onChange={(e) => changeDetails(e)} required/>
                    </div>

                    <div className="form-group">
                      <label for="type"> Tipo de Veículo <sup>*</sup></label>
                      <select type="text" id="type" name="type" onChange={(e) => changeDetails(e)} required>
                        <option disabled selected value={''}> Selecione... </option>
                        <option> Usado </option>
                        <option> Novo </option>
                      </select>
                    </div>
                  </div>

                  <div className="grid-flex">
                    <div className="form-group">
                      <label for="modelYear">Ano Modelo <sup>*</sup></label>
                      <input type="number" id="modelYear" name="modelYear" value={details?.modelYear} onChange={(e) => changeDetails(e)} required/>
                    </div>

                    <div className="form-group">
                      <label for="plate">Placa</label>
                      <input type="text" id="plate" name="plate" value={details?.plate} onChange={(e) => changeDetails(e)} />
                    </div>
                  </div>


                  <div className="grid-flex">
                    <div className="form-group">
                      <label for="chassi">Chassi: {details?.type == 'Usado' ? '* ' : ''}</label>
                      <input type="text" id="chassi" name="chassi" value={details?.chassi} onChange={(e) => changeDetails(e)} required={details?.type == 'Usado' ? true : false}/>
                    </div>

                    <div className="form-group">
                      <label for="renavam">Renavam: {details?.type == 'Usado' ? '* ' : ''}</label>
                      <input type="text" id="renavam" name="renavam" value={details?.renavam} onChange={(e) => changeDetails(e)} required={details?.type == 'Usado' ? true : false}/>
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="address">Endereço Completo <sup>*</sup></label>
                    <input type="text" id="address" name="address" value={details?.address} required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="grid-flex">
                    <div className="form-group">
                    <TextField
                      value={details?.state || ''}
                      onChange={(e) => changeDetails(e)}
                      varibleName="client_address_state"
                      name="state"
                      label="Estado"
                      className="control ms"
                    />
                    </div>

                    <div className="form-group">
                      <label for="municipio">Município: {details?.type == 'Usado' ? '* ' : ''}</label>
                      <input type="text" id="municipio" name="municipio" value={details?.municipio} onChange={(e) => changeDetails(e)} required/>
                    </div>
                  </div>

                  <div className="button-area">
                    <button className="button" type="submit">
                      {getFinalizarText()}
                    </button>
                  </div>
                </form>
              </Form>
            </Grow>
          )}

          {insurance.id == 4 && ( //residencial
            <Grow in={true}>
              <Form>
                <form className="form" onSubmit={handleSubmit}>
                  <h2>Residencial</h2>
                  <h3 className="description">O Seguro Residencial garante proteção de residências habituais ou de veraneio.</h3>

                  <div className="form-group">
                    <label for="address">Endereço Completo <sup>*</sup></label>
                    <input type="text" id="address" name="address" value={details?.address} required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="form-group">
                    <label for="type">Tipo <sup>*</sup></label>
                    <select type="text" id="type" name="type" required onChange={(e) => changeDetails(e)}>
                      <option disabled selected value={''}> Selecione... </option>
                      <option> Habitual </option>
                      <option> Veraneio </option>
                    </select>
                  </div>

                  <div className="button-area">
                    <button className="button" type="submit">
                      {getFinalizarText()}
                    </button>
                  </div>
                </form>
              </Form>
            </Grow>
          )}

          {insurance.id == 6 && ( //portateis
            <Grow in={true}>
              <Form>
                <form className="form" onSubmit={handleSubmit}>
                  <h2>Portáteis</h2>
                  <h3 className="description">
                    O Seguro Portáteis protege aparelhos portáteis contra riscos cotidianos – quebra, danos, elétricos,
                    roubo, furto, etc.
                  </h3>

                  <div className="form-group">
                    <label for="product">Produto que deseja colocar seguro <sup>*</sup></label>
                    <input type="text" id="product" value={details?.product} name="product" required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="form-group">
                    <label for="value">Valor que deseja colocar o seguro <sup>*</sup></label>
                    <CurrencyInput decimalSeparator="," groupSeparator="." value={details?.value}  prefix="R$ " required name='value' onChangeEvent={(e) => changeDetails(e)}/>
                  </div>

                  <div className="form-group">
                    <label for="date">Data de Aquisição <sup>*</sup></label>
                    <input type="date" id="date" name="date" value={details?.date} required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="button-area">
                    <button className="button" type="submit">
                      {getFinalizarText()}
                    </button>
                  </div>
                </form>
              </Form>
            </Grow>
          )}

          {insurance.id == 7 && ( //pets
            <Grow in={true}>
              <Form>
                <form className="form" onSubmit={handleSubmit}>
                  <h2>Pet</h2>
                  <h3 className="description">O Seguro Pet protege cães e gatos contra doenças e atendimentos emergenciais. <em>Contratação disponível nas capitais: <strong>Belo Horizonte, Rio de Janeiro e São Paulo</strong>.</em></h3>

                  <div className="form-group">
                    <label for="quantity">Quantidade de Pets <sup>*</sup></label>
                    <InputMask
                      required
                      mask={'99'}
                      maskChar=""
                      name="quantity"
                      value={details?.quantity}
                      onChange={(e) => changeDetails(e)}>
                      {(params) => <input type="text" id="quantity" name="quantity" {...params} />}
                    </InputMask>
                  </div>

                  <div className="form-group">
                    <label for="race">Raça <sup>*</sup></label>
                    <input type="text" id="race" name="race" required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="form-group">
                    <label for="type">Espécie <sup>*</sup></label>
                    <select type="text" id="type" name="type" required onChange={(e) => changeDetails(e)}>
                      <option disabled selected value={''}> Selecione... </option>
                      <option> Cão </option>
                      <option> Gato </option>
                    </select>
                  </div>

                  <div className="button-area">
                    <button className="button" type="submit">
                      {getFinalizarText()}
                    </button>
                  </div>
                </form>
              </Form>
            </Grow>
          )}

          {insurance.id == 9 && ( //vida
            <Grow in={true}>
              <Form>
                <form className="form" onSubmit={handleSubmit}>
                  <h2>Vida</h2>
                  <h3 className="description">
                    O Seguro Vida assegura que sua família e você não fiquem desamparados financeiramente em caso de
                    doenças, acidentes ou outros acontecimentos inesperados.
                  </h3>

                  <div className="form-group">
                    <label for="dependents">Quantidade de Dependentes <sup>*</sup></label>
                    <InputMask
                      required
                      mask={'99'}
                      maskChar=""
                      name="dependents"
                      value={details?.dependents}
                      onChange={(e) => changeDetails(e)}>
                      {(params) => <input type="text" id="dependents" name="dependents" {...params} />}
                    </InputMask>
                  </div>

                  <div className="form-group">
                    <label for="diseases">Histórico de doenças graves <sup>*</sup></label>
                    <input type="text" id="diseases" value={details?.diseases} required name="diseases" onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="form-group">
                    <label for="marital">Estado Civíl <sup>*</sup></label>
                    <select type="text" id="marital" value={details?.marital} name="marital" required onChange={(e) => changeDetails(e)}>
                    <option disabled selected value={''}> Selecione... </option>
                      <option> Solteiro </option>
                      <option> Casado </option>
                      <option> Divorciado </option>
                      <option> Viúvo </option>
                    </select>
                  </div>

                  <div className="button-area">
                    <button className="button" type="submit">
                      {getFinalizarText()}
                    </button>
                  </div>
                </form>
              </Form>
            </Grow>
          )}

          {insurance.id == 10 && ( //Bike
            <Grow in={true}>
              <Form>
                <form className="form" onSubmit={handleSubmit}>
                  <h2>Bike</h2>
                  <h3 className="description">
                    O Seguro Bike é uma solução que pode ser completa, não só para a bicicleta e o ciclista, mas também
                    para terceiros.
                  </h3>

                  <div className="grid-flex">
                    <div className="form-group">
                      <label for="brand">Marca <sup>*</sup></label>
                      <input type="text" id="brand" name="brand" value={details?.brand} required onChange={(e) => changeDetails(e)} />
                    </div>

                    <div className="form-group">
                      <label for="value">Valor <sup>*</sup></label>
                      <CurrencyInput decimalSeparator="," groupSeparator="." value={details?.value}  prefix="R$ " required name='value' onChangeEvent={(e) => changeDetails(e)}/>
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="date">Data de Aquisição <sup>*</sup></label>
                    <input type="date" id="date" name="date" value={details?.date} required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="form-group">
                    <label for="address">Em qual endereço a Bike é guardada? <sup>*</sup></label>
                    <input type="text" id="address" value={details?.address} name="address" required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="form-group">
                    <label for="function">A Bike é utilizada para qual finalidade?</label>
                    <select type="text" id="function" value={details?.function} name="function" required onChange={(e) => changeDetails(e)}>
                    <option disabled selected value={''}> Selecione... </option>
                      <option> Competição </option>
                      <option> Lazer </option>
                      <option> Trasnporte </option>
                    </select>
                  </div>

                  <div className="button-area">
                    <button className="button" type="submit">
                      {getFinalizarText()}
                    </button>
                  </div>
                </form>
              </Form>
            </Grow>
          )}

          {insurance.type == 2 && ( //PJ
            <Grow in={true}>
              <Form>
                <form className="form" onSubmit={handleSubmit}>
                  <h2 className="form-title">{insurance.name}</h2>
                  <h3 className="description"> {pjTXT[insurance.id]}</h3>

                  <div className="form-group">
                    <label for="name">Nome do responsável por seguros na empresa <sup>*</sup></label>
                    <input type="text" id="name" name="name" value={details?.name} required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="form-group">
                    <label for="email">Email do responsável por seguros na empresa <sup>*</sup></label>
                    <input type="email" id="email" name="email" value={details?.email} required onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="form-group">
                    <label for="phone">Celular do responsável por seguros na empresa <sup>*</sup></label>
                    <InputMask
                      required
                      mask={details?.cellphone?.length < 15 ? '(99) 9999-99999' : '(99) 99999-9999'}
                      maskChar=""
                      name="cellphone"
                      value={details?.cellphone}
                      onChange={(e) => changeDetails(e)}>
                      {(params) => <input type="text" id="phone" name="phone" {...params} />}
                    </InputMask>
                  </div>

                  <div className="form-group">
                    <label for="cellphone">Telefone Fixo</label>
                    <InputMask
                      mask={details?.phone?.length < 15 ? '(99) 9999-99999' : '(99) 99999-9999'}
                      maskChar=""
                      name="phone"
                      value={details?.phone}
                      onChange={(e) => changeDetails(e)}>
                      {(params) => <input type="text" id="phone" name="phone" {...params} />}
                    </InputMask>
                  </div>

                  <div className="form-group">
                    <label for="obs">Observação</label>
                    <input type="text" id="obs" name="obs" value={details?.obs} onChange={(e) => changeDetails(e)} />
                  </div>

                  <div className="button-area">
                    <button className="button" type="submit">
                      {getFinalizarText()}
                    </button>
                  </div>
                </form>
              </Form>
            </Grow>
          )}
        </>
      )}
    </>
  );
};


export default DetailsForms;
