/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable */

import P, { object } from 'prop-types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetApp from '@material-ui/icons/GetApp';
import useSWR from 'swr';
import { useRef, useEffect, useState, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, IconButton, InputAdornment } from '@material-ui/core';
import { DateTime, Interval } from 'luxon';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { CloudDownload, Payment } from '@material-ui/icons';
import api from '../../service/api';
import CallModal from '../CallModal';
import ScheduleModal from '../ScheduleModal';
import DeadlineModal from '../DeadlineModal';
import CancelLeadModal from '../CancelLeadModal';
import SimpleModal from '../SimpleModal';
import { Container, FormGroup, ButtonGroup, LoadingContainer, LoadingDiv } from './styles';
import { TextField, AutocompleteField, DateField, MaskedTextField, TextFieldAdorned } from '../FormFields';
import { RemoveMask } from '../../utils/phoneMask';
import UtilsTime from '../../utils/utilsTime';
import { Loading, LoadingButton } from '../Loading';
import {
  handleQuotationQuiverMulti,
  handleQuotationQuiverMultiResidential,
  handleQuotationQuiverOn,
  handleQuotationQuiverOnResidential,
} from '../../service/quotation.service';
import { Beneficiary } from './beneficiary';
import {
  checkCEP,
  checkFinancialCEP,
  checkCPF,
  editLead,
  getLeadPermissions,
  goToNextStatus,
  handleCall,
  verify,
  handleSaveFlowService,
  checkAge18,
  checkAge61,
  isCpfValid,
  getNumParcelas
} from '../../service/lead.service';
import {
  months,
  offer,
  maritalStatus,
  sexo,
  Nacionalidade,
  optProposta,
  optCertificado,
  SN,
  UF
} from '../../mock/leadInfo.com';
import GetProposal from '../GetProposal';
import { useAuth } from '../../hooks/useAuth';
import { dataCreate } from '../../service/data.service';
import useApp from '../../hooks/useApp';
import { fetcher } from '../../service/SWRFetcher';
import { editUser } from '../../service/user.service';
import { createClient, editClient, getClientByCpf } from '../../service/client.service';
import { NumericFormat } from 'react-number-format';
import AlertDialog from '../ConfirmationDialog';
import { MainContext } from '../../context/ApiContext';
import {
  handleChangeAutoCompleteQuotedType,
  handleChangeAutoCompleteContactType,
  handleChangeAutoCompleteSoldType,
  handleChangeAutoCompleteValidations,
} from './validations';
import { fileUpload, onClickVisualizarAnexo, getPdfFileName } from './services';
import { SelectInsuranceOptions } from './InsuranceQuestions/insuranceOptions';
import { InsuranceComponents } from './insuranceComponents';
import { InsuranceResidenceHomeHabitationArray } from './InsuranceQuestions/insuranceResidenceQuestions';
import { InsuranceBusinessPersonTypeArray } from './InsuranceQuestions/insuranceBusinessQuestions';
import { InsuranceNewArray, InsuranceOwnerArray } from './InsuranceQuestions/commonQuestions';
import { InsuranceBikeTypeArray } from './InsuranceQuestions/insuranceBikeQuestions';
import { InsuranceTravelReasonArray } from './InsuranceQuestions/insuranceTravelQuestions';
import { InsuranceBailHomeHabitationTypeArray } from './InsuranceQuestions/insuranceBailQuestions';
import { Pdf } from '../../service/excel.service';
import { floatParaEstiloPTBR, formatarParaEstiloPTBR } from '../../hooks/Utils';
import { ConfirmModalGeneric } from '../../components/SubscriptionForm/confirmModal';
import { IsCorujaFlow } from '../../service/flow.service.ts';
import { CommissionComponents } from './commissionComponents';

export default function LeadForm({ type, leadCurrent, setOpen, mutate }) {
  const { user, updateUser } = useAuth();
  const {
    lead_contact_type,
    lead_sold,
    lead_lost_sale,
    lead_status,
    competitor_brokers,
    insurances,
    partners,
    time_to_finish_lead,
    setState,
  } = useApp();

  const { data: usersData, mutate: mutateUsers, isValidating } = useSWR(`/users`, fetcher);

  // useEffect(() => {
  //   setState('loading', isValidating);
  // }, [isValidating]);

  // const { data: clientsData, mutate: mutateClients } = useSWR(user.role < 3 ? `/clients`: null, fetcher);

  const context = useContext(MainContext);

  // const [user, setUser] = useState(null);
  const [backupCar, setBackupCar] = useState(null);
  const [pagueMenos, setPagueMenos] = useState(null);
  const [seguroConteudo, setSeguroConteudo] = useState(null);
  const [status, setStatus] = useState(null);
  const [contact_type, setContact_type] = useState(null);
  const [sold, setSold] = useState(null);
  const [selectedSold, setSelectedSold] = useState(null);
  const [lost_sale, setLost_sale] = useState(null);
  const [competitor_broker, setCompetitor_broker] = useState(null);
  const [deadlinesModal, setDeadlinesModal] = useState(false);
  const [cancelLeadModal, setCancelLeadModal] = useState(false);
  const form = useRef(null);
  const [car, setCar] = useState(false);
  const [createError, setCreateError] = useState(false);
  const [openCallModal, setOpenCallModal] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openPendencyModal, setOpenPendencyModal] = useState(false);
  const [openInsuranceModal, setOpenInsuranceModal] = useState(false);
  const [openEndossoModal, setOpenEndossoModal] = useState(false);
  const [finish, setFinish] = useState(false);
  const [close, setClose] = useState(false);
  const [partnersOptions, setPartnersOptions] = useState([]);
  const [insurancesOptions, setInsurancesOptions] = useState([]);
  const [lead, setLead] = useState(false);
  const [check, setCheck] = useState(false);
  const [file, setFile] = useState(false);
  const [hideToast, setHideToast] = useState(false);
  const [openGetProposal, setOpenGetProposal] = useState(false);
  const [fillable, setFillable] = useState(null);
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState(null);
  const [callType, setCallType] = useState(1);
  const [callLabel, setCallLabel] = useState(null);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [openConfirmGerarLink, setOpenConfirmGerarLink] = useState(false);
  const [saleValueDisabled, setSaleValueDisabled] = useState(true);
  const [commissionPercentageDisabled, setCommissionPercentageDisabled] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  
  const NewBeneficiary = () => {
    setBeneficiaries([
      ...beneficiaries,
      {
        name: '',
        cpf: '',
        rg: '',
        birth_date: '',
        parentesco: null,
        sex: null,
        maritalStatus: null,
        phone: '',
        cellphone: '',
        email: '',
        instagram: '',
        facebook: '',
        participation: beneficiaries.length < 1 ? 100 : 0,
      },
    ]);
  };
  const RemoveBeneficiary = (i) => {
    beneficiaries.splice(i, 1);
    var obj = [];
    obj = obj.concat(obj, beneficiaries);
    setBeneficiaries(obj);
  };
  const date = new Date();
  const [values, setValues] = useState({
    // -->inviter information<--
    inviter: null,
    inviter_Phone: '',
    partner: null,
    insurance: null,
    // -->client information<--
    proposal: '',
    client_name: '',
    client_cellphone: '',
    client_phone: '',
    birth_date: '',
    client_Cpf: '',
    client_RG: '',
    client_CEP: '',
    client_address_street: '',
    client_address_number: '',
    client_address_comp: '',
    client_address_district: '',
    client_address_city: '',
    client_address_state: '',
    email: '',
    insurance_expiration: null,
    // -->lead table<--
    proposal_date: null,
    product: '',
    product_details: '',
    product_chassis: '',
    product_license_plate: '',
    product_year: '',
    product_brand: '',
    additional_information: '',
    facebook: '',
    instagram: '',
    cliente_beneficiarios: [],
    estado_civil: null,
    servidor_publico: '',
    gender: '',
    nacionalidade: '',
    nome_de_recado: '',
    telefone_de_recado: '',
    celular_de_recado: '',
    profissao: '',

    broker: null, // users ->removido
    status: null,
    state: null,
    contact_type: null,
    sold: null,
    lost_sale: null,
    pague_menos: null,
    backup_car: null,
    seguro_conteudo: null,
    endorsement_date: null,
    competitor_broker: null,
    attendance_on_time: null,
    indication_on_time: null,
    end_lead_date: null,
    first_call_date: null,
    lead_scheduling: null,
    arquivo_oc: null,
    date_fail: false,
    sold_date: '',
    sold_type: null,
    quoted_date: '',
    quoted_type: null,
    contact_date: '',
    sale_value: '',
    commission_percentage: '',
    valor_adesao: '',

    // insurance questions
    insurance_residence_homeType: '',
    insurance_residence_homeHabitation: null,
    insurance_residence_amount: '',
    insurance_residence_owner: null,
    insurance_residence_new: null,
    insurance_business_personType: null,
    insurance_business_activityPlace: '',
    insurance_condo_fire_amount: '',
    insurance_condo_blocksAmount: '',
    insurance_condo_elevatorsAmount: '',
    insurance_condo_floorsAmount: '',
    insurance_condo_apartmentFloorAmount: '',
    insurance_condo_parkingSpaceAmount: '',
    insurance_invoice: '',
    insurance_brand: '',
    insurance_model: '',
    insurance_manufactureYear: '',
    insurance_bike_cost: '',
    insurance_bike_type: null,
    insurance_bike_useType: '',
    insurance_life_work: '',
    insurance_life_civilStatus: null,
    insurance_life_monthlySalary: '',
    insurance_practicesSports: null,
    insurance_life_smoker: null,
    insurance_life_covidVaccineAmout: '',
    insurance_life_deathCoverAmout: '',
    insurance_travel_passengersAmout: '',
    insurance_travel_allAges: '',
    insurance_travel_fromCountry: '',
    insurance_travel_toCountry: '',
    insurance_travel_goingDate: null,
    insurance_travel_returnDate: null,
    insurance_travel_reason: null,
    insurance_buyAmount: '',
    insurance_buyDate: null,
    insurance_equipmentType: '',
    insuranceBailHomeHabitationType: null,
    insurance_bail_homeHabitationType: null,
    insurance_bail_name: '',
    insurance_bail_rentAmount: '',
    insurance_bail_iptuAmount: '',
    insurance_bail_condoAmount: '',

    certificate_number: null,
    registrationDate: '',
    financialName: '',
    financialCPF: null,
    financialRg: null,
    financialPhone: null,
    financialCellphone: null,
    financialEmail: '',
    financialCEP: null,
    financialAddress: '',
    financialAddressNumber: null,
    financialAddressComplement: '',
    financialNeighborhood: '',
    financialCity: '',
    financialUF: null,
    uploadCertificado: false,
    uploadProposta: false,
    monthly_subscription: '',
    print_proposal: null,
    print_certificate: null,
    assinatura_recorrente: [],

    loadingButton_proposal: false,
    loadingButton_certificate: false,

    // Comissão
    insurance_sold_value: '',
    assinatura_mensal: '',
    assinatura_mensal_num_parcelas: 0,
    commission_value: '',
  });
  const Loadingg = (value) => {
    setLoading(value);
  };
  const clear = async () => {
    setCar(false);
    setPartnersOptions([]);
    const now = new Date().toISOString().split('.')[0];
    // const res = await context.auth.getUserInfo();

    setValues({
      ...values,
      inviter: type == 'createUser' ? user?.data : null,
      inviter_Phone: type == 'createUser' ? user.phone : '',
      channel_id: type == 'createUser' ? user.channel : null,
      partner: null,
      insurance: null,
      proposal: '',
      client_name: '',
      client_cellphone: '',
      client_phone: '',
      birth_date: '',
      client_Cpf: '',
      client_Rg: '',
      client_CEP: '',
      client_address_street: '',
      client_address_number: '',
      client_address_comp: '',
      client_address_district: '',
      client_address_city: '',
      client_address_state: '',
      client_Email: '',
      proposal_date: now,
      insurance_expiration: null,
      product: '',
      product_details: '',
      product_chassis: '',
      product_license_plate: '',
      product_year: '',
      product_brand: '',
      additional_information: '',
      date_fail: false,
    });
  };

  const updateLead = async () => {
    try {
      const tempLead = await api.get(`/leads/${lead?.id}`);

      setLead(tempLead.data.data);
      setBeneficiaries(tempLead.data.data.cliente_beneficiarios);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const getPermissions = async () => {
    const permissionsResponse = await getLeadPermissions(leadCurrent.id);
    setPermissions(permissionsResponse);
  };

  const setButtonLoadingProposal = (value) => {
    setValues({...values, loadingButton_proposal: value});
  }

  const setButtonLoadingCertificate = (value) => {
    setValues({...values, loadingButton_certificate: value});
  }

  const GetCommissionPercentageDisabled = () => {
    if (IsCorujaFlow()) {
      return commissionPercentageDisabled;
    }
    else {
      return false || selectedSold?.id == 1;
    }
  }

  const GetSaleValueDisabled = () => {
    if (IsCorujaFlow()) {
      return saleValueDisabled;
    }
    else {
      return values.assinatura_mensal?.id == 0;
    }
  }

  const GetTirouPrintPropostaDisabled = () => {
    if (IsCorujaFlow()) {
      return !values.uploadProposta;
    }
    else {
      return false;
    }
  }

  const GetTirouPrintCertificadoDisabled = () => {
    if (IsCorujaFlow()) {
      return !values.uploadCertificado;
    }
    else {
      return false;
    }
  }

  const GetAnexarApoliceDisabled = () => {
    if (IsCorujaFlow()) {
      return values.assinatura_recorrente[0]?.status != 'paid';
    }
    else {
      return false;
    }
  }
  
  const GetInsuranceName = () => {
    if (values.insurance?.id != selectedInsuranceId) {
      return insurances.find((ins) => ins.id === selectedInsuranceId)?.name;      
    }
    else {
      return values.insurance?.name;
    }
  }

  const PaymentIsAllowed = () => {
    return values.status &&
      values.status.id >= 4 &&
      (!values.assinatura_recorrente[0] || values.assinatura_recorrente[0]?.status == 'created')
  }

  useEffect(() => {
    (async () => {
      Loadingg(true);
      setLead({...leadCurrent});
      if (leadCurrent) {
        await getPermissions();
      }
      context.getters.getLeadInsurances();
      // getUserInfo().then((res) => setUser(res.data));
      const filteredContactTypes = lead_contact_type.filter((item) => item.value !== 'Whatsapp/Telefone');
      setContact_type(filteredContactTypes?.map((ct) => ({ id: ct.var_id, name: ct.value })) || '');
      setSold(lead_sold?.map((s) => ({ id: s.var_id + 1, name: s.value })) || '');
      setLost_sale(lead_lost_sale?.map((ls) => ({ id: ls.var_id, name: ls.value })) || '');
      setStatus(lead_status?.map((st) => ({ id: st.var_id, name: st.value })) || '');
      setCompetitor_broker(competitor_brokers?.map((st) => ({ id: st.var_id, name: st.value })) || '');
      if (type === 'createUser') clear();
      Loadingg(false);
    })();
  }, [user]);

  useEffect(() => {
    if (usersData && lead) {
      const inviterInfo = usersData.find((i) => i.id === lead?.inviter_id);
      setValues({ ...values, inviter: inviterInfo || null, inviter_Phone: inviterInfo?.phone || '' });
    }
  }, [usersData, lead]);

  useEffect(() => {
    if (!loading && usersData) {
      if (type !== 'edit') {
        const now = new Date().toISOString().split('.')[0];
        setValues({ ...values, proposal_date: now });
        if (type == 'createUser') {
          if (user.role == 5 && !localStorage.getItem('login-role-5-redirect')) {
            localStorage.setItem('login-role-5-redirect', true);
            navigation.navigate('indicacao-externa');
            return false;
          }

          setValues({
            ...values,
            inviter: user?.data,
            inviter_Phone: user.phone,
            channel_id: user.channel,
            proposal_date: now,
          });
          // context.auth.getUserInfo().then((res) => {
          //   // setPartnersOptions(res.data.partners);
          // });
        }
      }

      if (type === 'edit' && lead) {
        if (lead?.cliente_beneficiarios) {
          var beneficiariosArry = lead?.cliente_beneficiarios;
          beneficiariosArry.map((x) => {
            if (x.maritalStatus) {
              x.maritalStatus = maritalStatus.find((y) => y.id == x.maritalStatus.id);
            }
            return x;
          });
          setBeneficiaries(beneficiariosArry);
        }
        const inviterInfo = usersData ? usersData.find((i) => i.id === lead?.inviter_id) : {};
        const insuranceInfo = insurances.find((ins) => ins.id === lead?.insurance_id);
        const client_name = lead.client ? lead?.client?.name : lead.client_info?.client_name;
        const client_phone = lead.client ? lead?.client?.phone : lead.client_info?.client_phone?.toString();
        const client_cellphone = lead.client ? lead?.client?.cellphone : lead.client_info?.client_cellphone?.toString();
        const email = lead.client ? lead?.client?.mail : lead.client_info?.client_email;
        const client_cpf = lead.client ? lead?.client?.cpf : lead.client_info?.client_cpf;
        const birth_date = lead.client ? lead?.client?.birth_date : lead.client_info?.birth_date;
        const client_cep = lead.client ? lead?.client?.address?.cep : lead.client_info?.cep;
        const client_address_street = lead.client ? lead?.client?.address?.street : lead.client_info?.address_street;
        const client_address_number = lead.client ? lead?.client?.address?.number : lead.client_info?.address_number;
        const client_address_comp = lead.client ? lead?.client?.address?.comp : lead.client_info?.address_comp;
        const client_address_uf = lead.client ? lead?.client?.address?.state : lead.client_info?.address_uf;
        const client_rg = lead.client ? lead?.client?.rg : lead.client_info?.rg;
        const client_address_district = lead.client
          ? lead?.client?.address?.district
          : lead.client_info?.address_district;
        const client_address_city = lead.client ? lead?.client?.address?.city : lead.client_info?.address_city;

        setSelectedInsuranceId(lead?.insurance_id);

        //setBackupCar(offer.find((o) => o.id == lead?.backup_car?.toString()) || null);
        //setPagueMenos(offer.find((o) => o.id == lead?.pague_menos?.toString()) || null);
        setSelectedSold((lead?.sold !== null && sold?.find((sd) => sd.id == lead?.sold + 1)) || null);

        setValues({
          ...values,
          inviter: inviterInfo || null,
          inviter_Phone: inviterInfo?.phone || '',
          partner: lead?.partner || null,
          insurance: insuranceInfo || null,
          proposal: lead?.proposal || '',
          client_name: client_name || '',
          client_cellphone: lead?.client?.cellphone || '',
          client_phone: client_phone || '',
          client_cellphone: client_cellphone || '',
          birth_date: birth_date || '',
          client_Cpf: client_cpf,
          client_Rg: client_rg || '',
          client_CEP: client_cep || '',
          client_address_street: client_address_street || '',
          client_address_number: client_address_number || '',
          client_address_comp: client_address_comp || '',
          client_address_district: client_address_district || '',
          client_address_city: client_address_city || '',
          client_address_state: client_address_uf || '',
          email: email || '',
          insurance_expiration: months.find((s) => s.id === lead?.insurance_expiration) || null,
          proposal_date: lead?.proposal_date || null,
          product: lead?.product || '',
          product_details: lead?.product_details?.details || '',
          product_chassis: lead?.product_details?.chassis || '',
          product_license_plate: lead?.product_details?.license_plate || '',
          product_year: lead?.product_details?.year || '',
          product_brand: lead?.product_details?.brand || '',
          additional_information: lead?.additional_information || '',
          facebook: lead?.client?.facebook || '',
          instagram: lead?.client?.instagram || '',
          cliente_beneficiarios: lead?.cliente_beneficiarios || [],

          estado_civil: maritalStatus.find((s) => s.name === lead?.client?.estado_civil) || null,
          servidor_publico: SN.find((i) => i.id == lead?.client?.servidor_publico) || '',
          gender: sexo.find((s) => s.name === lead?.client?.gender) || null,
          nacionalidade: Nacionalidade.find((s) => s.name === lead?.client?.nacionalidade) || null,
          nome_de_recado: lead?.client?.nome_de_recado || '',
          telefone_de_recado: lead?.client?.telefone_de_recado || '',
          celular_de_recado: lead?.client?.celular_de_recado || '',
          profissao: lead?.client?.profissao || '',
          broker: lead?.broker || null,
          status: status?.find((s) => s.id === lead?.status) || '',
          state: lead?.state || null,
          contact_type: contact_type?.find((ct) => ct.id === lead?.contact_type) || '',
          sold: (lead?.sold !== null && sold?.find((sd) => sd.id == lead?.sold + 1)) || null,
          lost_sale: lost_sale?.find((ls) => ls.id == lead?.lost_sale) || null,
          pague_menos: offer.find((o) => o.id == lead?.pague_menos?.toString()) || null,
          backup_car: offer.find((o) => o.id == lead?.backup_car?.toString()) || null,
          seguro_conteudo: offer.find((o) => o.id == lead?.seguro_conteudo?.toString()) || null,
          attendance_on_time: lead?.attendance_on_time || null,
          end_lead_date: lead?.end_lead_date || null,
          lead_scheduling: lead?.lead_scheduling || null,
          first_call_date: lead?.first_call_date || null,
          competitor_broker: competitor_broker?.find((cb) => cb.id == lead?.competitor_broker) || null,
          sold_date: lead?.sold_date || '',
          sold_type: contact_type?.find((cb) => cb.id == lead?.sold_type) || null,
          quoted_date: lead?.quoted_date || '',
          quoted_type: contact_type?.find((cb) => cb.id == lead?.quoted_type) || null,
          contact_date: lead?.contact_date || '',
          sale_value: lead?.sale_value ? lead?.sale_value?.replaceAll('.', ',') : '',
          aquisition_value: lead?.aquisition_value || '',

          // Comissão
          commission_percentage: lead?.commission_percentage || '',
          insurance_sold_value: lead?.insurance_sold_value?.replaceAll('.', ','),
          assinatura_mensal: SN.find((i) => i.id == lead.assinatura_mensal) || '',
          assinatura_mensal_num_parcelas: lead.assinatura_mensal_num_parcelas,
          commission_value: lead.commission_value,

          // Residencia e Empresarial
          insurance_residence_homeType: lead.client?.address?.homeType || '',
          insurance_residence_amount: lead?.product_details?.insurance_residence_amount,
          insurance_residence_homeHabitation:
            InsuranceResidenceHomeHabitationArray.find((s) => s.id === lead.client?.address?.homeHabitation) || null,
          insurance_residence_owner: InsuranceOwnerArray.find((s) => s.id === lead.client?.address?.owner) || null,
          insurance_residence_new:
            InsuranceNewArray.find((s) => s.id === lead?.product_details?.insurance_residence_new) || null,
          insurance_business_personType:
            InsuranceBusinessPersonTypeArray.find((s) => s.id === lead.client?.client_details?.personType) || null,

          // Condomínio
          insurance_business_activityPlace: lead.client?.client_details?.activityPlace,
          insurance_condo_fire_amount: lead?.product_details?.insurance_condo_fire_amount,
          insurance_condo_blocksAmount: lead?.product_details?.insurance_condo_blocksAmount,
          insurance_condo_elevatorsAmount: lead?.product_details?.insurance_condo_elevatorsAmount,
          insurance_condo_floorsAmount: lead?.product_details?.insurance_condo_floorsAmount,
          insurance_condo_apartmentFloorAmount: lead?.product_details?.insurance_condo_apartmentFloorAmount,
          insurance_condo_parkingSpaceAmount: lead?.product_details?.insurance_condo_parkingSpaceAmount,

          // Bike
          insurance_invoice: lead?.product_details?.insurance_invoice,
          insurance_brand: lead?.product_details?.insurance_brand,
          insurance_model: lead?.product_details?.insurance_model,
          insurance_manufactureYear: lead?.product_details?.insurance_manufactureYear,
          insurance_bike_cost: lead?.product_details?.insurance_bike_cost,
          insurance_bike_useType: lead?.product_details?.insurance_bike_useType,
          insurance_bike_type:
            InsuranceBikeTypeArray.find((s) => s.id === lead?.product_details?.insurance_bike_type) || null,

          // Vida
          insurance_life_work: lead?.product_details?.insurance_life_work,
          insurance_life_monthlySalary: lead?.product_details?.insurance_life_monthlySalary,
          insurance_practicesSports: lead?.product_details?.insurance_practicesSports,
          insurance_life_smoker: lead?.product_details?.insurance_life_smoker,
          insurance_life_covidVaccineAmout: lead?.product_details?.insurance_life_covidVaccineAmout,
          insurance_life_deathCoverAmout: lead?.product_details?.insurance_life_deathCoverAmout,
          insurance_life_civilStatus:
            maritalStatus.find((s) => s.id === lead?.product_details?.insurance_life_civilStatus) || null,

          // Viagem
          insurance_travel_passengersAmout: lead?.product_details?.insurance_travel_passengersAmout,
          insurance_travel_allAges: lead?.product_details?.insurance_travel_allAges,
          insurance_travel_fromCountry: lead?.product_details?.insurance_travel_fromCountry,
          insurance_travel_toCountry: lead?.product_details?.insurance_travel_toCountry,
          insurance_travel_goingDate: lead?.product_details?.insurance_travel_goingDate,
          insurance_travel_returnDate: lead?.product_details?.insurance_travel_returnDate,
          insurance_travel_reason:
            InsuranceTravelReasonArray.find((s) => s.id === lead?.product_details?.insurance_travel_reason) || null,

          // Celular
          insurance_buyAmount: lead?.product_details?.insurance_buyAmount,
          insurance_buyDate: lead?.product_details?.insurance_buyDate,

          // Equipamentos
          insurance_equipmentType: lead?.product_details?.insurance_equipmentType,

          // Fianca
          insurance_bail_name: lead?.product_details?.insurance_bail_name,
          insurance_bail_rentAmount: lead?.product_details?.insurance_bail_rentAmount,
          insurance_bail_iptuAmount: lead?.product_details?.insurance_bail_iptuAmount,
          insurance_bail_condoAmount: lead?.product_details?.insurance_bail_condoAmount,
          insurance_bail_homeHabitationType:
            InsuranceBailHomeHabitationTypeArray.find((s) => s.id === lead?.product_details?.insurance_travel_reason) ||
            null,

          //cartão de credito
          certificate_number: lead?.certificate_number || '',
          registrationDate: lead?.financialData?.data_registro || new Date().toISOString().split('T')[0],
          financialName: lead?.financialData?.nome || '',
          financialCPF: lead?.financialData?.cpf || null,
          financialRg: lead?.financialData?.rg || null,
          financialPhone: lead?.financialData?.telefone || null,
          financialCellphone: lead?.financialData?.celular || null,
          financialEmail: lead?.financialData?.email || '',
          financialCEP: lead?.financialData?.cep || null,
          financialAddress: lead?.financialData?.endereco || '',
          financialAddressNumber: lead?.financialData?.numero || null,
          financialAddressComplement: lead?.financialData?.complemento || '',
          financialNeighborhood: lead?.financialData?.bairro || '',
          financialCity: lead?.financialData?.cidade || '',
          financialUF: lead?.financialData?.uf || null,
          assinatura_recorrente: lead.assinatura_recorrente || [],
          print_proposal: optProposta.find((x) => x.name == lead?.print_proposal),
          print_certificate: optCertificado.find((x) => x.name == lead?.print_certificate),
          term_begin: lead?.term_begin,
          term_final: lead?.term_final
        });

        if (status && lost_sale && contact_type && sold && competitor_broker && lead) {
          setCheck(true);
        }
      }
    }
  }, [loading, usersData]);

  useEffect(() => {
    if (lead.insurance_id) {
      if ((lead.insurance_id >= 1 && lead.insurance_id <= 3) 
          || lead.insurance_id == 18
          || lead.insurance_id == 26) 
        setCar(true);
      else 
        setCar(false);
    }
  }, [lead.insurance_id]);

  useEffect(() => {
    (async () => {
      if (type !== 'edit' && fillable != null) {
        const now = DateTime.now();
        const proposalDate = DateTime.fromISO(fillable.proposal_date);
        // eslint-disable-next-line no-unused-vars
        const diffProposalNow = now.diff(proposalDate, ['hours']);

        await setValues({
          ...values,
          partner: partners.find((p) => p.id == fillable.partner_id),
          proposal: fillable.proposal || '',
          client_name: fillable.client_informations.name || '',
          client_Cpf: fillable.client_informations.cpf || '',
          client_Rg: fillable.client_informations.rg || '',
          client_birth_date: fillable.client_informations.birth_date.split('T')[0] || '',
          client_CEP: fillable.client_informations.address.cep || '',
          client_address_street: fillable.client_informations.address.street || '',
          client_address_number: fillable.client_informations.address.number || '',
          client_address_comp: fillable.client_informations.address.comp || '',
          client_address_district: fillable.client_informations.address.district || '',
          client_address_city: fillable.client_informations.address.city || '',
          client_address_state: '',
          client_cellphone: fillable.client_informations.cellphone || '',
          client_phone: fillable.client_informations.phone || '',
          email: fillable.client_informations.email || '',
          proposal_date: fillable.proposal_date || '',
          product: fillable.product_informations.name || '',
          product_chassis: fillable.product_informations.chassis || '',
          product_license_plate: fillable.product_informations.license_plate || '',
          product_year: fillable.product_informations.year || '',
          product_brand: fillable.product_informations.brand || '',
          insurance: insurances.find((i) => i.id == fillable.insurance.id),
        });
      }
    })();
  }, [fillable]);

  useEffect(() => {
    if (partners) {
      if (type !== 'edit') setValues({ ...values, partner: null, insurance: null });
      let insurance = null;
      if (values.inviter) {
        const partnersFilered = partners?.filter((p) => {
          let found = false;
          p.users.forEach((u) => {
            if (u.id === values.inviter.id) {
              found = true;
            }
          });
          return found;
        });
        if (values.inviter?.roles.length > 0 && values.inviter?.roles[0].id <= 2) {
          insurance = insurances;
        } else {
          insurance = values.inviter.channel.id === 1 ? insurances.filter((i) => i.id <= 3) : insurances;
        }
        setInsurancesOptions(insurance);
        setPartnersOptions(partnersFilered);
      } else {
        setPartnersOptions([]);
      }
    }
  }, [values.inviter, partners]);

  const verifyCertificateFile = async () => {
    const dataFiles = await api.get(`leads/findFilesByClientId?client_id=${client.id}`);

    const files = dataFiles.data;
  };

  const getFile = async () => {
    try {
      const responseFile = await api.get(`leads/fileDownloadByName?lead_id=${lead.id}&fileName=certificado`, {
        responseType: 'arraybuffer',
      });

      const binaryData = btoa(
        new Uint8Array(responseFile.data).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, '')
      );
      return binaryData;
    } catch (error) {
      throw error;
    }
  };

  const checkIsRequiredByContactType = () => {
    return values.contact_type && values.contact_type?.id != 4; // Não conseguiu contato
  }

  const checkIsPaymentAccepted = () => {
    return values.assinatura_recorrente[0]?.status == 'paid';
  }

  // Vendido
  const IsLeadSold = () => selectedSold?.id == 2;

  // Conseguiu contato com o client
  const getContactClient = () => values.contact_type.id != 4;

  const openGerarLinkPagamento = () => {
    GerarLinkPagamento();
    setOpenConfirmGerarLink(true);
  };

  const handleConfirmGerarLink = () => {
    navigator.clipboard.writeText(urlGeradaPagamento);
    toast.success('Link de pagamento copiado para sua área de transferência.');
  };

  const handleCloseGerarLink = () => {
    setOpenConfirmGerarLink(false);
  };

  const handleCanFinish = () => {
    if (permissions?.can_finish) {
      if (!values.sold_type) {
        toast.warning('Informe a forma que recebeu a resposta da venda/não venda');
        return false;
      }

      if (!values.sold_date) {
        toast.warning('Informe a data da venda/não venda');
        return false;
      }

      if (permissions?.can_finish) {
        if (IsLeadSold()) {
          if (!values.competitor_broker) {
            toast.warning('Informe a seguradora');
            return false;
          }
        }

        if (!values.sold_type) {
          toast.warning('Informe a forma que recebeu a resposta da venda/não venda');
          return false;
        }

        if (!values.sold_date) {
          toast.warning('Informe a data da venda/não venda');
          return false;
        }
      }
    }

    return true;
  }

  const handleSendProposal = () => {
    if (values.loadingButton_proposal) return;

    setButtonLoadingProposal(true);
    Pdf(`/lead/gerarProposta/${lead.id}`, 
      `Proposta_${getPdfFileName(lead)}`, 
      () => setButtonLoadingProposal(false));
  }

  const handleSendCertificated = () => {
    if (values.loadingButton_certificate) return;
    
    setButtonLoadingCertificate(true);
    Pdf(`/lead/gerarCertificado/${lead.id}`, 
      `Certificado_${getPdfFileName(lead)}`, 
      () => setButtonLoadingCertificate(false));
  }


  const handleSave = async () => {

    // WhatsApp Ligou ou Em Cotação 
    // Não vendido
    if (values.state > 2 && !IsLeadSold()) {
      if (!values.lost_sale) {
        toast.warning('Informe o motivo de não venda');
        return;
      }
    }

    // Vendido
    if (IsLeadSold()) {
      if (values.inviter?.channel.id == 1 && !values.proposal && type !== 'edit') {
        toast.warning('Proposta não selecionada');
        return;
      }
  
      if (lead.quoted == 1) {
        if (!values.quoted_type) {
          toast.warning('Informe a forma que apresentou a proposta');
          return;
        }
  
        if (!values.quoted_date) {
          toast.warning('Informe a data da cotação');
          return;
        }
      }
  
      if (!handleCanFinish()) {
        return;
      }

      if (!handleSaveFlowService(checkIsLifeInsurance(), values.birth_date)) {
        return;
      }  

    }

    try {
      setLoading(true);
      if (values.inviter)
        await editUser({ phone: values.inviter_Phone && RemoveMask(values.inviter_Phone) }, values.inviter.id);
      // updateUser();

      let clientId;
      let client;

      if (lead?.client?.id) {
        client = lead?.client;
      } else {
        if (values.client_Cpf && values.client_Cpf.length > 0) {
          client = await getClientByCpf(values.client_Cpf);
        } else {
          client = null;
        }
      }

      const clientData = {
        name: values.client_name,
        phone: (values.client_phone && RemoveMask(values.client_phone)) || ' ',
        cellphone: (values.client_cellphone && RemoveMask(values.client_cellphone)) || ' ',
        mail: values.email,
        cpf: (values.client_Cpf && RemoveMask(values.client_Cpf)) || '',
        rg: (values.client_Rg && RemoveMask(values.client_Rg)) || '',
        birth_date: values.birth_date,
        address: {
          cep: (values.client_CEP && RemoveMask(values.client_CEP)) || null,
          street: values.client_address_street || null,
          number: values.client_address_number || null,
          comp: values.client_address_comp || null,
          district: values.client_address_district || null,
          city: values.client_address_city || null,
          state: values.client_address_state || null,
          homeType: values.insurance_residence_homeType || null,
          homeHabitation: values.insurance_residence_homeHabitation ? values.insurance_residence_homeHabitation.id : 0,
          owner: values.insurance_residence_owner ? values.insurance_residence_owner.id : 0,
        },
        facebook: values.facebook || '',
        instagram: values.instagram || '',
        estado_civil: values.estado_civil ? values.estado_civil.name : '',
        servidor_publico: values.servidor_publico.id || 0,
        gender: values.gender ? values.gender.name : '',
        nacionalidade: values.nacionalidade ? values.nacionalidade.name : '',
        nome_de_recado: values.nome_de_recado || '',
        telefone_de_recado: values.telefone_de_recado || '',
        celular_de_recado: values.celular_de_recado || '',
        profissao: values.profissao || '',
        client_details: {
          personType: values.insurance_business_personType ? values.insurance_business_personType.id : 0,
          activityPlace: values.insurance_business_activityPlace || null,
        },
      };
      if (!client) {
        if (values.inviter?.id != 6) {
          const data = await createClient(clientData);
          clientId = data.id;
        } else if (values.inviter?.id == 6 && values.client_Cpf) {
          const data = await createClient(clientData);
          clientId = data.id;
        }
      } else {
        await editClient(clientData, client.id);
        clientId = client.id;
      }
      let whatsappCallStatus = 1;
      if (type === 'edit') {
        whatsappCallStatus = lead.status == 2 && values.contact_type ? 3 : values.status.id;
      }
      if (values?.status?.id == 6) {
        whatsappCallStatus = 6;
      }

      let soma = 0;
      beneficiaries?.forEach((objeto) => {
        soma += parseInt(objeto.participation, 10);
      });
      if (beneficiaries?.length > 0 && soma < 100) {
        toast.warning('A soma das participações dos beneficiarios deve ser igual a 100%');
        setLoading(false);
        return;
      }

      let insuranceSoldValue = IsCorujaFlow() 
        ? '9.95'
        : values.insurance_sold_value?.replaceAll('.', '')?.replaceAll(',', '.');

      let assinaturaMensal = IsCorujaFlow()
        ? 1
        : values.assinatura_mensal?.id;

      var leadData = {
        proposal: values.proposal || null,
        proposal_date: values.proposal_date,
        product: values.product,
        product_details: {
          brand: values.product_brand,
          license_plate: values.product_license_plate,
          chassis: values.product_chassis,
          year: values.product_year,
          details: values.product_details,

          // Residencia
          insurance_residence_amount: values.insurance_residence_amount,
          insurance_residence_new: values.insurance_residence_new ? values.insurance_residence_new.id : 0,

          // Condomínio
          insurance_condo_fire_amount: values.insurance_condo_fire_amount,
          insurance_condo_blocksAmount: values.insurance_condo_blocksAmount,
          insurance_condo_elevatorsAmount: values.insurance_condo_elevatorsAmount,
          insurance_condo_floorsAmount: values.insurance_condo_floorsAmount,
          insurance_condo_apartmentFloorAmount: values.insurance_condo_apartmentFloorAmount,
          insurance_condo_parkingSpaceAmount: values.insurance_condo_parkingSpaceAmount,

          // Bike
          insurance_invoice: values.insurance_invoice,
          insurance_brand: values.insurance_brand,
          insurance_model: values.insurance_model,
          insurance_manufactureYear: values.insurance_manufactureYear,
          insurance_bike_cost: values.insurance_bike_cost,
          insurance_bike_type: values.insurance_bike_type ? values.insurance_bike_type.id : 0,
          insurance_bike_useType: values.insurance_bike_useType,

          // Vida
          insurance_life_work: values.insurance_life_work,
          insurance_life_monthlySalary: values.insurance_life_monthlySalary,
          insurance_practicesSports: values.insurance_practicesSports,
          insurance_life_smoker: values.insurance_life_smoker,
          insurance_life_covidVaccineAmout: values.insurance_life_covidVaccineAmout,
          insurance_life_deathCoverAmout: values.insurance_life_deathCoverAmout,
          insurance_life_civilStatus: values.insurance_life_civilStatus ? values.insurance_life_civilStatus.id : 0,

          // Viagem
          insurance_travel_passengersAmout: values.insurance_travel_passengersAmout,
          insurance_travel_allAges: values.insurance_travel_allAges,
          insurance_travel_fromCountry: values.insurance_travel_fromCountry,
          insurance_travel_toCountry: values.insurance_travel_toCountry,
          insurance_travel_goingDate: values.insurance_travel_goingDate,
          insurance_travel_returnDate: values.insurance_travel_returnDate,
          insurance_travel_reason: values.insurance_travel_reason ? values.insurance_travel_reason.id : 0,

          // Celular
          insurance_buyAmount: values.insurance_buyAmount,
          insurance_buyDate: values.insurance_buyDate,

          // Equipamentos
          insurance_equipmentType: values.insurance_equipmentType,

          // Fianca
          insurance_bail_name: values.insurance_bail_name,
          insurance_bail_rentAmount: values.insurance_bail_rentAmount,
          insurance_bail_iptuAmount: values.insurance_bail_iptuAmount,
          insurance_bail_condoAmount: values.insurance_bail_condoAmount,
          insurance_bail_homeHabitationType: values.insurance_bail_homeHabitationType
            ? values.insurance_bail_homeHabitationType.id
            : 0,
        },
        channel_id: values.channel_id,
        inviter_id: values.inviter?.id,
        broker_id: values.broker?.id,
        insurance_id: selectedInsuranceId,
        insurance_expiration: values.insurance_expiration ? values.insurance_expiration?.id : 0,
        partner_id: values.partner.id,
        additional_information: values.additional_information || ' ',
        cliente_beneficiarios: beneficiaries || [],

        client_id: clientId,
        contact_type: (values.contact_type && values.contact_type.id) || null,
        sold: selectedSold?.id -1, 
        lost_sale: values.lost_sale?.id || null,
        //backup_car: backupCar?.id.toString() || null,
        //pague_menos: pagueMenos?.id.toString() || null,

        monthly_subscription: values.monthly_subscription.id,
        print_proposal: values.print_proposal?.name || null,
        print_certificate: values.print_certificate?.name || null,
        // status: whatsappCallStatus || null,
        state: values.state || null,
        endorsement_date: values.endorsement_date || null,
        competitor_broker: values.competitor_broker?.id || null,
        attendance_on_time: values.attendance_on_time || null,
        // indication_on_time: values.indication_on_time || null,
        end_lead_date: values.end_lead_date,
        first_call_date: values.first_call_date,
        date_fail: values.date_fail,
        // arquivo_oc: values.arquivo_oc,
        pending_sell: values.pending_sell || null,
        negociate: values.negociate || null,
        sold_date: values.sold_date,
        sold_type: (values.sold_type && values.sold_type.id) || null,
        quoted_date: values.quoted_date,
        quoted_type: (values.quoted_type && values.quoted_type.id) || null,
        contact_date: values.contact_date,
        sale_value: values.sale_value?.replaceAll('.', '')?.replaceAll(',', '.'),

        // Comissão
        commission_percentage: values.commission_percentage,
        insurance_sold_value: insuranceSoldValue,
        assinatura_mensal: assinaturaMensal,
        commission_value: values.commission_value,

        //dados cartão

        financialData: {
          certificate_number: values.certificate_number,
          data_registro: values.registrationDate,
          nome: values.financialName,
          cpf: values.financialCPF,
          rg: values.financialRg,
          telefone: values.financialPhone,
          celular: values.financialCellphone,
          email: values.financialEmail,
          cep: values.financialCEP,
          endereco: values.financialAddress,
          numero: values.financialAddressNumber,
          complemento: values.financialAddressComplement,
          bairro: values.financialNeighborhood,
          cidade: values.financialCity,
          uf: values.financialUF,
        },
      };

      if (!IsCorujaFlow()) {
        leadData = {
          ...leadData, 
          term_begin: values.term_begin,
          term_final: values.term_final,
          assinatura_mensal_num_parcelas: values.assinatura_mensal_num_parcelas};
      }

      if (type === 'edit') {
        const saveResponse = await editLead(leadData, lead?.id);
        const newStatus = lead_status?.find((st) => st.var_id == saveResponse.status);

        await updateLead();
        await getPermissions();

        if (newStatus) 
          setValues({ ...values, status: { id: newStatus.var_id, name: newStatus.value } });

        if (!hideToast) 
          toast.success('Lead salvo com sucesso!');
        else 
          setHideToast(false);        
      } else {
        const editResponse = await dataCreate('/leads', leadData);
        if (editResponse) {
          toast.success('Lead criado com sucesso!');
          if (editResponse.indication_on_time == 0) toast.warning('Lead indicada fora do prazo!');
        }
      }
      if (values.state == 3) {
        setOpen(false);
      }
      if (mutate) mutate();
      setLoading(false);
      setLoadingButton_salvar(false);

      return true;
    } 
    catch (error) {
      setLoadingButton_salvar(false);
      console.log('error:', error);
      
      setLoading(false);
      if (error && error.response?.status === 401) {
        toast.warning('Proposta já registrada');
        setLoadingButton_salvar(false);
        return;
      }
      let msg;
      if (error && error?.statusError === 1) {
        msg = error.message;
        setCreateError(true);
      } 
      else msg = 'Atenção! Verifique os campos preenchidos';
      toast.warning(msg);
    }
  };

  const handleChangeBeneficiary = (key, event, obj) => {
    if (obj) {
      if (!(obj.id || obj.name)) beneficiaries[key][obj.variable] = undefined;
      else beneficiaries[key][obj.variable] = { id: obj.id, name: obj.name };

      setBeneficiaries([...beneficiaries]);
    } else {
      if (event.target.type == 'number') {
        if (event.target.max && parseInt(event.target.max) <= parseInt(event.target.value))
          event.target.value = event.target.max;
        if (event.target.min && parseInt(event.target.min) >= parseInt(event.target.value))
          event.target.value = event.target.min;
        if (event.target.name == 'participation') {
          if (beneficiaries.length == 1) {
            event.target.value = 100;
          } else {
            let soma = 0;
            beneficiaries.forEach((objeto) => {
              soma += parseInt(objeto.participation, 10);
            });
            soma -= parseInt(beneficiaries[key][event.target.name]);
            if (soma + parseInt(event.target.value) > 100) {
              event.target.value = 100 - soma;
            }
          }
        }
      }
      beneficiaries[key][event.target.name] = event.target.value;
      setBeneficiaries([...beneficiaries]);
    }
  };

  const handleChange = (event) => {
    if (event.target.name === 'commission_percentage') {
      const value = Math.max(0, Math.min(100, Number(event.target.value)));

      setValues({
        ...values,
        [event.target.name]: value,
      });
    }
    
    else {
      var eventValue = null;
      if (event.target.type === 'checkbox') eventValue = event.target.checked;
      else if (event.target.type === 'date') {
        if (!event.target.value) eventValue = null;
        else eventValue = event.target.value;
      } else eventValue = event.target.value;

      setValues({
        ...values,
        [event.target.name]: eventValue,
      });
    }
  };

  const handleChangeTermBegin = (event) => {
    if (!IsCorujaFlow()) {
      const termBeginDate = new Date(event.target.value);
      termBeginDate.setFullYear(termBeginDate.getFullYear() + 1);
      const termFinalDate = termBeginDate.toISOString().split('T')[0];

      setValues({
        ...values,
        term_begin: event.target.value,
        term_final: termFinalDate,
      });  
    }
    else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  }

  const handleChangeSelectedSold = (event, newValue) => {
    setSelectedSold(newValue);
  }

  const handleChangeAutoComplete = (event, newValue, variable) => {
    handleChangeAutoCompleteValidations(newValue, variable, values, setValues);
  };

  const handlePendency = async () => {
    setValues({ ...values, pending_sell: true });
    setFinish(true);
  };

  const [selectedInsuranceId, setSelectedInsuranceId] = useState(null);
  const handleInsuranceOptions = async (item) => {
    setLead({
      ...lead,
      insurance_id: item.id,
    });

    if (openInsuranceModal) setSelectedInsuranceId(item.id);
  };

  const handleQuotationAuto = async (event, newValue) => {
    const url = `https://www.corretor-online.com.br/canalcliente/index.htm?ParamWS=ZTc5N2YwNW1jbXBlY3FLdGs3OWZjS2l6YTNDd3YxcEJmbEZKWW82dHFiRmFjbjVPZnBGdG1wR1pxWkdSV21sUmptMUdTVWxCc1pXK2ZubDV1VjV5ZmxGUmFrbCtrVzUrWGs1bVhpcVFuNnVYVktTdmxwcVNXUUxTbFpoVG5Bb0E=`;
    window.open(url);
  };

  const checkBeneficiaries = () => {
    if (IsCorujaFlow() && checkIsLifeInsurance() && beneficiaries.length == 0) {
      toast.warning('Informe pelo menos 1 Beneficiário!');
      return false;
    }

    return true;
  };

  const handleFinishLead = async () => {
    if (permissions?.next_status < 5 && !values.contact_type) {
      toast.warning('Você não tem permissão para finalizar essa lead devido a mudança no tipo de contato!');
      return;
    }
    if (values.inviter?.id != 6) {
      verify(values, setValues, car);
      if (!selectedSold) {
        toast.warning('Informe o Status da venda');
        return;
      }
      if (!IsLeadSold() && !values.lost_sale) {
        toast.warning('Informe o Motivo da não venda');
        return;
      }

      if (IsCorujaFlow()) {
        if (checkIsPaymentAccepted() && !IsLeadSold()) {
          setAlert({
            open: true,
            title: 'AVISO',
            msg: `O pagamento deste Lead já foi realizado e autorizado, portanto o status da venda deve ser = "${sold[1].name}"`,
          });
          return;          
        }

        if (!values.print_proposal && !values.lost_sale) {
          toast.warning('Informe se foi enviada a proposta');
          return;
        }
        if (!values.print_certificate && !values.lost_sale) {
          toast.warning('Informe se foi enviado o certificado');
          return;
        }
      }

      if (values.lost_sale?.id == 5) {
        setOpenEndossoModal(true);
        return;
      }
    }

    if (lead.quoted == 1) {
      if (!values.quoted_type) {
        toast.warning('Informe a forma que apresentou a proposta');
        return;
      }

      if (!values.quoted_date) {
        toast.warning('Informe a data da cotação');
        return;
      }
    }

    if (permissions?.can_finish) {
      if (IsLeadSold()) {
        if (!values.competitor_broker) {
          toast.warning('Informe a seguradora');
          return;
        }

        if (IsCorujaFlow() && !values.sale_value) {
          setSaleValueDisabled(false);
          setCommissionPercentageDisabled(false);
          toast.warning('Informe o valor da venda');
          return;
        }

        if (!values.commission_percentage) {
          setCommissionPercentageDisabled(false);
          toast.warning('Informe a porcentagem da comissão');
          return;
        }
      }

      if (!values.sold_type) {
        toast.warning('Informe a forma que recebeu a resposta da venda/não venda');
        return;
      }

      if (!values.sold_date) {
        toast.warning('Informe a data da venda/não venda');
        return;
      }
    }

    if (!IsLeadSold()) {
      if (!values.lost_sale) {
        toast.warning('Informe o motivo de não venda');
        return;
      }
    }

    if (IsCorujaFlow() && IsLeadSold()) {
      setShowLoading(true);
      if (! await onClickVisualizarAnexo(lead, 'proposta', true)) {
        setShowLoading(false);
        toast.warning('A Proposta não foi anexada!');
        return;
      }
      if (!await onClickVisualizarAnexo(lead, 'apolice', true)) {
        setShowLoading(false);
        toast.warning('A Apólice/Certificado não foi anexado!');
        return;
      }
    }

    const finishTime = DateTime.now().toISO().split('.')[0];
    const fullTime = UtilsTime(lead?.create_lead_date.split(' ').join('T'), finishTime);

    setValues({
      ...values,
      state: 3,
      status: { id: 6, name: 'Finalizado' },
      attendance_on_time: fullTime.hours + fullTime.minute / 60 < time_to_finish_lead.value ? 1 : 0,
      end_lead_date: finishTime,
    });
    setFinish(true);
  };

  // #endregion

  // CALL HANDLE SAVE
  useEffect(() => {
    if (finish != false) {
      handleSave();
      setFinish(false);
    }
  }, [finish]);

  useEffect(() => {
    if (close != false) {
      handleFinishLead();
      setClose(false);
    }
  }, [close]);

  const downloadFile = async () => {
    try {
  
      const response = await api.get(`leads/fileDownload?lead_id=${leadCurrent.id}`,
          {
              responseType: 'arraybuffer',
          }
      );
  
      const binaryData = btoa(
          new Uint8Array(response.data).reduce(function (data, byte) {
              return data + String.fromCharCode(byte);
          }, '')
      );
        
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${binaryData}`;
      link.download = leadCurrent.id + ' OF.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
    } catch (error) {
        console.log(error);
    }
  };  

  // const downloadFile = async () => {
  //   const token = localStorage.getItem('token');
  //   const requestOptions = {
  //     method: 'GET',
  //     headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
  //   };
  //   await fetch(`leads/fileDownload?lead_id=${leadCurrent.id}`, requestOptions)
  //     .then((res) => {
  //       if (res.status === 400) {
  //         return;
  //       } else {
  //         return res.blob();
  //       }
  //     })
  //     .then((blob) => {
  //       const href = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = href;
  //       link.setAttribute('download', leadCurrent.id + ' OF.pdf'); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       toast.success('Fazendo Download do arquivo...');
  //     })
  //     .catch((error) => {
  //       toast.error('Não foi possível fazer o download do arquivo.');
  //     });
  // };

  const [getLoadingButton_salvar, setLoadingButton_salvar] = useState(false);
  // Buttons
  const ButtonDiv = () => (
    <div className="form-buttons" style={{ margin: '14px 0' }}>
      {type !== 'edit' &&
        values.inviter?.roles[0] &&
        (values.inviter?.roles[0].id === 7 || values.inviter?.roles[0].id === 1) &&
        values.partner?.release_quote && (
          <Button
            onClick={() => {
              handleQuotationAuto();
            }}
            variant="contained"
            color="default">
            Simular cotação
          </Button>
        )}
      {type === 'edit' && (
        <>
          {lead?.insurance_id == '25' && (
            <Button
              variant="contained"
              color="default"
              onClick={() => {
                setCancelLeadModal(true);
              }}>
              Cancelamento no Quiver
            </Button>
          )}

          {lead?.insurance_id != '25' && (
            <Button
              variant="contained"
              color="default"
              onClick={() => {
                verify(values, setValues, car, permissions);
                setClose(true);
              }}
              disabled={!permissions?.can_finish}>
              Finalizar
            </Button>
          )}

          {user?.role === 1 && lead?.insurance_id != '25' && (
            <Button
              variant="contained"
              color="default"
              onClick={() => {
                setDeadlinesModal(true);
              }}>
              Alterar Prazos
            </Button>
          )}

          {lead?.insurance_id != '25' && (
            <>
              <Button
                style={{ width: 'auto' }}
                variant="contained"
                color="default"
                onClick={() => setOpenScheduleModal(true)}
                disabled={!values.contact_type || values.contact_type.id < 1}>
                {values.lead_scheduling && values.lead_scheduling.status === 0 ? 'Agendamento' : 'Agendar Retorno'}
              </Button>
              <Button
                style={{ width: 'auto' }}
                onClick={() => setOpenInsuranceModal(true)}
                variant="contained"
                color="default"
                disabled={false}>
                Tipo de Seguro
              </Button>
              <Button
                style={{ width: 'auto' }}
                onClick={() => setOpenPendencyModal(true)}
                variant="contained"
                color="default"
                disabled={permissions?.next_status < 5}>
                Vendido com pendência
              </Button>
              {permissions?.can_quote.quote_type != null && (
                <Button
                  onClick={async () => {
                    switch (permissions?.can_quote.quote_type) {
                      case 1:
                        await handleQuotationQuiverMulti(values, setValues, lead);
                        break;
                      case 2:
                        await handleQuotationQuiverMultiResidential(values, setValues, lead);
                        break;
                      default:
                        setAlert({
                          open: true,
                          title: 'Cotação',
                          msg: 'Cotação realizada com sucesso',
                        });

                        const newStatus = await goToNextStatus(lead?.id);

                        setValues({ ...values, status: { id: newStatus.var_id, name: newStatus.value } });
                        await editLead({ quoted: 1 }, lead?.id);
                        await updateLead();
                        await getPermissions();
                        return;
                    }
                    await getPermissions();
                    setFinish(true);
                  }}
                  variant="contained"
                  color="default"
                  disabled={!permissions?.can_quote.able_to_quote || false}>
                  Cotação
                </Button>
              )}
            </>
          )}
          {permissions?.can_negociate && (
            <Button
              onClick={async () => {
                setValues({ ...values, status: { id: 2, name: 'Negociação' }, negociate: true });
                setFinish(true);
              }}
              variant="contained"
              color="default"
              disabled={!permissions?.can_negociate || false}>
              Negociação
            </Button>
          )}

          <ButtonGroup>
            <Button
              onClick={() => {
                setCallLabel('Ligar Para');
                setCallType(1);
                setOpenCallModal(true);
              }}
              variant="contained"
              color="default"
              disabled={!permissions?.can_call || false}>
              Ligar
            </Button>

            <Button
              onClick={() => {
                setCallLabel('WhatsApp para');
                setCallType(2);
                setOpenCallModal(true);
              }}
              variant="contained"
              color="default"
              disabled={!permissions?.can_call || false}>
              WhatsApp
            </Button>
          </ButtonGroup>
        </>
      )}
      <Button data-testid="Salvar" type="submit" variant="contained" color="default">
        {getLoadingButton_salvar ? 
          (<LoadingButton />) : 
          (<></>) 
        }
        Salvar
      </Button>

      {type !== 'edit' && (
        <Button onClick={clear} variant="contained" color="default">
          Descartar
        </Button>
      )}
    </div>
  );

  const lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().slice(0, 10) + 'T23:59';

  const [alert, setAlert] = useState({
    open: false,
    msg: '',
    title: '',
  });

  const checkIsLifeInsurance = () => {
    return selectedInsuranceId && selectedInsuranceId == 9;
  }

  const CarregarBeneficiarios = () => {
    var componentes = [];
    beneficiaries?.forEach(
      (x, index) =>
        (componentes = [
          ...componentes,
          <>
            <div
              style={{
                backgroundColor: '#fff',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '10px',
              }}>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '1.3rem',
                  fontWeight: '700',
                }}>
                {index + 1}° Beneficiário:
              </label>
              <Button
                style={{ backgroundColor: '#fa9c12' }}
                className={'button'}
                onClick={() => RemoveBeneficiary(index)}
                variant="contained"
                color="default">
                <label style={{ color: '#fff', fontWeight: '800' }}>Remover</label>
              </Button>
            </div>
            <Beneficiary index={index} beneficiary={x} handleChange={handleChangeBeneficiary} />
          </>,
        ])
    );
    return (
      <>
        {componentes}
        <div
          style={{
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'row-reverse',
            width: '100%',
            padding: '10px',
          }}>
          <Button
            style={{ backgroundColor: '#fa9c12' }}
            onClick={() => NewBeneficiary()}
            variant="contained"
            color="default">
            <label style={{ color: '#fff', fontWeight: '800' }}>Adicionar Beneficiário</label>
          </Button>
        </div>
      </>
    );
  };
  const preencherDadosFinancial = () => {
    setValues({
      ...values,
      certificate_number: '',
      registrationDate: new Date().toISOString().split('T')[0],
      financialName: values.client_name,
      financialCPF: values.client_Cpf,
      financialRg: values.client_Rg,
      financialPhone: values.client_phone,
      financialCellphone: values.client_cellphone,
      financialEmail: values.email,
      financialCEP: values.client_CEP,
      financialAddress: values.client_address_street,
      financialAddressNumber: values.client_address_number,
      financialNeighborhood: values.client_address_district,
      financialCity: values.client_address_city,
      financialUF: values.client_address_state,
      financialAddressComplement: values.client_address_comp,
    });
  };

  const [urlGeradaPagamento, setUrlGeradoPagamento] = useState();

  const GerarLinkPagamento = async () => {
    try {
      setLoading(true);
      const paymentLinkData = {
        identificadorLead: lead.id,
      };
      var resp = await api.post(`maistodos/gerarLinkPagamento`, paymentLinkData);
      if (resp.status == 200) {
        const domainMatch = window.location.href.match(/(?:https?:\/\/)?(?:www\.)?([^:/\n?]+)/);

        if (domainMatch) {
          const domain = domainMatch[1];
          const tenantId = localStorage.getItem('tenantId');
          const finalURL = `https://${domain}/payment/${resp.data.external_public_key};${resp.data.external_private_key};${tenantId}`;
          setUrlGeradoPagamento(finalURL);
        } else {
          toast.error('Não foi possível gerar o link.');
        }
      } else {
        toast.error('Não foi possivel gerar o link.');
        return;
      }
    } catch (error) {
        console.log('error:', error);
      toast.error(`Não foi possivel gerar o link. ${error}`, { autoClose : false });
    } finally {
      setLoading(false);
    }
  };

  const ExecutePayment = async () => {
    const dataAtual = new Date();
    var iniciopag = dataAtual.setDate(dataAtual.getDate() + 3);
    iniciopag = new Date(iniciopag).toISOString().split('T')[0];
    if (
      !(
        !!lead?.id &&
        !!values.creditCardNumber &&
        !!values.securitycode &&
        !!values.expirationYear &&
        !!values.expirationMonth
      )
    ) {
      toast.warning('Preencha todos os campos corretamente');
      return;
    }
    try {
      if ( await handleSave() !== true )
        return;

      setLoading(true);
      const paymentData = {
        identificadorLead: lead?.id,
        cartao: {
          numero: values.creditCardNumber,
          cvv: values.securitycode,
          validade: `${values.expirationYear}-${values.expirationMonth}`,
        },
      };

      var resp = await api.post(`maistodos/assinaturarecorrente`, paymentData);
      if (resp.status == 200 || resp.status == 201) {
        toast.success(resp.data.message);
        await updateLead();
      } else {
        toast.error(resp.data.message, { autoClose : false });
        return;
      }
    } 
    catch (error) 
    {
      console.log(error);
      if (error.response && (error.response.status === 400 || error.response.status === 500)) {
        toast.error(error.response.data.message, { autoClose : false });
      } else {
        toast.error(`Não foi possível concluir o pagamento! ${error}`, { autoClose : false });
      }

      toast.warning('Verifique se todos os dados do cliente estão corretos!', { autoClose : false });

      setLoading(false);
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (getLoadingButton_salvar) return;
    
    setLoadingButton_salvar(true);
    verify(values, setValues, car);
    setFinish(true);
  }

  return (
    <>
      {true === IsCorujaFlow()}

      <AlertDialog
        open={alert.open}
        msg={alert.msg}
        title={alert.title}
        ok={() => {
          setAlert({ open: false, msg: '', title: '' });
        }}
      />
      {(loading || showLoading) && (
        <LoadingDiv>
          <Loading />
        </LoadingDiv>
      )}

      {usersData && (
        <Container style={{ backgroundColor: '#e7e7e7' }}>
          <GetProposal
            open={openGetProposal}
            setOpen={setOpenGetProposal}
            setFillable={setFillable}
            inviter={values.inviter}
          />
          {lead && (
            <>
              <CallModal
                open={openCallModal}
                setOpen={setOpenCallModal}
                clientName={values.client_name}
                clientPhones={[values.client_phone, values.client_cellphone]}
                broker={values.broker}
                callLabel={callLabel}
                callType={callType}
                clientId={lead.client?.id}
              />
              <ScheduleModal
                open={openScheduleModal}
                setOpen={setOpenScheduleModal}
                lead={lead}
                next={() => {
                  setFinish(true);
                }}
              />
              <SimpleModal
                title="Registrar Pendência"
                fieldName="Observação"
                open={openPendencyModal}
                setOpen={setOpenPendencyModal}
                value={values.additional_information}
                onChange={(e) => setValues({ ...values, additional_information: e.target.value })}
                next={handlePendency}
              />
              <SelectInsuranceOptions
                open={openInsuranceModal}
                setOpen={setOpenInsuranceModal}
                values={values}
                setValues={setValues}
                next={handleInsuranceOptions}
              />
              <DeadlineModal
                title="Alterar Prazos - Administrador"
                open={deadlinesModal}
                setOpen={setDeadlinesModal}
                value={leadCurrent}
              />
              <CancelLeadModal
                title="Confirmar Lead Cancelado?"
                open={cancelLeadModal}
                setOpen={setCancelLeadModal}
                value={leadCurrent}
                setOpenLeadForm={setOpen}
              />
              <SimpleModal
                title="Agendamento endosso"
                fieldName="Data de expiração do seguro do cliente"
                open={openEndossoModal}
                setOpen={setOpenEndossoModal}
                value={values.endorsement_date}
                finish
                finishValue={values.date_fail}
                finishonChange={(e) => setValues({ ...values, date_fail: e.target.checked })}
                onChange={(e) => setValues({ ...values, endorsement_date: e.target.value })}
                next={() => {
                  const finishTime = DateTime.now().toISO().split('.')[0];
                  const fullTime = UtilsTime(lead?.create_lead_date.split(' ').join('T'), finishTime);
                  setValues({
                    ...values,
                    state: 3,
                    attendance_on_time: fullTime.hours + fullTime.minute / 60 < time_to_finish_lead.value ? 1 : 0,
                    end_lead_date: finishTime,
                  });
                  setFinish(true);
                  setOpen(false);
                }}
                date
              />
            </>
          )}
          <form
            ref={form}
            onSubmit={handleSubmit}>
            <ButtonDiv />
            {type === 'edit' && lead?.insurance_id != '25' && (
              <div>
                <h2>Informações do Corretor - Lead: {lead?.id}</h2>
                <span>
                  <h4>Status: {values.status && values.status.name}   -   Tipo de Seguro: {GetInsuranceName()}</h4>
                </span>
                <div className="form">
                  <FormGroup dark>
                    <AutocompleteField
                      medium
                      className="control g"
                      valueText={values.broker ? values.broker.name : ''}
                      options={usersData ? usersData.filter((u) => u.roles[0]?.id <= 3) : [values.broker]}
                      value={values.broker || null}
                      varibleName="broker"
                      onChange={handleChangeAutoComplete}
                      label="Corretor"
                      disabled={user?.role >= 2}
                      required
                    />

                    {permissions?.can_call && (
                      <AutocompleteField
                        medium
                        valueText={values.contact_type ? values.contact_type.name : ''}
                        options={contact_type}
                        value={values.contact_type || null}
                        varibleName="contact_type"
                        onChange={(event, newValue, variable) =>
                          handleChangeAutoCompleteContactType(newValue, variable, values, setValues)
                        }
                        label="Forma de contato inicial"
                      />
                    )}

                    {permissions?.can_call && (
                      <TextField
                        id="datetime-local"
                        label="Data do contato"
                        type="datetime-local"
                        value={values.contact_date || ''}
                        onChange={(e) => setValues({ ...values, contact_date: e.target.value })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        maxDate={lastDay}
                      />
                    )}
                  </FormGroup>

                  {lead.quoted == 1 && (
                    <>
                      <Box>
                        <FormGroup dark style={{ alignItems: 'center' }}>
                          <AutocompleteField
                            medium
                            valueText={values.quoted_type || ''}
                            options={contact_type}
                            value={values.quoted_type || null}
                            varibleName="quoted_type"
                            onChange={(event, newValue, variable) =>
                              handleChangeAutoCompleteQuotedType(newValue, variable, values, setValues)
                            }
                            label="Forma que apresentou a proposta"
                            required
                          />

                          <TextField
                            id="datetime-local"
                            label="Data da Cotação"
                            type="datetime-local"
                            value={values.quoted_date || ''}
                            onChange={(e) => setValues({ ...values, quoted_date: e.target.value })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            maxDate={lastDay}
                            required
                          />
                        </FormGroup>
                      </Box>
                    </>
                  )}

                  {permissions?.can_finish && (
                    <Box>
                      <FormGroup dark>
                        <AutocompleteField
                          medium
                          valueText={selectedSold ? selectedSold.name : ''}
                          options={sold}
                          value={selectedSold || null}
                          varibleName="sold"
                          onChange={handleChangeSelectedSold}
                          label="Status da venda"
                          required
                        />
                        {!IsLeadSold() && (
                          <AutocompleteField
                            medium
                            valueText={values.lost_sale ? values.lost_sale.name : ''}
                            options={lost_sale}
                            value={values.lost_sale || null}
                            varibleName="lost_sale"
                            onChange={handleChangeAutoComplete}
                            label="Motivo da não venda"
                            required
                          />
                        )}
                        {IsLeadSold() && (
                          <AutocompleteField
                            medium
                            valueText={values.competitor_broker ? values.competitor_broker.name : ''}
                            options={competitor_broker}
                            value={values.competitor_broker || null}
                            varibleName="competitor_broker"
                            onChange={handleChangeAutoComplete}
                            label="Seguradora"
                            required
                          />
                        )}

                        <AutocompleteField
                          medium
                          valueText={values.sold_type ? values.sold_type.name : ''}
                          options={contact_type}
                          value={values.sold_type || null}
                          varibleName="sold_type"
                          onChange={(event, newValue, variable) =>
                            handleChangeAutoCompleteSoldType(newValue, variable, values, setValues)
                          }
                          label="Forma que recebeu a resposta venda/não venda"
                          required
                        />

                        <TextField
                          id="datetime-local"
                          label="Data da venda/não venda"
                          type="datetime-local"
                          value={values.sold_date || ''}
                          onChange={(e) => setValues({ ...values, sold_date: e.target.value })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          maxDate={lastDay}
                          required
                        />
                      </FormGroup>

                      <CommissionComponents 
                        values={values}
                        setValues={setValues}
                        handleChange={handleChange}
                        handleChangeTermBegin={handleChangeTermBegin}
                        termBegin={values.term_begin}
                        termFinal={values.term_final}

                        handleChangeCommissionPercentage={(e) => setValues({ ...values, commission_percentage: e.target.value })}
                        isDisableCommissionPercentage={GetCommissionPercentageDisabled()}
                        commissionPercentage={values.commission_percentage}

                        handleChangeInsuranceSoldValue={(e) => setValues({ ...values, insurance_sold_value: e.target.value })}
                        insuranceSoldValue={values.insurance_sold_value}

                        handleChangeAutoComplete={handleChangeAutoComplete}
                        assinaturaMensal={values.assinatura_mensal}
                        assinaturaMensalValueText={values.assinatura_mensal ? values.assinatura_mensal.name : ''}

                        assinaturaMensalNumParcelas={values.assinatura_mensal_num_parcelas}

                        isDisabled={IsCorujaFlow() || !IsLeadSold()}
                      ></CommissionComponents>
                      
                    </Box>
                  )}

                  {permissions?.can_finish && (
                    <Box>
                      <FormGroup dark>
                        {IsLeadSold() && (
                          <>
                            <NumericFormat
                              label="Valor da Assinatura Mensal:"
                              customInput={TextField}
                              decimalSeparator=","
                              decimalScale={2}
                              autoComplete="off"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                              }}
                              value={floatParaEstiloPTBR(parseFloat(values.sale_value?.replace(',', '.')))}
                              onChange={() => {}}
                              disabled={GetSaleValueDisabled()}
                            />
                            <NumericFormat
                              label="Valor da venda"
                              customInput={TextField}
                              decimalSeparator=","
                              decimalScale={2}
                              autoComplete="off"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                              }}
                              value={floatParaEstiloPTBR(parseFloat(values.sale_value?.replace(',', '.')))}
                              onChange={(e) => setValues({ ...values, sale_value: e.target.value })}
                              disabled={GetSaleValueDisabled()}
                            />
                            <NumericFormat
                              label="Valor da adesão"
                              customInput={TextField}
                              decimalSeparator=","
                              decimalScale={2}
                              autoComplete="off"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                              }}
                              value={formatarParaEstiloPTBR(values.aquisition_value || '0')}
                              disabled={GetSaleValueDisabled()}
                              onChange={() => {}}
                            />
                          </>
                        )}
                      </FormGroup>

                      <>
                        {values.status && values.status.id >= 4 ? (
                          <FormGroup dark>
                            <AutocompleteField
                              medium
                              valueText={values.print_proposal ? values.print_proposal.name : ''}
                              options={optProposta}
                              value={values.print_proposal || null}
                              varibleName="print_proposal"
                              onChange={(event, newValue, variable) =>
                                handleChangeAutoCompleteSoldType(newValue, variable, values, setValues)
                              }
                              label='Tirou print do retorno do cliente, dando "OK" do envio da proposta e anexou a proposta no CRM, botão no final desta página?'
                              disabled={GetTirouPrintPropostaDisabled()}
                              required={IsLeadSold()}
                            />
                          </FormGroup>
                        ) : (
                          <></>
                        )}
                        {values.status && values.status.id >= 4 ? (
                          <FormGroup dark>
                            <AutocompleteField
                              medium
                              valueText={values.print_certificate ? values.print_certificate.name : ''}
                              options={optCertificado}
                              value={values.print_certificate || null}
                              varibleName="print_certificate"
                              onChange={(event, newValue, variable) =>
                                handleChangeAutoCompleteSoldType(newValue, variable, values, setValues)
                              }
                              label='Tirou print do retorno do cliente, dando "OK" do envio do certificado e anexou o certificado / Apólice no CRM, botão no final desta página?'
                              disabled={GetTirouPrintCertificadoDisabled()}
                              required={IsLeadSold()}
                            />
                          </FormGroup>
                        ) : (
                          <></>
                        )}
                      </>

                      {IsCorujaFlow() && (
                        <>
                          <Box>
                            <FormGroup dark>
                              <div style={{ alignItems: 'start', width: '100%', marginBottom: '1.5%' }}>
                                {values.status && values.status.id >= 4 ? (
                                  <Button
                                    style={{ width: 'auto' }}
                                    onClick={handleSendProposal}
                                    variant="contained"
                                    color="default">
                                      
                                    {values.loadingButton_proposal ? 
                                      (<LoadingButton />) : 
                                      (<></>) 
                                    }
                                    Enviar Proposta
                                  </Button>
                                ) : (
                                  <></>
                                )}
                                {values.status && values.status.id >= 4 ? (
                                  <Button
                                    style={{ width: 'auto', marginLeft: '2%', marginRight: '2%' }}
                                    onClick={handleSendCertificated}
                                    variant="contained"
                                    color="default"
                                    disabled={!checkIsPaymentAccepted()}>
                                    
                                    {values.loadingButton_certificate ? (
                                      <LoadingButton />
                                    ) : 
                                      (<></>) 
                                    }
                                    Enviar Certificado
                                  </Button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </FormGroup>
                          </Box>
                        </>
                      )}

                    </Box>
                  )}
                </div>
              </div>
            )}
            {IsCorujaFlow() &&
             PaymentIsAllowed() ? (
              <div>
                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h2>Informações de cobrança:</h2>
                  <div>
                    <Button
                      onClick={openGerarLinkPagamento}
                      component="label"
                      variant="contained"
                      color="default"
                      className="of-file">
                      Gerar link pagamento
                    </Button>
                    {urlGeradaPagamento && (
                      <ConfirmModalGeneric
                        title="Link Gerado"
                        description={
                          <>
                            <textarea rows={5} style={{ width: '100%', padding: 10 }} disabled>
                              {urlGeradaPagamento}
                            </textarea>
                          </>
                        }
                        open={openConfirmGerarLink}
                        customZIndex={9999}
                        textConfirmButton="Copiar Link"
                        textCancelButton="Fechar"
                        handleConfirm={handleConfirmGerarLink}
                        handleCloseConfirmation={handleCloseGerarLink}
                      />
                    )}

                    <Button
                      onClick={() => ExecutePayment()}
                      component="label"
                      variant="contained"
                      color="default"
                      className="of-file">
                      Realizar pagamento
                    </Button>
                  </div>
                </span>
                <div className="form">
                  <FormGroup>
                    <TextField
                      medium
                      valueText={'A vista'}
                      options={offer}
                      value={'A Vista'}
                      varibleName="paymentType"
                      onChange={() => {}}
                      label="Forma de Pagamento"
                      disabled
                    />

                    <MaskedTextField
                      mask="9999 9999 9999 9999"
                      value={values.creditCardNumber || ''}
                      onChange={handleChange}
                      varibleName="creditCardNumber"
                      label="Numero do Cartão"
                      maxChars={16}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      value={values.expirationMonth || ''}
                      onChange={handleChange}
                      varibleName="expirationMonth"
                      label="Mês Validade"
                      maxChars={2}
                      minChars={2}
                    />
                    <TextField
                      value={values.expirationYear || ''}
                      onChange={handleChange}
                      varibleName="expirationYear"
                      label="Ano Validade"
                      maxChars={4}
                      minChars={4}
                    />
                    <TextField
                      value={values.securitycode || ''}
                      onChange={handleChange}
                      varibleName="securitycode"
                      label="Código de segurança"
                      maxChars={3}
                    />
                  </FormGroup>
                </div>
              </div>
            ) : (
              
                <>
                {IsCorujaFlow() && values.assinatura_recorrente[0]?.status == 'paid' && (
                  <span style={{ display: 'flex', textAlign: 'left' }}>
                  <h3 style={{ width: '100%'}}><Payment /> Pagamento realizado!</h3>
                  <br/><br/>
                  </span>
                )}
                </>
              
            )}
            {values.status && 
             values.status.id >= 4 ? (
              <div>
                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h2>Responsável Financeiro:</h2>
                  <Button
                    onClick={() => preencherDadosFinancial()}
                    component="label"
                    variant="contained"
                    color="default"
                    className="of-file">
                    Preencher com dados do cliente
                  </Button>
                </span>
                <div className="form">
                  <FormGroup>
                    <TextField
                      value={values.certificate_number || ''}
                      onChange={handleChange}
                      varibleName="certificate_number"
                      label="Matrícula:"
                      disabled
                    />
                    <TextField
                      value={values.registrationDate || ''}
                      type={'date'}
                      onChange={handleChange}
                      varibleName="registrationDate"
                      label="Data cadastro:"
                      disabled
                    />
                    <TextField
                      value={values.financialName || ''}
                      onChange={handleChange}
                      varibleName="financialName"
                      label="Nome Completo:"
                      required={!!values.print_proposal}
                    />
                  </FormGroup>
                  <FormGroup>
                    <MaskedTextField
                      mask={'999.999.999-99'}
                      value={values.financialCPF || ''}
                      onChange={handleChange}
                      varibleName="financialCPF"
                      label="CPF:"
                      onBlur={() => isCpfValid(values.financialCPF)}
                      required={!!values.print_proposal}
                    />
                    <TextField
                      value={values.financialRg || ''}
                      onChange={handleChange}
                      varibleName="financialRg"
                      label="RG:"
                      maxChars={10}
                      required={!!values.print_proposal}
                    />
                    <MaskedTextField
                      mask={
                        values.financialCellphone && values.financialCellphone?.replace(/[^\d]/g, '').length < 11
                          ? '(99)9999-99999'
                          : '(99)99999-9999'
                      }
                      value={values.financialCellphone || ''}
                      onChange={handleChange}
                      varibleName="financialCellphone"
                      label="Celular:"
                      required={!!values.print_proposal}
                    />
                    <MaskedTextField
                      mask={
                        values.financialPhone && values.financialPhone?.replace(/[^\d]/g, '').length < 11
                          ? '(99)9999-99999'
                          : '(99)99999-9999'
                      }
                      value={values.financialPhone || ''}
                      onChange={handleChange}
                      varibleName="financialPhone"
                      label="Telefone:"
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      value={values.financialEmail || ''}
                      onChange={handleChange}
                      varibleName="financialEmail"
                      label="E-mail:"
                      required={!!values.print_proposal}
                    />
                    <MaskedTextField
                      mask={'99999-999'}
                      value={values.financialCEP || ''}
                      onChange={handleChange}
                      onBlur={() => checkFinancialCEP(values, setValues)}
                      varibleName="financialCEP"
                      label="CEP:"
                      required={!!values.print_proposal}
                    />
                    <TextField
                      value={values.financialAddress || ''}
                      onChange={handleChange}
                      varibleName="financialAddress"
                      label="Endereço:"
                      required={!!values.print_proposal}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      value={values.financialAddressNumber || ''}
                      onChange={handleChange}
                      varibleName="financialAddressNumber"
                      label="Numero:"
                      required={!!values.print_proposal}
                    />
                    <TextField
                      value={values.financialAddressComplement || ''}
                      onChange={handleChange}
                      varibleName="financialAddressComplement"
                      label="Complemento:"
                    />
                    <TextField
                      value={values.financialNeighborhood || ''}
                      onChange={handleChange}
                      varibleName="financialNeighborhood"
                      label="Bairro:"
                      required={!!values.print_proposal}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      value={values.financialCity || ''}
                      onChange={handleChange}
                      varibleName="financialCity"
                      label="Cidade:"
                      required={!!values.print_proposal}
                    />
                    <TextField
                      value={values.financialUF || ''}
                      onChange={handleChange}
                      varibleName="financialUF"
                      label="Estado:"
                      required={!!values.print_proposal}
                    />
                  </FormGroup>
                </div>
              </div>
            ) : (
              <></>
            )}
            {lead?.insurance_id != '25' && (
              <div>
                <h2>Informações do Indicador</h2>
                <div className="form">
                  <FormGroup dark>
                    <AutocompleteField
                      medium
                      className="control g"
                      valueText={values.inviter ? values.inviter.name : ''}
                      options={usersData ? usersData?.filter((u) => u.roles[0]?.id !== 6) : [values.inviter]}
                      value={values.inviter || null}
                      varibleName="inviter"
                      onChange={handleChangeAutoComplete}
                      label="Indicador"
                      disabled={type === 'createUser' || (values.state && user?.role >= 2)}
                      required
                    />
                    <MaskedTextField
                      mask={
                        values.inviter_Phone && values.inviter_Phone?.replace(/[^\d]/g, '').length < 11
                          ? '(99)9999-99999'
                          : '(99)99999-9999'
                      }
                      value={values.inviter_Phone || ''}
                      onChange={handleChange}
                      varibleName="inviter_Phone"
                      label="Telefone"
                      disabled={values.state && user?.role >= 2}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    {((values.inviter?.technical_team &&
                      values.inviter?.technical_team?.channels.find((ch) => ch.id === 1) !== null) ||
                      values.inviter?.channel.id === 1) && (
                      <>
                        <Box
                          paddingX="5px"
                          color="#00000087"
                          display="flex"
                          my="auto"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          height="100%"
                          bgcolor="background.paper">
                          {!values.proposal && (
                            <p
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: '0.8rem',
                                fontWeight: '400',
                                lineHeight: '1',
                                letterSpacing: '0.00938em',
                              }}>
                              Proposta
                            </p>
                          )}
                          <IconButton
                            aria-label="upload picture"
                            component="span"
                            onClick={() => setOpenGetProposal(true)}>
                            <SearchIcon />
                          </IconButton>
                        </Box>
                        {values.proposal && (
                          <TextFieldAdorned
                            className="control sm"
                            value={values.proposal.toString().padStart(9,"0") || ''}
                            varibleName="proposal"
                            label="Proposta"
                            error={createError}
                            disabled
                            endAdornment={
                              <InputAdornment position="start">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    setValues({ ...values, proposal: '', partner: '', insurance: '' });
                                    setFillable(null);
                                  }}>
                                  {' '}
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                      </>
                    )}
                    {values.proposal && (
                      <>
                        <TextFieldAdorned
                          className="control g"
                          value={values.partner?.name || ''}
                          varibleName="partner"
                          label="Empresa na qual trabalho"
                          disabled
                          endAdornment={
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="clear"
                                onClick={() => {
                                  setValues({ ...values, proposal: '', partner: '', insurance: '' });
                                  setFillable(null);
                                }}>
                                {' '}
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <TextFieldAdorned
                          className="control g"
                          value={values.insurance?.name || ''}
                          varibleName="insurance"
                          label="Setor"
                          disabled
                          endAdornment={
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="clear"
                                onClick={() => {
                                  setValues({ ...values, proposal: '', partner: '', insurance: '' });
                                  setFillable(null);
                                }}>
                                {' '}
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </>
                    )}
                  </FormGroup>
                </div>
              </div>
            )}

            <div>
              <h2>Informações do Indicado</h2>
              {((values.partner && values.insurance) || type == 'edit') && (
                <div className="form">
                  <FormGroup dark>
                    <MaskedTextField
                      mask={
                        values?.client_Cpf && values?.client_Cpf?.replace(/[^\d]/g, '').length < 12
                          ? '999.999.999-999'
                          : '99.999.999/9999-99'
                      }
                      value={values.client_Cpf || ''}
                      onChange={handleChange}
                      onBlur={async (e) => await checkCPF(e, values, setValues)}
                      varibleName="client_Cpf"
                      label="CPF/CNPJ"
                      className="control s"
                      required={IsCorujaFlow() && IsLeadSold()}
                    />
                    <MaskedTextField
                      mask="999999999"
                      value={values.client_Rg || ''}
                      onChange={handleChange}
                      varibleName="client_Rg"
                      label="RG"
                      className="control s"
                    />
                    <TextField
                      value={values.client_name || ''}
                      onChange={handleChange}
                      varibleName="client_name"
                      label="Nome do cliente"
                      className="control gg"
                      required
                    />
                  </FormGroup>

                  <FormGroup dark>
                    <MaskedTextField
                      value={values.client_cellphone || ''}
                      onChange={handleChange}
                      varibleName="client_cellphone"
                      label="Telefone celular"
                      required={IsCorujaFlow() && IsLeadSold() && values.client_phone == ''}
                      mask={
                        values.client_cellphone && values.client_cellphone?.replace(/[^\d]/g, '').length < 11
                          ? '(99)9999-99999'
                          : '(99)99999-9999'
                      }
                    />
                    <MaskedTextField
                      value={values.client_phone || ''}
                      onChange={handleChange}
                      varibleName="client_phone"
                      label="Telefone"
                      required={IsCorujaFlow() && IsLeadSold() && values.client_cellphone == ''}
                      mask={
                        values.client_phone && values.client_phone?.replace(/[^\d]/g, '').length < 11
                          ? '(99)9999-99999'
                          : '(99)99999-9999'
                      }
                    />
                    <TextField
                      value={values.birth_date || ''}
                      type={'date'}
                      onChange={handleChange}
                      varibleName="birth_date"
                      label="Data de nascimento:"
                      onBlur={() => { 
                        checkAge18(values.birth_date, true);
                        checkAge61(values.birth_date, checkIsLifeInsurance());
                      }}
                      required={IsCorujaFlow() && IsLeadSold()}
                    />                    
                  </FormGroup>

                  <FormGroup dark>
                    <MaskedTextField
                      value={values.client_CEP || ''}
                      onChange={handleChange}
                      onBlur={() => checkCEP(values, setValues)}
                      varibleName="client_CEP"
                      label="CEP"
                      className="control s"
                      mask="99999-999"
                      required={IsCorujaFlow() && IsLeadSold()}
                    />
                    <TextField
                      value={values.client_address_street || ''}
                      onChange={handleChange}
                      varibleName="client_address_street"
                      label="Endereço"
                      className="control xl"
                      required={IsCorujaFlow() && IsLeadSold()}
                    />
                    <TextField
                      value={values.client_address_number || ''}
                      onChange={handleChange}
                      varibleName="client_address_number"
                      label="Número"
                      type="number"
                      className="control s"
                    />
                    <TextField
                      value={values.client_address_comp || ''}
                      onChange={handleChange}
                      varibleName="client_address_comp"
                      label="Complemento"
                      className="control s"
                    />
                  </FormGroup>
                  <FormGroup dark>
                  {IsCorujaFlow()?
                    <AutocompleteField
                              medium
                              valueText={values.client_address_state ? values.client_address_state : ''}
                              options={UF}
                              value={values.client_address_state || null}
                              varibleName="client_address_state"
                              onChange={handleChangeAutoComplete}
                              label='Estado'
                              required
                            />:
                            <TextField
                      value={values.client_address_state || ''}
                      onChange={handleChange}
                      varibleName="client_address_state"
                      label="Estado"
                      className="control ms"
                      required={IsCorujaFlow() && IsLeadSold()}
                    />}
                    <TextField
                      value={values.client_address_district || ''}
                      onChange={handleChange}
                      varibleName="client_address_district"
                      label="Bairro"
                      required={IsCorujaFlow() && IsLeadSold()}
                    />
                    <TextField
                      value={values.client_address_city || ''}
                      onChange={handleChange}
                      varibleName="client_address_city"
                      label="Cidade"
                      required={IsCorujaFlow() && IsLeadSold()}
                    />
                  </FormGroup>

                  <InsuranceComponents
                    selectedInsuranceId={selectedInsuranceId}
                    handleChange={handleChange}
                    handleChangeAutoComplete={handleChangeAutoComplete}
                    values={values}
                    setValues={setValues}
                  />

                  <FormGroup dark>
                    <TextField
                      value={values.email || ''}
                      onChange={handleChange}
                      varibleName="email"
                      label="E-mail"
                      className="control gg"
                      required
                    />

                    <AutocompleteField
                      medium
                      className="control m"
                      valueText={values.insurance_expiration ? values.insurance_expiration.name : ''}
                      options={months}
                      value={values.insurance_expiration || null}
                      varibleName="insurance_expiration"
                      onChange={handleChangeAutoComplete}
                      label="Já possui seguro, qual mês de vencimento"
                    />
                  </FormGroup>

                  <FormGroup dark>
                    <TextField
                      value={values.facebook || ''}
                      onChange={handleChange}
                      varibleName="facebook"
                      label="Facebook"
                      className="control gg"
                    />
                    <TextField
                      value={values.instagram || ''}
                      onChange={handleChange}
                      varibleName="instagram"
                      label="Instagram"
                      className="control gg"
                    />
                    <AutocompleteField
                      medium
                      className="control m"
                      valueText={values.estado_civil ? values.estado_civil.name : ''}
                      options={maritalStatus}
                      value={values.estado_civil || null}
                      varibleName="estado_civil"
                      onChange={handleChangeAutoComplete}
                      label="Estado Civil"
                      required={checkIsRequiredByContactType()}
                    />
                  </FormGroup>
                  <FormGroup dark>
                    <AutocompleteField
                      medium
                      className="control m"
                      valueText={values.servidor_publico ? values.servidor_publico.name : ''}
                      options={SN}
                      value={values.servidor_publico || null}
                      varibleName="servidor_publico"
                      onChange={handleChangeAutoComplete}
                      label="Servidor Público"
                      required={checkIsRequiredByContactType()}
                    />
                    <AutocompleteField
                      medium
                      className="control m"
                      valueText={values.gender ? values.gender.name : ''}
                      options={sexo}
                      value={values.gender || null}
                      varibleName="gender"
                      onChange={handleChangeAutoComplete}
                      label="Sexo"
                      required={checkIsRequiredByContactType()}
                    />
                    <AutocompleteField
                      medium
                      className="control m"
                      valueText={values.nacionalidade ? values.nacionalidade.name : ''}
                      options={Nacionalidade}
                      value={values.nacionalidade || null}
                      varibleName="nacionalidade"
                      onChange={handleChangeAutoComplete}
                      label="Nacionalidade"
                      required={checkIsRequiredByContactType()}
                    />
                  </FormGroup>

                  {IsCorujaFlow() && (
                    <FormGroup dark>
                      <TextField
                        value={values.nome_de_recado || ''}
                        onChange={handleChange}
                        varibleName="nome_de_recado"
                        label="Nome de recado"
                        className="control gg"
                      />
                      <MaskedTextField
                        value={values.telefone_de_recado || ''}
                        onChange={handleChange}
                        varibleName="telefone_de_recado"
                        label="Telefone de recado"
                        mask={
                          values.telefone_de_recado && values.telefone_de_recado?.replace(/[^\d]/g, '').length < 11
                            ? '(99)9999-99999'
                            : '(99)99999-9999'
                        }
                      />
                      <MaskedTextField
                        value={values.celular_de_recado || ''}
                        onChange={handleChange}
                        varibleName="celular_de_recado"
                        label="Celular de recado"
                        mask={'(99)99999-9999'}
                      />
                    </FormGroup>
                  )}

                  <FormGroup dark>
                    <TextField
                      value={values.profissao || ''}
                      onChange={handleChange}
                      varibleName="profissao"
                      label="Profissão"
                      className="control gg"
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      type="datetime"
                      value={(values.proposal_date && values.proposal_date?.replace('T', ' ')) || ''}
                      onChange={handleChange}
                      varibleName="proposal_date"
                      label="Data da Proposta"
                      disabled
                      required
                    />
                    <TextField
                      value={values.product || ''}
                      onChange={handleChange}
                      varibleName="product"
                      label={car ? 'Modelo do Veículo' : 'Produto segurado'}
                    />
                    {car && (
                      <TextField
                        value={values.product_brand || ''}
                        onChange={handleChange}
                        varibleName="product_brand"
                        label="Marca"
                        required={car}
                      />
                    )}
                  </FormGroup>

                  {car && (
                    <FormGroup>
                      <TextField
                        value={values.product_chassis || ''}
                        onChange={handleChange}
                        varibleName="product_chassis"
                        label="Chassi do veículo"
                        required={!values.product_chassis && !values.product_license_plate}
                      />
                      <TextField
                        value={values.product_license_plate || ''}
                        onChange={handleChange}
                        varibleName="product_license_plate"
                        label="Placa do veículo"
                        required={!values.product_chassis}
                      />
                      <TextField
                        value={values.product_year || ''}
                        onChange={handleChange}
                        varibleName="product_year"
                        label="Ano de modelo do veículo"
                        required={car}
                      />
                    </FormGroup>
                  )}

                  <FormGroup>
                    <TextField
                      value={values.product_details || ''}
                      onChange={handleChange}
                      varibleName="product_details"
                      label={car ? 'Outras informações do veículo segurado' : 'Outras informações do produto segurado'}
                      multiline
                      rows={5}
                    />
                  </FormGroup>

                  <FormGroup>
                    <TextField
                      value={values.additional_information || ''}
                      onChange={handleChange}
                      varibleName="additional_information"
                      label="Observações"
                      multiline
                      rows={5}
                    />
                  </FormGroup>
                  
                  {checkIsLifeInsurance() ? CarregarBeneficiarios() : <></>}

                  <div className="buttonContainer">
                    {leadCurrent?.file_path && (
                      <Button
                        onClick={downloadFile}
                        component="label"
                        variant="contained"
                        color="default"
                        className="of-file"
                        startIcon={<GetApp />}>
                        Download Arquivo
                      </Button>
                    )}

                    <Button
                      onChange={async (event) => await fileUpload(event, setFile, leadCurrent, '')}
                      component="label"
                      variant="contained"
                      color="default"
                      className="of-file"
                      startIcon={<CloudUploadIcon />}>
                      Enviar Arquivo
                      <input type="file" accept="image/*, application/pdf" hidden />
                    </Button>
                  </div>

                  <div className="buttonContainer">
                    <div style={{ display: 'grid'}}>
                    <Button
                      onChange={async (event) => {
                        setLoading(true);
                        // await handleSave();
                        const uploadProposta = await fileUpload(
                          event,
                          setFile,
                          leadCurrent,
                          'proposta',
                          'Proposta anexada com sucesso!'
                        );
                        setValues({
                          ...values,
                          uploadProposta: uploadProposta,
                        });
                        setLoading(false);
                      }}
                      component="label"
                      variant="contained"
                      color="default"
                      className="of-file"
                      startIcon={<CloudUploadIcon />}>
                      Anexar Proposta
                      <input type="file" accept="application/pdf" hidden />
                    </Button>
                    <Button 
                      onClick={() => { onClickVisualizarAnexo(lead, 'proposta') }}
                      style={{marginTop: '2px'}}
                      className="of-file"
                      variant="contained"
                      startIcon={<CloudDownload />} >
                      Visualizar
                    </Button>
                    </div>

                    <div style={{ display: 'grid'}}>
                    <Button
                      onChange={async (event) => {
                        setLoading(true);
                        // await handleSave();
                        setValues({
                          ...values,
                          uploadCertificado: await fileUpload(
                            event,
                            setFile,
                            leadCurrent,
                            'apolice',
                            'Apolice anexada com sucesso!'
                          ),
                        });
                        setLoading(false);
                      }}
                      component="label"
                      variant="contained"
                      color="default"
                      className="of-file"
                      startIcon={<CloudUploadIcon />}
                      disabled={GetAnexarApoliceDisabled()}>
                      Anexar Apólice/Certificado
                      <input type="file" accept="application/pdf" hidden />
                    </Button>
                    <Button 
                      onClick={() => { onClickVisualizarAnexo(lead, 'apolice') }}
                      style={{marginTop: '2px'}}
                      className="of-file"
                      variant="contained"
                      startIcon={<CloudDownload />} >
                      Visualizar
                    </Button>
                    </div>


                  </div>
                </div>
              )}
            </div>
            <ButtonDiv />
            <button ref={form} type="submit" hidden>
              hidden save
            </button>
          </form>
        </Container>
      )}
    </>
  );
}

LeadForm.propTypes = {
  type: P.oneOf(['edit', 'createUser', 'createAdmin']).isRequired,
  setOpen: P.func,
  leadCurrent: P.shape({
    id: P.number,
    proposal: P.number,
    foreign_proposal: P.number,
    product: P.string,
    product_details: P.oneOfType([P.arrayOf(P.any), P.objectOf(P.any)]),
    channel_id: P.number,
    client_id: P.number,
    broker_id: P.number,
    inviter_id: P.number,
    status: P.number,
    state: P.number,
    insurance_id: P.number,
    insurance_expiration: P.number,
    partner: P.objectOf(P.any),
    broker: P.objectOf(P.any),
    channel: P.objectOf(P.any),
    client: P.objectOf(P.any),
    client_adress: P.objectOf(P.any),
    inviter: P.objectOf(P.any),
    inviter_Phone: P.string,
    partner_id: P.number,
    additional_information: P.string,
    proposal_date: P.string,
    contact_type: P.number,
    sold: P.number,
    lost_sale: P.number,
    quoted: P.number,
    pague_menos: P.number,
    seguro_conteudo: P.number,
    backup_car: P.number,
    competitor_broker: P.number,
    attendance_on_time: P.number,
    indication_on_time: P.number,
    create_lead_date: P.string,
    end_lead_date: P.string,
    first_call_date: P.string,
    lead_scheduling: P.objectOf(P.any),
  }),
};
LeadForm.defaultProps = {
  leadCurrent: null,
  setOpen: () => {},
};
