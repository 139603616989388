/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const FunnilRow = styled.div`
  /* background-color: ${(props) => props.bg}; */
  width: ${(props) => (props.width ? (typeof props.width === 'string' ? props.width : `${props.width}px`) : '100px')};
  height: ${(props) =>
    props.height ? (typeof props.height === 'string' ? props.height : `${props.height}px`) : '100px'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FunnilRowData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const FunnilRowPercent = styled.div`
  background-color: ${(props) => props.bg || 'lightblue'};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${(props) => (props.percent !== undefined ? `${props.percent}%` : '100%')};
  padding-top: ${(props) => (props.size === 'small' ? '0' : '15px')};
  font-size: ${(props) => (props.size == 'small' ? '10px' : '15px')};

  & + & {
    border-left: gray solid 2px;
  }

  
`;

export const FunnilConnectRows = styled.div`
  width: ${(props) =>
    props.topWidth ? (typeof props.topWidth === 'string' ? props.topWidth : `${props.topWidth}px`) : '50px'};
  height: 0;
  border-top: ${(props) =>
      props.height ? (typeof props.height === 'string' ? props.height : `${props.height}px`) : '50px'}
    solid ${(props) => props.bg || 'white'};
  border-inline: calc(
      (
          ${(props) =>
              props.topWidth ? (typeof props.topWidth === 'string' ? props.topWidth : `${props.topWidth}px`) : '50px'} -
            ${(props) =>
              props.bottomWidth
                ? typeof props.bottomWidth === 'string'
                  ? props.bottomWidth
                  : `${props.bottomWidth}px`
                : '50px'}
        ) / 2
    )
    solid transparent;
`;
