import { useEffect } from 'react';
import RedefineForm from '../../components/RedefineForm';
import { Container } from './style';

const Redefine = () => (
    <Container>
      <RedefineForm />
    </Container>
  );

export default Redefine;
