/* eslint-disable no-console */
import { toast } from 'react-toastify';
import api from './api';

export const excelExport = (url, documentName) => {
  api
    .get(url, {
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      const redirect = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = redirect;
      link.setAttribute('download', `${documentName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Fazendo Download do arquivo...');
    })
    .catch((error) => {
      toast.error('Erro ao Fazer Download do Arquivo');
    });
};
export const Pdf = async (url, documentName, thenCallBack) => {
  api
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      const redirect = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = redirect;
      link.setAttribute('download', `${documentName}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(`Fazendo Download do ${documentName}...`);
      thenCallBack();
    })
    .catch((error) => {
      toast.error(`Erro ao Fazer Download do ${documentName}`);
      console.log(error)
    });



};
