/* eslint-disable */
import { useContext, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import P, { shape } from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import { MainContext } from '../../context/ApiContext';

const SummaryReport = ({ dataItems }) => {
  const {
    deleters: { deleteUser },
  } = useContext(MainContext);

  const formatDateHour = (item) => {
    if (item >= 24) {
      const days = Math.floor(item / 24);
      const remainder = item % 24;
      const hours = Math.floor(remainder);
      const minutes = Math.floor(60 * (remainder - hours));

      const daysString = days > 1 ? days + ' dias' : days + ' dia';
      const hoursString = hours > 1 ? hours + ' horas' : hours > 0 && hours + ' hora';
      const minutesString = minutes > 1 ? minutes + ' minutos' : minutes > 0 && minutes + ' minuto';
      return `${daysString || ''} ${hoursString || ''} ${minutesString || ''}`;
    } else {
      return item + ' horas';
    }
  };

  const getMTRenov = (item) => {
    return dataItems.items['resultGoals'][item].length > 0 
      ? dataItems.items['resultGoals'][item][0]['value']
      : 0;
  }


  return (
    <div>
      <div style={{ display: 'flex' }}>
        {/* <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>{'Dados'}</div> */}
        <TableContainer component={Paper} style={{ marginRight: 80 }}>
          <Table aria-label="simple table">
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell style={{ fontWeight: 'bold' }}>Metas</TableCell>
            </TableRow>

            <TableBody>
              <TableRow>
                <TableCell>Quantos leads venceram neste período</TableCell>
                <TableCell colspan={3}>{dataItems.items['count_leads']}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Puxou no prazo (7 dias antes vencimento)</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(dataItems.items['leads_capture_on_time']) <
                        Number(getMTRenov('mt_renov_captured_leads_on_time_rate')) &&
                      'red',
                  }}>
                  {((dataItems.items['leads_capture_on_time'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
                <TableCell>{dataItems.items['leads_capture_on_time']}</TableCell>

                <TableCell>
                  {getMTRenov('mt_renov_captured_leads_on_time_rate')}%
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cotações</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(dataItems.items['quoted']) <
                        Number(getMTRenov('mt_renov_captured_quoted_leads_rate')) &&
                      'red',
                  }}>
                  {((dataItems.items['quoted'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
                <TableCell>{dataItems.items['quoted']}</TableCell>
                <TableCell>
                  {getMTRenov('mt_renov_captured_quoted_leads_rate')} %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowspan={4}>Mandou cotação por onde</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Whatsapp</TableCell>

                <TableCell colspan={2}>Telefone/Celular</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {((dataItems.items['quoted_whatsapp'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>

                <TableCell colspan={2}>
                  {((dataItems.items['quoted_tel_cel'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{dataItems.items['quoted_whatsapp']}</TableCell>
                <TableCell colspan={2}>{dataItems.items['quoted_tel_cel']}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cotou quanto tempo depois de puxar o lead</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(dataItems.items['average_of_sell_quotation_time']) >
                        Number(
                          getMTRenov('mt_renov_quotation_hours_after_lead_capture')
                        ) && 'red',
                  }}>
                  {dataItems.items['average_of_sell_quotation_time']}{' '}
                  {Number(dataItems.items['average_of_sell_quotation_time']) === 1 ? 'hora' : 'horas'}
                </TableCell>
                <TableCell>
                  {getMTRenov('mt_renov_quotation_hours_after_lead_capture')}{' '}
                  {Number(getMTRenov('mt_renov_quotation_hours_after_lead_capture')) ===
                  1
                    ? 'hora'
                    : 'horas'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowspan={4}>Conectou por onde</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Whatsapp</TableCell>

                <TableCell colspan={2}>Telefone/Celular</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {((dataItems.items['contact_whatsapp'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>

                <TableCell colspan={2}>
                  {((dataItems.items['contact_tel_cel'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{dataItems.items['contact_whatsapp']}</TableCell>
                <TableCell>{dataItems.items['contact_tel_cel']}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>Entrou em contato quanto tempo depois da cotação</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(dataItems.items['average_of_contact_after_quotation']) >
                        Number(
                          getMTRenov('mt_renov_contact_after_quotation_in_hours')
                        ) && 'red',
                  }}>
                  {dataItems.items['average_of_contact_after_quotation']}{' '}
                  {Number(dataItems.items['average_of_contact_after_quotation']) === 1 ? 'hora' : 'horas'}
                </TableCell>
                <TableCell>
                  {getMTRenov('mt_renov_contact_after_quotation_in_hours')}{' '}
                  {Number(getMTRenov('mt_renov_contact_after_quotation_in_hours')) === 1
                    ? 'hora'
                    : 'horas'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Conexões</TableCell>
                <TableCell>
                  {((dataItems.items['all_conections'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>

                <TableCell
                  style={{
                    color:
                      Number(dataItems.items['all_conections']) <
                        Number(getMTRenov('mt_renov_conections_rate')) && 'red',
                  }}>
                  {dataItems.items['all_conections']}
                </TableCell>
                <TableCell>{getMTRenov('mt_renov_conections_rate')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowspan={4}>Renovou por onde</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Whatsapp</TableCell>

                <TableCell>Telefone/Celular</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  {((dataItems.items['renewed_whatsapp'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>

                <TableCell>
                  {((dataItems.items['renewed_tel_cel'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>{dataItems.items['renewed_whatsapp']}</TableCell>
                <TableCell>{dataItems.items['renewed_tel_cel']}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>Renovou quanto tempo depois da conexão</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(dataItems.items['average_time_of_renew_after_conection']) >
                        Number(
                          getMTRenov('mt_renov_renewed_after_conection_in_days')
                        ) && 'red',
                  }}>
                  {formatDateHour(Number(dataItems.items['average_time_of_renew_after_conection']))}
                </TableCell>
                <TableCell>
                  {getMTRenov('mt_renov_renewed_after_conection_in_days')}{' '}
                  {Number(getMTRenov('mt_renov_renewed_after_conection_in_days')) === 1
                    ? 'hora'
                    : 'horas'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>% de seguros Renovados X Vencidos no período</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(
                        (
                          ((dataItems.items['renewed_whatsapp'] + dataItems.items['renewed_tel_cel']) /
                            dataItems.items['count_leads']) *
                          100
                        ).toFixed(2)
                      ) <
                        Number(
                          getMTRenov('mt_renov_renewed_insurance_and_expired_rate')
                        ) && 'red',
                  }}>
                  {(
                    ((dataItems.items['renewed_whatsapp'] + dataItems.items['renewed_tel_cel']) /
                      dataItems.items['count_leads']) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell>
                  {getMTRenov('mt_renov_renewed_insurance_and_expired_rate')} %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tempo total médio da venda</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(dataItems.items['average_of_sales_time']) >
                        Number(getMTRenov('mt_renov_average_sale_time_in_days')) &&
                      'red',
                  }}>
                  {dataItems.items['average_of_sales_time']} dias
                </TableCell>
                <TableCell>
                  {getMTRenov('mt_renov_average_sale_time_in_days')} dias
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Venda de backup car % e Número</TableCell>
                <TableCell>
                  {((dataItems.items['backup_car_sales'] / dataItems.items['sales_quantity']) * 100).toFixed(2)}%
                </TableCell>

                <TableCell
                  style={{
                    color:
                      Number(dataItems.items['backup_car_sales']) <
                        Number(getMTRenov('mt_renov_backup_car_sales')) && 'red',
                  }}>
                  {dataItems.items['backup_car_sales']}
                </TableCell>

                <TableCell>{getMTRenov('mt_renov_backup_car_sales')}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  Motivos principais das perdas (3)
                </TableCell>
                <TableCell />
              </TableRow>

              {dataItems.items['lost_sale_causes'].length > 0 ? (
                <>
                  {dataItems.items['lost_sale_causes'][0] && (
                    <TableRow>
                      <TableCell>{`1) ${dataItems.items['lost_sale_causes'][0].value}`}</TableCell>
                      <TableCell colSpan={3}>
                        {(
                          (dataItems.items['lost_sale_causes'][0].quantity / dataItems.items['sales_quantity']) *
                          100
                        ).toFixed(2)}
                        %
                      </TableCell>
                    </TableRow>
                  )}

                  {dataItems.items['lost_sale_causes'][1] && (
                    <TableRow>
                      <TableCell>{`2) ${dataItems.items['lost_sale_causes'][1].value}`}</TableCell>
                      <TableCell colSpan={3}>
                        {(
                          (dataItems.items['lost_sale_causes'][1].quantity / dataItems.items['sales_quantity']) *
                          100
                        ).toFixed(2)}
                        %
                      </TableCell>
                    </TableRow>
                  )}

                  {dataItems.items['lost_sale_causes'][2] && (
                    <TableRow>
                      <TableCell>{`3) ${dataItems.items['lost_sale_causes'][2].value}`}</TableCell>
                      <TableCell colSpan={3}>
                        {(
                          (dataItems.items['lost_sale_causes'][2].quantity / dataItems.items['sales_quantity']) *
                          100
                        ).toFixed(2)}
                        %
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell>{`Não existem motivos de perda`}</TableCell>
                  <TableCell colSpan={3}>N/A</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell style={{ fontWeight: 'bold' }}>Metas</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Venda de seguros novos/externos no período</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(dataItems.newTableItems['new_insurances_sells']) <
                        Number(
                          getMTRenov('mt_renov_new_and_extern_insurances_sales')
                        ) && 'red',
                  }}>
                  {dataItems.newTableItems['new_insurances_sells']} %
                </TableCell>
                <TableCell>
                  {getMTRenov('mt_renov_new_and_extern_insurances_sales')} %
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Comissão média no período</TableCell>
                <TableCell
                  colSpan={2}
                  style={{
                    color:
                      Number(dataItems.newTableItems['average_comissions']) <
                        Number(getMTRenov('mt_renov_average_commission_rate')) && 'red',
                  }}>
                  {dataItems.newTableItems['average_comissions']} %
                </TableCell>
                <TableCell>
                  {getMTRenov('mt_renov_average_commission_rate')} %
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  De todas as finalizações, as que não venderam gastaram o tempo médio a seguir da conexão para a
                  finalização
                </TableCell>
                <TableCell colSpan={3}>
                  {dataItems.newTableItems['average_of_conection_finalization_time']} dias
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  De todas as finalizações, as que não venderam gastaram o tempo médio a seguir da cotação para a
                  finalização
                </TableCell>
                <TableCell colSpan={3}>{dataItems.newTableItems['average_of_quoted_finalization_time']} dias</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowspan={3}>
                  De todas as finalizações, as que venderam gastaram o tempo médio a seguir da conexão para o fechamento
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  {dataItems.newTableItems['resultDiffHoursConection']['hours']}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>{dataItems.newTableItems['resultDiffHoursConection']['countItems']}</TableCell>
                <TableCell colSpan={2}>
                  {(
                    (dataItems.newTableItems['resultDiffHoursConection']['countItems'] /
                      (dataItems.items['renewed_tel_cel'] + dataItems.items['renewed_whatsapp'])) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowspan={3}>
                  De todas as finalizações, as que venderam gastaram o tempo médio a seguir do envio da cotação para o
                  fechamento
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  {dataItems.newTableItems['resultDiffHoursQuoted']['hours']}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>{dataItems.newTableItems['resultDiffHoursQuoted']['countItems']}</TableCell>
                <TableCell colSpan={2}>
                  {(
                    (dataItems.newTableItems['resultDiffHoursQuoted']['countItems'] /
                      (dataItems.items['renewed_tel_cel'] + dataItems.items['renewed_whatsapp'])) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Conversão destas cotações enviadas pelo Whatsapp em %</TableCell>
                <TableCell>
                  {((dataItems.newTableItems['quoted_conversion_wpp'] / dataItems.items['count_leads']) * 100).toFixed(
                    2
                  )}
                  %
                </TableCell>

                <TableCell colSpan={2}>{dataItems.newTableItems['quoted_conversion_wpp']}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Conversão destas cotações enviadas pelo Telefone/Celular em %</TableCell>
                <TableCell>
                  {(
                    (dataItems.newTableItems['quoted_conversion_cel_tel'] / dataItems.items['count_leads']) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>

                <TableCell colSpan={2}>{dataItems.newTableItems['quoted_conversion_cel_tel']}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>% conversão (Cnt) Cel/Tel / (Cot) Cel/Tel / (Ven) Cel/Tel</TableCell>
                <TableCell colSpan={3}>{dataItems.conversions['conversion_on_cel_tel']} %</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>% conversão (Cnt) Whatsapp / (Cot) Cel/Tel / (Ven) Cel/Tel</TableCell>
                <TableCell colSpan={3}>{dataItems.conversions['conversion_on_wpp_cel_tel']} %</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>% conversão (Cnt) Whatsapp / (Cot) Whatsapp / (Ven) Whatsapp</TableCell>
                <TableCell colSpan={3}>{dataItems.conversions['conversion_on_wpp']} %</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>% conversão (Cnt) Whatsapp / (Cot) Whatsapp / (Ven) Cel/Tel</TableCell>
                <TableCell colSpan={3}>{dataItems.conversions['conversion_on_wpp_wpp_cel_tel']} %</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>% conversão (Cnt) Whatsapp / (Cot) Cel/Tel / (Ven) Whatsapp</TableCell>
                <TableCell colSpan={3}>{dataItems.conversions['conversion_on_wpp_cel_tel_wpp']} %</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>% conversão (Cnt) Cel/Tel / (Cot) Whatsapp / (Ven) Whatsapp</TableCell>
                <TableCell colSpan={3}>{dataItems.conversions['conversion_on_cel_tel_wpp_wpp']} %</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>% conversão (Cnt) Cel/Tel / (Cot) Whatsapp / (Ven) Cel/Tel</TableCell>
                <TableCell colSpan={3}>{dataItems.conversions['conversion_on_cel_tel_wpp_cel_tel']} %</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>% conversão (Cnt) Cel/Tel / (Cot) Cel/Tel / (Ven) Whatsapp</TableCell>
                <TableCell colSpan={3}>{dataItems.conversions['conversion_on_cel_tel_wpp']} %</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

SummaryReport.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
    })
  ),
  title: P.string.isRequired,
};

SummaryReport.defaultProps = {
  listItem: null,
};

export default SummaryReport;
