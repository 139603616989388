/* eslint-disable */
import { useContext, useState } from 'react';
import { MainContext } from '../../../context/ApiContext';
import { Nav, NavbarForm } from './styles';
import { DateTime } from 'luxon';
import useApp from '../../../hooks/useApp';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import { fetcher } from '../../../service/SWRFetcher';
import useSWR from 'swr';
import IndicationManagerReportTableList from '../../IndicationManagerReportTableList';
import SimpleModal from '../../SimpleModal';
import SearchClientModal from '../../SearchClientModal';

// function handleSubmit (event, getters)  {
//   event.preventDefault()
//   let filters = {
//     date_ini : event.target.elements.date_ini?.value,
//     date_end : event.target.elements.date_end?.value,
//     partner_id : event.target.elements.partner_id?.value,
//     insurance_id : event.target.elements.insurance_id?.value,
//     paginate : null,
//   }
//   setFilters({...filters, ...filt})
//   // getters.getIndicationReportGerVeic(filters)
// }

const DatePeriodFilter = ({ userId, filters, setFilters }) => {
  const { partners, insurances } = useApp();
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [dates, setDates] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: inviters } = useSWR(`/users`, fetcher);

  function handleSubmit(event) {
    event.preventDefault();
    let filt = {
      date_ini: event.target.elements.date_ini?.value,
      date_end: event.target.elements.date_end?.value,
      partner_id: partner.map((p) => ({ id: p.id })) || null,
      insurance_id: insurance.map((i) => ({ id: i.id })) || null,
      inviter_id: inviter?.id,
      client_id: client?.id,
      paginate: null,
    };
    setFilters({ ...filters, ...filt });
  }

  const [partner, setPartner] = useState([]);

  const [insurance, setInsurance] = useState([]);

  const [inviter, setInviter] = useState([]);

  const [client, setClient] = useState([]);

  return (
    <Nav>
      <div className="filter">Filtros</div>

      <NavbarForm>
        <form className="form" onSubmit={handleSubmit}>
          <div className="inputs">
            <div className="date_ini">
              <input
                type="date"
                name="date_ini"
                className="input-form"
                placeholder="Data Inicial"
                value={dates.date_ini}
                onChange={(e) => setDates(e)}
              />
            </div>
            <div className="date_end">
              <input
                type="date"
                name="date_end"
                className="input-form"
                placeholder="Data Final"
                value={dates.date_end}
                onChange={(e) => setDates(e)}
              />
            </div>
          </div>
          {/* <div className="partner_id">
            <select type="select" name="partner_id" className="select-form" placeholder="Empresa" value={partner.partner_id} onChange={(e) =>setPartner(e)}>
            <option value="">Empresa</option>
              {partners.map((item) => (
               <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
          </div> */}

          <Autocomplete
            multiple
            options={partners ? partners : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setPartner(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Empresas" />
            )}
          />

          {/* <div className="insurance_id">
            <select type="select" name="insurance_id" className="select-form" placeholder="Seguro" value={insurance.partner_id} onChange={(e) =>setInsurance(e)}>
            <option value="">Tipo Estoque</option>
              {insurances.map((item) => (
               <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
          </div> */}

          <Autocomplete
            multiple
            options={insurances ? insurances : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setInsurance(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Tipos de Estoque" />
            )}
          />

          <Autocomplete
            options={inviters ? inviters : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setInviter(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Indicador" />
            )}
          />

          <div className="inputs">
            <div className="client_name">
              <div className="customInput">
                <input
                  maxlength="0"
                  value={client.name ? client.name : ''}
                  name="client_name"
                  className="input-form"
                  placeholder="Nome do Cliente"
                  onClick={() => setIsModalOpen(true)}
                />
                  <button type="button" onClick={() => setClient([])}>
                    <svg
                      className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
                      focusable="false"
                      viewBox="0 0 24 24"
                      ariaHidden="true">
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </button>
              </div>
            </div>
          </div>

          <SearchClientModal
            open={isModalOpen}
            title="Nome do Cliente"
            setOpen={() => {
              setIsModalOpen((oldState) => !oldState);
            }}
            fieldName="Pesquise pelo nome do cliente"
            next={(client) => {
              setClient(client);
              setIsModalOpen(false);
            }}
          />

          <div>
            <button className="button" type="submit">
              Filtrar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
