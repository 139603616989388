import { Route } from 'react-router-dom';
import { Container } from '@material-ui/core';
import SampleClient from "../service/Tenants/SampleClient";
import M3tas from '../service/Tenants/M3tas';
import Corretora3C from '../service/Tenants/Corretora3C';
import Coruja from '../service/Tenants/Coruja';
import Default from '../service/Tenants/Default';

export const TenantRoutes = () => 
(
    <Container>
        <Route path="/default" component={Default} />
        <Route path="/m3tas" component={M3tas} />
        <Route path="/sampleclient" component={SampleClient} />
        <Route path="/3ccorretora" component={Corretora3C} />
        <Route path="/coruja" component={Coruja} />
    </Container>
);

export function GetTenantRoute() 
{
    const tenantId = localStorage.getItem("tenantId");

    if (tenantId)
        return localStorage.getItem("tenantId");

    return "default";
}


