import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: gray;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: gray;
        }
    }
    html{
        font-size: 88%;
        font-family: 'Poppins', sans-serif;

        .MuiListItemIcon-root{
            color: rgb(250 156 18);
        }
        .MuiFormLabel-root.Mui-focused{
            color: rgb(250 156 18);
        }

        .Toastify__toast--default {
        }
        .Toastify__toast--info {
        }
        .Toastify__toast--success {
            background: #3ba559;
          /* color: #000; */
        }
        .Toastify__toast--warning {
            background: #ccc659;
            color: #000;
        }
        .Toastify__toast--error {
        }

        .loading {
            /* height: calc(100vh - 10px); */
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .__json-pretty__ {
            background: #fff;
        }
        .__json-pretty__ .__json-key__ {
            color: rgb(250 156 18);
        }
        .__json-pretty__ .__json-string__ {
            color: #333;
        }
        .__json-pretty__ .__json-value__ {
            color: #722;
        }

    }
    body{
        background-color: #f1f1f1;
    }

`;
