/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useRef, useState } from 'react';
import { 
    Button, 
    IconButton, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Dialog,
    TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import P from 'prop-types';
import { changeUserPassword } from '../../service/user.service'

export function PasswordModal({open, setOpen, editedUser})
{
    const password = useRef('');
    const passwordVerify = useRef('');

    const handleClose = () => 
    {
        setOpen(false);
    };

    const handleChangePassword = async () => {
        try 
        {
            if (password.current.value !== passwordVerify.current.value){
                toast.error('Confirmação de senha inválida!');
                return;
            }

            editedUser = {...editedUser, password: password.current.value};
            await changeUserPassword(editedUser);

            toast.success('Senha alterada!');
            handleClose();
        } 
        catch 
        {
            toast.error('Erro ao alterar a senha');
        }      
    }

    return (
        <>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
            <div
                style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                }}>
                Alterar a Senha
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
                </IconButton>
            </div>
            </DialogTitle>

            <form>
            <DialogContent>
                <div>
                    <TextField inputRef={password} 
                        className="autocomplete" 
                        variant="standard" 
                        type='password'
                        placeholder="Nova Senha" />
                </div>
                <div>
                    <TextField inputRef={passwordVerify} 
                        className="autocomplete" 
                        variant="standard" 
                        type='password'
                        placeholder="Confirmar Nova Senha" />
                </div>
            </DialogContent>
            <DialogActions style={{position: 'sticky', bottom: 0, backgroundColor:'white', width:'100%',}}>
                <Button color="primary" onClick={handleChangePassword}>
                    Gravar
                </Button>
            </DialogActions>
            </form>
        </Dialog>
        </>
    )
}

PasswordModal.propTypes = {
    open: P.bool.isRequired,
    setOpen: P.func.isRequired,  
    editedUser: P.object.isRequired
}