/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import P from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import { TextField, AutocompleteField } from '../FormFields';
import { FormGroup } from './styles';
import { floatParaEstiloPTBR, formatarParaEstiloPTBR } from '../../hooks/Utils';
import { SN } from '../../mock/leadInfo.com';


export function CommissionComponents({
    values,
    setValues,
    handleChange,
    handleChangeTermBegin,
    termBegin,
    termFinal,

    handleChangeCommissionPercentage,
    isDisableCommissionPercentage,
    commissionPercentage,

    handleChangeInsuranceSoldValue,
    insuranceSoldValue,

    handleChangeAutoComplete,
    assinaturaMensal,
    assinaturaMensalValueText,

    assinaturaMensalNumParcelas,

    isDisabled,
})
{
    const getComissionValue = (percentage, soldValue) => {
        if (!percentage || !soldValue)
            return 0;
    
        const commissionValue = 
            ( percentage / 100 ) * 
            parseFloat(soldValue.replace('.', '').replace(',','.'));
    
        if (commissionValue !== values.commission_value) {
            setValues({ ...values, commission_value: commissionValue });
        }
    
        return floatParaEstiloPTBR(commissionValue);
    }

    return (
        <>
        <FormGroup>
            <TextField
                label="Início de vigência:"
                type="date"
                varibleName='term_begin'
                value={termBegin || ''}
                onChange={handleChangeTermBegin}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label="Final de vigência:"
                type="date"
                varibleName='term_final'
                value={termFinal || ''}
                onChange={handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <NumericFormat
                label="Valor do seguro vendido (Prêmio liquido)"
                customInput={TextField}
                decimalSeparator=","
                decimalScale={2}
                autoComplete="off"
                InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
                value={insuranceSoldValue}
                onChange={handleChangeInsuranceSoldValue}      
                disabled={isDisabled}
            />     
            <TextField
                value={commissionPercentage || 0}
                varibleName="commission_percentage"
                label="Percentual de comissão"
                onChange={handleChangeCommissionPercentage}
                InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                styleWidth='120px'
                disabled={isDisableCommissionPercentage}
            />               
        </FormGroup>
        <FormGroup>
        <NumericFormat
                label="Valor em Reais(R$) de comissão"
                customInput={TextField}
                decimalSeparator=","
                decimalScale={2}
                autoComplete="off"
                InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
                value={getComissionValue(commissionPercentage, insuranceSoldValue)}
                style={{color: 'black'}}
                disabled
            />  
           <AutocompleteField
                medium
                className="control m"
                valueText={assinaturaMensalValueText}
                options={SN}
                value={assinaturaMensal || null}
                varibleName="assinatura_mensal"
                onChange={handleChangeAutoComplete}
                label="Tem Assinatura mensal"
                disabled={isDisabled}
            />
            <NumericFormat
                label="Número de parcelas"
                customInput={TextField}
                decimalScale={0}
                autoComplete="off"
                varibleName="assinatura_mensal_num_parcelas"
                onChange={handleChange}
                value={assinaturaMensalNumParcelas}
                disabled={isDisabled || assinaturaMensal?.id == 1 }
            />  
        </FormGroup>
        </>
    )
}


CommissionComponents.propTypes = {
    values: P.objectOf(P.any).isRequired,
    setValues: P.objectOf(P.any).isRequired,
    handleChange: P.objectOf(P.any).isRequired,
    handleChangeTermBegin: P.objectOf(P.any).isRequired,
    termBegin: P.string.isRequired,
    termFinal: P.string.isRequired,

    handleChangeCommissionPercentage: P.objectOf(P.any).isRequired,
    isDisableCommissionPercentage: P.bool.isRequired,
    commissionPercentage: P.number.isRequired,

    handleChangeInsuranceSoldValue: P.objectOf(P.any).isRequired,
    insuranceSoldValue: P.number.isRequired,

    handleChangeAutoComplete: P.objectOf(P.any).isRequired,
    assinaturaMensal: P.string.isRequired,
    assinaturaMensalValueText: P.string.isRequired,

    assinaturaMensalNumParcelas: P.number.isRequired,

    isDisabled: P.bool.isRequired,
}
