import styled, {keyframes} from 'styled-components';

const move = keyframes`
  0% {
    transform: scaleY(1.2);
  }
  50% {
    transform: scaleY(0.5);
  }
  100% {
    transform: scaleY(1.2);
  }
`;

export const Ul = styled.ul`
  display: flex;
  .li {
    list-style: none;
    width: 10px;
    height: 60px;
    background: #5d5d5d;
    margin: 0 5px;
    border-radius: 5px;
  }
  .liButton {
    list-style: none;
    width: 6px;
    height: 25px;
    background: #5d5d5d;
    margin: 0 3px;
    border-radius: 5px;
  }
  .li-1{
    animation: ${move} 0.95s ease-in-out 0.1s infinite;
  }
  .li-2{
    animation: ${move} 0.95s ease-in-out 0.2s infinite;
  }
  .li-3{
    animation: ${move} 0.95s ease-in-out 0.3s infinite;
  }
  .li-4{
    animation: ${move} 0.95s ease-in-out 0.4s infinite;
  }
  .li-5{
    animation: ${move} 0.95s ease-in-out 0.5s infinite;
  }
  .li-6{
    animation: ${move} 0.95s ease-in-out 0.6s infinite;
  }
  /* @for $i from 1 through 6 {
    .li-#{$i} {
      animation: ${move} 0.95s ease-in-out $i * 0.1s infinite;
    }
  } */

`;
