/* eslint-disable */
// /* eslint-disable no-unreachable */
// /* eslint-disable consistent-return */
// /* eslint-disable array-callback-return */
// /* eslint-disable no-return-assign */
import P from 'prop-types';
import { useState, useContext, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Button } from '@material-ui/core';
import { ButtonMenu, SubMenu, Shadow } from './styles';
import { GrupoCarbel, GrupoVNVD, SubChannel, SubChannelGroup } from './grupos';
import ItemSubMenu from '../ItemSubMenu';


export default function MenuButton({ count, subCount, name, onClick, className, user, onChangeFlow }) {
  const [chanelClick, setChanelClick] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  function countChannel(channel) {
    if (channel.children?.length > 0) {
      let varTotal = 0;
      subCount.forEach((e) => {
        if (channel?.children.some((ch) => ch.id == e.id)) {
          varTotal += user.technical_team.channels.some((cha) => cha.id == e.id) ? parseInt(e.total) : 0;
        }
      });
      return parseInt(varTotal);
    }
    return subCount?.find((c) => c.id == channel.id)?.total;
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  //#region Checa se o usuário tem technical_team e se o channel pertence ao team do mesmo
  const Web = ({ WebChildren }) => (
    <>
      {WebChildren.map(
        (wc) =>
          !user.technical_team ||
          (user.technical_team && user.technical_team.channels.filter((tt) => tt.id == wc.id).length > 0 && (
            <li key={wc.id} className="item">
              <Button
                fullWidth
                onClick={() => {
                  if (onChangeFlow != null) {
                    localStorage.setItem('pessego_attendance_flow', JSON.stringify(wc.attendance_flow.status_flow));
                    onChangeFlow(wc.attendance_flow.status_flow);
                  }
                  onClick(wc.id, null);
                  setOpen(false);
                  setOpenSubMenu(false);
                }}>
                {wc.name} <span className="count">{countChannel(wc) ?? 0}</span>
              </Button>
            </li>
          ))
      )}
    </>
  );
  const Partners = ({ PartnersChildren }) => (
    <>
      {PartnersChildren.map(
        (pc) =>
          !user?.technical_team ||
          (user?.technical_team && user?.technical_team.channels.filter((tt) => tt.id == pc.id).length > 0 && (
            <li key={pc.id} className="item">
              <Button
                fullWidth
                onClick={() => {
                  if (onChangeFlow != null) {
                    localStorage.setItem('pessego_attendance_flow', JSON.stringify(pc.attendance_flow.status_flow));
                    onChangeFlow(pc.attendance_flow.status_flow);
                  }
                  onClick(pc.id, null);
                  setOpen(false);
                  setOpenSubMenu(false);
                }}>
                {pc.name} <span className="count">{countChannel(pc) ?? 0}</span>
              </Button>
            </li>
          ))
      )}
    </>
  );

  //#endregion

  return (
    <>
      <div style={{ position: 'relative' }}>
        <ButtonMenu className={className} variant="contained" onClick={handleToggle}>
          {name} <span className="count">{count}</span>
        </ButtonMenu>

        <AnimatePresence>
          {open && (
            <SubMenu
              onClick={() => {
                if (openSubMenu) setOpenSubMenu(false);
              }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
              transition={{
                type: 'tween',
                duration: 0.2,
              }}>
              <div className="sub-menus-content">
                <ul>
                  {user?.technical_team?.channels
                    .filter((c) => !c.parent_id)
                    .map((channel) => {
                      if (true) {
                        if (
                          user?.technical_team === null ||
                          user?.role !== 3 ||
                          user?.technical_team?.channels?.some((c) => c.id === channel.id)
                        ) {
                          return (
                            <li className="item" key={channel.id} onClick={() => setChanelClick(channel.id)}>
                              <ItemSubMenu
                                clickMenu={chanelClick}
                                item={channel}
                                count={countChannel(channel) ?? "0"}
                                openSubMenu={openSubMenu}
                                setOpenSubMenu={setOpenSubMenu}
                                action={
                                  !channel.custom
                                    ? () => {
                                        if (onChangeFlow != null) {
                                          localStorage.setItem(
                                            'pessego_attendance_flow',
                                            JSON.stringify(channel.attendance_flow.status_flow)
                                          );
                                          onChangeFlow(channel.attendance_flow.status_flow);
                                        }
                                        onClick(channel.id, null);
                                        setOpen(false);
                                        setOpenSubMenu(false);
                                      }
                                    : null
                                }>
                                {chanelClick === 1 && (
                                  <SubChannelGroup 
                                    subChildren={channel.children} 
                                    user={user} 
                                    countChannel={(p) => countChannel(p)} 
                                    onChangeFlow={onChangeFlow}
                                    onClick={onClick}
                                    setOpen={setChanelClick}
                                    setOpenSubMenu={setOpenSubMenu}
                                    subCount={subCount}/>
                                )}

                                {chanelClick === 2 && channel.id == 2 ? (
                                  <Partners PartnersChildren={channel.children} />
                                ) : (
                                  ''
                                )}
                                {chanelClick === 3 && channel.id == 3 ? <Web WebChildren={channel.children} /> : ''}
                                {chanelClick === 21 && channel.id == 21 ? (
                                  <SubChannel subChildren={channel.children} user={user} />
                                ) : (
                                  ''
                                )}
                                {chanelClick > 3 && chanelClick != 21 && channel.children ? (
                                  <SubChannel subChildren={channel.children} user={user} />
                                ) : (
                                  ''
                                )}
                              </ItemSubMenu>
                            </li>
                          );
                        }
                      }
                    })}                    
                </ul>
              </div>
            </SubMenu>
          )}
        </AnimatePresence>
      </div>
      <Shadow
        onClick={() => {
          setOpen(false);
          setOpenSubMenu(false);
        }}
        open={open}
      />
    </>
  );
}

MenuButton.propTypes = {
  name: P.string.isRequired,
  status: P.number,
  count: P.number.isRequired,
  className: P.string.isRequired,
  user: P.objectOf(P.any),
  onClick: P.func.isRequired,
};

MenuButton.defaultProps = {
  user: null,
  status: 0,
};
