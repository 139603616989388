/* eslint-disable */
import { useContext, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import P, { shape } from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import { MainContext } from '../../context/ApiContext';
import UserForm from '../UserForm';
import { MaskedTextField } from '../FormFields';
import { Duration, DateTime } from 'luxon';

const SummaryReport = ({ listItem, title, c1, c2, c3, name_id, withName, noTotal, defaultTableBody = true }) => {
  const {
    deleters: { deleteUser },
  } = useContext(MainContext);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [user, setUser] = useState(null);
  const [id, setId] = useState('');
  const [totals, setTotals] = useState({
    inviter_all_leads: 0,
    captures_on_time: 0,
    attendances_on_time: 0,
    had_contact: 0,
    quoted: 0,
    backup_car: 0,
    pague_menos: 0,
    sold: 0,
    conversion: 0,
  });

  const [dialog, setDialog] = useState({
    msg: '',
    title: '',
  });

  function formatDate(date) {
    if (date == null) {
      return null;
    }
    var mydate = date?.substring(0, 10) || '';
    var year = mydate?.substring(0, 4) || '';
    var month = mydate?.substring(5, 7) || '';
    var day = mydate?.substring(8, 10) || '';

    var newDate = day + '/' + month + '/' + year;
    return newDate;
  }

  function formatPhone(phone) {
    if (phone?.length == 11) {
      var ddd = phone?.substring(0, 2) || '';
      var prefix = phone?.substring(2, 7) || '';
      var sufix = phone?.substring(7, 11) || '';
    }
    if (phone?.length == 10) {
      var ddd = phone?.substring(0, 2) || '';
      var prefix = phone?.substring(2, 6) || '';
      var sufix = phone?.substring(6, 10) || '';
    }
    if (phone?.length < 10 || phone?.length == undefined) {
      return '-';
    }

    var newPhone = '(' + ddd + ') ' + prefix + '-' + sufix;
    return newPhone;
  }

  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        msg={dialog.msg.length > 0 ? dialog.msg : 'Dialog'}
        title={dialog.title.length > 0 ? dialog.title : 'Dialog'}
      />
      <UserForm open={open} setOpen={setOpen} title={title} user={user} />
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>{title}</div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>{c1}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{c2}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{c3}</TableCell>
            </TableRow>
          </TableHead>
          {defaultTableBody ? (
            <TableBody>
              {listItem !== null &&
                listItem?.map((item) => (
                  <TableRow key={item?.name_id}>
                    <TableCell>
                      {withName ? item.name || '-' : name_id.find((i) => i.var_id == item?.name_id)?.value || '-'}
                    </TableCell>
                    <TableCell>{Number(item?.v1).toFixed(2)} %</TableCell>
                    <TableCell>
                      {item?.v2} / {item.v3}
                    </TableCell>
                  </TableRow>
                ))}
              {noTotal ? (
                ''
              ) : (
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {(listItem?.reduce(function (sum, current) {
                      return parseInt(sum) + parseInt(current.v2);
                    }, 0) *
                      100) /
                      (listItem[0]?.v4
                        ? listItem[0].v4
                        : listItem?.reduce(function (sum, current) {
                            return parseInt(sum) + parseInt(current.v3);
                          }, 0)) || ''}
                    %
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {listItem?.reduce(function (sum, current) {
                      return parseInt(sum) + parseInt(current.v2);
                    }, 0)}{' '}
                    /{' '}
                    {listItem[0]?.v4
                      ? listItem[0].v4
                      : listItem?.reduce(function (sum, current) {
                          return parseInt(sum) + parseInt(current.v3);
                        }, 0)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              {listItem.map((item) => (
                <TableRow>
                  <TableCell>{item.client.name}</TableCell>
                  <TableCell>{item.broker.name}</TableCell>
                  <TableCell>{item.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

SummaryReport.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
    })
  ),
  title: P.string.isRequired,
};

SummaryReport.defaultProps = {
  listItem: null,
};

export default SummaryReport;
