import { useContext } from "react";
import { AuthContext } from "../context/auth";


export function useAuth() {
  const { user, updateUser, logoutUser } = useContext(AuthContext);

  const isAdminUser = () => user.data.roles[0].id === 1;
  const isM3tasUser = () => user.data.login == "m3tas";
  
  return { user, updateUser, logoutUser, isAdminUser, isM3tasUser };
}
