import { DateTime } from 'luxon';


  const getDateTimeFormatted = () => 
    DateTime.now().toISO().split('.')[0];

  const checkInviter = (inviterValue, values, setValues) => {
    setValues({
        ...values,
        inviter: inviterValue,
        inviter_Phone: inviterValue?.phone,
        channel_id: inviterValue?.channel.id,
    });
  };    

  export const handleChangeAutoCompleteValidations = (newValue, variable, values, setValues, variableDate) => {       
    if (variableDate) {
        setValues({
            ...values,
            [variable]: newValue,
            [variableDate]: getDateTimeFormatted()
            });
    }
    else {
        setValues({
            ...values,
            [variable]: newValue,
            });    
    }

    if (variable === 'inviter') checkInviter(newValue, values, setValues);
  };
  
  export const handleChangeAutoCompleteQuotedType = (newValue, variable, values, setValues) => {
    handleChangeAutoCompleteValidations(newValue, variable, values, setValues, 'quoted_date');
  };

  export const handleChangeAutoCompleteContactType = (newValue, variable, values, setValues) => {
    handleChangeAutoCompleteValidations(newValue, variable, values, setValues, 'contact_date');
  };

  export const handleChangeAutoCompleteSoldType = (newValue, variable, values, setValues) => {
    handleChangeAutoCompleteValidations(newValue, variable, values, setValues, 'sold_date');
  };