/* eslint-disable default-case */
export function reducer(state, action) {
  switch (action.type) {
    case 'GET_USERS': {
      return { ...state, users: [...action.payload] };
    }
    case 'GET_CHANNELS': {
      return { ...state, channels: [...action.payload] };
    }
    case 'GET_INSURANCES': {
      return { ...state, insurances: [...action.payload] };
    }
    case 'GET_LEAD_INSURANCES': {
      return { ...state, leadInsurances: [...action.payload] };
    }
    case 'GET_PARTNERS': {
      return { ...state, partners: [...action.payload] };
    }
    case 'GET_CALLSRESULT': {
      return { ...state, lead_calls_result: [...action.payload] };
    }
    case 'GET_LEADVERIFICATION': {
      return { ...state, json_response: [...action.payload] };
    }
    case 'GET_CLIENTS': {
      return { ...state, clients: [...action.payload] };
    }
    case 'GET_LEADS': {
      return { ...state, leads: [...action.payload] };
    }
    case 'GET_LEADS_SUMMARY': {
      return { ...state, leads_summary: [...action.payload] };
    }
    case 'GET_LEAD_STATES': {
      return { ...state, lead_states: [...action.payload] };
    }
    case 'GET_LEAD_STATUS': {
      return { ...state, lead_status: [...action.payload] };
    }
    case 'GET_LEAD_SOLD': {
      return { ...state, lead_sold: [...action.payload] };
    }
    case 'GET_LEAD_LOST_SALE': {
      return { ...state, lead_lost_sale: [...action.payload] };
    }
    case 'GET_LOST_SALE_REPORT': {
      return { ...state, lost_sale_report: [...action.payload] };
    }
    case 'GET_LEAD_CALL_TYPE': {
      return { ...state, lead_contact_type: [...action.payload] };
    }
    case 'GET_COMPETITOR_BROKERS': {
      return { ...state, competitor_brokers: [...action.payload] };
    }


    case 'AUTH_USER': {
      return { ...state, user: action.payload };
    }
    case 'GET_ALL': {
      return {
        ...state,
        users: action.payload.users,
        partners: action.payload.partners,
        channels: action.payload.channels,
        insurances: action.payload.insurances,
        clients: action.payload.clients,
        technical_teams: action.payload.technical_teams,

        time_to_capture_lead: action.payload.time_to_capture_lead[0],
        time_to_finish_lead: action.payload.time_to_finish_lead[0],
        indication_on_time: action.payload.indication_on_time[0],
        first_call_on_time: action.payload.first_call_on_time[0],
        finished_leads_days_to_show: action.payload.finished_leads_days_to_show[0],
        endorsement_leads_days_to_show: action.payload.endorsement_leads_days_to_show[0],
        lost_leads_last_year_days_to_show: action.payload.lost_leads_last_year_days_to_show[0],

        lead_states: action.payload.leads_states,
        lead_status:action.payload.leads_status,
        lead_sold: action.payload.leads_sold,
        lead_lost_sale: action.payload.leads_lost_sale,
        lead_contact_type: action.payload.leads_contact_type,
        lead_service_status: action.payload.leads_service_status,
        competitor_brokers: action.payload.competitor_brokers,
        
        refresh_lead_seconds: action.payload.refresh_lead_seconds,
      };
    }
  }
  return { ...state };
}
