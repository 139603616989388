
/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import { TextField, AutocompleteField } from '../../FormFields';
import { formatarParaEstiloPTBR } from '../../../hooks/Utils';


export const InsuranceOwnerArray = [
    { name: 'Próprio', id: 1 },
    { name: 'Alugado', id: 2 },
];

export const InsuranceNewArray = [
    { name: 'Novo', id: 1 },
    { name: 'Renovação', id: 2 },
];


export function CommonResidenceAndBusinessQuestions({
    onChangeAutoComplete,
    onChangeNumeric,
    insuranceAmount,
    insuranceOwner,
    insuranceNew
})
{

    return (
        <>
        <NumericFormat
            label="Valor do imóvel"
            customInput={TextField}
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            autoComplete="off"
            InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
            value={formatarParaEstiloPTBR(insuranceAmount)}
            onChange={onChangeNumeric}
        />
        <AutocompleteField
            medium
            value={insuranceOwner || null}
            onChange={onChangeAutoComplete}
            valueText={insuranceOwner ? insuranceOwner.name : ''}
            options={InsuranceOwnerArray}
            varibleName="insurance_residence_owner"
            className="control m"
            label="Imóvel"
        />
        <AutocompleteField
            medium
            value={insuranceNew || null}
            onChange={onChangeAutoComplete}
            valueText={insuranceNew ? insuranceNew.name : ''}
            options={InsuranceNewArray}
            varibleName="insurance_residence_new"
            className="control m"
            label="Seguro"
        />        
        </>
    )
}

CommonResidenceAndBusinessQuestions.propTypes = {
    insuranceAmount: P.string.isRequired,
    insuranceOwner: P.objectOf(P.any).isRequired,
    insuranceNew: P.objectOf(P.any).isRequired,
    onChangeAutoComplete: P.objectOf(P.any).isRequired,
    onChangeNumeric: P.objectOf(P.any).isRequired,
}


// Seguro novo ou removação
export function CommonNewInsuranceQuestions({
    insuranceNew,
    onChangeAutoComplete
})
{
    return (
        <AutocompleteField
            medium
            value={insuranceNew || null}
            onChange={onChangeAutoComplete}
            valueText={insuranceNew ? insuranceNew.name : ''}
            options={InsuranceNewArray}
            varibleName="insurance_residence_new"
            className="control ms"
            label="Seguro"
        />        
    )
}

CommonNewInsuranceQuestions.propTypes = {
    insuranceNew: P.objectOf(P.any).isRequired,
    onChangeAutoComplete: P.objectOf(P.any).isRequired,
}

// Praticar esporte
export function CommonPraticeSportInsuranceQuestions({
    onChange,
    insurancePracticesSports
})
{
    return (
        <FormControlLabel
            style={{ marginLeft: 10 }}
            control={
            <Checkbox 
                color="default" 
                onChange={onChange} 
                checked={insurancePracticesSports} 
                name="insurance_practicesSports" 
            />}
            label="Pratica Algum Esporte?"
        />        
    )
}

CommonPraticeSportInsuranceQuestions.propTypes = {
    onChange: P.objectOf(P.any).isRequired,
    insurancePracticesSports: P.bool.isRequired,
}

// Nota fiscal
export function CommonInvoiceInsuranceQuestions({
    onChange,
    insuranceInvoice,
    insuranceBrand,
    insuranceModel,
})
{
    return (
        <>
        <TextField
            value={insuranceInvoice || ''}
            onChange={onChange}
            varibleName="insurance_invoice"
            placeholder="Nome na Nota Fiscal"
            className="control ms"
            label="Tem nota fiscal? Se sim, em nome de quem?"
        />
        <TextField
            value={insuranceBrand || ''}
            onChange={onChange}
            varibleName="insurance_brand"
            placeholder="Marca"
            className="control ms"
            label="Marca"
        />
        <TextField
            value={insuranceModel || ''}
            onChange={onChange}
            varibleName="insurance_model"
            placeholder="Modelo"
            className="control ms"
            label="Modelo"
        />
        </>
    )
}

CommonInvoiceInsuranceQuestions.propTypes = {
    onChange: P.objectOf(P.any).isRequired,
    insuranceInvoice: P.string.isRequired,
    insuranceBrand: P.string.isRequired,
    insuranceModel: P.string.isRequired,
}

// Ano, Valor e Data
export function CommonYearValueDateInsuranceQuestions({
    onChange,
    insuranceManufactureYear,
    insuranceBuyAmount,
    insuranceBuyDate,
})
{
    return (
        <>
        <NumericFormat
            label="Ano de fabricação"
            name="insurance_manufactureYear"
            customInput={TextField}
            decimalScale={0}
            autoComplete="off"
            value={insuranceManufactureYear || ''}
            onChange={onChange}
        />
        <NumericFormat
            label="Valor de compra"
            name="insurance_buyAmount"
            customInput={TextField}
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            autoComplete="off"
            InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
            value={formatarParaEstiloPTBR(insuranceBuyAmount)}
            onChange={onChange}
        />
        <TextField
            name="insurance_buyDate"
            label="Data de compra"
            type="datetime-local"
            value={insuranceBuyDate || ''}
            onChange={onChange}
            InputLabelProps={{shrink: true}}
        />
        </>
    )
}

CommonYearValueDateInsuranceQuestions.propTypes = {
    onChange: P.objectOf(P.any).isRequired,
    insuranceManufactureYear: P.number.isRequired,
    insuranceBuyAmount: P.number.isRequired,
    insuranceBuyDate: P.string.isRequired,
}
