import create from 'zustand';

const appStore = create((set) => ({
  channels: [],
  insurances: [],
  partners: [],
  lead_states:[],
  lead_status:[],
  lead_sold:[],
  lead_calls_result:[],
  lead_lost_sale:[],
  lead_contact_type:[],
  lead_service_status:[],
  json_response:[],
  competitor_brokers:[],
  technical_teams:[],
  lost_sale_report:[],
  first_call_on_time: {},
  indication_on_time: {},
  time_to_capture_lead: {},
  time_to_finish_lead: {},
  proposal_on_time: {},
  finished_leads_days_to_show: {},
  endorsement_leads_days_to_show: {},
  lost_leads_last_year_days_to_show: {},
  refresh_lead_seconds:[],
  loading: false,
  dashboard_check: false,
  setState: (key, value) => {
    set((state) => ({
      ...state,
      [key]: value,
    }));
  },
}));

export default appStore;
