/* eslint-disable camelcase */
import { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { TablePagination } from '@material-ui/core';
import { motion } from 'framer-motion';
import ClientForm from '../../components/ClientForm';
import { Header, Container } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import ClientTableList from '../../components/ClientTableList';
import DatePeriodFilter from '../../components/ClientTableList/Filter';
import LoadingPage from '../LoadingPage';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import FullScreenDialog from '../../components/FullScreenDialog';
import LeadForm from '../../components/LeadForm';
import useApp from '../../hooks/useApp';
import { createClient, deleteClient, editClient } from '../../service/client.service';

const Clients = () => {
  const { lead_states, lead_status, lead_sold, lead_lost_sale, setState } = useApp();
  const [openLead, setOpenLead] = useState(false);
  const [leadToEdit, setLeadToEdit] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [listClients, setListClients] = useState(0);
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    per_page: 10,
    page: 0,
    isActive: false,
  });
  const { data: clients, isValidating: validateClient, mutate } = useSWR(
    `/clients/list?name=${filters.name}&email=${filters.email}&is_active=${filters.isActive || ''}&per_page=${
      filters.per_page
    }&page=${filters.page + 1}`,
    variantFetcher
  );
  const { data: lead, isValidating: validateLeads } = useSWR(`/leads/${leadToEdit || 1}`, fetcher);

  useEffect(() => {
    setState('loading', (validateClient || validateLeads));
  }, [validateClient, validateLeads]);

  useEffect(() => {
    if (clients) {
      setTotalPages(clients?.pagination?.total);
      setListClients([...clients?.data]);
    }
  }, [clients]);

  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, page: 0, per_page: parseInt(event.target.value, 10) });
  };

  return (
    <>
      <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
        <Container>
          <FullScreenDialog open={openLead} setOpen={setOpenLead}>
            {lead && <LeadForm type="edit" leadCurrent={lead} setOpen={setOpenLead} />}
          </FullScreenDialog>
          <Header>
            <h1>Clientes</h1>
          </Header>
          <DatePeriodFilter setFilters={setFilters} filters={filters} />
          {listClients && (
            <>
              <TablePagination
                component="div"
                count={totalPages}
                page={filters.page}
                onPageChange={handleChangePage}
                rowsPerPage={filters.per_page}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <ClientTableList
                listItem={listClients}
                stringValues={[lead_states, lead_status, lead_sold, lead_lost_sale]}
                deleteData={deleteClient}
                editData={editClient}
                title="Cliente"
                filters={filters}
                setLead={(leadId) => {
                  setLeadToEdit(leadId);
                  setOpenLead(true);
                }}
                mutate={mutate}
              />
            </>
          )}
        </Container>
      </motion.div>
    </>
  );
};

export default Clients;
