/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import axios from 'axios';
import { getToken } from './auth.service';


const getTenant = () => 
{
  const tenantId = `${localStorage.getItem("tenantId")}`;
  const url = process.env.REACT_APP_API_ADM_BASE_URL.replace("{tenantId}",tenantId);

  return url;
}

const adm = axios.create({
  baseURL: getTenant(),
});

adm.interceptors.request.use((request) => {
  request.baseURL = getTenant();

  if (
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/register' &&
    !window.location.pathname.includes('verify')
  ) {
    const token = getToken();
    request.headers.authorization = `Bearer ${token}`;
  }
  return request;
});

adm.interceptors.response.use(
  (response) => response,
  (err) => Promise.reject(err.response.data),
);

export default adm;
