/* eslint-disable react/prop-types */
import { useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import P, { shape } from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import { deleteLeadBrokerConfig } from '../../service/leadBrokerConfig.service';
import LeadBrokerConfigForm from '../LeadBrokerConfigForm';

const LeadBrokerConfigTableList = ({ listItem, title, mutate }) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [brokerConfig, setBrokerConfig] = useState(null);
  const [id, setId] = useState('');
  const [dialog, setDialog] = useState({
    msg: '',
    title: '',
  });

  const deleteFunc = () => {
    deleteLeadBrokerConfig(id);
    mutate()
  };

  const handleDelete = (idItem) => {
    setDialog({
      msg: `Tem certeza que deseja remover ${idItem}?`,
      title: `Remover ${idItem} da tabela ${title}`,
    });
    setId(idItem);
    setOpenConfirm(true);
  };

  const handleEdit = async (item) => {
    await setBrokerConfig(item);
    setOpen(true);
  };

  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        msg={dialog.msg.length > 0 ? dialog.msg : 'Dialog'}
        title={dialog.title.length > 0 ? dialog.title : 'Dialog'}
        next={deleteFunc}
      />
      <LeadBrokerConfigForm open={open} setOpen={setOpen} title={title} leadBrokerConfig={brokerConfig} mutate={mutate} />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">ID</TableCell>
              <TableCell width="15%">Canal</TableCell>
              <TableCell width="15%">Empresa</TableCell>
              <TableCell width="15%">Tipo de Estoque</TableCell>
              <TableCell width="20%">Corretor</TableCell>
              <TableCell width="15%">Status</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItem !== null &&
              listItem.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell>{item.channel?.name}</TableCell>
                  <TableCell>{item.partner?.name}</TableCell>
                  <TableCell>{item.insurance?.name}</TableCell>
                  <TableCell>{item.broker?.name}</TableCell>
                  <TableCell>{item.status == "1" ? 'Ativo' : 'Inativo'}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleEdit(item)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

LeadBrokerConfigTableList.propTypes = { 
  listItem: P.arrayOf(
    shape({
      id: P.number,
    })
  ),
  title: P.string.isRequired,
};

LeadBrokerConfigTableList.defaultProps = {
  listItem: null,
};

export default LeadBrokerConfigTableList;
