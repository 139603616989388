/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import { IconButton, InputAdornment, Input, InputLabel, Button, Divider, FormControl } from '@material-ui/core';
// import { Divider } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { MaskedTextField, TextField } from "../FormFields";
import {Container, ContainerFields, InputControl} from './styles'
import api from "../../service/api";
import { MainContext } from "../../context/ApiContext";
import { useRef } from "react";


const ProfileForm = () => {
  const form = useRef(null)
  const [fields, setFields] = useState({
    email:'',
    emailVerify: '',
    phone:'',
    old_password:'',
    password:'',
    password_confirm:'',
  })
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    re_new: false,
  })


  const {
    auth: { getUserInfo },
  } = useContext(MainContext);
  const [user, setUser] = useState(null)

  const getUser = async() => {
    const temp = await getUserInfo();
      setUser(temp.data);
  }
  useEffect(() => {
    getUser()
  }, []);

  const handleChange = (event) => {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = (variable) => {
    setShowPassword({ ...showPassword, [variable]: !showPassword[variable] });
  };

  const handleSubmitFields = async (e) => {
    e.preventDefault();
    if ((fields.email || fields.emailVerify) && fields.email !== fields.emailVerify) {
      return;
    }
    try {
      await api.post('/update-user', { email: fields.email, phone: fields.phone });
      getUser();
      setFields({ ...fields, email: null, emailVerify: null, phone: null });
    } catch {
      // console.log('error');
    }

  };
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if(fields.password === fields.password_confirm){
      try{
        await api.post('/update-user', {old_password: fields.old_password, password: fields.password})
        getUser();
        setFields({...fields, old_password: null, password: null, password_confirm: null})
        form.current.reset()
      }catch{
        setFields({...fields, old_password: null, password: null, password_confirm: null})
        form.current.reset()
      }
    }else{
      setFields({...fields, old_password: null, password: null, password_confirm: null})
      form.current.reset()
    }
  }

  const phoneMask = (phone) => {
    if(user?.roles[0]?.id >= 4){
      if(phone?.replace(/[^\d]/g, '').length < 11)
      return '(99)9999-99999'
      return '(99)99999-9999'
    }
    return '99999999999'
  }

  return (
    <Container>
      <div className="card">
        <div className="user-info">
          <h2 className="name">{user?.name}</h2>
          <div className="login">{user?.login}</div>
          <div className="phone-email">
            <div className="phone"><PhoneIcon /> {user?.phone}</div>&#8226;
            <div className="email"><MailOutlineIcon /> {user?.email}</div>
          </div>
        </div>
        <div style={{width: '100%', borderBottom: 'rgba(0, 0, 0, 0.12) 2px solid', marginTop: '20px'}}/>
        <ContainerFields>
          <div className="profile-container">
            <form onSubmit={handleSubmitFields}>
              <div className="fields">
                <FormControl fullWidth>
                  <TextField
                    className="control sm"
                    value={fields.email || ''}
                    onChange={handleChange}
                    varibleName="email"
                    label="Novo e-mail"
                    variant="standard"
                    fullWidth
                    required={false}
                    />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    className="control sm"
                    value={fields.emailVerify || ''}
                    onChange={handleChange}
                    varibleName="emailVerify"
                    label="Confirmação de e-mail"
                    variant="standard"
                    fullWidth
                    required={false}
                    />
                </FormControl>
                <MaskedTextField
                  mask={phoneMask(fields.phone)}
                  variant="standard"
                  value={fields.phone || ''}
                  onChange={handleChange}
                  varibleName="phone"
                  label="Novo telefone"
                  fullWidth
                  required={false}
                  />
              </div>
              <div className="btns">
                <Button type="submit">Salvar</Button>
                <Button onClick={() => console.log(fields)}>Cancelar</Button>
              </div>
            </form>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="password-container">
            <form onSubmit={handleSubmitPassword} ref={form}>
              <div className="fields">
                <InputControl fullWidth style={{ marginBottom: 8, marginTop: 16 }}>
                  <InputLabel htmlFor="old-password" shrink>
                    Senha Antiga
                  </InputLabel>
                  <Input
                    id="old-password"
                    type={showPassword.old ? 'text' : 'password'}
                    value={fields.old_password}
                    onChange={(e) => setFields({ ...fields, old_password: e.target.value })}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleClickShowPassword('old')}>
                          {showPassword.old ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </InputControl>
                <InputControl fullWidth style={{ marginBottom: 8, marginTop: 16 }}>
                  <InputLabel htmlFor="password" shrink>
                    Ńova senha
                  </InputLabel>
                  <Input
                    id="password"
                    type={showPassword.new ? 'text' : 'password'}
                    value={fields.password}
                    onChange={(e) => setFields({ ...fields, password: e.target.value })}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleClickShowPassword('new')}>
                          {showPassword.new ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </InputControl>
                <InputControl fullWidth style={{ marginBottom: 8, marginTop: 16 }}>
                  <InputLabel htmlFor="password_confirm" shrink>
                    Confirmação de Senha
                  </InputLabel>
                  <Input
                    id="password_confirm"
                    type={showPassword.re_new ? 'text' : 'password'}
                    value={fields.password_confirm}
                    onChange={(e) => setFields({ ...fields, password_confirm: e.target.value })}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleClickShowPassword('re_new')}>
                          {showPassword.re_new ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </InputControl>
              </div>
              <div className="btns">
                <Button type="submit">Salvar</Button>
                <Button>Cancelar</Button>
              </div>
            </form>
          </div>
        </ContainerFields>
      </div>
    </Container>
  );
}

export default ProfileForm;
