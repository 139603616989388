/* eslint-disable react/prop-types */
/* eslint-disable */
import { useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import { getClientByName } from '../../service/client.service';
import SearchIcon from '@material-ui/icons/Search';

export default function SearchClientModal({ title, fieldName, open, setOpen, next }) {
  const [searchedClients, setSearchedClients] = useState();

  const clientName = useRef('');

  const handleClose = () => {
    setOpen(false);
    setSearchedClients([]);
  };

  async function searchClient() {
    const data = await getClientByName(clientName.current.value);

    setSearchedClients(data);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {title}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form>
          <DialogContent>
            <Box width="500px">
              <p>{fieldName}</p>
              <TextField inputRef={clientName} className="autocomplete" variant="standard" placeholder="Cliente" />

              <IconButton aria-label="upload picture" component="span" onClick={() => searchClient()}>
                <SearchIcon />
              </IconButton>
            </Box>

            {!!searchedClients &&
              searchedClients.map((client) => (
                <div
                  onClick={() => {
                    next(client);
                    setSearchedClients([]);
                  }}
                  style={{cursor:"pointer"
                  }}>
                  {client.name}
                </div>
              ))}
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}

SearchClientModal.propTypes = {
  title: P.string.isRequired,
  fieldName: P.string.isRequired,
  open: P.bool.isRequired,
  setOpen: P.func.isRequired,
  onChange: P.func.isRequired,
  next: P.func.isRequired,
};

SearchClientModal.defaultProps = {
  date: false,
  value: null,
};
