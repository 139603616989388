import styled from 'styled-components';

export const Header = styled.header`
  padding-bottom: 16px;
  max-height: 100%;
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .menu {
      ul {
        display: flex;
        list-style: none;
        li + li {
          margin-left: 8px;
        }
      }
    }
    .user {
      background-color: gray;
      box-sizing: border-box;
      position: relative;
      display: block;
      padding: 6px;
      margin-left: 8px;
    }
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  .seller {
    margin-bottom: 8px;
  }
  .button {
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    + .button {
      margin-top: 8px;
    }
  }
`;

export const LogoDiv = styled.div`
  a {
    color: #666;
    display: none;
    padding: 12px;
    border-radius: 8px;
    align-items: center;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.8);
    img {
      border: 0;
      vertical-align: middle;
      height: auto;
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 150px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .leadBar {
    color: rgb(203 123 6);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

export const Leads = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px 12px;
`;

export const LeadsContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  max-height: calc(100vh - 259px);
`;
