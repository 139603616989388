/* eslint-disable camelcase */
import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import SaveIcon from '@material-ui/icons/Save';
import { Header, Container, AddButton } from '../../styles/shared/styles';
import useApp from '../../hooks/useApp';
import { fetcher } from '../../service/SWRFetcher';
import LeadBrokerConfigTableList from '../../components/LeadBrokerConfigTableList';
import { deleteLeadBrokerConfig, editLeadBrokerConfig } from '../../service/leadBrokerConfig.service';
import LeadBrokerConfigForm from '../../components/LeadBrokerConfigForm';

const LeadBrokerConfig = () => {
  const {setState} = useApp();
  const { data: lead_broker_configurations, mutate } = useSWR(`/lead-broker-config`, fetcher);

  useEffect(() => {
    if(lead_broker_configurations)
      setState('lead_broker_configurations', lead_broker_configurations)
  }, [lead_broker_configurations])
  
  const [open, setOpen] = useState(false);

  return (
    <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
      <Container>
        <LeadBrokerConfigForm mutate={mutate} open={open} setOpen={setOpen} title="Lead Corretor Config" />
        <Header>
          <h1>Lead Corretor Config</h1>
          <div>
            <AddButton tvariant="contained" size="large" startIcon={<SaveIcon />} onClick={() => setOpen(true)}>
              Adicionar
            </AddButton>
          </div>
        </Header>
        <LeadBrokerConfigTableList
          listItem={lead_broker_configurations}
          deleteLeadConfigBroker={deleteLeadBrokerConfig}
          editLeadConfigBroker={editLeadBrokerConfig}
          title="Lead Corretor Config"
          mutate={mutate}
        />
      </Container>
    </motion.div>
  );
};

export default LeadBrokerConfig;
