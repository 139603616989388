/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { dataEdit } from '../../service/data.service';

export default function DeadlineModal({ title, open, setOpen, value }) {
  const [deadlineForm, setDeadlineForm] = useState();
  const [response, setResponse] = useState();

  const ResetForm = () => {
    setDeadlineForm({
      isDeadlineEdit: true,
      attendance_on_time:
        value?.attendance_on_time == 0 ? '0' : (value?.attendance_on_time && value?.attendance_on_time.toString()) || '',
      capture_on_time:
        value?.capture_on_time == 0 ? '0' : (value?.capture_on_time && value?.capture_on_time.toString()) || '',
      first_call_on_time:
        value?.first_call_on_time == 0 ? '0' : (value?.first_call_on_time && value?.first_call_on_time.toString()) || '',
      indication_on_time:
        value?.indication_on_time == 0 ? '0' : (value?.indication_on_time && value?.indication_on_time.toString()) || '',
    });
  };

  useEffect(() => {
    ResetForm();
  }, []);

  if (response == 'success') {

    toast.success('Prazo sobrescrito com sucesso!');
    setResponse(null);
    setOpen(false);
  } else if (response == 'fail') {
    toast.error('Houve um Problema');
    setResponse(null);
  }

  const handleClose = () => {
    setOpen(false);
  };  

  const handleSubmit = async () => {
    setResponse(await dataEdit('/leads', deadlineForm, value?.id));
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {title}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}>
          <DialogContent>
            <div style={{ margin: '15px' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ marginBottom: '5px' }}>
                  Capturado no Prazo?{' '}
                </FormLabel>
                <RadioGroup
                  aria-label="Capturado no Prazo"
                  name="captured_on_time"
                  value={deadlineForm?.capture_on_time}
                  row
                  onChange={(e) => setDeadlineForm((state) => ({ ...state, capture_on_time: e.target.value }))}>
                  <FormControlLabel value="" control={<Radio />} label="Inativo" />
                  <FormControlLabel value="0" control={<Radio />} label="Não" />
                  <FormControlLabel value="1" control={<Radio />} label="Sim" />
                </RadioGroup>
              </FormControl>
            </div>
            
            <div style={{ margin: '15px' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ marginBottom: '5px' }}>
                  Primeira Ligação no Prazo?{' '}
                </FormLabel>
                <RadioGroup
                  aria-label="Primeira Ligação no Prazo"
                  name="first_call_on_time"
                  value={deadlineForm?.first_call_on_time}
                  row
                  onChange={(e) => setDeadlineForm((state) => ({ ...state, first_call_on_time: e.target.value }))}>
                  <FormControlLabel value="" control={<Radio />} label="Inativo" />
                  <FormControlLabel value="0" control={<Radio />} label="Não" />
                  <FormControlLabel value="1" control={<Radio />} label="Sim" />
                </RadioGroup>
              </FormControl>
            </div>
            
            <div style={{ margin: '15px' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ marginBottom: '5px' }}>
                  Indicado no Prazo?{' '}
                </FormLabel>
                <RadioGroup
                  aria-label="Indicado no Prazo"
                  name="indication_on_time"
                  value={deadlineForm?.indication_on_time}
                  row
                  onChange={(e) => setDeadlineForm((state) => ({ ...state, indication_on_time: e.target.value }))}>
                  <FormControlLabel value="" control={<Radio />} label="Inativo" />
                  <FormControlLabel value="0" control={<Radio />} label="Não" />
                  <FormControlLabel value="1" control={<Radio />} label="Sim" />
                </RadioGroup>
              </FormControl>
            </div>
            
            <div style={{ margin: '15px' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ marginBottom: '5px' }}>
                  Atendimento no Prazo?{' '}
                </FormLabel>
                <RadioGroup
                  aria-label="Atendimento no Prazo"
                  name="attendance_on_time"
                  value={deadlineForm?.attendance_on_time}
                  row
                  onChange={(e) => setDeadlineForm((state) => ({ ...state, attendance_on_time: e.target.value }))}>
                  <FormControlLabel value="" control={<Radio />} label="Inativo" />
                  <FormControlLabel value="0" control={<Radio />} label="Não" />
                  <FormControlLabel value="1" control={<Radio />} label="Sim" />
                </RadioGroup>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Salvar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
