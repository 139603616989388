/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { createContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { FullPageLoading } from '../../components/FullPageLoading';
import { Loading } from '../../components/Loading';
import useApp from '../../hooks/useApp';
import LoadingPage from '../../pages/LoadingPage';
import api from '../../service/api';
import { getUserInfo, isAuthenticated, logout } from '../../service/auth.service';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { dashboard_check, setState } = useApp();

  const [user, setUser] = useState({
    name: '',
    phone: '',
    id: '',
    role: '',
    channel: '',
    partners: [],
    technical_team: {
      id: '',
      description: '',
      channels: [],
    },
    data: {},
  });

  const clearUser = () => {
    setUser({
      name: '',
      phone: '',
      id: '',
      role: '',
      channel: '',
      partners: [],
      technical_team: {
        id: '',
        description: '',
        channels: [],
      },
      data: {},
    })
  }

  async function getCustomLogoConfig() {
    const { data } = await api.get('/getCustomLogoConfig');
    const logoM3tas = "logoM3tas"
    
    if (data.logoVertical == null)
      data.logoVertical = logoM3tas;
    if (data.logoHorizontal == null)
      data.logoHorizontal = logoM3tas;
    if (data.logoImg == null)
      data.logoImg = logoM3tas;
    if (data.logoBandeiras == null)
      data.logoBandeiras = logoM3tas;

    localStorage.setItem("logoVertical", data.logoVertical);
    localStorage.setItem("logoHorizontal", data.logoHorizontal);
    localStorage.setItem("logoImg", data.logoImg);
    localStorage.setItem("logoBandeiras", data.logoBandeiras);
  } 

  async function getVisibilityConfig() {
    const { data } = await api.get('/getVisibilityConfig');

    localStorage.setItem("menuItemsVisibility", data.menuItemsVisibility);
  } 

  async function getWhatsAppConfig() {
    const { data } = await api.get('/getWhatsAppProviderConfig');

    localStorage.setItem("whatsAppProvider", data.provider);
    localStorage.setItem("whatsAppProviderApiKey", data.apiKey);
    localStorage.setItem("whatsAppProviderCompany", data.company);
  }

  async function getCustomFlowConfig() {
    const { data } = await api.get('/getCustomFlowConfig');

    localStorage.setItem("corujaFlow", data.corujaFlow == 'True');
  }

  const dashboard = async () => {
    await getVisibilityConfig();
    await getCustomLogoConfig();
    await getCustomFlowConfig();

    const { data } = await api.get('/dashboard');

    setState('dashboard_check', false);

    setState('channels', data.channels);
    setState('partners', data.partners);
    setState('lead_sold', data.leads_sold);
    setState('insurances', data.insurances);
    setState('lead_states', data.leads_states);
    setState('lead_status', data.leads_status);
    setState('lead_lost_sale', data.leads_lost_sale);
    setState('technical_teams', data.technical_teams);
    setState('first_call_on_time', data.first_call_on_time[0]?.value);
    setState('competitor_brokers', data.competitor_brokers);
    setState('lead_contact_type', data.leads_contact_type);
    setState('indication_on_time', data.indication_on_time[0]?.value);
    setState('time_to_finish_lead', data.time_to_finish_lead[0]?.value);
    setState('lead_service_status', data.leads_service_status);
    setState('time_to_capture_lead', data.time_to_capture_lead[0]?.value);
    setState('refresh_lead_seconds', data.refresh_lead_seconds[0]?.value);
    setState('finished_leads_days_to_show', data.finished_leads_days_to_show[0]?.value);
    setState('endorsement_leads_days_to_show', data.endorsement_leads_days_to_show[0]?.value);
    setState('lost_leads_last_year_days_to_show', data.lost_leads_last_year_days_to_show);

    setState('dashboard_check', true);

    await getWhatsAppConfig();
  };

  const logoutUser = async () => {
    await logout();
    await clearUser()
  }

  const updateUser = async () => {
    try {
      const resp = await getUserInfo();
      setUser({
        data: resp,
        id: resp?.id,
        name: resp?.name,
        phone: resp?.phone,
        role: resp?.roles[0].id,
        channel: resp?.channel.id,
        partners: [...resp?.partners],
        technical_team: resp.technical_team
          ? {
            id: resp?.technical_team?.id,
            description: resp?.technical_team?.description,
            channels: [...resp?.technical_team?.channels],
          }
          : {},
      });
      dashboard()
      // if (resp?.roles[0].id <= 3) await dashboard();
    } catch (error) {
      logout();
    }
  };

  useEffect(() => {
    async function getUser() {
      await updateUser();
      await dashboard();
    }
    async function getDashboard() {
      await dashboard();
    }

    if (isAuthenticated()) {
      if (!user.id) {
        getUser();
        return;
      }
      if (user.role >= 3 && !dashboard_check) {
        getDashboard();
      }
    }else{
      clearUser();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, updateUser, clearUser, logoutUser }}>
      {!isAuthenticated() || (!!user.id && dashboard_check ) ? <>{children}</> : <FullPageLoading />}
    </AuthContext.Provider>
  );
};
