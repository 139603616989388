/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import { FormGroup } from '../styles';
import { TextField, AutocompleteField, DateField } from '../../FormFields';
import { CommonPraticeSportInsuranceQuestions } from './commonQuestions';


export const InsuranceTravelReasonArray = [
    { name: 'Lazer', id: 1 },
    { name: 'Estudo', id: 2 },
    { name: 'Profissional', id: 2 },
]

export function TravelQuestions({
    onChange,
    onChangeAutoComplete,
    insuranceTravelPassengersAmout,
    insuranceTravelAllAges,
    insuranceTravelFromCountry,
    insuranceTravelToCountry,
    insuranceTravelGoingDate,
    insuranceTravelReturnDate,
    insuranceTravelReason,
    insurancePracticesSports,
})
{
    return (
        <>
        <FormGroup dark>
            <NumericFormat
                label="Quantidade de passageiros"
                customInput={TextField}
                decimalScale={0}
                autoComplete="off"
                value={insuranceTravelPassengersAmout || ''}
                name="insurance_travel_passengersAmout"
                onChange={onChange}
            />
            <TextField
                value={insuranceTravelAllAges || ''}
                onChange={onChange}
                varibleName="insurance_travel_allAges"
                placeholder="Idade"
                className="control ml"
                label="Idade de todos os passageiros"
            />
            <TextField
                value={insuranceTravelFromCountry || ''}
                onChange={onChange}
                varibleName="insurance_travel_fromCountry"
                placeholder="Origem"
                className="control ml"
                label="País de origem"
            />
            <TextField
                value={insuranceTravelToCountry || ''}
                onChange={onChange}
                varibleName="insurance_travel_toCountry"
                placeholder="Destino"
                className="control ml"
                label="País de destino"
            />
        </FormGroup>

        <FormGroup dark>
            <TextField
                name="insurance_travel_goingDate"
                label="Data de ida"
                type="datetime-local"
                value={insuranceTravelGoingDate || ''}
                onChange={onChange}
                InputLabelProps={{shrink: true}}
            />
            <TextField
                name="insurance_travel_returnDate"
                label="Data de volta"
                type="datetime-local"
                value={insuranceTravelReturnDate || ''}
                onChange={onChange}
                InputLabelProps={{shrink: true}}
            />
            <AutocompleteField
                medium
                value={insuranceTravelReason || null}
                onChange={onChangeAutoComplete}
                valueText={insuranceTravelReason ? insuranceTravelReason.name : ''}
                options={InsuranceTravelReasonArray}
                varibleName="insurance_travel_reason"
                className="control ms"
                label="Motivo da viagem"
            />
            <CommonPraticeSportInsuranceQuestions
                onChange={onChange}
                insurancePracticesSports={insurancePracticesSports}
            />
         </FormGroup>
       </>
    )
}

TravelQuestions.propTypes = {
    onChange: P.objectOf(P.any).isRequired,
    onChangeAutoComplete: P.objectOf(P.any).isRequired,
    insuranceTravelPassengersAmout: P.number.isRequired,
    insuranceTravelAllAges: P.string.isRequired,
    insuranceTravelFromCountry: P.string.isRequired,
    insuranceTravelToCountry: P.string.isRequired,
    insuranceTravelGoingDate: P.string.isRequired,
    insuranceTravelReturnDate: P.string.isRequired,
    insuranceTravelReason: P.objectOf(P.any).isRequired,
    insurancePracticesSports: P.bool.isRequired,
}