import { useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import P, { shape } from 'prop-types';
import BasicFormRegister from '../BasicForm';
import ConfirmationDialog from '../ConfirmationDialog';

const TableList = ({ listItem, deleteData, editData, title }) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [id, setId] = useState(null);
  const [value, setValue] = useState('');
  const [order, setOrder] = useState(null);
  const [dialog, setDialog] = useState({
    msg: '',
    title: '',
  });

  const deleteFunc = () => {
    deleteData(id);
  };

  const handleDelete = (idItem, valueItem) => {
    setDialog({
      msg: `Tem certeza que deseja remover ${valueItem}?`,
      title: `Remover ${valueItem} da tabela ${title}`,
    });
    setId(idItem);
    setValue(valueItem);
    setOpenConfirm(true);
  };

  const handleEdit = (idItem, valueItem, ordenation) => {
    setId(idItem);
    setValue(valueItem);
    if(ordenation) setOrder(ordenation)
    setOpen(true);
  };

  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        msg={dialog.msg.length > 0 ? dialog.msg : 'Dialog'}
        title={dialog.title.length > 0 ? dialog.title : 'Dialog'}
        next={deleteFunc}
      />
      <BasicFormRegister
        open={open}
        setOpen={setOpen}
        action={editData}
        title={title}
        id={id}
        value={[value]}
        fields={['name']}
        order={order}
      />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="70%">Name</TableCell>
              {title === 'Parceiro' && <TableCell>Code_WF</TableCell>}
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItem !== null &&
              listItem.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.order ? item.order : item.id}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  {title === 'Parceiro' && (item.code_wf ? <TableCell>{item.code_wf}</TableCell> : <TableCell />)}
                  <TableCell align="right">
                    <IconButton onClick={() => handleEdit(item.id, item.name, item.order ? item.order : null)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(item.id, item.name, item.order ? item.order : null)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

TableList.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
  title: P.string.isRequired,
  deleteData: P.func.isRequired,
  editData: P.func.isRequired,
};

TableList.defaultProps = {
  listItem: null,
};

export default TableList;
