/* eslint-disable */
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

export const ButtonMenu = styled.button`
  && {
    border: none;
    display: flex;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    line-height: 1.3;
    min-width: 120px;
    padding: 6px 12px;
    user-select: none;
    text-align: center;
    border-radius: 6px;
    white-space: normal;
    align-items: center;
    text-decoration: none;
    background-color: #e7e7e7;
    flex-direction: column-reverse;
    transition: all .15s ease-in-out;
    font-family: 'DM Sans', sans-serif;
    .count {
      font-size: 18px;
      font-weight: 700;
    }
    &.blue {
      color: #2980b9;
      &.active,
      &:hover {
        background-color: #2980b9;
      }
    }
    &.purple {
      color: #8e44ad;
      &.active,
      &:hover {
        background-color: #8e44ad;
      }
    }
    &.green {
      color: #27ae60;
      &.active,
      &:hover {
        background-color: #27ae60;
      }
    }
    &.sea {
      color: #16a085;
      &.active,
      &:hover {
        background-color: #16a085;
      }
    }
    &.yellow {
      color: #f39c12;
      &.active,
      &:hover {
        background-color: #f39c12;
      }
    }
    &.gray {
      color: #7f8c8d;
      &.active,
      &:hover {
        background-color: #7f8c8d;
      }
    }
    &.orange {
      color: #d35400;
      &.active,
      &:hover {
        background-color: #d35400;
      }
    }
    &.active,
    &:hover {
      color: #fff;
      z-index: 100;
      position: relative;
      text-decoration: none;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {

    background-color: #e7e7e7;

    .sub-text {
      font-size: 12px;
      font-weight: 300;
      color: grey;
    }

    :focus {
      background-color: blue;

      .MuiListItemIcon-root,
      .MuiListItemText-primary {
        color: #fff;
      }
    }
  }
`;
