/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import ClientForm from '../../components/ClientForm';
import { Header, Container } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import BaseForm from '../../components/ExternalIndication/BaseForm';
import LoadingPage from '../LoadingPage';
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import { createClient } from '../../service/client.service';

const ExternalIndication = () => {
  const [open, setOpen] = useState(false);
  const { values, getters, creators } = useContext(MainContext);


  const [filters, setFilters] = useState({
    partner: null,
    leadNum: null,
  });

  useEffect(() => {
    getters.getInsurances();
  }, [open]);

  const { data: userID } = useSWR('/tokenconfirm', variantFetcher);

  return (
    <>
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
          <Container>
            <ClientForm open={open} setOpen={setOpen} action={createClient} title="Clientes"/>
            <Header>
              <h1>Indicação externa</h1>
            </Header>
            <BaseForm setFilters={setFilters} withAuth inviter_id={userID?.id}/>
            <Paper elevation={3}>
            </Paper>
          </Container>
        </motion.div>
    </>
  );
};

export default ExternalIndication;
