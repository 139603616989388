/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable */

import React, { useState, useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { InputAdornment, IconButton, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import { DateTime } from 'luxon';
import { AutocompleteField, MaskedTextField } from '../FormFields';
import { FormGroup } from '../LeadForm/styles';
import { maskDate } from '../../utils/masks';
import { DivBotoes, DivSeparacao } from './styles';
import api from '../../service/api';
import ModalBeneficiarios from './modalBeneficiarios';
import ModalHistoricoEndossos from './modalHistoricoEndosso';
import Sinistro from './sinistro'
import ModalHistoricoSinistro from './modalHistoricoSinistro';
import ModalArquivos from './modalArquivos';
import Endosso from './endosso';
import { formatarParaEstiloPTBR, scrollToElement, scrollToElementRef } from '../../hooks/Utils';
import { SN } from '../../mock/leadInfo.com';


export default function ClientForm({ open, setOpen, title, client }) {

  const [optionsSegurosAdquiridos, setOptionsSeguros] = useState({
    id: '',
    name: '',
    lead_id: ''
  });

  const [optionSelecionado, setOptionsSeguroSelecionado] = useState({
    id: '',
    name: '',
    lead_id: ''
  });

  const formatDateToBrazilian = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-');
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    }
    return dateString
  };

  const [endosso, setEndosso] = useState([{
    id: '',
    created_at: '',
    status: '',
    finish_date: '',
    changes: '',
    insurance_id: '',
    observation: '',
    lead_id: ''
  }]);

  const [cliente, setInfo] = useState({
    id: '',
    name: '',
    phone: '',
    cellphone: '',
    mail: '',
    cpf: '',
    rg: '',
    address: '',
    cep: '',
    city: '',
    comp: '',
    state: '',
    number: '',
    street: '',
    district: '',
    birth_date: '',
    client_details: '',
    gender: '',
    facebook: '',
    instagram: '',
    estado_civil: '',
    servidor_publico: '',
    nacionalidade: '',
    nome_de_recado: '',
    telefone_de_recado: '',
    celular_de_recado: '',
    profissao: '',
    maistodos_identificador: '',
    leads: [],
    competitorBrokers: [],
    beneficiarios: [],
    insurance: ''
  })
  const [seguro, setSeguro] = useState({
    data_que_se_tornou_cliente: '',
    vendedor_deste_cliente: '',
    seguradora: '',
    ativo_ou_inativo: '',
    valor_da_venda_assinatura_seguro: '',
    porcentagem_comissao: '',
    inicio_vigencia: '',
    fim_vigencia: '',
    assinatura_mensal: '',
    valor_comissao: '',
    valor_adesao: '',
    bem_segurado: '',
    forma_pagamento: '',
    quantas_vezes: '',
    renovar_assinar_cancelar: '',
    indicador_nome: '',
    indicador_telefone: '',
    responsavel_financeiro: '',
    observacoes: '',
    valor_comissao_contratual: ''
  });

  const calculoValorComissao = (valor, porcentagem) => {
    if (valor && porcentagem) {
      const percent = porcentagem / 100;
      const resultado = parseFloat(valor) * percent;
      return resultado.toFixed(2);
    }
    return valor;
  }

  const [openBeneficiarios, setOpenBeneficiarios] = useState(false);

  const [openHistoricoEndossos, setOpenHistoricoEndossos] = useState(false);
  const [openEndossoBlock, setOpenEndossoBlock] = useState(false);
  const [openNewEndosso, setOpenNewEndosso] = useState(false);


  const [openHistoricoSinistros, setOpenHistoricoSinistros] = useState(false);
  const [openSinistroBlock, setOpenSinistroBlock] = useState(false);
  const [openNewSinistro, setOpenNewSinistro] = useState(false);

  const [openApolices, setOpenApolices] = useState(false);
  const [apolicesLead, setOpenApolicesLeads] = useState([]);

  const [openPropostas, setOpenPropostas] = useState(false);
  const [propostasLead, setOpenPropostasLeads] = useState([]);

  const [openArquivos, setOpenSArquivos] = useState(false);
  const [openArquivosSinisto, setOpenSArquivosSinisto] = useState(false);
  const [openArquivosEndosso, setOpenSArquivosEndosso] = useState(false);
  const [arquivosLead, setOpenArquivosLeads] = useState([]);
  const [arquivosEndosso, setArquivosEndosso] = useState([]);
  const [arquivosSinistro, setArquivosSinistro] = useState([]);






  const handleOpenBeneficiarios = () => {
    setOpenBeneficiarios(true);
  }


  const calcularDiferenca = (dateString) => {
    if (dateString) {

      const today = new Date();
      const parts = dateString.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);

      const targetDate = new Date(year, month, day);
      const difference = targetDate - today;
      const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

      if (daysDifference > 15) {
        return 'ASSINATURA';
      }

      if (daysDifference >= 0) {
        return 'RENOVAR';
      }

      return 'VENCIDO'
    }
    return dateString;
  };

  const calcularValorVenda = (valor, vezes) => {
    const resultado = parseFloat(valor) * vezes;
    return resultado.toFixed(2);
  };


  const preencherSeguroAdquirido = async (lead) => {
    if (lead && lead != '') {
      const dashboard = await api.get(`dashboard/`);
      const competitorBrokers = dashboard.data.competitor_brokers;
      const seguradora = competitorBrokers.find((item) => item.var_id == lead.competitor_broker)
      const inicio_vigencia = formatDateToBrazilian(lead.term_begin);
      const assinaturaRecorrente = lead.assinatura_recorrente.find((item) => item.identificadorLead === lead.id)
      const fim_vigencia = formatDateToBrazilian(assinaturaRecorrente?.fimVigencia);
      const valor_comissao = calculoValorComissao((assinaturaRecorrente?.valorAssinatura || 0), lead.commission_percentage) || 0;
      const valor_comissao_contratual = valor_comissao * assinaturaRecorrente?.periodoMeses;
      setSeguro({
        data_que_se_tornou_cliente: lead?.create_lead_date || '',
        vendedor_deste_cliente: lead?.broker?.name || '',
        seguradora: seguradora?.value || '',
        ativo_ou_inativo: assinaturaRecorrente?.status == "paid" ? "Ativo" : "Inativo" || '',
        valor_comissao: valor_comissao,
        porcentagem_comissao: lead.commission_percentage || 0,
        inicio_vigencia: inicio_vigencia || '',
        fim_vigencia: fim_vigencia || '',
        assinatura_mensal: (assinaturaRecorrente?.valorAssinatura || '0.00') || '',
        valor_da_venda_assinatura_seguro: calcularValorVenda((assinaturaRecorrente?.valorAssinatura || 0), assinaturaRecorrente?.periodoMeses) || '',
        valor_adesao: assinaturaRecorrente?.valorAdesao || '0.00',
        bem_segurado: lead.product_details?.details || '',
        forma_pagamento: 'Cartão de Crédito',
        quantas_vezes: assinaturaRecorrente?.periodoMeses || '',
        renovar_assinar_cancelar: calcularDiferenca(fim_vigencia),
        indicador_nome: lead.inviter?.name || '',
        indicador_telefone: lead.inviter?.phone || '',
        responsavel_financeiro: lead.financialData || '',
        observacoes: lead.additional_information || '',
        valor_comissao_contratual: valor_comissao_contratual || '0.00'
      });
    } else {
      setSeguro({
        data_que_se_tornou_cliente: '',
        vendedor_deste_cliente: '',
        seguradora: '',
        ativo_ou_inativo: '',
        valor_comissao: 0,
        porcentagem_comissao: 0,
        inicio_vigencia: '',
        fim_vigencia: '',
        assinatura_mensal: '',
        valor_da_venda_assinatura_seguro: '',
        valor_adesao: '0.00',
        bem_segurado: '',
        forma_pagamento: '',
        quantas_vezes: '',
        renovar_assinar_cancelar: '',
        indicador_nome: '',
        indicador_telefone: '',
        responsavel_financeiro: '',
        observacoes: '',
        valor_comissao_contratual: '0.00'

      });
    }
  }

  const getSeguros = async (leads) => {
    let resultados = [];
    resultados = leads.map((lead) => {
      if (lead.insurance) {
        return { id: lead.insurance.id, name: lead.insurance.name, lead_id: lead.id };
      }
      return {};
    });

    if (resultados.length > 0) {
      setOptionsSeguros(resultados);
      const ultimoLead = resultados[resultados.length - 1];
      setOptionsSeguroSelecionado({
        id: ultimoLead.id,
        name: ultimoLead.name
      })
      const lead = leads.find((item) => item.id == ultimoLead.lead_id)
      preencherSeguroAdquirido(lead)
    }
    else {
      preencherSeguroAdquirido(null)
    }

    await getEndossos(client.id, resultados);

  }
  const [endossosEmAberto, setEndossosEmAberto] = useState([]);

  const getEndossos = async (id, seguros) => {
    const { data } = await api.get(`endorsements/list/v2?clientId=${id}`);
    const endossos = data.data;

    const resultado = endossos.map((item) =>
    ({
      id: item.id || '',
      created_at: item.created_at || '',
      status: item.status || '',
      finish_date: item.finish_date || '',
      changes: item.changes || '',
      insurance_id: item.insurance_id || '',
      insurance: item.insurance || '',
      lead_id: item.lead_id || '',
      observation: item.observation || ''
    }));

    setEndosso(resultado)

    const temEndossoAberto = resultado.some((endosso) => endosso.status === 'Aberto')

    if (temEndossoAberto) {
      const endossosAbertos = resultado.filter((endosso) => endosso.status === 'Aberto')
      setEndossosEmAberto(endossosAbertos);
    }
  }

  const [sinistros, setSinistros] = useState([]);
  const [sinistroEmAberto, setSinistroAberto] = useState([]);



  const getSinistros = async (id) => {
    const { data } = await api.get(`sinisters/list/v2?clientId=${id}`);
    const response = data.data;


    setSinistros(response);
    const sinistroAberto = response.filter((sinistro) => sinistro.status != 'Finalizado' && !sinistro.finish_date)
    setSinistroAberto(sinistroAberto)

  }

  const entering = async () => {
    try {
      const dataFiles = await api.get(`leads/findFilesByClientId?client_id=${client.id}`);
      const SinistroFiles = await api.get(`sinisters/files?identificadorCliente=${client.id}`);
      const EndossoFiles = await api.get(`endorsements/files?identificadorCliente=${client.id}`);
      
      const files = dataFiles.data;

      if (files.length > 0) {
        const leadsComProposta = files
          .filter(item => item.proposta === true)
          .map(item => item.lead);

        setOpenPropostasLeads(leadsComProposta);

        const leadsComApolice = files
          .filter(item => item.apolice === true)
          .map(item => item.lead);

        setOpenApolicesLeads(leadsComApolice);

        const leadsComOf = files
          .filter(item => item.of === true)
          .map(item => item.lead);

        setOpenArquivosLeads(leadsComOf)
        setArquivosEndosso(EndossoFiles.data)
        setArquivosSinistro(SinistroFiles.data)
      }



      const responseLead = await api.get(`leads/list-broker?client_id=${client.id}`);
      let response;

      if (responseLead.data.data.leads.length > 0)
        response = responseLead.data.data.leads[0].client;
      else {
        const res = await api.get(`client/${client.id}`);
        response = res.data.data;

      }
      setInfo({
        id: client.id || '',
        name: response.name || '',
        phone: response.phone || '',
        cellphone: response.cellphone || '',
        email: response.mail || '',
        cpf: response.cpf || '',
        rg: response.rg || '',
        address: response.address || '',
        cep: response.address?.cep || '',
        city: response.address?.city || '',
        comp: response.address?.comp || '',
        state: response.address?.state || '',
        number: response.address?.number || '',
        street: response.address?.street || '',
        district: response.address?.district || '',
        birth_date: formatDateToBrazilian(response.birth_date) || '',
        client_details: response.clieent_details || '',
        gender: response.gender || '',
        facebook: response.facebook || '',
        instagram: response.instagram || '',
        estado_civil: response.estado_civil || '',
        servidor_publico: SN.find((i) => i.id == response?.servidor_publico) || '',
        nacionalidade: response.nacionalidade || '',
        nome_de_recado: response.nome_de_recado || '',
        telefone_de_recado: response.telefone_de_recado || '',
        celular_de_recado: response.celular_de_recado || '',
        profissao: response.profissao || '',
        maistodos_identificador: response.maistodos_identificador || '',
        leads: responseLead.data.data.leads || '',
        beneficiarios: responseLead.data.data?.cliente_beneficiarios || '',
        insurance: responseLead.data.data.insurance || ''
      });



      if (responseLead.data.data.leads.length > 0) {
        await getSeguros(responseLead.data.data.leads);
        await getSinistros(client.id);
      }
      else {
        setOptionsSeguros([{ id: 0, name: '', lead_id: 0 }]);
        setOptionsSeguroSelecionado({
          id: 0, name: '', lead_id: 0
        })
        preencherSeguroAdquirido(null)
      }


    } catch (error) {
      toast.error("Erro ao obter dados do cliente.")
      console.log("Erro ao obter dados do cliente: ", error)
    }

  }



  const handleClose = () => {
    setOpen(false);
  };

  const onChangeSeguro = (event, newValue, variable) => {

    let resultado = { id: 0, name: '', lead_id: 0 };
    if (newValue && newValue != undefined)
      resultado = optionsSegurosAdquiridos.find((item) => newValue.id === item.id)

    setOptionsSeguroSelecionado({
      id: resultado.id,
      name: resultado.name,
      lead_id: resultado.lead_id
    })

    if (resultado.lead_id != 0) {
      const lead = cliente.leads.find((item) => resultado.lead_id == item.id)
      preencherSeguroAdquirido(lead)
    } else {
      preencherSeguroAdquirido(null)
    }
  };


  const isActive = () => {
    let active = false;
    const now = DateTime.now();
    const oneYearAgo = DateTime.now().plus({ years: 1 });
    cliente.leads.forEach((l) => {
      if (l.sold == 1 && l.end_lead_date && DateTime.fromISO(l.end_lead_date.replace(' ', 'T')) < oneYearAgo) active = true;
      if (l.channel_id == 6 && l.create_lead_date && DateTime.fromISO(l.create_lead_date.replace(' ', 'T')) > now.minus({ days: 1 })) active = true;
    });
    return active;
  };

  const StyledBoxesHeader = {
    marginTop: 16,
    marginBottom: 8,
    marginRight: 6,
    marginLeft: 12,
  };

  const StyledBoxes = {
    marginTop: 16,
    marginBottom: 8,
    marginRight: 6,
    marginLeft: 6,
  };
  const StyledBoxesWidth = {
    marginTop: 16,
    marginBottom: 8,
    marginRight: 6,
    marginLeft: 6,
    width: 280,
  };
  const endossoSectionRef = useRef(null);
  const sinistroSectionRef = useRef(null);

  const openEndossoBlockDiv = async () => {
    setOpenEndossoBlock(true)
    setOpenNewEndosso(!openNewEndosso)
  }



  const openSinistroBlockDiv = async () => {
    setOpenSinistroBlock(true)
    setOpenNewSinistro(!openNewSinistro)
  }

  return (

    <div>

      <Dialog
        onEntering={entering}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="100%">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <h3>{title}</h3>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>

        </DialogTitle>

        <DivSeparacao >
          <DivBotoes>
            <Button
              onClick={() => { setOpenApolices(!openApolices) }}
              variant="contained"
              color="default"
              className="botoes-cliente"
              component="label"

            >
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <CloudUploadIcon style={{ alignSelf: 'flex-start' }} />
                <p style={{ textAlign: 'center', width: '100%' }}>Apólice(s) Anexada(s)</p>
              </div>
            </Button>
            <ModalArquivos title='Apólice(s) Anexada(s)' fileName='apolice' open={openApolices} setOpen={setOpenApolices} leads={apolicesLead} cliente={cliente} />

            <Button
              onClick={() => { setOpenPropostas(!openPropostas) }}
              variant="contained"
              color="default"
              className="of-file botoes-cliente"
              component="label"
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <CloudUploadIcon style={{ alignSelf: 'flex-start' }} />
                <p style={{ textAlign: 'center', width: '100%' }}>Proposta(s) Anexada(s)</p>
              </div>

            </Button>
            <ModalArquivos title='Proposta(s) Anexada(s)' fileName='proposta' open={openPropostas} setOpen={setOpenPropostas} leads={propostasLead} cliente={cliente}/>


            <Button
              onClick={() => { setOpenSArquivos(!openArquivos) }}
              variant="contained"
              color="default"
              className="of-file botoes-cliente"
              component="label"
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <CloudUploadIcon style={{ alignSelf: 'flex-start' }} />
                <p style={{ textAlign: 'center', width: '100%' }}> Arquivos e Prints Anexados</p>
              </div>
            </Button>
            <ModalArquivos title='Arquivos e Prints Anexados' fileName='OF' open={openArquivos} setOpen={setOpenSArquivos} leads={arquivosLead} cliente={cliente} />
            <Button
              onClick={() => { setOpenSArquivosEndosso(!openArquivosEndosso) }}
              variant="contained"
              color="default"
              className="of-file botoes-cliente"
              component="label"
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <CloudUploadIcon style={{ alignSelf: 'flex-start' }} />
                <p style={{ textAlign: 'center', width: '100%' }}>Anexos Endosso</p>
              </div>
            </Button>
            <ModalArquivos title='Anexos Endosso' fileName='/endorsements/file' open={openArquivosEndosso} setOpen={setOpenSArquivosEndosso} leads={arquivosEndosso} cliente={cliente} especial />
            <Button
              onClick={() => { setOpenSArquivosSinisto(!openArquivosSinisto) }}
              variant="contained"
              color="default"
              className="of-file botoes-cliente"
              component="label"
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <CloudUploadIcon style={{ alignSelf: 'flex-start' }} />
                <p style={{ textAlign: 'center', width: '100%' }}> Anexos Sinistro</p>
              </div>
            </Button>
            <ModalArquivos title='Anexos Sinistro' fileName='/sinisters/file' open={openArquivosSinisto} setOpen={setOpenSArquivosSinisto} leads={arquivosSinistro} cliente={cliente} especial />

            <Button
              className='botoes-cliente'
              onClick={async () => {
                await openEndossoBlockDiv()
                scrollToElementRef(endossoSectionRef)
              }}
              variant="contained"
              color="default">
              Incluir Endosso
            </Button>

            <Button
              className='botoes-cliente'
              onClick={async () => {
                await openSinistroBlockDiv();
                scrollToElementRef(sinistroSectionRef)
              }}
              variant="contained"
              color="default">
              Incluir Sinistro
            </Button>

            <Button
              className='botoes-cliente'
              onClick={handleOpenBeneficiarios}
              variant="contained"
              color="default">
              Beneficiário(s)
            </Button>
            <ModalBeneficiarios title="Beneficiários" open={openBeneficiarios} setOpen={setOpenBeneficiarios} cliente={cliente} />
          

          </DivBotoes>
          <form style={{ width: '85%' }}>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Nome do cliente"
                type="name"
                value={cliente.name}
                disabled
                style={{
                  width: '40%', marginTop: 16,
                  marginBottom: 8,
                  marginRight: 6,
                  marginLeft: 12,
                }}
              />
              <MaskedTextField
                mask={
                  cliente.cpf && cliente.cpf.replace(/[^\d]/g, '').length < 12 ? '999.999.999-99' : '99.999.999/9999-99'
                }
                variant="standard"
                value={cliente.cpf || ''}
                varibleName="cpf"
                label="CPF"
                disabled
                style={StyledBoxesHeader}
              />

              <TextField
                variant="standard"
                value={isActive() ? 'Ativo' : 'Inativo'}
                varibleName="status_client"
                label="Status:"
                style={StyledBoxesHeader}
                disabled
              />

              <Paper style={StyledBoxes}>
                <FormGroup>

                  <TextField
                    variant="outlined"
                    label="RG"
                    value={cliente.rg || ''}
                    varibleName='rg'
                    style={{
                      width: '20%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />
                  <TextField

                    variant="outlined"
                    label="E-mail"
                    type="email"
                    varibleName='email'
                    value={cliente.email || ''}

                    style={{
                      width: '60%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />

                  <TextField
                    variant="outlined"
                    label="Data de Nascimento:"
                    value={cliente.birth_date || ''}
                    varibleName='birth_date'
                    style={{
                      width: '20%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />

                </FormGroup>
                <FormGroup>
                  <TextField
                    variant="outlined"
                    label="Estado Civil"
                    value={cliente.estado_civil}
                    varibleName='estado_civil'
                    style={{
                      width: '33%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />

                  <MaskedTextField
                    mask={
                      cliente.phone && cliente.phone.replace(/[^\d]/g, '').length < 11 ? '(99) 9999-99999' : '(99) 99999-9999'
                    }
                    variant="outlined"
                    value={cliente.phone || ''}
                    varibleName="phone"
                    label="Telefone"
                    style={StyledBoxesHeader}
                    disabled />

                  <MaskedTextField
                    mask={
                      cliente.cellphone && cliente.cellphone.replace(/[^\d]/g, '').length < 11
                        ? '(99) 9999-99999'
                        : '(99) 99999-9999'
                    }
                    variant="outlined"
                    value={cliente.cellphone || ''}
                    varibleName="cellphone"
                    label="Celular"
                    style={StyledBoxesHeader}
                    disabled
                  />


                </FormGroup>

                <FormGroup>

                  <TextField
                    variant="outlined"
                    label="Servidor Público"
                    varibleName='servidor_publico'
                    value={cliente.servidor_publico.name || ''}
                    style={{
                      width: '33%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />

                  <TextField
                    variant="outlined"
                    label="Sexo"
                    value={cliente.gender || ''}
                    varibleName='gender'
                    style={{
                      width: '33%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />

                  <TextField
                    variant="outlined"
                    label="Nacionalidade"
                    value={cliente.nacionalidade || ''}
                    varibleName='nacionalidade'
                    style={{
                      width: '33%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />
                </FormGroup>


                <FormGroup>

                  <TextField
                    variant="outlined"
                    label="Nome de Recado"
                    value={cliente.nome_de_recado || ''}
                    varibleName='nome_de_recado'
                    style={{
                      width: '40%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />

                  <MaskedTextField
                    mask={
                      cliente.telefone_de_recado && cliente.telefone_de_recado.replace(/[^\d]/g, '').length < 11
                        ? '(99) 9999-99999'
                        : '(99) 99999-9999'
                    }
                    variant="outlined"
                    value={cliente.telefone_de_recado || ''}
                    varibleName="phone_recado"
                    label="Telefone de Recado"
                    style={StyledBoxesHeader}
                    disabled
                  />

                  <MaskedTextField
                    mask={
                      cliente.celular_de_recado && cliente.celular_de_recado.replace(/[^\d]/g, '').length < 11
                        ? '(99) 9999-99999'
                        : '(99) 99999-9999'
                    }
                    variant="outlined"
                    value={cliente.celular_de_recado || ''}
                    varibleName="celular_de_recado"
                    label="Celular de Recado"
                    style={StyledBoxesHeader}
                    disabled
                  />

                </FormGroup>

                <FormGroup>
                  <TextField
                    variant="outlined"
                    label="Profissão"
                    value={cliente.profissao}
                    varibleName='profissao'
                    style={{
                      width: '33%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />
                  <TextField
                    variant="outlined"
                    label="Facebook"
                    value={cliente.facebook}
                    varibleName='facebook'
                    style={{
                      width: '33%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />
                  <TextField
                    variant="outlined"
                    label="Instagram"
                    value={cliente.instagram}
                    varibleName='instagram'
                    style={{
                      width: '33%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled
                  />


                </FormGroup>
                <FormGroup>


                  <MaskedTextField
                    mask="99999-999"
                    variant="outlined"
                    value={cliente?.cep}
                    varibleName="cep"
                    label="CEP"
                    style={{ marginTop: 16, marginBottom: 8, marginRight: 6, marginLeft: 3, width: '60%' }}
                    disabled
                  />
                </FormGroup>
                <FormGroup>

                  <TextField
                    mask=""
                    value={cliente?.street}
                    variant="outlined"
                    varibleName="street"
                    label="Endereço"
                    style={{ marginTop: 16, marginBottom: 8, marginRight: 6, marginLeft: 8, width: '98%' }}
                    disabled
                  />
                </FormGroup>
                <FormGroup>

                  <TextField
                    variant="outlined"
                    value={cliente.number}
                    varibleName="number"
                    label="Número"
                    style={{
                      width: '20%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled
                  />


                  <TextField
                    variant="outlined"
                    value={cliente.district}
                    varibleName="district"
                    label="Bairro"
                    style={{
                      width: '20%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled
                  />

                  <TextField
                    variant="outlined"
                    value={cliente.comp}
                    varibleName="comp"
                    label="Complemento"
                    style={{
                      width: '60%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled
                  />
                </FormGroup>
                <FormGroup>

                  <TextField
                    value={cliente.city}
                    variant="outlined"
                    varibleName="city"
                    label="Cidade"
                    style={StyledBoxesWidth}
                    disabled
                  />

                  <MaskedTextField
                    mask="**"
                    variant="outlined"
                    value={cliente.state}
                    varibleName="state"
                    label="Estado"
                    style={StyledBoxesWidth}
                    disabled
                  />
                </FormGroup>

              </Paper>
              <Paper style={StyledBoxes}>
                <h2 style={StyledBoxes}>Seguros Adquiridos</h2>
                <FormGroup>
                  <AutocompleteField
                    medium
                    valueText={optionSelecionado ? optionSelecionado.name : ''}
                    options={optionsSegurosAdquiridos}
                    value={optionSelecionado}
                    varibleName="Seguro"
                    onChange={(event, newValue, variable) => onChangeSeguro(event, newValue, variable)}
                    label='Seguro'
                    style={StyledBoxesHeader}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    variant="outlined"
                    value={seguro.seguradora}
                    varibleName="seguradora"
                    label="Seguradora:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                  <TextField
                    variant="outlined"
                    value={seguro.data_que_se_tornou_cliente ? maskDate(seguro.data_que_se_tornou_cliente) : ''}
                    varibleName="data_que_se_tornou_cliente"
                    label="Data que se tornou cliente:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                  <TextField
                    variant="outlined"
                    value={seguro.vendedor_deste_cliente}
                    varibleName="vendedor_deste_cliente"
                    label="Vendedor deste cliente:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />



                </FormGroup>
                <FormGroup >
                  <TextField
                    variant="outlined"
                    value={seguro.ativo_ou_inativo}
                    varibleName="ativo_ou_inativo"
                    label="Ativo ou Inativo:"
                    style={{
                      width: '20%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />


                  <NumericFormat
                    variant="outlined"
                    customInput={TextField}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    value={formatarParaEstiloPTBR(seguro.valor_comissao)}
                    varibleName="valor_comissao"
                    label="Ganho de comissão em R$ (reais):"
                    style={{
                      width: '30%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                  <NumericFormat
                    variant="outlined"
                    customInput={TextField}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    value={formatarParaEstiloPTBR(seguro.valor_comissao_contratual)}
                    varibleName="valor_comissao_contratual"
                    label="Ganho contratual de comissão em R$ (reais):"
                    style={{
                      width: '30%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 6,
                    }}
                    disabled />

                  <NumericFormat
                    variant="outlined"
                    customInput={TextField}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    value={seguro.porcentagem_comissao}
                    varibleName="porcentagem_comissao"
                    label="Porcentagem comissão:"
                    style={{
                      width: '15%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                </FormGroup>

                <FormGroup >
                  <TextField
                    variant="outlined"
                    value={seguro.inicio_vigencia}
                    varibleName="inicio_vigencia"
                    label="Início de vigência:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled
                  />

                  <TextField
                    variant="outlined"
                    value={seguro.fim_vigencia}
                    varibleName="fim_vigencia"
                    label="Final de vigência:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled
                  />

                  <NumericFormat
                    variant="outlined"
                    customInput={TextField}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    value={formatarParaEstiloPTBR(seguro.assinatura_mensal || '0')}
                    varibleName="assinatura_mensal"
                    label="Assinatura mensal:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled
                  />
                </FormGroup>

                <FormGroup >
                  <NumericFormat
                    variant="outlined"
                    customInput={TextField}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    value={formatarParaEstiloPTBR(seguro.valor_da_venda_assinatura_seguro)}
                    varibleName="valor_da_venda_assinatura_seguro"
                    label="Valor da venda:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                  <TextField
                    variant="outlined"
                    value={seguro.valor_adesao}
                    varibleName="valor_adesao"
                    label="Valor de Adesão:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                  <TextField
                    variant="outlined"
                    value={seguro.bem_segurado}
                    varibleName="bem_segurado"
                    label="Bem segurado:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                </FormGroup>

                <FormGroup>
                  <TextField
                    variant="outlined"
                    value={seguro.forma_pagamento}
                    varibleName="forma_pagamento"
                    label="Forma de pagamento:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                  <TextField
                    variant="outlined"
                    value={seguro.quantas_vezes}
                    varibleName="quantas_vezes"
                    label="Quantas vezes:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                  <TextField
                    variant="outlined"
                    value={seguro.renovar_assinar_cancelar}
                    varibleName="renovar_assinar_cancelar"
                    label="Renovar, Assinatura, ou Vencido:"
                    style={{
                      width: '33.333%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                </FormGroup>
              </Paper>
              <Paper style={StyledBoxes}>
                <h2 style={StyledBoxes}>Responsável Financeiro</h2>

                <FormGroup>
                  <TextField
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.numero_certificado || ''}
                    varibleName="numero_certificado"
                    label="Número de Certificado:"
                    style={{
                      width: '20%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                  <TextField
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.data_registro ? maskDate(seguro.responsavel_financeiro?.data_registro) : ''}
                    varibleName="data_registro"
                    label="Data Cadastro:"
                    style={{
                      width: '20%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                  <TextField

                    variant="outlined"
                    value={seguro.responsavel_financeiro?.nome || ''}
                    varibleName="nome"
                    label="Nome Completo:"
                    style={{
                      width: '60%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />

                  <MaskedTextField
                    mask={
                      seguro.responsavel_financeiro?.cpf && seguro.responsavel_financeiro?.cpf.replace(/[^\d]/g, '').length < 12 ? '999.999.999-99' : '99.999.999/9999-99'
                    }
                    variant='outlined'
                    value={seguro.responsavel_financeiro?.cpf || ''}
                    varibleName="cpf"
                    label="CPF:"
                    style={StyledBoxesHeader}
                    disabled />

                </FormGroup>
                <FormGroup>
                  <TextField
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.rg || ''}
                    varibleName="rg"
                    label="RG:"
                    style={{
                      width: '20%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                  <MaskedTextField
                    mask={
                      seguro.responsavel_financeiro?.telefone && seguro.responsavel_financeiro?.telefone.replace(/[^\d]/g, '').length < 11
                        ? '(99) 9999-99999'
                        : '(99) 99999-9999'
                    }
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.telefone || ''}
                    varibleName="telefone"
                    label="Telefone:"
                    style={StyledBoxesHeader}
                    disabled
                  />
                  <MaskedTextField
                    mask={
                      seguro.responsavel_financeiro?.celular && seguro.responsavel_financeiro?.celular.replace(/[^\d]/g, '').length < 11
                        ? '(99) 9999-99999'
                        : '(99) 99999-9999'
                    }
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.celular || ''}
                    varibleName="cellphone"
                    label="Celular:"
                    style={StyledBoxesHeader}
                    disabled
                  />
                  <TextField
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.email || ''}
                    varibleName="email"
                    label="Email:"
                    style={{
                      width: '30%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                </FormGroup>
                <FormGroup>
                  <MaskedTextField
                    mask="99999-999"
                    variant="outlined"
                    value={seguro?.responsavel_financeiro?.cep || ''}
                    varibleName="cep"
                    label="CEP:"
                    style={{
                      width: '90%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                  <TextField
                    variant="outlined"
                    value={seguro?.responsavel_financeiro?.endereco || ''}
                    varibleName="endereco"
                    label="Endereço:"
                    style={{
                      width: '60%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                  <TextField
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.numero || ''}
                    varibleName="numero"
                    label="Número:"
                    style={{
                      width: '30%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                </FormGroup>
                <FormGroup>
                  <TextField
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.complemento || ''}
                    varibleName="complemento"
                    label="Complemento:"
                    style={{
                      width: '30%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                  <TextField
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.bairro || ''}
                    varibleName="bairro"
                    label="Bairro:"
                    style={{
                      width: '30%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                  <TextField
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.cidade || ''}
                    varibleName="cidade"
                    label="Cidade:"
                    style={{
                      width: '30%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                  <TextField
                    variant="outlined"
                    value={seguro.responsavel_financeiro?.uf || ''}
                    varibleName="uf"
                    label="Estado:"
                    style={{
                      width: '30%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                </FormGroup>
              </Paper>
              <Paper style={StyledBoxes}>
                <h2 style={StyledBoxes}>Informações do Indicador:</h2>
                <FormGroup>
                  <TextField
                    variant="outlined"
                    value={seguro.indicador_nome}
                    varibleName="indicador_nome"
                    label="Indicador:"
                    style={{
                      width: '40%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                  <MaskedTextField
                    mask={
                      seguro.indicador_telefone && seguro.indicador_telefone.replace(/[^\d]/g, '').length < 11
                        ? '(99) 9999-99999'
                        : '(99) 99999-9999'
                    }
                    variant="outlined"
                    value={seguro.indicador_telefone || ''}
                    varibleName="cellphone"
                    label="Telefone:"
                    style={StyledBoxesHeader}
                    disabled
                  />
                </FormGroup>
              </Paper>
              <Paper style={{ padding: 12, marginTop: 20 }}>
                <h2 style={StyledBoxes}>Observações:</h2>
                <FormGroup>
                  <TextField
                    variant="outlined"
                    value={seguro.observacoes}
                    varibleName="observacoes"
                    label=""
                    multiline
                    rows={5}
                    style={{
                      width: '100%',
                      marginTop: 16,
                      marginBottom: 8,
                      marginRight: 6,
                      marginLeft: 12,
                    }}
                    disabled />
                </FormGroup>
                <div style={{ display: 'flex', width: "100%", gap: "5%", marginLeft: "2%" }}>
                  {endosso && endosso[0]?.id != '' && endosso.length > 0 && (
                    <Button
                      className='botoes-cliente'
                      onClick={() => { setOpenEndossoBlock(!openEndossoBlock) }}
                      variant="contained"
                      color="default"
                    >
                      Endosso
                    </Button>
                  )}
                  {sinistros && sinistros[0]?.id != '' && sinistros.length > 0 && (
                    <Button
                      className='botoes-cliente'
                      onClick={() => { setOpenSinistroBlock(!openSinistroBlock) }}
                      variant="contained"
                      color="default">
                      Sinistro
                    </Button>
                  )}
                </div>
              </Paper>

              {openEndossoBlock && (
                <>
                  <Paper ref={endossoSectionRef} style={{ padding: 12, marginTop: 20 }}>
                    <h2 >Dados do Endosso:</h2>
                    <div style={{ marginTop: 20, display: 'flex', gap: 15 }}>
                      <Button
                        className='botoes-cliente'
                        onClick={() => { setOpenHistoricoEndossos(true) }}
                        variant="contained"
                        color="default">
                        Visualizar Histórico Endossos
                      </Button>
                      <ModalHistoricoEndossos title='Histórico Endossos' open={openHistoricoEndossos} setOpen={setOpenHistoricoEndossos} endossos={endosso} />
                    </div>

                    {openNewEndosso && (<>
                      <Endosso cliente={cliente} segurosEndosso={optionsSegurosAdquiridos} entering={entering} />
                    </>
                    )}
                  </Paper>

                  {endossosEmAberto && endossosEmAberto.length > 0 && (
                    <>
                      <Paper style={{ padding: 12, marginTop: 20 }}>
                        <h2>Endossos em aberto:</h2>
                        {endossosEmAberto.map((endosso, index) => (
                          <>
                            <Endosso cliente={cliente} endosso={[endosso]} segurosEndosso={optionsSegurosAdquiridos} entering={entering} />

                          </>
                        ))}
                      </Paper>
                    </>
                  )}
                </>
              )}

              {openSinistroBlock && (
                <Paper ref={sinistroSectionRef} style={{ padding: 12, marginTop: 20 }}>
                  <h2>Dados do Sinistro:</h2>
                  <div style={{ marginTop: 20, display: 'flex', gap: 15 }}>
                    <Button
                      className='botoes-cliente'
                      onClick={() => { setOpenHistoricoSinistros(!openHistoricoSinistros) }}
                      variant="contained"
                      color="default">
                      Visualizar Histórico Sinistro
                    </Button>
                    <ModalHistoricoSinistro title='Histórico de Sinistros' sinistros={sinistros} open={openHistoricoSinistros} setOpen={setOpenHistoricoSinistros} />
                  </div>

                  {openNewSinistro && (
                    <Sinistro segurosAtivos={optionsSegurosAdquiridos} cliente={cliente} />
                  )}

                  {sinistroEmAberto && sinistroEmAberto.length > 0 && (
                    <>
                      <Paper style={{ padding: 12, marginTop: 20 }}>
                        <h2>Sinistros em aberto:</h2>
                        {
                          sinistroEmAberto.map((e) => (
                            <>
                              <Sinistro segurosAtivos={optionsSegurosAdquiridos} cliente={cliente} sinistro={e} />
                            </>
                          ))
                        }
                      </Paper>
                    </>
                  )}

                </Paper>
              )}


            </DialogContent>
          </form>
        </DivSeparacao>
      </Dialog >

    </div >
  );
}

ClientForm.propTypes = {
  open: P.bool.isRequired,
  title: P.string.isRequired,
  setOpen: P.func.isRequired,
  client: P.objectOf(P.string),
};

ClientForm.defaultProps = {
  client: null,
};
