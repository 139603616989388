/* eslint-disable no-continue */
// /* eslint-disable no-undef */
// /* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
const { DateTime, Duration } = require("luxon");

export default function UtilsTime(dateStart,dateEnd) {
  const createdTime = DateTime.fromISO(dateStart);
  const now = DateTime.fromISO(dateEnd);
  const dif = now.diff(createdTime, 'days').toObject();

  let diference = dif.days;
  let contDay = 0;
  let contTime = Duration.fromObject({ hours: 0 });
  const endFirstDay = createdTime.plus({ days: contDay }).set({ hour: 18, minute: 0, second: 0 });
  if(createdTime > endFirstDay){
    diference += 1;
    contDay += 1;
  }
  
  do {
    let startDay = createdTime.plus({ days: contDay }).set({ hour: 8, minute: 30, second: 0 });
    let endDay = createdTime.plus({ days: contDay }).set({ hour: 18, minute: 0, second: 0 });
    if (startDay < createdTime) startDay = createdTime;
    if (endDay > now || now > startDay) endDay = now;
    if (endDay.weekday < 6) {
      contTime = contTime.plus({ hours: endDay.diff(startDay, 'hours').hours });
    }
    contDay++;
  } while (contDay < dif.days);

  return { 
    hours: Math.floor(contTime.hours), 
    minute: Math.floor((contTime.hours - Math.floor(contTime.hours)) * 60), 
    seconds: Math.floor((((contTime.hours - Math.floor(contTime.hours)) * 60) - Math.floor((contTime.hours - Math.floor(contTime.hours)))  * 60))
  };
}
