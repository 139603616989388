import CryptoJS from 'crypto-js';

export const decriptURLSearchParams = (encryptedParams) => {
    const paramsBase64 = JSON.parse(decodeURIComponent(atob(encryptedParams)));
    const key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_CRIPTO_KEY);
    const iVector = CryptoJS.enc.Base64.parse(paramsBase64.iv);

    const decrypted = CryptoJS.AES.decrypt(paramsBase64.value, key, {
        iv: iVector,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8)
        .replace('s:37:"', "")
        .slice(0, -2);
}