/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import P from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
    Box, IconButton, Paper,
    Table, TableBody,
    TableCell,
    TableContainer, TableHead, TableRow, makeStyles,
    Collapse,
    Typography, Dialog, DialogTitle, Divider

} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { DivSeparacao } from './styles';
import { maskDateTime } from '../../utils/masks';
import { AutocompleteField, DateField, MaskedTextField, TextFieldAdorned } from '../FormFields';
import { Container, FormGroup, LoadingContainer, LoadingDiv } from '../LeadForm/styles';
import { Beneficiary } from '../LeadForm/beneficiary';
import api from '../../service/api';


function Row(props) {

    const StyledBoxesHeader = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 12,
    };

    const StyledBoxes = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 6,
    };
    const StyledBoxesWidth = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 6,
        width: 280,
    };

    const { row } = props;
    const [open, setOpen] = useState(false);

    const verificarSeIdEstaNaLista = (id) => {
        const arrayOptions = row.observation.split(',').map(item => Number(item));
        if (arrayOptions.length > 0) {
            const verificacao = arrayOptions.some(option => option === id);
            return verificacao;
        }
        return false;
    }

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        tableCell: {
            fontSize: '14px', // Defina o tamanho da fonte conforme necessário
            whiteSpace: 'nowrap', // Impede a quebra de linha
        },
    });

    const classes = useStyles();

    const handleChange = (key, event, obj) => {


    }

    const finalizarEndosso = async (e, val) => {

        try {
            const response = await api.put(`endorsements/finishEndorsement/${row.id}`);

            if (response.status === 200) {
                toast.success('Endosso finalizado com sucesso.');
            }
        } catch (error) {
            if (error.response) {
                console.log('Status do Erro:', error.response.status);
                console.log('Dados do Erro:', error.response.data);

                if (error.response.status == 400 && error.response.data.message === 'Endorsement finish not permited, one active endoirsement is required.') {
                    toast.error("Não existe um endosso aberto no momento.")
                    return;

                }
            } else if (error.request) {
                console.log('Erro de Resposta:', error.request);
            } else {
                console.log('Erro ao Configurar a Solicitação:', error.message);
            }

            toast.error("Erro ao finalizar endosso, favor sinalizar o suporte caso persista.")
        }
    }

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.tableCell} >{row.insurance ? row.insurance.name : ''}</TableCell>
                <TableCell className={classes.tableCell} >{maskDateTime(row.created_at)}</TableCell>
                <TableCell className={classes.tableCell} >{maskDateTime(row.finish_date || '')}</TableCell>
                <TableCell className={classes.tableCell} >{row.status}</TableCell>


            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h5" gutterBottom component="div">
                                Mudanças
                            </Typography>
                            
                            <form>
                                {verificarSeIdEstaNaLista(1) && (
                                    <TextField
                                        variant='outlined'
                                        disabled
                                        label="Nome do cliente"
                                        type="name"
                                        style={{
                                            width: '40%', marginTop: 16,
                                            marginBottom: 8,
                                            marginRight: 6,
                                            marginLeft: 12,
                                        }}
                                        value={row.changes.nome_cliente || undefined}
                                        
                                    />
                                )}
                                {verificarSeIdEstaNaLista(2) && (
                                    <>
                                        <FormGroup>
                                            <MaskedTextField
                                                disabled
                                                mask="99999-999"
                                                variant="outlined"
                                                varibleName="cep"
                                                label="CEP"
                                                value={row.changes.endereco.cep || undefined}
                                                style={{ marginTop: 16, marginBottom: 8, marginRight: 6, marginLeft: 3, width: '60%' }}

                                            />
                                        </FormGroup>
                                        <FormGroup>

                                            <TextField
                                                mask=""
                                                disabled
                                                variant="outlined"
                                                varibleName="street"
                                                label="Endereço"
                                                value={row.changes.endereco.logradouro || undefined}
                                                style={{ marginTop: 16, marginBottom: 8, marginRight: 6, marginLeft: 8, width: '98%' }}

                                            />
                                        </FormGroup>
                                        <FormGroup>

                                            <TextField
                                                variant="outlined"
                                                varibleName="number"
                                                label="Número"
                                                value={row.changes.endereco.numero || undefined}
                                                style={{
                                                    width: '20%',
                                                    marginTop: 16,
                                                    marginBottom: 8,
                                                    marginRight: 6,
                                                    marginLeft: 12,
                                                }}
                                                disabled


                                            />


                                            <TextField
                                                variant="outlined"
                                                varibleName="district"
                                                label="Bairro"
                                                value={row.changes.endereco.bairro || undefined}
                                                style={{
                                                    width: '20%',
                                                    marginTop: 16,
                                                    marginBottom: 8,
                                                    marginRight: 6,
                                                    marginLeft: 12,
                                                }}
                                                disabled

                                            />

                                            <TextField
                                                variant="outlined"
                                                varibleName="comp"
                                                label="Complemento"
                                                value={row.changes.endereco.complemento || undefined}
                                                style={{
                                                    width: '60%',
                                                    marginTop: 16,
                                                    marginBottom: 8,
                                                    marginRight: 6,
                                                    marginLeft: 12,
                                                }}
                                                disabled

                                            />
                                        </FormGroup>
                                        <FormGroup>

                                            <TextField
                                                variant="outlined"
                                                varibleName="city"
                                                label="Cidade"
                                                value={row.changes.endereco.cidade || undefined}
                                                style={StyledBoxesWidth}
                                                disabled
                                            />

                                            <MaskedTextField
                                                mask="**"
                                                variant="outlined"
                                                varibleName="state"
                                                label="Estado"
                                                value={row.changes.endereco.estado || undefined}
                                                style={StyledBoxesWidth}
                                                disabled
                                            />
                                        </FormGroup>
                                    </>
                                )}

                                {verificarSeIdEstaNaLista(3) && (

                                    <MaskedTextField
                                        mask={
                                            row.changes.telefone && row.changes.telefone.replace(/[^\d]/g, '').length < 11 ? '(99) 9999-99999' : '(99) 99999-9999'
                                        }
                                        variant="outlined"
                                        varibleName="phone"
                                        label="Telefone"
                                        value={row.changes.telefone || undefined}
                                        style={StyledBoxesHeader}
                                        disabled
                                    />


                                )}
                                {verificarSeIdEstaNaLista(4) && (

                                    <MaskedTextField
                                        mask={
                                            row.changes.celular && row.changes.celular.replace(/[^\d]/g, '').length < 11
                                                ? '(99) 9999-99999'
                                                : '(99) 99999-9999'
                                        }
                                        variant="outlined"
                                        varibleName="cellphone"
                                        label="Celular"
                                        value={row.changes.celular || undefined}
                                        style={StyledBoxesHeader}
                                        disabled
                                    />

                                )}
                                {verificarSeIdEstaNaLista(5) && (

                                    <TextField
                                        variant="outlined"
                                        varibleName="bem_segurado"
                                        label="Bem segurado:"
                                        value={row.changes.bem_segurado || undefined}
                                        style={{
                                            width: '33.333%',
                                            marginTop: 16,
                                            marginBottom: 8,
                                            marginRight: 6,
                                            marginLeft: 12,
                                        }}
                                        disabled
                                    />

                                )}

                                {verificarSeIdEstaNaLista(6) && (

                                    <div>
                                        <TextField
                                            variant="outlined"
                                            varibleName="inicio_vigencia"
                                            type="date"
                                            label="Início de vigência:"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={row.changes.vigencia?.inicio ? row.changes.vigencia.inicio : undefined}
                                            style={StyledBoxesHeader}
                                            disabled

                                        />

                                        <TextField
                                            variant="outlined"
                                            varibleName="fim_vigencia"
                                            
                                            type="date"
                                            label="Final de vigência:"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={row.changes.vigencia?.fim ? row.changes.vigencia.fim : undefined}
                                            style={StyledBoxesHeader}
                                            disabled

                                        />

                                    </div>
                                )}

                                {verificarSeIdEstaNaLista(7) && (

                                    row.changes.beneficiarios.map((e, i) => (
                                        <>
                                            <h4>Beneficiário {i + 1}:</h4>

                                            <Beneficiary beneficiary={e} index={i} handleChange={handleChange} disabledField />
                                            <Divider style={{marginBottom: 15}} />
                                        </>
                                    ))


                                )}


                            </form>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: P.objectOf(P.string).isRequired,
};





export default function ModalHistoricoEndossos({ title, open, setOpen, endossos }) {

    const handleClose = () => {
        setOpen(false);
    };


    const [openRow, setOpenRow] = useState(false);

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        tableCell: {
            fontSize: '18px', // Defina o tamanho da fonte conforme necessário
            whiteSpace: 'nowrap', // Impede a quebra de linha
        },
    });

    const classes = useStyles();


    return (
        <>
            <Dialog onClose={handleClose}
                aria-labelledby="form-dialog-title"
                open={open}
                maxWidth="md"
                fullWidth

                style={{ zIndex: 15615 }}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <h3>{title}</h3>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>

                </DialogTitle>
                <DivSeparacao>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell className={classes.tableCell}>Seguro</TableCell>
                                    <TableCell className={classes.tableCell}>Data Criação</TableCell>
                                    <TableCell className={classes.tableCell}>Data Finalização</TableCell>
                                    <TableCell className={classes.tableCell} >Status</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {endossos.sort((a, b) => b.id - a.id).map((row) => (
                                    <Row key={row.id} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DivSeparacao>

            </Dialog>
        </>
    )
}

ModalHistoricoEndossos.propTypes = {
    open: P.bool.isRequired,
    title: P.string.isRequired,
    setOpen: P.func.isRequired,
    endossos: P.objectOf(P.string),
};

ModalHistoricoEndossos.defaultProps = {
    endossos: null,
};

