/* eslint-disable */
import { useState } from 'react';
import { Nav, NavbarForm } from './styles';

const statusList = [
  { name: 'Atendida', value: 'ANSWERED' },
  { name: 'Não atendida', value: 'NO ANSWER' },
  { name: 'Ocupado', value: 'BUSY' },
  { name: 'Falha', value: 'FAILED' },
];

const DatePeriodFilter = ({ setFilters, filters }) => {
  function handleSubmit(event) {
    event.preventDefault();
    setFilters((f) => ({ ...f, ...filt }));
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setFilt({ ...filters, [e.target.name]: value });
  };

  const [filt, setFilt] = useState({
    name: '',
    email: '',
    isActive: false,
  });

  return (
    <Nav>
      <div className="filter">Filtros</div>
      <NavbarForm>
        <form className="form" onSubmit={handleSubmit}>

          <div className="client">
            <input
              type="text"
              name="name"
              className="select-form"
              placeholder="Cliente"
              value={filt.name}
              onChange={handleChange}
            />
          </div>
          <div className="email">
            <input
              name="email"
              className="select-form"
              placeholder="E-mail"
              value={filt.email}
              onChange={handleChange}
            />
          </div>
          <label className="is-active">
            <input type="checkbox" name="isActive" class="isActive" checked={filt.isActive} onChange={handleChange} />
            <p>Exibir somente ativos</p>
          </label>

          <div>
            <button className="button" onClick={() => setFilt({ name: '', email: '', isActive: false })}>
              Limpar
            </button>
            <button className="button" type="submit">
              Filtrar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
