import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';

export const ExportButton = styled.button`
    min-width: fit-content;
    ursor: pointer;
    color: rgb(255, 255, 255);
    border: none;
    font-weight: 700;
    padding: 8px 16px;
    text-align: center;
    border-radius: 5px;
    white-space: nowrap;
    vertical-align: middle;
    background-color: rgb(242, 101, 34);
    text-transform: uppercase;
    font-family: "DM Sans", sans-serif;
    :hover{
      background-color: rgb(220, 75, 34);
    }
`;

const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 1.5rem;
  }
`;