/* eslint-disable */
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import P from 'prop-types';
import { TableCommissionReport, TableRowHead, TableCellCR, StyledTableCell, H3, H4, H5, H6 } from './style'; 

const CommissionReportGroupTable = ({ colums, data, layout }) => {
  
    let commissionInsurance = '';
    let insuranceSoldValue = 0;
    let commissionValue = 0;
    let insuranceItens = 0;
    let sellerInsuranceValue = 0;
    let sellerCommissionValue = 0;
    let sellerItens = 0;
    let insuranceTotal = 0;
    let commissionTotal = 0;
    let totalItens = 0;
    let breakInsurance = false;

    const CheckCommissionInsurance = (commission) => {
        breakInsurance = commissionInsurance != commission.Seguradora;
        commissionInsurance = commission.Seguradora;        
    }

    const SumValues = (commission) => {        
        insuranceSoldValue += parseFloat(commission.insurance_sold_value);
        commissionValue += parseFloat(commission.commission_value);
        insuranceItens++;
        
        sellerInsuranceValue += parseFloat(commission.insurance_sold_value);
        sellerCommissionValue += parseFloat(commission.commission_value);
        sellerItens++;

        totalItens++;
    }


    const CheckBreakTotalInsurance = (commission, index) => {
        return index > 1 && 
            breakInsurance && 
            insuranceSoldValue > parseFloat(commission.insurance_sold_value);
    }

    const ResetInsuranceSoldValue = () => {
        insuranceSoldValue = 0;
        commissionValue = 0;
        insuranceItens = 0;
    }

    const ResetSellerValue = () => {
        sellerInsuranceValue = 0;
        sellerCommissionValue = 0;
        sellerItens = 0;
    }

    const FormatDate = (date) => {
        if (date == null) return '';

        let dateFormat = DateTime.fromFormat(date, 'yyyy-MM-dd');
        return dateFormat.toLocaleString(DateTime.DATE_SHORT);
    }

    const GetBreakInsuranceTableCell = (isuranceIndex) => {
        return (
            <>
            <TableRow key={isuranceIndex}>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell><H5>Total Seguradora:</H5></TableCell>
            <TableCell><H5>{insuranceItens}</H5></TableCell>
            <TableCell></TableCell>
            <TableCell><H5>R$ {insuranceSoldValue.toFixed(2)}</H5></TableCell>
            <TableCell>{}</TableCell>
            <TableCell><H5>{GetCommissionPercentMed(commissionValue, insuranceSoldValue)}</H5></TableCell>
            <TableCell><H5>R$ {commissionValue.toFixed(2)}</H5></TableCell>
            </TableRow>
            {ResetInsuranceSoldValue()}
            </>
        )
    }

    const GetBreakSellerTableCell = (isuranceIndex) => {
        return (
            <>
            <TableRow key={isuranceIndex}>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <StyledTableCell className='backSilver'><H5>Total Vendedor:</H5></StyledTableCell>
            <TableCell><H5>{sellerItens}</H5></TableCell>
            <TableCell></TableCell>
            <TableCell><H5>R$ {sellerInsuranceValue.toFixed(2)}</H5></TableCell>
            <TableCell>{}</TableCell>
            <TableCell><H5>{GetCommissionPercentMed(sellerCommissionValue, sellerInsuranceValue)}</H5></TableCell>
            <TableCell><H5>R$ {sellerCommissionValue.toFixed(2)}</H5></TableCell>
            </TableRow>
            {SumTotal()}
            {ResetInsuranceSoldValue()}
            {ResetSellerValue()}
            </>            
        )
    }

    const GetBreakTotalTableCell = (isuranceIndex) => {
        return (
            <>
            <TableRow key={isuranceIndex}>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <StyledTableCell className='backSilver'><h4>Total período:</h4></StyledTableCell>
            <TableCell><h4>{totalItens}</h4></TableCell>
            <TableCell></TableCell>
            <TableCell><h4>R$ {insuranceTotal.toFixed(2)}</h4></TableCell>
            <TableCell>{}</TableCell>
            <TableCell><h4>{GetCommissionPercentMed(commissionTotal, insuranceTotal)}</h4></TableCell>
            <TableCell><h4>R$ {commissionTotal.toFixed(2)}</h4></TableCell>
            </TableRow>
            </>            
        )
    }

    const SumTotal = () => {
        insuranceTotal += sellerInsuranceValue;
        commissionTotal += sellerCommissionValue;
    }

    const GetCommissionPercentMed = (commissionTotal, insuranceTotal) => {
        return ((commissionTotal / insuranceTotal) * 100).toFixed(2);
    }

    const GetSellerCommissionPercent = (value) => {
        if (value.commission) 
            return parseFloat(value.commission).toFixed(0);
        else
         return 0;
    }

    const GetSellerCommissionValue = (value) => {
        if (value.sellerCommissionValue) 
            return parseFloat(value.sellerCommissionValue).toFixed(2);
        else
         return 0;
    }

    const ShowSellerCommissionColumns = () => 
        layout == 2 && colums.length > 14;


    return (
    <div>
        {data.map((sellerData, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
            <TableCommissionReport>
            <H3>Vendedor: {sellerData.seller}</H3>
            <TableRowHead>
                {colums.map((x) => <TableCellCR key={x.titulo}>{x.titulo}</TableCellCR>)}
            </TableRowHead>
            <tbody>
                {sellerData.insurances.map((insuranceData, insuranceIndex) => (
                    <div key={insuranceIndex} style={{ display: 'contents', marginBottom: '20px' }}>
                        
                        {CheckCommissionInsurance(insuranceData.commission)}

                        {
                            (CheckBreakTotalInsurance(insuranceData.commission, insuranceIndex) || 
                                (breakInsurance && insuranceIndex > 0 )) &&
                                GetBreakInsuranceTableCell(insuranceIndex)
                        }

                        {SumValues(insuranceData.commission)}

                        {
                            (breakInsurance || insuranceIndex == 0) &&
                            <>
                                <H4>{insuranceData.commission.Seguradora}</H4>
                            </>
                        }
                    
                        <TableRow key={insuranceIndex}>
                            <TableCell style={{width: '200px'}}>{insuranceData.commission.Cliente}</TableCell>
                            <TableCell>{FormatDate(insuranceData.commission?.term_begin)}</TableCell>
                            <TableCell>{insuranceData.commission?.propostal_number}</TableCell>
                            <TableCell>{insuranceData.commission?.certificate_number}</TableCell>
                            <TableCell>{}</TableCell>
                            <TableCell>{insuranceData.commission.product}</TableCell>
                            <TableCell>{insuranceData.commission.Seguro}</TableCell>
                            <TableCell>{insuranceData.commission.Negocio}</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>{FormatDate(insuranceData.commission?.term_begin)}  {FormatDate(insuranceData.commission?.term_final)}</TableCell>
                            <TableCell style={{width: '100px'}}>R$ {insuranceData.commission.insurance_sold_value}</TableCell>
                            <TableCell>{insuranceData.commission.assinatura_mensal_num_parcelas}</TableCell>
                            <TableCell>{insuranceData.commission.commission_percentage}</TableCell>
                            <TableCell>R$ {insuranceData.commission.commission_value}</TableCell>
                            {
                                (ShowSellerCommissionColumns()) &&
                                <>
                                <TableCell>{GetSellerCommissionPercent(insuranceData.commission)}</TableCell>
                                <TableCell>R$ {GetSellerCommissionValue(insuranceData.commission)}</TableCell>
                                </>
                            }
                        </TableRow>

                        {
                            (sellerData.insurances.length-1 == insuranceIndex) &&
                                GetBreakInsuranceTableCell(insuranceIndex)
                        }      

                </div>
                ))}

                {GetBreakSellerTableCell(index)}

                {
                    (index+1 == data.length) &&
                        GetBreakTotalTableCell(1)
                }

                
            </tbody>
            </TableCommissionReport>
        </div>
        ))}
    </div>
    );
  };
  
  CommissionReportGroupTable.propTypes = {
    colums: P.arrayOf(P.object),
    data: P.arrayOf(P.object),
    layout: P.number,
};

CommissionReportGroupTable.defaultProps = {
    colums: [],
    data: [],
    layout: 1,
};

export default CommissionReportGroupTable;
