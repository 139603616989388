/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { InputAdornment } from '@material-ui/core';
import { FormGroup } from '../styles';
import { TextField, AutocompleteField } from '../../FormFields';
import { CommonNewInsuranceQuestions } from './commonQuestions';
import { formatarParaEstiloPTBR } from '../../../hooks/Utils';


export const InsuranceBusinessPersonTypeArray = [
    { name: 'Física', id: 1 },
    { name: 'Jurídica', id: 2 },
];

export function CondoQuestions({
    insuranceCondoFireAmount,
    insuranceCondoBlocksAmount,
    insuranceCondoElevatorsAmount,
    insuranceCondoFloorsAmount,
    insuranceCondoApartmentFloorAmount,
    insuranceCondoParkingSpaceAmount,
    insuranceNew,
    onChangeAutoComplete,
    onChangeNumericFireAmount,
    onChangeNumericBlocksAmount,
    onChangeNumericElevatorsAmount,
    onChangeNumericFloorsAmount,
    onChangeNumericApartmentFloorAmount,
    onChangeNumericParkingSpaceAmount,
})
{
    
    return (
        <>
        <FormGroup dark>
        <NumericFormat
            label="Valor da cobertura de incêndio"
            customInput={TextField}
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            autoComplete="off"
            InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
            value={formatarParaEstiloPTBR(insuranceCondoFireAmount)}
            onChange={onChangeNumericFireAmount}
        />
        <NumericFormat
            label="Quantos blocos?"
            customInput={TextField}
            decimalScale={0}
            autoComplete="off"
            value={insuranceCondoBlocksAmount || ''}
            onChange={onChangeNumericBlocksAmount}
        />
        <NumericFormat
            label="Tem elevador? Quantos?"
            customInput={TextField}
            decimalScale={0}
            autoComplete="off"
            value={insuranceCondoElevatorsAmount || ''}
            onChange={onChangeNumericElevatorsAmount}
        />
        <NumericFormat
            label="Quantos andares?"
            customInput={TextField}
            decimalScale={0}
            autoComplete="off"
            value={insuranceCondoFloorsAmount || ''}
            onChange={onChangeNumericFloorsAmount}
        />
        </FormGroup>
        
        <FormGroup dark>
        <NumericFormat
            label="Apartamentos por andar?"
            customInput={TextField}
            decimalScale={0}
            autoComplete="off"
            value={insuranceCondoApartmentFloorAmount || ''}
            onChange={onChangeNumericApartmentFloorAmount}
        />
        <NumericFormat
            label="Vagas de garagem?"
            customInput={TextField}
            decimalScale={0}
            autoComplete="off"
            value={insuranceCondoParkingSpaceAmount || ''}
            onChange={onChangeNumericParkingSpaceAmount}
        />
        <CommonNewInsuranceQuestions
            insuranceNew={insuranceNew}
            onChangeAutoComplete={onChangeAutoComplete}
        />
        </FormGroup>
        </>
    )
};

CondoQuestions.propTypes = {
    insuranceCondoFireAmount: P.number.isRequired,
    insuranceCondoBlocksAmount: P.number.isRequired,
    insuranceCondoElevatorsAmount: P.number.isRequired,
    insuranceCondoFloorsAmount: P.number.isRequired,
    insuranceCondoApartmentFloorAmount: P.number.isRequired,
    insuranceCondoParkingSpaceAmount: P.number.isRequired,
    insuranceNew: P.objectOf(P.any).isRequired,
    onChangeAutoComplete: P.objectOf(P.any).isRequired,
    onChangeNumericFireAmount: P.objectOf(P.any).isRequired,
    onChangeNumericBlocksAmount: P.objectOf(P.any).isRequired,
    onChangeNumericElevatorsAmount: P.objectOf(P.any).isRequired,
    onChangeNumericFloorsAmount: P.objectOf(P.any).isRequired,
    onChangeNumericApartmentFloorAmount: P.objectOf(P.any).isRequired,
    onChangeNumericParkingSpaceAmount: P.objectOf(P.any).isRequired,
}

CondoQuestions.defaultProps = {
    
}