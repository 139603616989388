/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-return-assign */
/* eslint eqeqeq: "off", curly: "error" */
/* eslint no-const-assign: "off", curly: "error" */
/* eslint assignment: "off", curly: "error" */
/* eslint assign: "off", curly: "error" */
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import LeadForm from '../../components/LeadForm';
import { Header, LogoDiv, Container, Leads, LeadsContainer, ButtonBox, LogoMetasWarpper } from './styles';
import { MainContext } from '../../context/ApiContext';
import LeadList from '../../components/Dashboard/LeadList';
import MenuButton from '../../components/Dashboard/MenuButton';
import MenuItem from '../../components/Dashboard/MenuItem';
import LeadCard from '../../components/Dashboard/LeadCard';
import Navbar from '../../components/Dashboard/Navbar';
import { LogoHorizontal, LogoMetas } from '../../components/Logo';
import FullScreenDialog from '../../components/FullScreenDialog';
import { Backdrop, Fade, makeStyles, Modal, Button, Box } from '@material-ui/core';
import MenuItemAgendamentos from '../../components/Dashboard/MenuItemAgendamentos';
import LoadingPage from '../LoadingPage';
import { toast } from 'react-toastify';
import { listToMatrix } from '../../utils/array';
import { FixedSizeGrid as Grid } from 'react-window';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LeadsGridVirtualized from '../../components/LeadsGridVirtualized';
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import LeadView from '../../components/LeadView';
import { removeMask } from '../../utils/masks';
import { useAuth } from '../../hooks/useAuth';
import useApp from '../../hooks/useApp';
import { FullPageLoading } from '../../components/FullPageLoading';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '60%',
    height: '80%',
    borderRadius: 8,
    minWidth: '500px',
    minHeight: '300px',
    padding: '24px 32px',
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
  },
}));

const Lead = () => {
  const { user } = useAuth();
  const { refresh_lead_seconds, lead_status, insurances, setState } = useApp();
  const {
    // values,
    // getters,
    // auth: { getUserInfo },
  } = useContext(MainContext);
  const { height, width } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [openLeadView, setOpenLeadView] = useState(false);
  const [lead, setLead] = useState();
  const [leadCount, setLeadCount] = useState();
  const [lockTabs, setLockTabs] = useState(false);
  const [convertedLeads, setConvertedLeads] = useState(null);
  const [schedulesLead, setSchedulesLead] = useState({ count: null, expired: false });
  const [leadToEdit, setLeadToEdit] = useState(null);
  const [isSchedulable, setIsSchedulable] = useState(false);
  const [leadsListCount, setLeadsListCount] = useState(false);
  const [leadsList, setLeadsList] = useState(false);
  const [filter, setFilter] = useState({
    lead_id: '',
    status: 1,
    state: 1,
    name: 'Origem',
    channel: 1,
    insurance: null,
    partner: null,
    schedule: false,
    sevenDays: false,
    onlySchedules: false,
    start_date: '',
    end_date: '',
    clientName: '',
    broker: '',
    cpf: '',
    isStatusFilterButton: 0,
    soldLeads: false,
    cached: true,
  });
  const { data: leadsCount, mutate: mutateCount } = useSWR(`/leads/list-count`, 
    fetcher, 
    { refreshInterval: getRefreshLead(), dedupingInterval: 5000 });

  const leadsListBrokerOnSuccess = (data, key) => {
    //setFilter({
    //  ...filter,
    //  cached: !filter.cached,
    //});
  }

  const leadsListBrokerOnError = (err, key) => {
    console.log(err);
  }
  
  const {
    data: leads,
    mutate: mutateList,
    isValidating,
    error,
  } = useSWR(
    `/leads/list-broker?` +
    `channel_id=${filter.channel || ''}` +
    `&client_name=${filter.clientName || ''}` +
    `&client_cpf=${removeMask(filter.cpf) || ''}` +
    `&broker=${filter.broker || ''}` +
    `&status=${filter.status || ''}` +
    `&schedule=${filter.schedule ? 1 : ''}` +
    `&saven_days=${filter.sevenDays ? 1 : ''}` +
    `&only_schedules=${filter.onlySchedules ? 1 : ''}` +
    `&state=${filter.state || ''}` +
    `&start_date=${filter.start_date || ''}` +
    `&end_date=${filter.end_date || ''}` +
    `&partner_id=${filter.partner || ''}` +
    `&lead_id=${filter.lead_id || ''}` +
    `&isStatusFilterButton=${filter.isStatusFilterButton || ''}` +
    `&insurance_id=${filter.insurance || ''}` +
    `&soldLeads=${filter.soldLeads || ''}`,
    fetcher,
    { refreshInterval: getRefreshLead(), 
      dedupingInterval: 10000, 
      onSuccess: leadsListBrokerOnSuccess,
      onError: leadsListBrokerOnError 
    }
  );

  const { data: older, mutate: mutateOlder } = useSWR(`/leads/older`, 
    fetcher, 
    { refreshInterval: getRefreshLead(), dedupingInterval: 10000 });

  const [selectedAttendanceFlow, setSelectedAttendanceFlow] = useState([1, 2, 3, 4, 5, 6, 7]);

  useEffect(() => {
    const attendantFlow = JSON.parse(localStorage.getItem('pessego_attendance_flow'));
    if (attendantFlow) setSelectedAttendanceFlow(attendantFlow);
    else {
      setSelectedAttendanceFlow([1, 2, 3, 4, 5, 6, 7]);
      localStorage.setItem('pessego_attendance_flow', JSON.stringify([1, 2, 3, 4, 5, 6, 7]));
    }
  }, []);

  useEffect(() => {
    if (leadsCount) {
      setLeadsListCount(leadsCount);
    }
  }, [leadsCount]);

  useEffect(() => {
   if (leads) {
      setLeadsList(leads);
    }
  }, [leads]);

  function getRefreshLead($lead, $date) {
    const refreshTime = (refresh_lead_seconds || 60) * 1000 ?? 60000;
    return refreshTime;
  }
  
  function countFilteredStatus(sts) {
    let count = 0;
    leadsList?.leads.forEach((L) => {
      if (L.status == sts) count += 1;
    });
    return count;
  }

  const leadsFilteredCount = () => {
    lead_status?.forEach((status) => {
      setLeadCount((state) => ({ ...state, [status.var_id]: countFilteredStatus(status.var_id) }));
    });
  };

  const handleEditLead = (ld) => {
    mutateList();
    mutateCount();
    setLeadToEdit(ld);
    setOpen(true);
  };
  const handleViewLead = (ld) => {
    setLead(ld);
    setOpenLeadView(true);
  };

  function countAllLeads() {
    let count = 0;
    leadsListCount?.leads_channel_count?.forEach((lead) => {
      count += parseInt(lead.total);
    });
    return count;
  }

  function countStatus(sts) {
    const count = leadsListCount?.leads_status_count?.find((count) => count.status == sts);
    if (sts == 6) return count?.news ?? 0;
    return count?.total ?? 0;
  }

  function countFilteredStatus(sts) {
    let count = 0;
    leadsList?.leads.forEach((lead) => {
      if (lead.status == sts) count++;
    });
    return count;
  }

  const nextSchedulers = () => {
    let expired = false;
    const expiredNum = leadsListCount?.leads_status_count?.reduce(
      (acc, cur) => (acc += Number(cur.late_scheduling)),
      0
    );
    if (expiredNum > 0) expired = true;
    setSchedulesLead({ count: expiredNum, expired });
  };

  useEffect(() => {
    nextSchedulers();
    setLockTabs(true);
    setFilter({
      ...filter,
      cached: true,
    });

  }, [leadsList]);

  useEffect(() => {
    if (leadsList?.leads) { 
      let orderedLeads = leadsList?.leads.sort((a, b) => {
        return a.status < b.status ? -1 : 1;
      });
      const matrix = listToMatrix(leadsList?.leads, width > 1259 ? 4 : 3);
      setConvertedLeads(matrix);

      if (lockTabs == false) {
        leadsFilteredCount();
      }
    }
  }, [leadsList, filter]);

  const Cell = ({ columnIndex, rowIndex, style }) => (
    <div
      className={
        columnIndex % 2
          ? rowIndex % 2 === 0
            ? 'GridItemOdd'
            : 'GridItemEven'
          : rowIndex % 2
            ? 'GridItemOdd'
            : 'GridItemEven'
      }
      style={style}>
      {convertedLeads && rowIndex < convertedLeads.length && columnIndex < convertedLeads[rowIndex].length && (
        <LeadCard
          older={older}
          lead={convertedLeads[rowIndex][columnIndex]}
          key={convertedLeads[rowIndex][columnIndex].id}
          onClick={handleEditLead}
          onClickView={handleViewLead}
          user={JSON.stringify(user)}
          mutate={() => {
            mutateCount();
            mutateList();
          }}
        />
      )}
    </div>
  );

  const classes = useStyles();

  const menuItems = [
    <MenuButton
      count={countAllLeads()}
      subCount={leadsListCount?.leads_channel_count}
      className="blue"
      name="Origem"
      key={Math.random()}
      user={user}
      onChangeFlow={(flow) => setSelectedAttendanceFlow(flow)}

      onClick={(e, i, c) => {
        setFilter({
          ...filter,
          state: 1,
          status: 1,
          channel: e,
          schedule: false,
          insurance: i,
          children: c,
          partner: null,
          name: 'Origem',
          cached: false,
        });
        setIsSchedulable([5, 6, 7, 8, 9].includes(e));
      }}
    />,
    <MenuItem
      count={countStatus(2)}
      className="yellow"
      name="Negociação"
      key="1"
      onClick={() => {
        setFilter({
          ...filter,
          state: 2,
          status: 2,
          channel: null,
          schedule: false,
          insurance: null,
          partner: null,
          name: 'Negociação',
          cached: false,
        });
        setIsSchedulable(false);
      }}
    />,
    <MenuItem
      count={countStatus(3)}
      className="sea"
      name="Whatsapp/Ligou"
      key="2"
      onClick={() => {
        setFilter({
          ...filter,
          state: 2,
          status: 3,
          channel: null,
          schedule: false,
          insurance: null,
          partner: null,
          name: 'Whatsapp/Ligou',
          cached: false,
        });
        setIsSchedulable(false);
      }}
    />,
    <MenuItem
      count={countStatus(4)}
      className="purple"
      name="Envio Cotação"
      key="3"
      onClick={() => {
        setFilter({
          ...filter,
          state: 2,
          status: 4,
          channel: null,
          schedule: false,
          insurance: null,
          partner: null,
          name: 'Envio Cotação',
          cached: false,
        });
        setIsSchedulable(false);
      }}
    />,
    <MenuItem
      count={countStatus(5)}
      className="gray"
      name="Vendido com Pendência"
      key="4"
      onClick={() => {
        setFilter({
          ...filter,
          state: 2,
          status: 5,
          channel: null,
          schedule: false,
          insurance: null,
          name: 'Vendido com Pendência',
          cached: false,
        });
        setIsSchedulable(false);
      }}
    />,
    <MenuItem
      count={countStatus(6)}
      className="green"
      name="Finalizados"
      key="5"
      onClick={() => {
        setFilter({
          ...filter,
          state: 3,
          status: 6,
          channel: null,
          schedule: false,
          insurance: null,
          partner: null,
          name: 'Finalizados',
          cached: false,
        });
        setIsSchedulable(false);
      }}
    />,
    <MenuItemAgendamentos
      nextSch={schedulesLead}
      count={leadsListCount?.leads_status_count?.reduce((total, count) => (total += parseInt(count.scheduling)), 0)}
      className="orange"
      name="Agendamentos"
      key="6"
      onClick={() => {
        setFilter({
          ...filter,
          state: null,
          status: null,
          channel: null,
          schedule: true,
          insurance: null,
          partner: null,
          sevenDays: true,
          name: 'Agendamentos',
          cached: false,
        });
        setIsSchedulable(true);
      }}
    />,
  ];

  const ExistsChannelChildren = () => {
    return filter.children;
  }

  const GetChannelChildren = () => {
    const descGrupo = filter.insurance === 1 ? 'VN' : 'VU';
    return `${user?.technical_team?.
      channels?.
      find((c) => c.id === filter.channel).
      children.
      find((c) => c.id === filter.children )?.
      name}${' - '}
      ${descGrupo}`
  }

  const handleClickFilter = (name, cpf, broker, start_date, end_date, lead_id, lead_channel) => {
      setFilter({
        ...filter,
        lead_id: lead_id,
        clientName: name,
        broker: broker,
        cpf: cpf,
        start_date: start_date,
        end_date: end_date,
        state: null,
        status: null,
        channel: lead_channel,
        partner: null,
        insurance: null,
        schedule: false,
        name: 'Resultados',
        sevenDays: false,
        onlySchedules: false,
        cached: false,
      });
      leadsFilteredCount();
  }

  const handleClearFilter = () => {
    setFilter({
      status: 1,
      state: 1,
      name: 'Origem',
      channel: 1,
      insurance: null,
      partner: null,
      schedule: false,
      sevenDays: false,
      onlySchedules: false,
      start_date: '',
      end_date: '',
      clientName: '',
      broker: '',
      cpf: '',
      isStatusFilterButton: 0,
      cached: false,
    });
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <FullScreenDialog open={open} setOpen={setOpen} leadId={leadToEdit?.id}>
        <LeadForm
          type="edit"
          leadCurrent={leadToEdit}
          setOpen={setOpen}
          mutate={() => {
            mutateList();
            mutateCount();
          }}
        />
      </FullScreenDialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openLeadView}
        onClose={() => setOpenLeadView(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openLeadView}>
          <div className={classes.paper}>
            <LeadView lead={lead} />
          </div>
        </Fade>
      </Modal>
      {leadsList && leadsListCount && (
        <>
          <Header id='HeaderFiltros' style={{height: '70px', backgroundColor: '#cdcdcd' }}>
            <div className="container">
              <div className="content">
                <LogoDiv>
                  <Link to="/">
                    <LogoHorizontal />
                  </Link>
                </LogoDiv>
                <div className="menu">
                  <ul>
                    {selectedAttendanceFlow.map((item) => {
                      return menuItems[item - 1];
                    })}                                        
                  </ul>
                </div>
                <div></div>
              </div>
            </div>
          </Header>
          <Container style={{ backgroundColor: '#cdcdcd' }}>
            {/* <button type="button" onClick={nextSchedulers}>
          Teste
        </button> */}
            <Navbar
              clickSevenDays={() => setFilter({ ...filter, sevenDays: !filter.sevenDays })}
              clickOnlySchedules={() => setFilter({ ...filter, onlySchedules: !filter.onlySchedules })}
              checkValue={filter.sevenDays}
              checkValueSchedules={filter.onlySchedules}
              clickFilter={handleClickFilter}
              clearFilter={handleClearFilter}
              clickFilterBtn={(name, value) => {
                if (value === 0) setFilter({ ...filter, partner: null, insurance: null, channel: null, [name]: null });
                else setFilter({ ...filter, partner: null, insurance: null, channel: null, [name]: value });
              }}
              clearSelected={() => setIsSelected(false)}
              scheduler={isSchedulable}
              setLockTabs={setLockTabs}
              checkSoldLeads={filter.soldLeads}
              clickSoldLeads={() => setFilter({ ...filter, soldLeads: !filter.soldLeads })}
            />

            <div className="leadBar">
              <Box id='Origem' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  {filter.name}{' '}
                  {filter.channel && `- ${user?.technical_team?.channels?.find((c) => c.id === filter.channel)?.name}`}{' '}
                  {ExistsChannelChildren() && `- ${GetChannelChildren()}`}{' '}
                  {filter.insurance && !ExistsChannelChildren() && `- ${insurances?.find((c) => c.id === filter.insurance)?.name}`}
                </Box>
                <Box>
                  {filter.name === 'Resultados' && filter.onlySchedules != true && (
                    <ButtonBox>
                      <Box sx={{ display: 'flex' }}>
                        {lead_status?.map((status) => (
                          <>
                            {status.var_id != 7 && (
                              <Button
                                key={status.id}
                                className="button"
                                type="button"
                                fullWidth
                                onClick={() => {
                                  setFilter({ ...filter, status: status.var_id, isStatusFilterButton: 1 }),
                                    setIsSelected(status.var_id);
                                }}
                                style={{
                                  marginTop: '8px',
                                  background: isSelected == status.var_id ? '#f26522' : '',
                                  color: isSelected == status.var_id ? '#ffffff' : '',
                                }}
                                variant="contained">
                                {status.value}
                                <br />

                                {leadCount?.[status.var_id]}
                              </Button>
                            )}
                          </>
                        ))}
                      </Box>
                    </ButtonBox>
                  )}
                </Box>
              </Box>
            </div>
            <LeadsGridVirtualized rows={convertedLeads?.length}>{Cell}</LeadsGridVirtualized>
            <LogoMetasWarpper>
              <LogoMetas />
            </LogoMetasWarpper>

          </Container>
        </>
      )}
    </motion.div>
  );
};
export default Lead;
