/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import P from 'prop-types';
import { useContext, useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { MainContext } from '../../context/ApiContext';
import useApp from '../../hooks/useApp';
import { Container } from './styles';
import Loading from '../Loading';
import useSWR from 'swr';
import { fetcher } from '../../service/SWRFetcher';
import { Button } from '@material-ui/core';
import DeadlineModal from '../DeadlineModal/index';
import { maskCpf, maskDate, maskDateTime } from '../../utils/masks';
import { SN } from '../../mock/leadInfo.com';
import { IsCorujaFlow } from '../../service/flow.service.ts';

const months = [
  { name: 'Janeiro', id: 1 },
  { name: 'Fevereiro', id: 2 },
  { name: 'Março', id: 3 },
  { name: 'Abril', id: 4 },
  { name: 'Maio', id: 5 },
  { name: 'Junho', id: 6 },
  { name: 'Julho', id: 7 },
  { name: 'Agosto', id: 8 },
  { name: 'Setembro', id: 9 },
  { name: 'Outubro', id: 10 },
  { name: 'Novembro', id: 11 },
  { name: 'Dezembro', id: 12 },
];

const offer = [
  { id: 0, name: 'Não oferecido' },
  { id: 1, name: 'Oferecido / não vendido' },
  { id: 2, name: 'Oferecido/vendido' },
];

export default function LeadView({ lead }) {
  const context = useContext(MainContext);
  const { data: user, mutate: mutateUser } = useSWR(`/users/me`, fetcher);
  
  const {
    lead_contact_type,
    lead_sold,
    lead_lost_sale,
    lead_status,
    competitor_brokers,
    insurances,
    partners,
    time_to_finish_lead,
    setState,
  } = useApp();

  // const [status, setStatus] = useState(null);
  // const [contact_type, setContact_type] = useState(null);
  // const [sold, setSold] = useState(null);
  // const [lost_sale, setLost_sale] = useState(null);
  const { data: usersData, mutate: mutateUsers, isValidating } = useSWR(`/users`, fetcher);
  const [deadlinesModal, setDeadlinesModal] = useState(false);

  const [values, setValues] = useState({
    // -->inviter information<--
    inviter: null,
    inviter_Phone: '',
    partner: null,
    insurance: null,
    // -->client information<--
    proposal: '',
    client_name: '',
    client_cellphone: '',
    client_phone: '',
    client_birth_date: '',
    client_Cpf: '',
    client_CEP: '',
    client_address_street: '',
    client_address_number: '',
    client_address_comp: '',
    client_address_district: '',
    client_address_city: '',
    client_address_state: '',
    client_Email: '',
    insurance_expiration: null,
    // -->lead table<--
    proposal_date: null,
    product: '',
    product_details: '',
    product_chassis: '',
    product_license_plate: '',
    product_year: '',
    product_brand: '',
    additional_information: '',

    broker: null,// users ->removido
    status: null,
    state: null,
    contact_type: null,
    sold: null,
    lost_sale: null,
    pague_menos: null,
    backup_car: null,
    seguro_conteudo: null,
    endorsement_date: null,
    competitor_broker: '',
    attendance_on_time: null,
    proposal_on_time: null,
    end_lead_date: null,
    first_call_date: null,

    contact_date: null,
    quoted_type: null,
    sold_type: null,
    sold_date: null,
    commission_percentage: null,
    sale_value: null,
  });



  // useEffect(() => {
  //   (async () => {
  //     context.getters.getDashboard();
  //     setContact_type(context.values.lead_contact_type?.map((ct) => ({ id: ct.var_id, name: ct.value })));
  //     setSold(context.values.lead_sold?.map((s) => ({ id: s.var_id + 1, name: s.value })));
  //     setLost_sale(context.values.lead_lost_sale?.map((ls) => ({ id: ls.var_id, name: ls.value })));
  //     setStatus(context.values.lead_status?.map((st) => ({ id: st.var_id, name: st.value })));
  //   })();
  // }, [context?.values?.lead_sold]);

  // useEffect(() => {
  //   setContact_type(context.values.lead_contact_type?.map((ct) => ({ id: ct.var_id, name: ct.value })));
  //   setSold(context.values.lead_sold?.map((s) => ({ id: s.var_id + 1, name: s.value })));
  //   setLost_sale(context.values.lead_lost_sale?.map((ls) => ({ id: ls.var_id, name: ls.value })));
  //   setStatus(context.values.lead_status?.map((st) => ({ id: st.var_id, name: st.value })));
  // }, [context?.values?.lead_sold]);

  useEffect(() => {
    (async () => {
      console.log('sold:',lead_sold);
      console.log('lead:', lead);
      console.log('sold-id:',lead_sold?.find((sd) => sd.var_id == lead.sold ));
  
      const inviterInfo = usersData?.find((i) => i?.id === lead?.inviter_id);
      const insuranceInfo = context?.values?.insurances?.find((ins) => ins.id === lead.insurance_id);
      setValues({
        inviter: inviterInfo ? inviterInfo : null,
        inviter_Phone: inviterInfo?.phone || '',
        partner: lead.partner || null,
        insurance: insuranceInfo || null,
        proposal: lead.proposal || '',
        client_name: lead.client?.name || '',
        client_cellphone: lead.client?.cellphone || '',
        client_phone: lead.client?.phone || '',
        client_birth_date: lead.client?.birth_date || '',
        client_Cpf: lead.client?.cpf || '',
        client_CEP: lead.client?.address.cep || '',
        client_address_street: lead.client?.address?.street || '',
        client_address_number: lead.client?.address?.number || '',
        client_address_comp: lead.client?.address.comp || '',
        client_address_district: lead.client?.address.district || '',
        client_address_city: lead.client?.address.city || '',
        client_address_state: lead.client?.address.state || '',
        client_Email: lead.client?.mail || '',
        insurance_expiration: months.find((s) => s.id === lead.insurance_expiration) || null,
        proposal_date: lead.proposal_date || null,
        product: lead.product || '',
        product_details: lead.product_details.details || '',
        product_chassis: lead.product_details.chassis || '',
        product_license_plate: lead.product_details.license_plate || '',
        product_year: lead.product_details.year || '',
        product_brand: lead.product_details.brand || '',
        additional_information: lead.additional_information || '',
        broker: lead.broker || null,
        competitor_broker: lead.competitor_broker || null,
        status: lead_status?.find((s) => s.var_id === lead.status) || '',
        state: lead.state || null,
        contact_type: lead_contact_type?.find((ct) => ct.var_id === lead.contact_type) || '',
        sold: lead_sold?.find((sd) => sd.var_id == lead.sold) || null,
        lost_sale: lead_lost_sale?.find((ls) => ls.var_id == lead.lost_sale) || null,
        pague_menos: offer.find((o) => o.id == lead.pague_menos?.toString()) || null,
        backup_car: offer.find((o) => o.id == lead.backup_car?.toString()) || null,
        seguro_conteudo: offer.find((o) => o.id == lead.seguro_conteudo?.toString()) || null,
        attendance_on_time: lead.attendance_on_time || null,
        end_lead_date: lead.end_lead_date || null,
        first_call_date: lead.first_call_date || null,
        contact_date: lead.contact_date || null,
        quoted_type: lead_contact_type?.find((cb) => cb.var_id == lead.quoted_type) || null,
        sold_type: lead_contact_type?.find((cb) => cb.var_id == lead.sold_type) || null,
        sold_date: lead.sold_date,
        commission_percentage: lead.commission_percentage,
        sale_value: lead.sale_value,
      });

      
    })();
  }, [lead_status, lead_lost_sale, lead_contact_type, lead_sold]);


  return (
    <Container>
      {!lead_status || !lead_lost_sale || !lead_contact_type || !lead_sold ? (
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
          <Loading />
        </Box>
      ) : (
        <>
        
          <h2 className="block-title">
            Informações do Corretor<span>Lead {lead.id}</span>
            {user?.roles[0]?.id === 1 && (
              <Button
                variant="contained"
                onClick={() => {
                  setDeadlinesModal(true);
                }}>
                <p style={{ color: '#000000' }}>Alterar Prazos</p>
              </Button>
            )}
          </h2>
          
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>Corretor: </strong> {values.broker?.name || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Tipo de contato: </strong> {values.contact_type?.value || '...'}
            </Typography>
          </Box>
          
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>Status da venda: </strong> {values.sold?.value || '...'}
            </Typography>
            {values.sold?.var_id == 1 && (
              <Typography variant="body2" gutterBottom>
                <strong>Motivo da não venda: </strong> {values.lost_sale?.value || '...'}
              </Typography>
            )}
          </Box>
          
          {values.sold?.var_id == 2 && IsCorujaFlow() && (
            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>Seguradora: </strong>
                {context?.values?.competitor_brokers?.find((cb) => cb.var_id == values.competitor_broker)?.value ||
                  '...'}
              </Typography>

              <Typography variant="body2" gutterBottom>
                <strong>Valor da venda: </strong>
                R$ {values.sale_value || '...'}
              </Typography>

              <Typography variant="body2" gutterBottom>
                <strong>Porcentagem da comissão: </strong>
                {values.commission_percentage || '...'} %
              </Typography>
            </Box>
          )}

          {!IsCorujaFlow() && (
            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>BackUp car: </strong> {values.backup_car?.name || '...'}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Pague Menos: </strong> {values.pague_menos?.name || '...'}
              </Typography>
            </Box>
          )}

          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>FORMA DE CONTATO INICIAL: </strong> {values.contact_type?.value || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>DATA DO CONTATO: </strong> {maskDateTime(values.contact_date) || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>FORMA QUE APRESENTOU A PROPOSTA: </strong> {values.quoted_type?.value || '...'}
            </Typography>
          </Box>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>FORMA QUE RECEBEU A RESPOSTA VENDA/NÃO VENDA: </strong> {values.sold_type?.value || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>DATA DA VENDA: </strong> {maskDateTime(values.sold_date) || '...'}
            </Typography>
          </Box>

          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>Inicio vigência: </strong> {lead.term_begin ? maskDate(lead.term_begin) : '...'}
            </Typography>

            <Typography variant="body2" gutterBottom>
              <strong>Fim vigência: </strong> {lead.term_final ? maskDate(lead.term_final) : '...'}
            </Typography>

          </Box>

          {IsCorujaFlow() && (
            <>
            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>Tirou print do retorno do cliente, dando "OK" do envio da proposta e anexou a proposta no CRM, botão no final desta página?</strong> 
                {lead.print_proposal || '...'}
              </Typography>
            </Box>
            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>Tirou print do retorno do cliente, dando "OK" do envio do certificado e anexou o certificado / Apólice no CRM, botão no final desta página?</strong> 
                {lead.print_certificate || '...'}
              </Typography>
            </Box>
            </>
          )}

          <h2 className="block-title">Informações do Indicador</h2>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>INDICADOR: </strong> {values.inviter?.name || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>TELEFONE: </strong> {values.inviter_Phone || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>EMPRESA: </strong> {values.partner?.name || '...'}
            </Typography>
          </Box>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>PROPOSTA: </strong> {values.proposal || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>SETOR: </strong> {values.insurance?.name || '...'}
            </Typography>
          </Box>

          <h2 className="block-title">Informações do Indicado</h2>

          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>CPF/CNPJ: </strong> {values.client_Cpf || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>NOME DO CLIENTE: </strong> {values.client_name || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>DATA DE NASCIMENTO: </strong> {maskDate(values.client_birth_date) || ''}
            </Typography>
          </Box>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>TELEFONE CELULAR: </strong> {values.client_cellphone || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>TELEFONE FIXO: </strong> {values.client_phone || ''}
            </Typography>
          </Box>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>CEP: </strong> {values.client_CEP || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Endereço: </strong> {values.client_address_street || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Número: </strong> {values.client_address_number || ''}
            </Typography>
          </Box>

          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>Complemento: </strong> {values.client_address_comp || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Estado: </strong> {values.client_address_state?.name || values.client_address_state}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Bairro: </strong> {values.client_address_district || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Cidade: </strong> {values.client_address_city || ''}
            </Typography>
          </Box>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>E-MAIL: </strong> {values.client_Email || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>JÁ POSSUI SEGURO, QUAL O MÊS DO VENCIMENTO: </strong> {values.insurance_expiration?.name || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>DATA DA PROPOSTA: </strong>{' '}
              {(values.proposal_date && values.proposal_date.replace('T', ' ')) || ''}
            </Typography>
          </Box>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>PRODUTO SEGURADO: </strong> {values.product || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>MARCA: </strong> {values.product_brand || ''}
            </Typography>
          </Box>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>CHASSI DO VEÍCULO: </strong> {values.product_chassis || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>PLACA DO VEÍCULO: </strong> {values.product_license_plate || ''}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>ANO DE MODELO DO VEÍCULO: </strong> {values.product_year || ''}
            </Typography>
          </Box>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>OUTRAS INFORMAÇÕES DO PRODUTO SEGURADO: </strong> {values.product_details || ''}
            </Typography>
          </Box>

          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>Estado Civil:</strong> {lead.client?.estado_civil || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Servidor Público:</strong> { SN.find((item) => lead.client?.servidor_publico == item.id)?.name  || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Sexo:</strong> {lead.client?.gender || '...'}
            </Typography>
          </Box>

          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>Nacionalidade: </strong> {lead.client?.nacionalidade || '...'}
            </Typography>

            <Typography variant="body2" gutterBottom>
              <strong>Nome de recado: </strong> {lead.client?.nome_de_recado || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Telefone de recado: </strong> {lead.client?.telefone_de_recado || '...'}
            </Typography>
          </Box>
          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>Celular de recado: </strong> {lead.client?.celular_de_recado || '...'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Profissão: </strong> {lead.client?.profissao || '...'}
            </Typography>
          </Box>

          <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
              <strong>OBSERVAÇÕES: </strong> {values.additional_information || ''}
            </Typography>
          </Box>

          {IsCorujaFlow() && (
            <>
            <h2 className="block-title">Responsável Financeiro</h2>
            <Box display="flex" p={1} bgcolor="background.paper">
            <Typography variant="body2" gutterBottom>
                <strong>Nome Completo: </strong> {lead.financialData?.nome || ''}
              </Typography>
            </Box>
            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>Número de certificado: </strong> {lead.financialData?.certificate_number || ''}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Data Cadastro: </strong> {maskDate(lead.financialData?.data_registro) || ''}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>CPF: </strong> {maskCpf( lead.financialData?.cpf) || ''}
              </Typography>
            </Box>
            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>RG: </strong> {lead.financialData?.rg || ''}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Celular: </strong> {lead.financialData?.celular || ''}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Telefone: </strong> {lead.financialData?.telefone || ''}
              </Typography>
            </Box>
            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>Email: </strong> {lead.financialData?.email || ''}
              </Typography>
            </Box>
            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>Cep: </strong> {lead.financialData?.cep || ''}
              </Typography>
            </Box>
            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>Endereço: </strong> {lead.financialData?.endereco || ''}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Nº: </strong> {lead.financialData?.numero || ''}
              </Typography>
            </Box>

            <Box display="flex" p={1} bgcolor="background.paper">
              <Typography variant="body2" gutterBottom>
                <strong>Complemento: </strong> {lead.financialData?.complemento || ''}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Bairro: </strong> {lead.financialData?.bairro || ''}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Cidade: </strong> {lead.financialData?.cidade || ''}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Estado: </strong> {lead.financialData?.uf?.name || lead.financialData?.uf}
              </Typography>
            </Box>
            </>
          )}

          <DeadlineModal
            title="Alterar Prazos - Administrador"
            open={deadlinesModal}
            setOpen={setDeadlinesModal}
            value={lead}
          />

        </>
      )}

    </Container>
  );
  
}

LeadView.propTypes = {
  lead: P.shape({
    id: P.number,
    proposal: P.number,
    foreign_proposal: P.number,
    product: P.string,
    product_details: P.oneOfType([P.arrayOf(P.any), P.objectOf(P.any)]),
    channel_id: P.number,
    client_id: P.number,
    broker_id: P.number,
    inviter_id: P.number,
    status: P.number,
    state: P.number,
    insurance_id: P.number,
    insurance_expiration: P.number,
    partner: P.objectOf(P.any),
    broker: P.objectOf(P.any),
    channel: P.objectOf(P.any),
    client: P.objectOf(P.any),
    client_adress: P.objectOf(P.any),
    inviter: P.objectOf(P.any),
    inviter_Phone: P.string,
    partner_id: P.number,
    additional_information: P.string,
    proposal_date: P.string,
    contact_type: P.number,
    sold: P.number,
    lost_sale: P.number,
    quoted: P.number,
    pague_menos: P.number,
    seguro_conteudo: P.number,
    backup_car: P.number,
    competitor_broker: P.number,
    attendance_on_time: P.number,
    proposal_on_time: P.number,
    create_lead_date: P.string,
    end_lead_date: P.string,
    first_call_date: P.string,
  }),
};

LeadView.defaultProps = {
  lead: null,
};
