export const formatarParaEstiloAmericano = (valorString) => {
  if (valorString) {
    const valorNumerico =
      typeof valorString === 'number' ? valorString : parseFloat(valorString.replace(/\./g, '').replace(',', '.'));
    return valorNumerico;
  }
  return '0.00';
};
export const formatarParaEstiloPTBR = (valorString) => {
  if (valorString && valorString != null) {
    const valorNumerico = typeof valorString === 'number' ? valorString : parseFloat(valorString.replace(/,/g, ''));
    const valorFormatado = valorNumerico.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return valorFormatado;
  }
  return '0,00';
};
export const floatParaEstiloPTBR = (valorNumerico) => {
  if (valorNumerico) {
    const valorFormatado = valorNumerico.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return valorFormatado;
  }
  return '0,00';
};
export const DataParaEstiloPTBR = (Data) => {
  if (Data) {
    const nova = new Date(Data);
    return `${nova.getDate() + 1}/${nova.getMonth() + 1}/${nova.getFullYear()}`;
  }
  return '01/01/0001';
};

export const scrollToElement = (id) => {
  const targetElement = document.getElementById(id);

  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth' });
  }
};

export const scrollToElementRef = (elementRef) => {
  if (elementRef.current) {
    elementRef.current.scrollIntoView({ behavior: 'smooth' });
  }
};
