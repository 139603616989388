import { Switch, useLocation, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../styles/global-styles';
import { theme } from '../styles/theme';
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import LoginRoute from './LoginRoute';
import MainLayout from '../pages/layouts/MainLayout';
import OfflinePayment from '../pages/OfflinePayment/index';
import ReplaceCard from '../pages/ReplaceCard/index';
import { AppContext } from '../context/ApiContext';
import Register from '../pages/Register';
import Verify from '../pages/Verify';
import Redefine from '../pages/Redefine';
import RedefinePassword from '../pages/RedefinePassword';
import ExternalIndicationNoAuth from '../pages/ExternalIndicationNoAuth/index';
import { TenantRoutes } from './tenantRoutes';

const Routes = () => {
  const location = useLocation();
  return (
    <Switch>
      <AppContext>
        <AnimatePresence>
          <ThemeProvider theme={theme}>
            <LoginRoute path="/verify/:hash" component={Verify} />
            <LoginRoute path="/redefine/:hash" component={RedefinePassword} />
            <LoginRoute path="/redefine" component={Redefine} />
            <LoginRoute path="/login" component={Login} />
            <LoginRoute path="/register" component={Register} />
            <TenantRoutes />
            <Route path="/payment/:hash" render={(props) => <OfflinePayment {...props} />} />
            <Route path="/replaceCard/:hash" render={(props) => <ReplaceCard {...props} />} />
            <PrivateRoute path="/externalIndicationNoAuth/verify" component={ExternalIndicationNoAuth} />
            {!location.pathname.includes('verify') &&
              !location.pathname.includes('redefine') &&
              !location.pathname.includes('payment') &&
              !location.pathname.includes('replaceCard') && <PrivateRoute path="/" component={MainLayout} />}
            <GlobalStyles />
          </ThemeProvider>
        </AnimatePresence>
      </AppContext>
    </Switch>
  );
};

export default Routes;
