/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { AppContext, MainContext } from '../context/ApiContext';
import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import Channels from '../pages/Channels';
import Insurances from '../pages/Insurances';
import Partners from '../pages/Partners';
import Users from '../pages/Users';
import Clients from '../pages/Clients';
import LeadsVerification from '../pages/LeadsVerification';
import UserIndication from '../pages/UserIndication';
import SelfIndication from '../pages/SelfIndication';
import InidicationReport from '../pages/InidicationReport';
import ExternalIndication from '../pages/ExternalIndication';
import InidicationManagerReport from '../pages/InidicationManagerReport';
import SalesReport from '../pages/SalesReport';
import InidicationReportGerVeic from '../pages/InidicationReportGerVeic';
import LostSaleReport from '../pages/LostSaleReport';
import Lead from '../pages/Lead';
import TechnicalTeam from '../pages/TechnicalTeam';
import LoadingPage from '../pages/LoadingPage';
import CallsReport from '../pages/CallsReport';
import AttendanceReport from '../pages/AttendanceReport';
import AccidentReport from '../pages/AccidentReport';
import PaymentReport from '../pages/PaymentReport';
import EndorsementReport from '../pages/endorsementReport';
import FunnelChart from '../pages/FunnelChart';
import { useAuth } from '../hooks/useAuth';
import ProcessesReport from '../pages/ProcessesReport/index';
import RenovationReport from '../pages/RenovationReport';
import DiagnosisReport from '../pages/DiagnosisReport';
import NewInsurancesReport from '../pages/NewInsurancesReport';
import LeadBrokerConfig from '../pages/LeadBrokerConfig';
import CustomerMat from '../pages/CustomerMat';
import SubscriptionsReport from '../pages/reports/SubscriptionsReport';
import ImportarLeads from '../pages/ImportarLeads';
import CommissionReport from '../pages/reports/CommissionReport'
import CommissionsSchema from '../pages/CommissionsSchema';
import M3tasResources from '../pages/M3tasResources';

export default function MainRoutes() {
  const { ...auth } = useAuth();
  const [role, setRole] = useState(null);

  useEffect(() => {
    setRole(auth.user.role);
  }, [auth.user]);

  return (
    <AnimatePresence>
      <Switch>
        {!role && <LoadingPage />}
        
        {role >= 4 && (
          <>
            <Route exact path="/" component={UserIndication} />
            <Route exact path="/profile" component={Profile} />
            {(role == 5 || role == 7) && (
              <>
                <Route exact path="/relatorio-indicador" component={InidicationReport} />
                <Route exact path="/indicacao-externa" component={ExternalIndication} />
              </>
            )}
            {role == 4 && (
              <Route exact path="/relatorio-indicacoes-concessionaria" component={InidicationReportGerVeic} />
            )}
          </>
        )}
        {role < 4 && ( // Admin - Gerente corretora - corretor
          <>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/clients" component={Clients} />
            {/* <Route exact path="/relatorio-indicador" component={InidicationReport} /> */}
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/conferencia-informacoes-lead" component={LeadsVerification} />
            {role == 3 && (
              <>
                <Route exact path="/lead" component={UserIndication} />
                <Route exact path="/seguros" component={Insurances} />
                <Route exact path="/parceiros" component={Partners} />
                <Route exact path="/technical-team" component={TechnicalTeam} />
                <Route exact path="/relatorio-indicacoes-corretora" component={InidicationManagerReport} />
                <Route exact path="/relatorio-ligacoes" component={CallsReport} />
                <Route exact path="/relatorio-perdas" component={LostSaleReport} />
              </>
            )}
            {(role == 1 || role == 2) && ( //Admin + gerente corretora
              <>
                {/* <Route exact path="/canais" component={Channels} />*/}
                <Route exact path="/lead" component={Lead} />
                <Route exact path="/seguros" component={Insurances} />
                <Route exact path="/parceiros" component={Partners} />
                <Route exact path="/technical-team" component={TechnicalTeam} />
                <Route exact path="/esteira-cliente" component={CustomerMat}/>
                <Route exact path="/relatorio-indicacoes-corretora" component={InidicationManagerReport} />
                <Route exact path="/relatorio-ligacoes" component={CallsReport} />
                <Route exact path="/relatorio-atendimento" component={AttendanceReport} />
                <Route exact path="/funnel-charts" component={FunnelChart} />
                <Route exact path="/relatorio-sinistros" component={AccidentReport} />
                <Route exact path="/relatorio-endossos" component={EndorsementReport} />
                <Route exact path="/relatorio-pagamentos" component={PaymentReport} />
                <Route exact path="/relatorio-assinaturas" component={SubscriptionsReport} />
                <Route exact path="/relatorio-perdas" component={LostSaleReport} />
                <Route exact path="/relatorio-comissao" component={CommissionReport} />
                <Route exact path="/importar-leads" component={ImportarLeads} />
              </>
            )}
            {role == 1 && ( //Admin
              <>
                <Route exact path="/relatorio-indicacoes-concessionaria" component={InidicationReportGerVeic} />
                <Route exact path="/indicacao-externa" component={ExternalIndication}/> 
                <Route exact path="/relatorio-vendas-quiver" component={SalesReport}/> 
                <Route exact path="/relatorio-processamentos" component={ProcessesReport}/>
                <Route exact path="/relatorio-indicacoes-concessionaria" component={InidicationReportGerVeic} />
                <Route exact path="/indicacao-externa" component={ExternalIndication} />
                <Route exact path="/relatorio-vendas-quiver" component={SalesReport} />
                <Route exact path="/relatorio-processamentos" component={ProcessesReport} />
                <Route exact path="/commissions-schema" component={CommissionsSchema} />
              </>
            )}
            {(role <=3 ) && (
              <>
                <Route exact path="/relatorio-renovacoes" component={RenovationReport} />
                <Route exact path="/relatorio-processamentos" component={ProcessesReport} />
                <Route exact path="/relatorio-novos-seguros" component={NewInsurancesReport} />
                <Route exact path="/relatorio-diagnostico" component={DiagnosisReport} />
                <Route exact path="/lead-broker-config" component={LeadBrokerConfig} />
                <Route exact path="/auto-indicação" component={SelfIndication} />
              </>
            )}

            {auth.isM3tasUser() && (
              <Route exact path="/m3tas-resources" component={M3tasResources} />
            )}
          </>
        )}
      </Switch>
    </AnimatePresence>
  );
}
