/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import { createTechnicalTeam, editTechnicalTeam } from '../../service/technicalTeam.service';
import useApp from '../../hooks/useApp';


export default function TechinicalTeamForm ({ open, setOpen, title, techinicalTeam, mutate }) {
  const {channels} = useApp();

  const [values, setValues] = useState({
    description: '',
    channels: null,
  });

  const entering = () => {
    setValues({
      description: techinicalTeam?.description || '',
      channels: techinicalTeam?.channels || null,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    const editedTechnicalTeam = {
      description: values.description,
      channels: values.channels,
    };
    try {
      if(techinicalTeam){
        await editTechnicalTeam(editedTechnicalTeam, techinicalTeam.id);
        toast.success('Equipe modificada com sucesso');
        mutate();
      }
      else{
        await createTechnicalTeam(editedTechnicalTeam);
        toast.success('Equipe criada com sucesso');
        mutate();
      }
    } catch (error) {
      toast.error('Ocorreu um erro');
    }
    handleClose();
  };

  const handleChangeAutoComplete = (event, newValue, variable) => {
    if (newValue) {
      setValues({
        ...values,
        [variable]: newValue,
      });
    }
  };

  return (
    <div>
      <Dialog
        onEntering={entering}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {title}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={submit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Descrição"
              type="name"
              value={values.description}
              multiline
              fullWidth
              required
              onChange={(e) => setValues({ ...values, description: e.target.value })}
            />
            <Autocomplete
              multiple
              options={channels}
              value={values.channels || []}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                handleChangeAutoComplete(event, newValue, 'channels');
              }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Canais" />}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Salvar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

TechinicalTeamForm .propTypes = {
  open: P.bool.isRequired,
  title: P.string.isRequired,
  setOpen: P.func.isRequired,
  techinicalTeam: P.objectOf(P.string),
};

TechinicalTeamForm .defaultProps = {
  techinicalTeam : null,
};
