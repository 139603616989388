/* eslint-disable */
const { DateTime, Duration } = require("luxon");
const  moment  = require("moment-business-time");

export default function BusinessTime(dateStart,dateEnd, {timeStart, timeEnd}={timeStart: '', timeEnd: ''}) {
 
  if(!timeStart) timeStart = '08:30:00';
  if(!timeEnd) timeEnd = '18:30:00';

  moment.updateLocale('en', {
    workinghours: {
        0: null, // Sunday
        1: [timeStart, timeEnd], // Monday
        2: [timeStart, timeEnd], // Tuesday
        3: [timeStart, timeEnd], // Wednesday
        4: [timeStart, timeEnd], // Thursday
        5: [timeStart, timeEnd], // Friday
        6: null, // Saturday
    }
});

const milliseconds = moment(dateEnd).workingDiff(moment(dateStart), 'milliseconds');
if (milliseconds < 0) {
  return {time:Duration.fromObject({milliseconds:0}).toFormat('hh:mm:ss'),ms:0}}

var resp = Duration.fromObject({milliseconds:milliseconds}).toFormat('hh:mm:ss')

const hours = Duration.fromObject({milliseconds:milliseconds}).toFormat('hh')
const minutes = Duration.fromObject({milliseconds:milliseconds}).toFormat('mm')

return {time:resp, ms:milliseconds, hours, minutes} };
