/* eslint-disable react/prop-types */
/* eslint-disable */
import { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { List, Button, ListItemText, Checkbox, FormControl, FormControlLabel, IconButton } 
from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import { AutocompleteField } from '../FormFields';
import { StyledlistItems } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function SimpleModal({
  title,
  fieldName,
  open,
  setOpen,
  value,
  onChange,
  next,
  date,
  valueBroker,
  onChange2,
  competitorBrokers,
  finish,
  finishonChange,
  finishValue,
  listItems
}) {
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isListItems = listItems !== null;
  
  const [valuesFF, setValuesFF] = useState({
    scheduling_date: '',
    scheduling_time: '',
  });
  
  const handleClose = () => {
    setOpen(false);
  };

  const listItemClick = (item) => {
    next(item);
    handleClose();
  }

  const classes = useStyles();

  useEffect(() => {
    onChange({ target: { value: `${valuesFF.scheduling_date}T${valuesFF.scheduling_time}` } });
  }, [valuesFF]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {title}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            next(e);
          }}>
          <DialogContent>
            {isFirefox ? (
              <>
                <Box width="500px">
                  <p>{fieldName}</p>
                  <FormControl style={{ width: '150px' }}>
                    <TextField
                      required
                      id="date"
                      label=""
                      type="date"
                      value={valuesFF.scheduling_date}
                      disabled={finishValue}
                      onChange={(e) => setValuesFF({ ...valuesFF, scheduling_date: e.target.value })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                  <FormControl style={{ width: '150px', marginLeft: '10px' }}>
                    <TextField
                      required
                      id="time"
                      label=""
                      type="time"
                      value={valuesFF.scheduling_time}
                      disabled={finishValue}
                      onChange={(e) => setValuesFF({ ...valuesFF, scheduling_time: e.target.value })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Box>
                {competitorBrokers !== undefined && (
                  <>
                    <Box m={2} />
                    <AutocompleteField
                      valueText={valueBroker ? valueBroker.name : ''}
                      options={competitorBrokers}
                      value={valueBroker || null}
                      varibleName="competitor_broker"
                      onChange={onChange2}
                      label="Fechou com qual corretora?"
                      required
                    />
                  </>
                )}
              </>
            ) : (
              <FormControl fullWidth>
                {isListItems ?
                  <StyledlistItems>
                    <List component="ul">
                      {listItems.map((i, index, data) =>
                        <li>
                        <Button 
                          variant='outlined' 
                          className='listItemsWidth'
                          onClick={() => listItemClick(data[index])}>
                        <ListItemText primary={data[index].name} />
                        </Button>
                        </li>
                      )}
                    </List>
                  </StyledlistItems>
                  :
                  <TextField
                    required
                    onChange={onChange}
                    label={fieldName}
                    value={value || ''}
                    type={date ? 'datetime-local' : 'text'}
                    multiline={!date}
                    rows={date ? 1 : 5}
                    disabled={finishValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                }
                {competitorBrokers !== undefined && (
                  <>
                    <Box m={2} />
                    <AutocompleteField
                      valueText={valueBroker ? valueBroker.name : ''}
                      options={competitorBrokers}
                      value={valueBroker || null}
                      varibleName="competitor_broker"
                      onChange={onChange2}
                      label="Fechou com qual corretora?"
                      required
                    />
                  </>
                )}
              </FormControl>
            )}
            {finish && (
              <FormControlLabel
                control={
                  <Checkbox
                     checked={finishValue}
                     onChange={finishonChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Não consegui a data com o cliente"
              />
            )}
          </DialogContent>
          {isListItems ?
            <div></div>
            :
            <DialogActions>
              <Button type="submit" color="primary">
                Salvar
              </Button>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
            </DialogActions>
          }
        </form>
      </Dialog>
    </div>
  );
}

SimpleModal.propTypes = {
  title: P.string.isRequired,
  fieldName: P.string.isRequired,
  open: P.bool.isRequired,
  setOpen: P.func.isRequired,
  onChange: P.func.isRequired,
  onChange2: P.func,
  value: P.string,
  next: P.func.isRequired,
  date: P.bool,
  valueBroker: P.objectOf(P.any),
  listItems: P.node
};

SimpleModal.defaultProps = {
  date: false,
  value: null,
  valueBroker: {},
  listItems: null
};
