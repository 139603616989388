import api from "./api";

export const getChartInformation = async (filter) => {
    try {
      const {
        data ,
      } = await api.get(`/lead-chart?`+
      `date_ini=${filter.startDate}`+
      `&date_end=${filter.endDate}`+
      `&broker_id=${filter.broker?.id || ''}`+
      `&insurance_id=${filter.insurance?.id || ''}`+
      `&channel_id=${filter.channel?.id || ''}`+
      `&technical_team_id=${filter.technical_team?.id || ''}`
      , filter);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };