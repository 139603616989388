import { logout } from '../auth.service';
import Login from '../../pages/Login';

const M3tas = () =>
{    
    localStorage.setItem("tenantId", "m3tas");
    localStorage.setItem("logoVertical", "logoM3tas");
    logout();

    return Login();
};

export default M3tas;