/* eslint-disable */
import { useContext, useState, useEffect } from 'react';
import { MainContext } from '../../context/ApiContext';
import { Form, Return, Tabs } from './styles';
import { Grow } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { dataCreate } from '../../service/data.service';
import { toast } from 'react-toastify';
import DetailsForms from './DetailsForms';
import { isCpfValid } from '../../service/lead.service';


const BaseForm = ({ setFilters, inviter_id, withAuth, setHideThanks }) => {
  const { values, getters, creators } = useContext(MainContext);

  const insurances = values?.insurances

  const create = async (filter) => {
    const {
      data: { data },
    } = await api.post('/leadVerification', filter);
    dispatch({ type: 'GET_LEADVERIFICATION', payload: data });
  };

  const [client, setClient] = useState();
  const [externalMail, setExternalMail] = useState();
  const [personType, setPersonType] = useState();
  const [details, setDetails] = useState();

  const [selectedInsurance, setSelectedInsurance] = useState(null);

  const [hideClientData, setHideClientData] = useState();
  const [hideInsuranceButtons, setHideInsuranceButtons] = useState();
  const [hideDetails, sethideDetails] = useState();
  const [response, setResponse] = useState();
  // const [hideToast, setHideToast] = useState(false);


  function handleSubmit(event) {
    event.preventDefault();
  }

  const createLead = async () => {
    let insurance = insurances.find((ins) => ins.id == selectedInsurance);
 
    let data = {
      client: client,
      insurance: insurance,
      details: details,
      inviter_id: inviter_id,
      withAuth: withAuth ? 1 : 0,
    };
    setResponse(await dataCreate('/leads/externallead', data));
  };

  if (response == 'success') {
    toast.success('Lead salvo com sucesso!');
    setResponse(null);
    sethideDetails(null);
    setHideInsuranceButtons(true);
    setHideClientData(false);
    setSelectedInsurance(null);
    setDetails(null);
    setPersonType(null);
    setClient(null);
    if (!withAuth) {
      setHideThanks(false);
      setHideClientData(true);
    }
  } else if (response == 'fail') {
    toast.error('Houve um Problema');
    setResponse(null);
  } else if (response == 'successMail') {
    toast.success('O email será enviado!');
    setExternalMail('')
    setHideClientData(true);
 
    setResponse(null);   
  } else if (response?.url != undefined) {
    navigator.clipboard.writeText(response.url);
    toast.success('O Link foi copiado para a área de transferência! (Ctrl + V)');
    setExternalMail('')
    setHideClientData(true); 
    setResponse(null);   
  }

  useEffect(() => {
    setPersonType((state) => ({ ...state, pf: false }));
    setPersonType((state) => ({ ...state, pj: false }));
    setHideClientData(false);
  }, []);

  function handleClick(e, item) {
    e.preventDefault();
    setDetails(null);
    setSelectedInsurance(item.id);
  }

  function handleClientSubmit(e, item) {
    e.preventDefault();

    if (!isCpfValid(client?.cpf)) 
      return;

    changePersonType();
    setSelectedInsurance(null);
    setHideInsuranceButtons(false);
  }

  const handleExternalMailSubmit = async (event) => {
    event.preventDefault();
    setResponse(await dataCreate('/externalMail', {externalMail : externalMail}));
  };

  const handleGetLinkSubmit = async (event) => {
    setResponse(await dataCreate('/externalMail?onlyLink=true'));
  };

  function handleClientBack(e, item) {
    e.preventDefault();
    changePersonType();
    setSelectedInsurance(null);
    setHideClientData(false);
    setHideInsuranceButtons(true);
  }

  const changeClient = (e) => {
    e.preventDefault();
    setClient((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const changeExternalEmail = (e) => {
    e.preventDefault();
    setExternalMail(e.target.value);
  };

  const changePersonType = (e) => {
    if (client?.cpf?.length == 18) {
      setPersonType((state) => ({ ...state, pf: false }));
      setPersonType((state) => ({ ...state, pj: true }));
      setHideClientData(true);
    } else if (client?.cpf?.length == 14) {
      setPersonType((state) => ({ ...state, pf: true }));
      setPersonType((state) => ({ ...state, pj: false }));
      setHideClientData(true);
    } else {
      setPersonType((state) => ({ ...state, pf: false }));
      setPersonType((state) => ({ ...state, pj: false }));
    }
  };

  const pjTXT = {
    //  Patrimonial / Incêndio
    16: 'O Seguro Patrimonial tem como função proteger a integridade física das pessoas e de uma organização, a fim de prevenir ou reduzir os danos ao patrimônio. O cliente adquire uma apólice onde constam todas as regras e coberturas do seu plano.',
    //  Responsabilidade Civil e D&O:
    11: 'O Seguro de Responsabilidade Civil prevê o reembolso referente a indenizações por danos corporais ou materiais, causados de maneira não intencional a terceiros, nas dependências da empresa ou no local de prestação de serviço. O D&O é direcionado para executivos de empresas.',
    //  Automóveis e Frota de automóveis:
    18: 'O Seguro para frota de veículos é muito parecido com o do auto comum, porém a diferença é que o de frotas oferece cobertura para um número maior de automóveis em um mesmo contrato',
    //  Seguro Saúde:
    14: 'O Plano de Saúde e o Seguro Saúde tem o mesmo objetivo, que é prestar assistência médica e hospitalar. Ambos costumam dar cobertura para consultas, exames e cirurgias e possuem carências, principalmente quando a pessoa possui doenças preexistentes.',
    //  Seguro Cibernético:
    12: 'O Seguro Cibernético, ou Seguro de Riscos Digitais, oferece assistência e proteção para pessoas e empresas que foram prejudicadas por ataques cibernéticos, ou seja, pela ação de hackers, Ou vazamento de dados de clientes dentre outros mais.',
    //  Seguro garantia:
    13: 'Esse tipo de seguro atende às necessidades de empresas privadas e órgãos públicos. Sua função é garantir que se cumpram obrigações estipuladas em contratos, dando proteção contra o não cumprimento de cláusulas relacionadas a serviços, fornecimento de recursos e outras situações. Também é utilizado como proteção a execuções fiscais e outros casos de ações judiciais que ponham em risco o patrimônio ou o fluxo financeiro da empresa. O objeto do Seguro Garantia são as obrigações estabelecidas entre as partes envolvidas: o Contratado (Tomador) e a Contratante (Segurado), por meio da existência de um Contrato, Edital ou Ordem de Compra.',
    //  Seguro Odontológico:
    15: 'O Seguro Odontológico para empresas proporciona aos colaboradores o benefício de contar com consultas, limpeza e procedimentos relacionados à saúde bucal de acordo com o que está estipulado na apólice',
    //  Seguro de vida em grupo:
    17: 'O Seguro de Vida coletivo é contratado por empresas a fim de garantir apoio e cuidado ao funcionário e seus dependentes em caso de acidentes e fatalidades. No caso de morte do funcionário da empresa que contratou o seguro, seus familiares ou dependentes indicados na apólice recebem uma indenização.',
    //  Seguro para obras
    19: 'O Seguro de Obra tem o mesmo objetivo que outras formas de seguro, como o de automóveis, de casa e de vida. A ideia é que se algum imprevisto comprometer a obra, a seguradora tem os recursos financeiros para cobrir o prejuízo.',
  };

  return (
    <>
      {hideClientData == false && (
        <>
          <Grow in={true}>
            <Form>
              <h2 className="subtitle">Dados do Cliente:</h2>
              <form className="form" onSubmit={handleClientSubmit}>
                <div className="form-group">
                  <label for="name">
                    Nome Completo <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    id="first"
                    name="name"
                    value={client?.name}
                    onChange={(e) => changeClient(e)}
                    required
                  />
                </div>

                <div className="grid-flex">
                  <div className="form-group">
                    <label for="email">
                      e-mail <sup>*</sup>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={client?.email}
                      onChange={(e) => changeClient(e)}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label for="cpf">
                      CPF/CNPJ <sup>*</sup>
                    </label>
                    <InputMask
                      required
                      mask={client?.cpf?.length < 15 ? '999.999.999-99*' : '99.999.999/9999-99'}
                      maskChar=""
                      name="cpf"
                      value={client?.cpf}
                      onChange={(e) => changeClient(e)}
                      onBlur={() => isCpfValid(client?.cpf)} >
                      
                      {(params) => <input type="text" id="cpf" name="cpf" minlength="8" {...params} />}
                    </InputMask>
                  </div>
                </div>

                {client?.cpf?.length < 15 && (
                  <div className="form-group">
                    <label for="rg">RG {client?.cpf?.length >= 15 ? '' : <sup>*</sup>} </label>
                    <InputMask
                      required={client?.cpf?.length >= 15 ? false : true}
                      mask="999999999"
                      maskChar=""
                      name="rg"
                      value={client?.rg}
                      onChange={(e) => changeClient(e)}>
                      {(params) => <input type="text" id="rg" name="rg" {...params} />}
                    </InputMask>
                  </div>
                )}

                <div className="grid-flex">
                  <div className="form-group">
                    <label for="phone">
                      Telefone <sup>*</sup>
                    </label>
                    <InputMask
                      required
                      mask={client?.phone?.length < 15 ? '(99) 9999-99999' : '(99) 99999-9999'}
                      maskChar=""
                      name="phone"
                      value={client?.phone}
                      onChange={(e) => changeClient(e)}>
                      {(params) => <input type="text" id="phone" name="phone" {...params} />}
                    </InputMask>
                  </div>

                  <div className="form-group">
                    <label for="cellphone">
                      Celular <sup></sup>
                    </label>
                    <InputMask
                      mask={client?.cellphone?.length < 15 ? '(99) 9999-99999' : '(99) 99999-9999'}
                      maskChar=""
                      name="cellphone"
                      value={client?.cellphone}
                      onChange={(e) => changeClient(e)}>
                      {(params) => <input type="text" id="cellphone" name="cellphone" {...params} />}
                    </InputMask>
                  </div>
                </div>

                <div className="button-area">
                  <button className="button" type="submit">
                    Continuar
                  </button>
                </div>
              </form>
            </Form>
          </Grow>
          {withAuth ? (
            <>
              <Form>
                <h3 style={{ marginTop: '70px' }} className="subtitle">
                  Ou envie o formulário por email:
                </h3>
                <form className="form" onSubmit={handleExternalMailSubmit}>
                  <div className="form-group">
                    <label for="name">
                      Email <sup>*</sup>
                    </label>
                    <input
                      type="email"
                      id="first"
                      name="name"
                      value={externalMail}
                      onChange={(e) => changeExternalEmail(e)}
                      required
                    />
                  </div>

                  <div className="button-area">
                    <button className="button" type="submit">
                      Enviar por email
                    </button>
                  </div>
                </form>

                <div className="button-area">
                  <button className="button" onClick={() => handleGetLinkSubmit()}>
                    Copiar link
                  </button>
                </div>
              </Form>
            </>
          ) : (
            ''
          )}
        </>
      )}

      {hideClientData == true && ( // botão de voltar
        <Grow in={true}>
          <Return>
            <button className="button" type="button" onClick={(e) => handleClientBack(e)}>
              Voltar
            </button>
          </Return>
        </Grow>
      )}

      {personType?.pf &&
        !hideInsuranceButtons && ( // Botões Pessoa Fisica
          <Grow in={personType?.pf || personType?.pj}>
            <div>
              <h2 className="tab-title">Pessoa Física</h2>
              <Tabs className="tabs">
                {insurances.map((item) => {
                  switch (item.id) {
                    case 1:
                      break;
                    case 2:
                      break;
                    case 3:
                      return (
                        <div key={item.id}>
                          <button
                            className={selectedInsurance != item.id ? 'button' : 'button button-selected'}
                            type="button"
                            onClick={(e) => handleClick(e, item)}>
                            Automóvel
                          </button>
                        </div>
                      );

                      break;

                    default:
                      return (
                        item.type == 1 && (
                          <div key={item.id}>
                            <button
                              className={selectedInsurance != item.id ? 'button' : 'button button-selected'}
                              type="button"
                              onClick={(e) => handleClick(e, item)}>
                              {item.name}
                            </button>
                          </div>
                        )
                      );
                      break;
                  }
                })}
              </Tabs>
            </div>
          </Grow>
        )}

      {personType?.pj &&
        !hideInsuranceButtons && ( // Botões Pessoa Juridica
          <Grow in={personType?.pj}>
            <div>
              <h2 className="tab-title">Pessoa Jurídica</h2>

              <Tabs className="tabs">
                {insurances.map(
                  (item) =>
                    item.type == 2 && (
                      <div key={item.id}>
                        <button
                          className={selectedInsurance != item.id ? 'button' : 'button button-selected'}
                          type="button"
                          onClick={(e) => handleClick(e, item)}>
                          {item.name}
                        </button>
                      </div>
                    )
                )}
              </Tabs>
            </div>
          </Grow>
        )}
      <DetailsForms
        createLead={createLead}
        details={details}
        setDetails={setDetails}
        insurance={insurances.find((ins) => ins.id == selectedInsurance)}
        pjTXT={pjTXT}
      />
    </>
  );
};

export default BaseForm;
