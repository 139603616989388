import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import SaveIcon from '@material-ui/icons/Save';
import BasicFormRegister from '../../components/BasicForm';
import TableList from '../../components/TableListBase';
import { Header, Container, AddButton } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';

const Partners = () => {
  const [open, setOpen] = useState(false);
  const {
    values: { partners },
    getters: { getPartners },
    creators: { createPartner },
    deleters: { deletePartner },
    editors: { editPartner },
  } = useContext(MainContext);

  useEffect(() => {
    getPartners();
  }, [open]);

  return (
    <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
      <Container>
        <BasicFormRegister open={open} setOpen={setOpen} action={createPartner} title="Parceiro" fields={['name']} />
        <Header>
          <h1>Parceiros</h1>
          <div>
            <AddButton tvariant="contained" size="large" startIcon={<SaveIcon />} onClick={() => setOpen(true)}>
              Adicionar
            </AddButton>
          </div>
        </Header>
        <TableList listItem={partners} deleteData={deletePartner} editData={editPartner} title="Parceiro" />
      </Container>
    </motion.div>
  );
};

export default Partners;
