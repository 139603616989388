/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import P from 'prop-types';
import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogTitle, DialogContent, IconButton, Button, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DivSeparacao } from '../ClientForm/styles';
import { Loading } from '../Loading';
import { MaskedTextField } from '../FormFields';
import { FormGroup } from '../LeadForm/styles';
import api from '../../service/api';
import { ConfirmModalGeneric } from './confirmModal';

export default function UpdateCreditCard({ title, open, handleClose, assinatura }) {
  const [loading, setLoading] = useState(false);
  const [openConfirmGerarLink, setOpenConfirmGerarLink] = useState(false);

  const [newCard, setNewCard] = useState({
    numero: '',
    cvv: '',
    vencimento: '',
  });

  const handleCloseModal = () => {
    setNewCard({
      numero: '',
      cvv: '',
      vencimento: '',
    });

    handleClose();
  };

  const tratarNumeroCartao = (cardNumber) => cardNumber.replaceAll(' ', '');

  const [urlGeradaCartao, setUrlGeradoCartao] = useState();

  const GerarLinkTrocaCartao = async () => {
    try {
      const paymentLinkChangeCard = {
        identificadorLead: assinatura.identificadorLead,
      };
      const resp = await api.post(`maistodos/gerarLinkTrocaCartaoCredito`, paymentLinkChangeCard);
      if (resp.status == 200) {
        const domainMatch = window.location.href.match(/(?:https?:\/\/)?(?:www\.)?([^:/\n?]+)/);

        if (domainMatch) {
          const domain = domainMatch[1];
          const tenantId = localStorage.getItem('tenantId');
          const finalURL = `https://${domain}/replaceCard/${resp.data.external_public_key};${resp.data.external_private_key};${tenantId}`;

          setUrlGeradoCartao(finalURL);
        } else {
          toast.error('Não foi possível gerar o link.');
        }
      } else {
        toast.error('Não foi possivel gerar o link.');
      }
    } catch (error) {
      toast.error('Não foi possivel gerar o link.');
    }
  };

  const tratarVencimento = (vencimento) => {
    const data = vencimento.split('/');
    return `${data[1]}-${data[0]}`;
  };
  const handleAtualizarDadosCartao = async () => {
    const numero = tratarNumeroCartao(newCard.numero);
    const { cvv } = newCard;
    const vencimento = tratarVencimento(newCard.vencimento);

    if (numero.length != 16) {
      toast.error('Favor preencher o Número do Cartão corretamente.');
      return;
    }

    if (cvv.length != 3) {
      toast.error('Favor preencher o Código de Segurança corretamente.');
      return;
    }

    if (vencimento.length != 7) {
      toast.error('Favor preencher a Data de Vencimento corretamente.');
      return;
    }

    setLoading(true);
    try {
      const response = await api.post(`/maistodos/substituirCartaoAssinatura/${assinatura.id}`, {
        numero,
        cvv,
        vencimento,
      });
      if (response.status == 200 || response.status == 201) {
        toast.success(response.data.message);
        setLoading(false);
        handleClose();
      } else {
        toast.error(response.data.message);
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      const msg = 'Não foi possível atualizar o cartão!';
      if (error.response && (error.response.status === 400 || error.response.status === 500)) {
        toast.error(`${msg} ${error.response.data.message}`);
      } else {
        toast.error(`${msg} ${error}`);
      }
      setLoading(false);
    }
  };

  const openGerarLinkTrocaCartao = () => {
    GerarLinkTrocaCartao();
    setOpenConfirmGerarLink(true);
  };
  const handleConfirmGerarLink = () => {
    navigator.clipboard.writeText(urlGeradaCartao);
    toast.success('Link de troca de cartão copiado para sua área de transferência.');
  };

  const handleCloseGerarLink = () => {
    setOpenConfirmGerarLink(false);
  };

  return (
    <>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="form-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
        style={{ zIndex: 1000 }}>
        <DialogTitle id="form-dialog-title">
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <h3>{title}</h3>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          {loading ? (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', verticalAlign: 'center' }}>
              <Loading />
            </div>
          ) : (
            <>
              <FormGroup>
                <MaskedTextField
                  mask="9999 9999 9999 9999"
                  variant="outlined"
                  varibleName="card_number"
                  label="Número Cartão"
                  onChange={(e) => {
                    setNewCard({ ...newCard, numero: e.target.value });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <MaskedTextField
                  mask="999"
                  variant="outlined"
                  label="Código de Segurança"
                  isRequired
                  onChange={(e) => {
                    setNewCard({ ...newCard, cvv: e.target.value });
                  }}
                />
                <MaskedTextField
                  mask="99/9999"
                  variant="outlined"
                  label="Data de Vencimento"
                  onChange={(e) => {
                    setNewCard({ ...newCard, vencimento: e.target.value });
                  }}
                />
              </FormGroup>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={openGerarLinkTrocaCartao} color="primary">
            Gerar link troca cartão
          </Button>
          {urlGeradaCartao && (
            <ConfirmModalGeneric
              title="Link Gerado"
              description={
                <>
                  <textarea rows={5} style={{ width: '100%', padding: 10 }} disabled>
                    {urlGeradaCartao}
                  </textarea>
                </>
              }
              open={openConfirmGerarLink}
              customZIndex={8000}
              textConfirmButton="Copiar Link"
              textCancelButton="Fechar"
              handleConfirm={handleConfirmGerarLink}
              handleCloseConfirmation={handleCloseGerarLink}
            />
          )}
          <Button onClick={handleAtualizarDadosCartao} color="primary" medium>
            Atualizar Cartão
          </Button>

          <Button onClick={handleCloseModal} color="secondary" medium>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

UpdateCreditCard.propTypes = {
  title: P.string.isRequired,
  open: P.bool.isRequired,
  handleClose: P.func.isRequired,
  assinatura: P.object.isRequired,
};
