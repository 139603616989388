/* eslint-disable */
import { useContext, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import P, { shape } from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import { MainContext } from '../../context/ApiContext';

const SummaryReport = ({ dataItems }) => {
  const {
    deleters: { deleteUser },
  } = useContext(MainContext);

  const getMTSegNovos = (item) => {
    return dataItems.items['resultGoals'][item].length > 0 
      ? dataItems.items['resultGoals'][item][0]['value']
      : 0;
  }

  const checkLostSaleCauses = (index) => {
    return dataItems.items['lost_sale_causes'][index] !== undefined
      && !!dataItems.items['lost_sale_causes'][index].value;
  }

  const hideRightPanel = () => true;

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {/* <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>{'Dados'}</div> */}
        <TableContainer component={Paper} style={{ marginRight: 80 }}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell style={{ fontWeight: 'bold' }}>Metas</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Quantidade de Leads puxados no período</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(dataItems.items['count_leads']) <
                        Number(getMTSegNovos('mt_segnovos_capture_leads')) && 'red',
                  }}>
                  {dataItems.items['count_leads']}
                </TableCell>
                <TableCell colspan={2}>
                  {getMTSegNovos('mt_segnovos_capture_leads')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Puxou e ligou no prazo</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(
                        ((dataItems.items['leads_capture_on_time'] / dataItems.items['count_leads']) * 100).toFixed(2)
                      ) <
                        Number(getMTSegNovos('mt_segnovos_capture_leads_on_time_rate')) &&
                      'red',
                  }}>
                  {((dataItems.items['leads_capture_on_time'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
                <TableCell>{dataItems.items['leads_capture_on_time']}</TableCell>
                <TableCell colspan={2}>
                  {getMTSegNovos('mt_segnovos_capture_leads_on_time_rate')}%
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Conexões</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(((dataItems.items['all_conections'] / dataItems.items['count_leads']) * 100).toFixed(2)) <
                        Number(getMTSegNovos('mt_segnovos_connected_leads')) && 'red',
                  }}>
                  {((dataItems.items['all_conections'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>

                <TableCell>{dataItems.items['all_conections']}</TableCell>
                <TableCell colspan={2}>
                  {getMTSegNovos('mt_segnovos_connected_leads')}%
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowspan={4}>Conectou por onde</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Whatsapp</TableCell>

                <TableCell colspan={2}>Telefone/Celular</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {((dataItems.items['contact_whatsapp'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>

                <TableCell colspan={2}>
                  {((dataItems.items['contact_tel_cel'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{dataItems.items['contact_whatsapp']}</TableCell>
                <TableCell colspan={2}>{dataItems.items['contact_tel_cel']}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Cotações</TableCell>
                <TableCell
                  style={{
                    color:
                      Number((dataItems.items['quoted'] / dataItems.items['all_conections']) * 100) <
                        Number(getMTSegNovos('mt_segnovos_quoted_leads_rate')) && 'red',
                  }}>
                  {((dataItems.items['quoted'] / dataItems.items['all_conections']) * 100).toFixed(2)}%
                </TableCell>
                <TableCell>{dataItems.items['quoted']}</TableCell>
                <TableCell>{getMTSegNovos('mt_segnovos_quoted_leads_rate')}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowspan={4}>Mandou cotação por onde</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Whatsapp</TableCell>

                <TableCell colspan={2}>Telefone/Celular</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {((dataItems.items['quoted_whatsapp'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>

                <TableCell colspan={2}>
                  {((dataItems.items['quoted_tel_cel'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{dataItems.items['quoted_whatsapp']}</TableCell>
                <TableCell colspan={2}>{dataItems.items['quoted_tel_cel']}</TableCell>
              </TableRow>

              {/*  <TableRow>
                <TableCell>Cotou quanto tempo depois de puxar o lead</TableCell>
                <TableCell colspan={2}>{dataItems.items['average_of_sell_quotation_time']} horas</TableCell>
              </TableRow> */}

              <TableRow>
                <TableCell>Mandou cotação quanto tempo depois da conexão</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(dataItems.items['average_of_contact_after_quotation']) >
                        Number(
                          getMTSegNovos('mt_segnovos_quotation_hours_after_connection')
                        ) && 'red',
                  }}>
                  {dataItems.items['average_of_contact_after_quotation']}{' '}
                  {Number(dataItems.items['average_of_contact_after_quotation']) === 1 ? 'hora' : 'horas'}
                </TableCell>
                <TableCell>
                  {getMTSegNovos('mt_segnovos_quotation_hours_after_connection')}{' '}
                  {Number(
                    getMTSegNovos('mt_segnovos_quotation_hours_after_connection')
                  ) === 1
                    ? 'hora'
                    : 'horas'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Vendas de Seguros (% é de leads puxados)</TableCell>
                <TableCell>
                  {((dataItems.items['sales_quantity'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
                <TableCell
                  style={{
                    color:
                      Number(dataItems.items['sales_quantity']) <
                        Number(getMTSegNovos('mt_segnovos_insurance_sales')) && 'red',
                  }}>
                  {dataItems.items['sales_quantity']}
                </TableCell>
                <TableCell>{getMTSegNovos('mt_segnovos_insurance_sales')}%</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowspan={4}>Vendeu por onde</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Whatsapp</TableCell>
                <TableCell>Telefone/Celular</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  {((dataItems.items['sales_on_whatsapp'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
                <TableCell>
                  {((dataItems.items['sales_on_tel_cel'] / dataItems.items['count_leads']) * 100).toFixed(2)}%
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>{dataItems.items['sales_on_whatsapp']}</TableCell>
                <TableCell>{dataItems.items['sales_on_tel_cel']}</TableCell>
                <TableCell />
              </TableRow>

              <TableRow>
                <TableCell>Vendeu quanto tempo depois da cotação</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(dataItems.items['average_of_quotation_time']) >
                        Number(getMTSegNovos('mt_segnovos_sold_after_days_quotation')) &&
                      'red',
                  }}>
                  {dataItems.items['average_of_quotation_time']} dias
                </TableCell>
                <TableCell colspan={2}>
                  {getMTSegNovos('mt_segnovos_sold_after_days_quotation')}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Tempo total médio da venda</TableCell>
                <TableCell
                  colspan={2}
                  style={{
                    color:
                      Number(dataItems.items['average_of_sales_time']) >
                        Number(getMTSegNovos('mt_segnovos_average_sales_time_days')) &&
                      'red',
                  }}>
                  {dataItems.items['average_of_sales_time']} dias
                </TableCell>
                <TableCell>
                  {getMTSegNovos('mt_segnovos_average_sales_time_days')}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Comissão média no período</TableCell>
                <TableCell
                  colSpan={2}
                  style={{
                    color:
                      Number(dataItems.newTableItems['average_comissions']) <
                        Number(getMTSegNovos('mt_segnovos_average_commission_rate')) &&
                      'red',
                  }}>
                  {dataItems.newTableItems['average_comissions']}%
                </TableCell>
                <TableCell>
                  {getMTSegNovos('mt_segnovos_average_commission_rate')}%
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  Motivos principais das perdas (3)
                </TableCell>
              </TableRow>

              {dataItems.items['lost_sale_causes'].length > 0 ? (
                <>
                  {checkLostSaleCauses(0) && (
                    <TableRow>
                      <TableCell>{`1) ${dataItems.items['lost_sale_causes'][0].value}`}</TableCell>
                      <TableCell colSpan={3}>
                        {(
                          (dataItems.items['lost_sale_causes'][0].quantity / dataItems.items['sales_quantity']) *
                          100
                        ).toFixed(2)}
                        %
                      </TableCell>
                    </TableRow>
                  )}

                  {checkLostSaleCauses(1) && (
                    <TableRow>
                      <TableCell>{`2) ${dataItems.items['lost_sale_causes'][1].value}`}</TableCell>
                      <TableCell colSpan={3}>
                        {(
                          (dataItems.items['lost_sale_causes'][1].quantity / dataItems.items['sales_quantity']) *
                          100
                        ).toFixed(2)}
                        %
                      </TableCell>
                    </TableRow>
                  )}

                  {checkLostSaleCauses(2) && (
                    <TableRow>
                      <TableCell>{`3) ${dataItems.items['lost_sale_causes'][2].value}`}</TableCell>
                      <TableCell colSpan={3}>
                        {(
                          (dataItems.items['lost_sale_causes'][2].quantity / dataItems.items['sales_quantity']) *
                          100
                        ).toFixed(2)}
                        %
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell>{`Não existem motivos de perda`}</TableCell>
                  <TableCell colSpan={3}>N/A</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {(!hideRightPanel() &&
        <TableContainer style={{display: 'none'}} component={Paper}>
          <Table>
            <TableBody>
              {/* <TableRow>
                <TableCell>Venda de seguros novos/externos no período</TableCell>
                <TableCell colSpan={3}>{dataItems.newTableItems['new_insurances_sells']} %</TableCell>
              </TableRow>*/}
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell style={{ fontWeight: 'bold' }}>Metas</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Percentual de conversão de lead que chegou pelo site/suthub/app</TableCell>
                <TableCell>{dataItems.conversions['conversion_site_sitehub_app']['average']}</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(dataItems.conversions['conversion_site_sitehub_app']['countItems']) <
                        Number(getMTSegNovos('mt_segnovos_site_suthub_app')) && 'red',
                  }}>
                  {(
                    (Number(dataItems.conversions['conversion_site_sitehub_app']['dividedBy']) /
                      Number(dataItems.items['count_leads'])) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell>{getMTSegNovos('mt_segnovos_site_suthub_app')}%</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Percentual de conversão de lead que chegou por indicação int./ext.</TableCell>
                <TableCell>{dataItems.conversions['conversion_intern_extern']['average']}</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(dataItems.conversions['conversion_intern_extern']['countItems']) <
                        Number(getMTSegNovos('mt_segnovos_indication_int_ext')) && 'red',
                  }}>
                  {(
                    (Number(dataItems.conversions['conversion_intern_extern']['dividedBy']) /
                      Number(dataItems.items['count_leads'])) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell>{getMTSegNovos('mt_segnovos_indication_int_ext')}%</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Percentual de conversão de lead que chegou por redes sociais</TableCell>
                <TableCell>{dataItems.conversions['conversion_social_media']['average']}</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(dataItems.conversions['conversion_social_media']['countItems']) <
                        Number(getMTSegNovos('mt_segnovos_social_medias')) && 'red',
                  }}>
                  {(
                    (Number(dataItems.conversions['conversion_social_media']['dividedBy']) /
                      Number(dataItems.items['count_leads'])) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell>{getMTSegNovos('mt_segnovos_social_medias')}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Percentual de conversão de lead que chegou por marketing cloud</TableCell>
                <TableCell>{dataItems.conversions['conversion_mkt_cloud']['average']}</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(dataItems.conversions['conversion_mkt_cloud']['countItems']) <
                        Number(getMTSegNovos('mt_segnovos_mkt_cloud')) && 'red',
                  }}>
                  {(
                    (Number(dataItems.conversions['conversion_mkt_cloud']['dividedBy']) / Number(dataItems.items['count_leads'])) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell>{getMTSegNovos('mt_segnovos_mkt_cloud')}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Percentual de conversão de lead que chegou por white label</TableCell>
                <TableCell>{dataItems.conversions['conversion_white_label']['average']}</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(dataItems.conversions['conversion_white_label']['countItems']) <
                        Number(getMTSegNovos('mt_segnovos_white_label')) && 'red',
                  }}>
                  {(
                    (Number(dataItems.conversions['conversion_white_label']['dividedBy']) / Number(dataItems.items['count_leads'])) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell>{getMTSegNovos('mt_segnovos_white_label')}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Percentual de conversão de lead que chegou por chatbot (blip)</TableCell>
                <TableCell>{dataItems.conversions['conversion_chatbot']['average']}</TableCell>
                <TableCell
                  style={{
                    color:
                      Number(dataItems.conversions['conversion_chatbot']['countItems']) <
                        Number(getMTSegNovos('mt_segnovos_chatbot')) && 'red',
                  }}>
                  {(
                    (Number(dataItems.conversions['conversion_chatbot']['dividedBy']) / Number(dataItems.items['count_leads'])) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell>{getMTSegNovos('mt_segnovos_chatbot')}%</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  De todas as finalizações, as que não venderam gastaram o tempo médio a seguir da cotação para a
                  finalização
                </TableCell>
                <TableCell colSpan={3}>{dataItems.newTableItems['average_of_quoted_finalization_time']} dias</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowspan={3}>
                  De todas as finalizações, as que não venderam gastaram o tempo médio a seguir do envio da cotação para
                  a finalização
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  {dataItems.newTableItems['notSoldresultDiffHoursQuoted']['hours']}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  {dataItems.newTableItems['notSoldresultDiffHoursQuoted']['countItems']}
                </TableCell>
                <TableCell colSpan={2}>
                  {(
                    (dataItems.newTableItems['notSoldresultDiffHoursQuoted']['countItems'] /
                      dataItems.items['quoted']) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowspan={4}>Conversão destas cotações enviadas por canal em %</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Whatsapp</TableCell>
                <TableCell colSpan={2}>Telefone/Celular</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {((dataItems.newTableItems['quoted_conversion_wpp'] / dataItems.items['count_leads']) * 100).toFixed(
                    2
                  )}
                  %
                </TableCell>

                <TableCell>
                  {(
                    (dataItems.newTableItems['quoted_conversion_cel_tel'] / dataItems.items['count_leads']) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>{dataItems.newTableItems['quoted_conversion_wpp']}</TableCell>
                <TableCell>{dataItems.newTableItems['quoted_conversion_cel_tel']}</TableCell>
                <TableCell />
              </TableRow>

              <TableRow>
                <TableCell rowspan={3}>
                  De todas as finalizações, as que não venderam gastaram o tempo médio a seguir do envio da cotação para
                  finalização
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  {dataItems.newTableItems['resultDiffHoursQuoted']['hours']}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>{dataItems.newTableItems['resultDiffHoursQuoted']['countItems']}</TableCell>
                <TableCell colSpan={2}>
                  {(
                    (dataItems.newTableItems['resultDiffHoursQuoted']['countItems'] / dataItems.items['quoted']) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowspan={3}>
                  De todas as finalizações, as que venderam gastaram o tempo médio a seguir desde que puxou e finalizou
                  o lead (tempo total da venda, do início ao fim)
                </TableCell>
              </TableRow>


              {dataItems.newTableItems['resultDiffDays'] !== undefined && (
                <>
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                      {dataItems.newTableItems['resultDiffDays']['days']}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>{dataItems.newTableItems['resultDiffDays']['countItems']}</TableCell>
                    <TableCell colSpan={2}>
                      {(
                        (dataItems.newTableItems['resultDiffDays']['countItems'] / dataItems.items['sales_on_tel_cel']) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                  </TableRow>
                </>
              )}

              
            </TableBody>
          </Table>
        </TableContainer>
        )}
      </div>
    </div>
  );
};

SummaryReport.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
    })
  ),
  title: P.string.isRequired,
};

SummaryReport.defaultProps = {
  listItem: null,
};

export default SummaryReport;
