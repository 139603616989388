/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import {
    Box, IconButton, Paper,
    Table, TableBody,
    TableCell,
    TableContainer, TableHead, TableRow, makeStyles,
    Collapse,
    Typography, Dialog, DialogTitle, Divider

} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import pdfIcon from '../../assets/img/pdf-icon.svg'
import { DivBotoes, DivSeparacao } from './styles';
import api from '../../service/api';
import { file } from '@babel/types';
import { CloudDownload, Visibility, VisibilitySharp } from '@material-ui/icons';



function Arquivo(props) {

    const { pdfData } = props;


    return (

        <>
            {pdfData && (
                <div style={{ height: '400px', width: '100%', padding: 10, display: 'flex' }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={`data:application/pdf;base64,${pdfData}`} />
                    </Worker>
                </div>
            )
            }
        </>

    )
}

Arquivo.propTypes = {
    pdfData: P.string.isRequired,
};



export default function ModalArquivos({ title, open, setOpen, fileName, leads, cliente ,especial}) {

    const handleClose = () => {
        setOpen(false);
    };

    const [pdfData, setPdfData] = useState('');

    const clickIcon = async (lead) => {

        try {

            const response = await api.get(
                especial?`${fileName}?identificadorLead=${lead.lead_id}&nomeArquivo=${lead.file_name.replace(".pdf","")}`:`leads/fileDownloadByName?lead_id=${lead}&fileName=${fileName}`,
                {
                    responseType: 'arraybuffer',
                }
            );

            const binaryData = btoa(
                new Uint8Array(response.data).reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                }, '')
            );

            setPdfData(binaryData);
        } catch (error) {
            console.log(error);
        }
    }

    const capitalizeFirstLetter = (str) => {
        if (typeof str !== 'string' || str.length === 0) {
            return str;
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    const criarFileName = (lead) => {
        if(especial)
            return lead.file_name;

        let tempFileName = fileName;

        if (tempFileName === 'certificado')
            tempFileName = 'apólice';

        if (cliente) {
            const filename = capitalizeFirstLetter(tempFileName);

            const leadArquivo = cliente.leads.find((item) => item.id == lead)

            const nomeArquivoExibicao = `${filename}-${leadArquivo?.insurance?.name}-${lead}`
            return nomeArquivoExibicao;
        }

        return `${capitalizeFirstLetter(tempFileName)}-${lead}`;

    }

    const onClickBaixar = async (lead) => {
        try {

            const response = await api.get(
                especial?`${fileName}?identificadorLead=${lead.lead_id}&nomeArquivo=${lead.file_name.replace(".pdf","")}`:`leads/fileDownloadByName?lead_id=${lead}&fileName=${fileName}`,
                {
                    responseType: 'arraybuffer',
                }
            );

            const binaryData = btoa(
                new Uint8Array(response.data).reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                }, '')
            );

            const link = document.createElement('a');
            link.href = `data:application/pdf;base64,${binaryData}`;
            link.download = especial?lead.file_name:`${criarFileName(lead)}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Dialog onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                open={open}
                maxWidth="md"
                style={{ zIndex: 15615 }}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <h3>{title}</h3>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>

                </DialogTitle>

                <DivSeparacao >
                    <div style={{ width: '100%' }}>
                        <DivSeparacao>
                            {
                                leads.length > 0 ?
                                    leads.map((lead) => (
                                        <div style={{ margin: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', width: 170 }}>
                                            <a onClick={() => { clickIcon(lead) }} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', width: 170 }} >
                                                <img src={pdfIcon} alt='PDF ICON' />
                                                <p style={{ textAlign: 'center', fontSize: '12px', marginTop: 5 }}>{criarFileName(lead)}</p>
                                            </a>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', }}>
                                                <Button onClick={() => { onClickBaixar(lead) }}
                                                    startIcon={<CloudDownload style={{ alignSelf: 'flex-start' }} />} >
                                                    Baixar
                                                </Button>
                                                <Button onClick={() => { clickIcon(lead) }} style={{ backgroundColor: '#fc986a' }}
                                                    startIcon={<VisibilitySharp style={{ alignSelf: 'flex-start' }} />} >
                                                    Ver
                                                </Button>
                                            </div>
                                        </div>
                                    )) : (
                                        <>
                                            <h3 style={{ margin: 20 }}>Cliente não possui este tipo de anexo.</h3>
                                        </>
                                    )
                            }



                        </DivSeparacao>
                        <Arquivo pdfData={pdfData} />
                    </div>

                </DivSeparacao>
            </Dialog>
        </div>
    )
}

ModalArquivos.propTypes = {
    open: P.bool.isRequired,
    title: P.string.isRequired,
    setOpen: P.func.isRequired,
    leads: P.objectOf(P.string),
    fileName: P.string.isRequired,
    cliente: P.objectOf(P.string).isRequired,
    especial:P.bool,
};

ModalArquivos.defaultProps = {
    leads: null,
    especial:false
};
