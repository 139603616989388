/* eslint-disable camelcase */
import useSWR from 'swr';
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import SaveIcon from '@material-ui/icons/Save';
import { Header, Container, AddButton } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import TechnicalTeamTableList from '../../components/TechnicalTeamTableList';
import TechinicalTeamForm from '../../components/TechinicalTeamForm';
import useApp from '../../hooks/useApp';
import { deleteTechnicalTeam, editTechnicalTeam } from '../../service/technicalTeam.service';
import { fetcher } from '../../service/SWRFetcher';

const TechnicalTeam = () => {
  const {setState} = useApp();
  const { data: technical_teams, mutate } = useSWR(`/technical-team`, fetcher);

  useEffect(() => {
    if(technical_teams)
      setState('technical_teams', technical_teams)
  }, [technical_teams])

  const [open, setOpen] = useState(false);

  return (
    <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
      <Container>
        <TechinicalTeamForm mutate={mutate} open={open} setOpen={setOpen} title="Equipe Técnica" />
        <Header>
          <h1>Equipes Técnicas</h1>
          <div>
            <AddButton tvariant="contained" size="large" startIcon={<SaveIcon />} onClick={() => setOpen(true)}>
              Adicionar
            </AddButton>
          </div>
        </Header>
        <TechnicalTeamTableList
          listItem={technical_teams}
          deleteTechnicalTeam={deleteTechnicalTeam}
          editTechnicalTeam={editTechnicalTeam}
          title="Equipes Técnicas"
          mutate={mutate}
        />
      </Container>
    </motion.div>
  );
};

export default TechnicalTeam;
