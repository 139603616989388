import { string } from "prop-types";
import { PhoneMask, RemoveMask } from '../utils/phoneMask';
import api from './api';


const FormatPhoneNumber = (phone: string) => {
    let phoneFormat = RemoveMask(phone);
    if (phoneFormat.indexOf('55') != 0) 
        phoneFormat = `55${phoneFormat}`;

    return phoneFormat;
}

export const GetDomainProvider = () =>
    'conectawebhook.com.br';

export const GetWhatsAppProvider = () => 
    localStorage.getItem("whatsAppProvider");

export const GetwhatsAppProviderApiKey = () => 
    localStorage.getItem("whatsAppProviderApiKey");

export const GetwhatsAppProviderCompany = () => 
    localStorage.getItem("whatsAppProviderCompany");    

export enum ProviderType {
    ToTalk = "TOTALK",
}

export const GetLiveChat = (phone: string) => {
    const phoneParam = FormatPhoneNumber(phone);
    const company = GetwhatsAppProviderCompany();

    return `https://www.totalk.chat/${company}/live-chat/all/${phoneParam}`;
}

export const PostCreateMsgFlow = 
    async (phone: string, clientId: number, handleCloseCallBack: any) => 
{
    const phoneParam = FormatPhoneNumber(phone);
    const url = `/totalk/post_create_msg_flow/${phoneParam}/client_id/${clientId}`;
    const res = await api.post(url);
    if (res) 
        handleCloseCallBack();
}

export const PostDeactivateRemider = 
    async (phone: string, clientId: number, handleCloseCallBack: any) => 
{
    const phoneParam = FormatPhoneNumber(phone);
    const url = `/totalk/post_control_msg_flow/${phoneParam}/client_id/${clientId}/value/1`;
    const res = await api.post(url);
    if (res) 
        handleCloseCallBack();
}

