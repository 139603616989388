import { useEffect, useState } from 'react';
import { TablePagination } from '@material-ui/core';
import { motion } from 'framer-motion';
import useSWR from 'swr';
import { Header, Container } from '../../styles/shared/styles';
import UserTableList from '../../components/UserTableList';
import { variantFetcher } from '../../service/SWRFetcher';
import DatePeriodFilter from '../../components/UserTableList/Filter';
import useApp from '../../hooks/useApp';
import { deleteUser, editUser } from '../../service/user.service';

const Users = () => {
  const { setState } = useApp();
  const [filters, setFilters] = useState({
    name: '',
    login: '',
    per_page: 10,
    page: 0,
    status: '',
  });

  const [listUsers, setListUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const {
    data: users,
    mutate,
    isValidating,
  } = useSWR(
    `/users/list?name=${filters.name}&login=${filters.login}&status=${filters.status}&per_page=${
      filters.per_page
    }&page=${filters.page + 1}`,
    variantFetcher
  );

  useEffect(() => {
    setState('loading', isValidating);
  }, [isValidating])

  useEffect(() => {
    if (users?.data) {
      setListUsers([...users?.data]);
      setTotalPages(users?.meta?.total);
    }
  }, [users]);

  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, page: 0, per_page: parseInt(event.target.value, 10) });
  };

  return (
    <>
      <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
        <Container>
          <Header>
            <h1>Usuários</h1>
          </Header>
          <DatePeriodFilter setFilters={setFilters} filters={filters} />
          {!!listUsers.length && (
            <>
              <TablePagination
                component="div"
                count={totalPages}
                page={filters.page}
                onPageChange={handleChangePage}
                rowsPerPage={filters.per_page}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <UserTableList
                listItem={listUsers}
                deleteUser={deleteUser}
                editData={editUser}
                title="Usuário"
                mutate={mutate}
              />
            </>
          )}
        </Container>
      </motion.div>
    </>
  );
};

export default Users;
