import { Container, FormContainer } from './style';
import { LogoVertical } from '../../components/Logo';


const DefaultPage = () => (
    <Container>
      <div style={{textAlign: 'center'}}> 
        <p><h3>SEJA BEM VINDO</h3></p>
        <br/>
        <p><LogoVertical /></p>
        <br/>
        <p>Para entrar informe uma empresa...</p>
      </div>
      
    </Container>
  );

export default DefaultPage;
