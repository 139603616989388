import styled from 'styled-components';
import { TextField, AppBar } from '@material-ui/core';

export const Container = styled.div`
  margin-top: 30px;
  padding: 0 100px;

  .form {
    box-shadow: 1px 2px 5px gray;
    margin: 5px 0 20px 0;
  }

  .form-buttons{
    display: flex;
    justify-content: flex-end;
    button {
      width: 150px;
    }
    button + button {
        margin-left: 10px;
      }
    }
    /* .bottom {
      margin: 20px 10px;
    } */

  .buttonContainer {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    .of-file {
      width: 300px;
      margin-left: 15px;
    }
  }
`;

export const StyledAppBar = styled(AppBar)`
  && {
    position: fixed;
    background-color: #f26522;
  }
  h4 {
    margin-left: 15px;
    flex: 1;
  }
  button {
    font-family: inherit;
    font-size: 14.08px;
  }
`;

export const ButtonGroup = styled.div`
  text-align: center;
  width: 170px;

  button {
    width: 120px !Important;
    height: 26px !Important;
    margin: 0px 0px 1px 0px !Important;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${(props) => (props.dark ? '#f1f1f1' : '#fefefe')};
  padding: 10px 0;

  .checkbox {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: blue;
  }

  .control {
    flex: 1 1 auto;
    margin: 0 5px;
  }
  .s {
    flex: 0.2 1 auto;
  }
  .ms {
    flex: 0.5 1 auto;
  }
  .m {
    flex: 2 1 auto;
  }
  .g {
    flex: 3 1 auto;
  }
  .gg {
    flex: 3 1 auto;
  }
  .x {
    flex: 4 1 auto;
  }
  .xl {
    flex: 5 1 auto;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    label.Mui-focused {
      color: #f26522;
    }
    .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #f26522;
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: calc(100vh - 55px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingDiv = styled.div`
  width: 99vw;
  height: 93vh;
  display: flex;        
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #ffffffaf;
  z-index: 9999;
`;
