import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Checkbox, FormControlLabel, IconButton, TextField, TableBody, TableRow,
    TableCell, TableHead } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { AddCircleRounded, DeleteForever } from '@material-ui/icons';
import { toast } from 'react-toastify';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import P from 'prop-types';
import { variantFetcher } from '../../service/SWRFetcher';
import { LoadingButton } from '../Loading';
import { editSchema, deletePercents } from '../../service/commissionSchema.service';
import CommissionPercentsRangeAdd from './CommissionPercentsRangeAdd';
import CommissionPercentsRangeEdit from './CommissionPercentsRangeEdit';


export default function CommissionPercents({ open, setOpen, commissionSchema, schemaName, mutate }) {
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [schemaValues, setSchemaValues] = useState({
        name: '',
        description: '',
        status: null
    });
    const [percentsValues, setPercentsValues] = useState([]);
    const [getPercents, setPercents] = useState([]);
    const [loading, setLoadding] = useState(true);

    const handleEdit = (item) => {
      setPercents(item);
      setOpenEdit(true);
    }

    const handleAddPercents = () => {
      setOpenAdd(true);
    }

    const handleClose = () => {
        setOpen(false);
    };
    
    const entering = async () => {
        setLoadding(true);

        const percents = await variantFetcher(`commission-percents/schema/${commissionSchema.id}`);
        // console.log(percents);
        setPercentsValues(percents);

        setSchemaValues({
          name: commissionSchema.name,
          description: commissionSchema.description,
          status: commissionSchema.status,
        });

        setLoadding(false);
    };

    const handleDelete = (item) => {
      if(window.confirm('Confirma Remover essa Comissão?')) {
        deletePercents(item.id);
        entering();
      }
    }
      
    const submit = async (e) => {
        e?.preventDefault();
        
        const schema = {
            name: schemaValues.name,
            description: schemaValues.description,
            status: schemaValues.status,
        };

        try {
            await editSchema(schema, commissionSchema.id);

            toast.success('Tabela atualizada com sucesso');
            mutate();
        } catch (error) {
            toast.error('Erro ao modificar a tabela');
        }

        handleClose();
    };


    function RenderCommissionPercentsList() {
        return (
            <>
            <TableHead>
                <TableRow>
                    <TableCell/>
                    <TableCell><b>De</b></TableCell>
                    <TableCell><b>Até</b></TableCell>
                    <TableCell><b>Comissão</b></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {percentsValues !== null &&
              percentsValues.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell>
                    {item.begin}%
                  </TableCell>
                  <TableCell>
                    {item.end}%
                  </TableCell>
                  <TableCell>
                    {item.commission}%
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleEdit(item)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(item)}>
                      <DeleteForever />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          </>
        )
    }

    function RenderDialogContent() {
        return (
            <DialogContent>

            <TextField
                autoFocus
                margin="dense"
                label="Tabela"
                type="name"
                value={schemaValues.name}
                fullWidth
                required
                onChange={(e) => setSchemaValues({ ...schemaValues, name: e.target.value })}
            />

            <TextField
                margin="dense"
                label="Descrição"
                type="text"
                value={schemaValues.description}
                fullWidth
                onChange={(e) => setSchemaValues({ ...schemaValues, description: e.target.value })}
            />              

            <FormControlLabel
                control={
                <Checkbox
                    checked={schemaValues.status}
                    onChange={(e) => setSchemaValues({ ...schemaValues, status: e.target.checked })}
                    color="default"
                />
                }
                label="Tabela ativa"
            />

            <br/>
            <p style={{border: '2px solid #ccc'}} />
            <br/>

            {RenderCommissionPercentsList()}

            </DialogContent>            
        )
    }

      
    return (
        <div>
          <CommissionPercentsRangeEdit
            open={openEdit} 
            setOpen={setOpenEdit} 
            commissionPercents={getPercents}
            mutate={mutate}
            update={entering}
          />

          <CommissionPercentsRangeAdd 
            open={openAdd} 
            setOpen={setOpenAdd} 
            schemaId={commissionSchema?.id} 
            mutate={mutate}
            update={entering}
          />

          <Dialog
            TransitionProps={{onEntering: entering}}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="md">
            <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Comissões - {schemaName}
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle >
            <form onSubmit={submit}>
              
              {loading ? 
                <div style={{width: '50px', margin:'auto'}}><LoadingButton /></div> :
                RenderDialogContent()
              }

              <DialogActions style={{position: 'sticky', bottom: 0, backgroundColor:'white', width:'100%',}}>
                <Button variant="contained" onClick={handleAddPercents} style={{position: 'absolute', left: '15px'}}>
                    <AddCircleRounded style={{marginRight: '10px'}}/>
                    Nova comissão
                </Button>

                <Button onClick={handleClose} color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Salvar
                </Button>
              </DialogActions>
    
            </form>
          </Dialog>
        
        </div>
      );
}
    
CommissionPercents.propTypes = {
    open: P.bool.isRequired,
    setOpen: P.func.isRequired,
    commissionSchema: P.element.isRequired,
    schemaName: P.string.isRequired,
    mutate: P.func.isRequired,
  };