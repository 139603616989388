import { forwardRef } from 'react';
import P from 'prop-types';
import { Dialog, Toolbar, IconButton, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StyledAppBar } from './styles';


const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function FullScreenDialog({ open, setOpen, leadId, children }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <StyledAppBar style={{backgroundColor: 'rgb(250 156 18)'}}>
        <div className='leadId'>Lead: {leadId}</div>
        <Toolbar style={{display: 'flex', justifyContent: 'flex-end', minHeight: '30px'}}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="small">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </StyledAppBar>
      <div style={{marginTop: '50px', position: 'relative', backgroundColor: '#e7e7e7'}}>
      {children}
      </div>
    </Dialog>
  );
}

FullScreenDialog.propTypes = {
  open: P.bool.isRequired,
  children: P.node.isRequired,
  setOpen: P.func.isRequired,
  leadId: P.number
};

FullScreenDialog.defaultProps = {
  leadId: null
}