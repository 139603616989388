import { useState, useEffect } from 'react';
import P from 'prop-types';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import useSWR from 'swr';
import InputMask from 'react-input-mask';
import { IconButton, TablePagination, Dialog, DialogTitle, DialogContent, Button, TextField, DialogActions, Divider, Paper, FormGroup } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import CloseIcon from '@material-ui/icons/Close';
import { useAuth } from '../../hooks/useAuth';
import { maskCpf, maskDateTime, maskCurrency, maskDate } from '../../utils/masks';
import api from '../../service/api';
import { fetcher } from '../../service/SWRFetcher';
import { Header, Container } from '../../styles/shared/styles';

export default function ConfirmModal({title, description, open, handleCloseConfirmation, handleConfirm }) {


    return (
        <Dialog open={open} onClose={handleCloseConfirmation} style={{zIndex: 99999}}>
            <DialogTitle><h3>{title} </h3></DialogTitle>
            <DialogContent>
                {description}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="secondary">
                    Confirmar
                </Button>
                <Button onClick={handleCloseConfirmation} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )

}

ConfirmModal.propTypes = {
    title: P.string.isRequired,
    description: P.string.isRequired,
    open: P.bool.isRequired,
    handleCloseConfirmation: P.func.isRequired,
    handleConfirm: P.func.isRequired
}


export function ConfirmModalGeneric({title, description, open, handleCloseConfirmation, handleConfirm, textConfirmButton, textCancelButton, customZIndex }) {


    return (
        <Dialog open={open} onClose={handleCloseConfirmation} style={{zIndex: customZIndex}} fullWidth >
            <DialogTitle><h3>{title} </h3></DialogTitle>
            <DialogContent>
                {description}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="secondary">
                    {textConfirmButton}
                </Button>
                <Button onClick={handleCloseConfirmation} color="primary">
                    {textCancelButton}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

ConfirmModalGeneric.propTypes = {
    title: P.string.isRequired,
    customZIndex: P.number.isRequired,
    description: P.string.isRequired,
    textConfirmButton: P.string.isRequired,
    textCancelButton: P.string.isRequired,
    open: P.bool.isRequired,
    handleCloseConfirmation: P.func.isRequired,
    handleConfirm: P.func.isRequired
}