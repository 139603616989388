/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { InputAdornment } from '@material-ui/core';
import { FormGroup } from '../styles';
import { TextField, AutocompleteField } from '../../FormFields';
import { CommonInvoiceInsuranceQuestions } from './commonQuestions';
import { formatarParaEstiloPTBR } from '../../../hooks/Utils';


export const InsuranceBikeTypeArray = [
    { name: 'Convencional', id: 1 },
    { name: 'Elétrica', id: 2 },
];

export function BikeQuestions({
    insuranceInvoice,
    insuranceModel,
    insuranceBrand,
    insuranceBikeManufactureYear,
    insuranceBikeCost,
    insuranceBikeType,
    insuranceBikeUseType,
    onChange,
    onChangeAutoComplete,
    onChangeNumericBikeManufactureYear,
    onChangeNumericBikeCost,
})
{
    
    return (
        <>
        <FormGroup dark>
            <CommonInvoiceInsuranceQuestions
                onChange={onChange}
                insuranceInvoice={insuranceInvoice}
                insuranceBrand={insuranceBrand}
                insuranceModel={insuranceModel}
            />
            </FormGroup>

            <FormGroup dark>
            <NumericFormat
                label="Ano de fabricação"
                customInput={TextField}
                decimalScale={0}
                autoComplete="off"
                value={insuranceBikeManufactureYear || ''}
                onChange={onChangeNumericBikeManufactureYear}
            />
            <NumericFormat
                label="Valor de mercado"
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                autoComplete="off"
                InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
                value={formatarParaEstiloPTBR(insuranceBikeCost)}
                onChange={onChangeNumericBikeCost}
            />
            <AutocompleteField
                medium
                value={insuranceBikeType || null}
                onChange={onChangeAutoComplete}
                valueText={insuranceBikeType ? insuranceBikeType.name : ''}
                options={InsuranceBikeTypeArray}
                varibleName="insurance_bike_useType"
                className="control ms"
                label="Tipo"
            />
            <TextField
                value={insuranceBikeUseType || ''}
                onChange={onChange}
                varibleName="insurance_bike_useType"
                placeholder="Tipo de utilização"
                className="control ms"
                label="Utilização"
            />
        </FormGroup>
        </>
    )
};

BikeQuestions.propTypes = {
    insuranceInvoice: P.string.isRequired,
    insuranceModel: P.string.isRequired,
    insuranceBrand: P.string.isRequired,
    insuranceBikeManufactureYear: P.number.isRequired,
    insuranceBikeCost: P.number.isRequired,
    insuranceBikeType: P.objectOf(P.any).isRequired,
    insuranceBikeUseType: P.string.isRequired,
    onChange: P.objectOf(P.any).isRequired,
    onChangeAutoComplete: P.objectOf(P.any).isRequired,
    onChangeNumericBikeManufactureYear: P.objectOf(P.any).isRequired,
    onChangeNumericBikeCost: P.objectOf(P.any).isRequired,
}

