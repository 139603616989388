/* eslint-disable */
import { useContext, useState } from 'react';
import { MainContext } from '../../../context/ApiContext';
import { Nav, NavbarForm } from './styles';
import { DateTime } from "luxon";
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';


const DatePeriodFilter = ({ setFilters , filters, isValidating, isValidatingProducts, products, error}) => {

  const dateNow = DateTime.now().toISODate()  
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [dates, setDates] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
  });

  function handleSubmit (event )  {
    event.preventDefault()
    setFilters = {
      date_ini : event.target.elements.date_ini?.value,
      date_end : event.target.elements.date_end?.value,
    }
  }

  return (
    <Nav>
      <div className="filter">Data de criação da proposta</div>

      <NavbarForm>
        <form className="form" onSubmit={(e) => handleSubmit(e)}>
          <div className="date_ini">
            <input type="date" name="date_ini" className="input-form" placeholder="Data Inicial" value={filters?.date_ini}  onChange={(e) => setFilters({...filters, date_ini: e.target.value})} />
          </div>
          <div className="date_end">
            <input type="date" name="date_end" className="input-form" placeholder="Data Final" value={filters?.date_end} onChange={(e) => setFilters({...filters, date_end: e.target.value})}/>
          </div>
          <div className="date_end">
            <select type="text" name="product" className="input-form" placeholder="Data Final" value={filters?.product} onChange={(e) => setFilters({...filters, product: e.target.value})}>
            <option value="">Todos os Produtos</option>
            
            {products?.data?.map((product) => (
              <option key={product} value={product}>{product}</option>
            ))}
            </select>
          </div>
          <div className="button">
          </div>
        </form>
      </NavbarForm>
      {isValidating  && (<CircularProgress size="20px" color="secondary"/>)}
      {isValidatingProducts  && (<CircularProgress size="20px" color="primary"/>)}
      {error  && (<CancelIcon size="20px" color="error"/>)}

    </Nav>
  )
};

export default DatePeriodFilter;
