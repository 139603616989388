/* eslint-disable */
import { useContext, useState } from 'react';
import { MainContext } from '../../../context/ApiContext';
import { Nav, NavbarForm } from './styles';
import { DateTime } from "luxon";


function handleSubmit (event, getters)  {
  event.preventDefault()
  let filters = {
    date_ini : event.target.elements.date_ini?.value,
    date_end : event.target.elements.date_end?.value,
    paginate : null
  }
  getters.getIndicationReportLeads(filters)
}





const DatePeriodFilter = (userId) => {

  const dateNow = DateTime.now().toISODate()  
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [dates, setDates] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
  });

  const { values, getters, creators } = useContext(MainContext);

  return (
    <Nav>
      <div className="filter">Data de criação do lead (ou agendamento, se houver)</div>

      <NavbarForm>
        <form className="form" onSubmit={(e) => handleSubmit(e, getters)}>
          <div className="date_ini">
            <input type="date" name="date_ini" className="input-form" placeholder="Data Inicial" value={dates.date_ini}  onChange={(e) =>setDates(e)} />
          </div>
          <div className="date_end">
            <input type="date" name="date_end" className="input-form" placeholder="Data Final" value={dates.date_end} onChange={(e) =>setDates(e)}/>
          </div>
          <div className="button">
          <button type="submit">Filtrar</button>
          </div>
        </form>
      </NavbarForm>

    </Nav>
  )
};

export default DatePeriodFilter;
