/* eslint-disable */
import { useContext, useState } from 'react';
import { MainContext } from '../../../context/ApiContext';
import { Nav, NavbarForm } from './styles';
import { DateTime } from 'luxon';
import { AutocompleteField } from '../../FormFields';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import useApp from '../../../hooks/useApp';

const statusList = [
  { name: 'Atendida', value: 'ANSWERED' },
  { name: 'Não atendida', value: 'NO ANSWER' },
  { name: 'Ocupado', value: 'BUSY' },
  { name: 'Falha', value: 'FAILED' },
];

const DatePeriodFilter = ({ setFilters }) => {
  const { partners} = useApp()

  function handleSubmit(event) {    
    event.preventDefault();
    
    let filters = {
      partner: partner?.code_wf || null,
      leadNum: leadNum || null,
    };
    setFilters((f) => ({ ...f, ...filters }));
  }

  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [partner, setPartners] = useState();
  const [leadNum, setLeadNum] = useState();

  return (
    <Nav>
      <div className="filter">Parâmetros</div>
      <NavbarForm>
        <form className="form" onSubmit={handleSubmit}>
          <Autocomplete
            options={partners}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setPartners(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="  Concessionária" />
            )}
          />          
            <TextField value={leadNum} type="number" className="autocomplete" variant="standard" placeholder="  Num. Proposta" onChange={(e, value) => setLeadNum(e.target.value)} />
          <div>
            <button className="button" type="submit">
              Enviar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
