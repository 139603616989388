

export const GetMenuItemsToHide = () => 
{
    const menuItemsVisibility = 
       JSON.parse(localStorage.getItem("menuItemsVisibility") || "{}");

    const menuItemsToHide = [""];

    Object.keys(menuItemsVisibility).forEach((key) => 
    {
        if ( !menuItemsVisibility[key] )
        menuItemsToHide.push(key);
    });

    return menuItemsToHide;
}