import { dataCreate, dataDelete, dataEdit } from './data.service';

const createUser = async (newData) => {
  const response = await dataCreate('/users', newData);
  return response;
};

const editUser = async (newData, dataID) => {
  const response = await dataEdit('/users', newData, dataID);
  return response;
};

const deleteUser = async (dataID) => {
  const response = await dataDelete('/users', dataID);
  return response;
};

const changeUserPassword = async (newData) => {
  const response = await dataEdit('/changeUserPassword', newData, newData.id);
  return response;
};

export { createUser, editUser, deleteUser, changeUserPassword };