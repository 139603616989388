/* eslint-disable */
import React from 'react';
import { useContext, useState, useEffect } from 'react';
import { MainContext } from '../../../context/ApiContext';
import { Nav, NavbarForm } from './styles';
import { DateTime } from 'luxon';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import useApp from '../../../hooks/useApp';
import useSWR from 'swr';
import { fetcher } from '../../../service/SWRFetcher';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '200px',
  },
}));

const DatePeriodFilter = ({ userId, filters, setFilters, filterClick }) => {
  const { partners: allPartners, insurances } = useApp();
  const { values, getters, creators } = useContext(MainContext);
  const classes = useStyles();
  const { data: users } = useSWR(`/users`, fetcher);


  async function handleSubmit(event, getters) {
    event.preventDefault();
    await filterClick();
  }

  return (
    <Nav>
      <div className="filter">Filtros</div>

      <NavbarForm>
        <form className="form" onSubmit={async (e) => await handleSubmit(e, getters)}>
          <div className="inputs">
            <div className="date_ini">
              <input
                type="date"
                name="date_ini"
                className="input-form"
                placeholder="Data Inicial"
                value={filters.date_ini}
                onChange={(e) => setFilters({...filters, date_ini: e.target.value})}
              />
            </div>
            <div className="date_end">
              <input
                type="date"
                name="date_end"
                className="input-form"
                placeholder="Data Final"
                value={filters.date_end}
                onChange={(e) => setFilters({...filters, date_end: e.target.value})}
              />
            </div>
          </div>

          <Autocomplete
            multiple
            options={allPartners}
            getOptionLabel={(option) => option?.name}
            onChange={(e, v) => setFilters({...filters, partner_id: v})}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Empresa" />
            )}
          />

          <Autocomplete
            multiple
            options={insurances ? insurances.filter((i) => i.id <= 3) : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, v) => setFilters({...filters, insurance_id: v})}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Tipos de Estoque" />
            )}
          />

          <Autocomplete
            multiple
            options={users || [0]}
            getOptionLabel={(option) => option?.name || ''}
            onChange={(e, v) => setFilters({...filters, broker_id: v})}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Corretor" />
            )}
          />

          <div>
            <button className="button" type="submit">
              Filtrar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
