import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    padding: 10px;
    font-size: 3.5rem;
  }
`;
