/* eslint-disable */
import { useContext, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import P, { shape } from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import { MainContext } from '../../context/ApiContext';
import UserForm from '../UserForm';
import { MaskedTextField } from '../FormFields';


const IndicationReportTableList = ({ listItem, title, states, status, sold, lostSale }) => {
  const {
    deleters: { deleteUser },
  } = useContext(MainContext);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [user, setUser] = useState(null);
  const [id, setId] = useState('');
  const [dialog, setDialog] = useState({
    msg: '',
    title: '',
  });

  function formatDate(date){
    var mydate = date?.substring(0, 10) || ""
    var year = mydate?.substring(0, 4) || ""
    var month = mydate?.substring(5,7) || ""
    var day = mydate?.substring(8, 10) || ""

    var newDate = day + '/' + month + '/' + year
    return newDate
  }

  function formatPhone(phone){
    if (phone?.length == 11) {
      var ddd = phone?.substring(0, 2) || ""
      var prefix = phone?.substring(2,7) || ""
      var sufix = phone?.substring(7, 11) || ""
    }
    if (phone?.length == 10) {
      var ddd = phone?.substring(0, 2) || ""
      var prefix = phone?.substring(2,6) || ""
      var sufix = phone?.substring(6, 10) || ""
    }
    if (phone?.length < 10 || phone?.length == undefined) {
      return "-"
    }


    var newPhone = '(' + ddd + ') ' + prefix + '-' + sufix
    return newPhone
  }

  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        msg={dialog.msg.length > 0 ? dialog.msg : 'Dialog'}
        title={dialog.title.length > 0 ? dialog.title : 'Dialog'}
      />
      <UserForm open={open} setOpen={setOpen} title={title} user={user} />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="30%">Nome do Cliente</TableCell>
              <TableCell>Data Criação</TableCell>
              <TableCell>Data Agendamento</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Situação Venda</TableCell>
              <TableCell>Corretor que Capturou</TableCell>
              <TableCell>Contato Corretor</TableCell>
              {/* <TableCell align="right">Ações</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {listItem !== null &&
              listItem.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell>{item.client?.name || '-'}</TableCell>
                  <TableCell>{formatDate(item.create_lead_date) || '-'}</TableCell>
                  <TableCell>{item.lead_scheduling?.status == 0 ? formatDate(item.lead_scheduling?.scheduling_date) : 0 || '-'}</TableCell>
                  <TableCell>{states.find((e) => e.var_id == item.state)?.value || '-'}</TableCell>
                  <TableCell>{status.find((e) => e.var_id == item.status)?.value || '-'}</TableCell>
                  <TableCell>{item.sold == 1? sold.find((e) => e.var_id == item.sold)?.value : lostSale.find((e) => e.var_id == item.lost_sale)?.value || '-'}</TableCell>
                  <TableCell>{item.broker?.name || '-'}</TableCell>
                  <TableCell> {formatPhone(item.broker?.phone)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

IndicationReportTableList.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
  title: P.string.isRequired,
};

IndicationReportTableList.defaultProps = {
  listItem: null,
};

export default IndicationReportTableList;
