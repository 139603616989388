/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import { Paper, Divider } from '@material-ui/core';
import P from 'prop-types';
import { DateTime } from 'luxon';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteField, MaskedTextField } from '../FormFields';
import { FormGroup } from '../LeadForm/styles';
import api from '../../service/api';
import { Beneficiary } from '../LeadForm/beneficiary';
import { EndossoAlteracao } from '../../mock/leadInfo.com';
import { maskDate, maskDateTime } from '../../utils/masks';
import { CustomFileUpload } from '../LeadForm/services';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


export default function Endosso({ cliente, endosso, segurosEndosso, entering }) {
    const [optionsSegurosAdquiridos, setOptionsSeguros] = useState({
        id: '',
        name: '',
        lead_id: ''
    });

    const dataAtual = DateTime.local().toFormat("dd/LL/yyyy HH:mm:ss");
    const [envioArquivo, setEnvioArquivo] = useState(false);
    const [endossoAberto, setEndossoAberto] = useState({
        id: '',
        created_at: '',
        status: '',
        finish_date: '',
        changes: {
            nome_cliente: '',
            endereco: {
                cep: '',
                logradouro: '',
                numero: '',
                bairro: '',
                complemento: '',
                cidade: '',
                estado: ''
            },
            telefone: '',
            celular: '',
            bem_segurado: '',
            vigencia: {
                inicio: '',
                fim: ''
            },
            beneficiarios: []
        },
        insurance_id: '',
        observation: '',
        lead_id: ''
    });

    const [newEndosso, setNewEndosso] = useState({
        id: '',
        created_at: '',
        status: '',
        finish_date: '',
        changes: {
            nome_cliente: '',
            endereco: {
                cep: '',
                logradouro: '',
                numero: '',
                bairro: '',
                complemento: '',
                cidade: '',
                estado: ''
            },
            telefone: '',
            celular: '',
            bem_segurado: '',
            vigencia: {
                inicio: '',
                fim: ''
            },
            beneficiarios: []
        },
        insurance_id: '',
        observation: '',
        lead_id: ''
    });

    const [changesEndosso, setCamposEndosso] = useState({
        nome_cliente: '',
        endereco: {
            cep: '',
            logradouro: '',
            numero: '',
            bairro: '',
            complemento: '',
            cidade: '',
            estado: ''
        },
        telefone: '',
        celular: '',
        bem_segurado: '',
        vigencia: {
            inicio: '',
            fim: ''
        },
        beneficiarios: []
    })

    const [optionSeguroAtivoEndossoSelecionado, setSeguroAtivoEndossoSelecionado] = useState({
        id: '',
        name: '',
        lead_id: ''
    });

    const alteracoesSelecionadas = useRef([]);
    const [file, setFile] = useState(false);
    const [camposAdicionaisEndosso, setCamposAdicionaisEndosso] = useState([]);
    if(alteracoesSelecionadas.current.length>0){
    if(!envioArquivo)setEnvioArquivo(true);}
    else {if(envioArquivo)setEnvioArquivo(false);}
    
    const aparecerCamposPreenchimentoEndosso = (newValue, insurance_id, firstTime) => {
        let resultado = alteracoesSelecionadas.current;
        if (!firstTime && newValue.length > alteracoesSelecionadas.current.length) {
            const valor = newValue.find(value => !alteracoesSelecionadas.current.some(val => val.id === value.id));
            
           

            if (valor) {
                alteracoesSelecionadas.current = [...alteracoesSelecionadas.current, valor].sort((a, b) => a.id - b.id);
                resultado = [...resultado, valor].sort((a, b) => a.id - b.id);
            } else {
                const removedValue = newValue.find(value => alteracoesSelecionadas.current.some(val => val.id === value.id));
                if (removedValue) {
                    alteracoesSelecionadas.current = alteracoesSelecionadas.current.filter(value => value.id !== removedValue.id).sort((a, b) => a.id - b.id);
                    resultado = alteracoesSelecionadas.current;
                } else {
                    alteracoesSelecionadas.current = [];
                    resultado = [];
                }
            }
        } else {
            alteracoesSelecionadas.current = newValue.sort((a, b) => a.id - b.id);
            resultado = alteracoesSelecionadas.current;
        }


        const novosCampos = resultado.map((value) => {
            const opcao = EndossoAlteracao.find((option) => option.id === value.id);
            const camposQuePrecisamDeSeguroAtivo = [1, 2, 3, 4, 5, 6, 7]
            const necessitaSeguroAtivo = camposQuePrecisamDeSeguroAtivo.some((id) => id === opcao.id);
            if (necessitaSeguroAtivo) {

                if (!insurance_id || insurance_id == undefined || insurance_id === '') {
                    toast.error(`Selecione um seguro ativo para essa opção (${opcao.name}).`)

                    alteracoesSelecionadas.current = alteracoesSelecionadas.current.filter(valor => valor.id !== opcao.id);

                    return null;
                }

                if (opcao.id === 7) {
                    let leadReferenteAoSeguro = cliente.leads.find((lead) => lead.id === newEndosso.lead_id);

                    if (!leadReferenteAoSeguro)
                        leadReferenteAoSeguro = cliente.leads.find((lead) => lead.id === endosso[0]?.lead_id);

                    if (leadReferenteAoSeguro?.cliente_beneficiarios) {
                        setCamposEndosso({ ...changesEndosso, beneficiarios: leadReferenteAoSeguro.cliente_beneficiarios })

                    } else {

                        toast.error(`Não existe ${opcao.name} para esse seguro/lead.`)
                        setCamposEndosso({ ...changesEndosso, beneficiarios: [] })
                        alteracoesSelecionadas.current = alteracoesSelecionadas.current.filter(valor => valor.id !== opcao.id);

                        return null;

                    }

                }
                else {
                    setCamposEndosso({ ...changesEndosso, beneficiarios: [] })
                }

            } else {

                setCamposEndosso({ ...changesEndosso, beneficiarios: [] })
            }

            return opcao ? { id: value } : null;
        });
        setCamposAdicionaisEndosso(novosCampos.filter((campo) => campo !== null));
    }

    const criacaoEndossoAberto = (endossos, seguros) => {

        const endossoEmAberto = endossos.find((e) => e.status === 'Aberto')

        const seguroAdquirido = seguros.find((item) => item.id === endossoEmAberto.insurance_id)

        if (seguroAdquirido) {
            setSeguroAtivoEndossoSelecionado(seguroAdquirido)
            setNewEndosso({ ...newEndosso, insurance_id: endossoEmAberto.insurance_id, lead_id: seguroAdquirido.lead_id })
        }

        setEndossoAberto(endossoEmAberto);
        setNewEndosso(endossoEmAberto);


        const arrayOptions = endossoEmAberto.observation.split(',')

        const opcoesSelecionadas = EndossoAlteracao.filter(item => {
            return arrayOptions.includes(item.id.toString());
        });
        aparecerCamposPreenchimentoEndosso(opcoesSelecionadas, endossoEmAberto.insurance_id, true)
    }

    const onChangeAlteracao = (event, newValue, variable) => {
        aparecerCamposPreenchimentoEndosso(newValue, newEndosso.insurance_id, false)
    }

    const handleChange = (key, event, obj) => {
        const beneficiaries = [...newEndosso.changes.beneficiarios];

        if (obj) {
            if (!(obj.id || obj.name))
                beneficiaries[key][obj.variable] = undefined;
            else
                beneficiaries[key][obj.variable] = { id: obj.id, name: obj.name };

            setCamposEndosso({
                ...changesEndosso,
                beneficiarios: beneficiaries
            });
            setNewEndosso({
                ...newEndosso,
                changes: {
                    ...newEndosso.changes,
                    beneficiarios: beneficiaries
                }
            })
        } else {
            if (event.target.type == 'number') {
                if (event.target.max && parseInt(event.target.max) <= parseInt(event.target.value))
                    event.target.value = event.target.max
                if (event.target.min && parseInt(event.target.min) >= parseInt(event.target.value))
                    event.target.value = event.target.min
                if (event.target.name == 'participation') {
                    if (beneficiaries.length == 1) {
                        event.target.value = 100;
                    } else {
                        let soma = 0;
                        beneficiaries.forEach((objeto) => { soma += parseInt(objeto.participation, 10) });
                        soma -= parseInt(beneficiaries[key][event.target.name]);
                        if (soma + parseInt(event.target.value) > 100) {
                            event.target.value = 100 - soma;
                        }
                    }
                }

            }
            beneficiaries[key][event.target.name] = event.target.value;
            setCamposEndosso({
                ...changesEndosso,
                beneficiarios: beneficiaries
            });
            setNewEndosso({
                ...newEndosso,
                changes: {
                    ...newEndosso.changes,
                    beneficiarios: beneficiaries
                }
            })
        }
    };


    const setArrayValue = (nameValue, arr, set) => {
        const defaultValue = { id: 0, name: '', lead_id: 0 };
        if (!nameValue || nameValue != '') {
            const value = arr.find((item) => item.name == nameValue)
            if (!value) {
                set(defaultValue)
                return defaultValue
            }
            set(value)
            return value;
        }
        return defaultValue;
    }

    useEffect(() => {
        setOptionsSeguros(segurosEndosso)
        if (endosso.length > 0) {
            criacaoEndossoAberto(endosso, segurosEndosso)
        } else {
            setArrayValue(segurosEndosso[0]?.name, segurosEndosso, setSeguroAtivoEndossoSelecionado)
            newEndosso.lead_id = segurosEndosso[0]?.lead_id;
            newEndosso.insurance_id = segurosEndosso[0]?.id;
            setNewEndosso(newEndosso);

        }
    }, [endosso])

    const gravarEndosso = async (e, val) => {

        if (alteracoesSelecionadas.current.length > 0) {
            if (!newEndosso.insurance_id || newEndosso.insurance_id == '') {
                toast.error("Selecione o seguro ativo para prosseguir com a gravação do endosso.")
                return;
            }
            let soma = 0;

            try {
                newEndosso.changes.beneficiarios.forEach((objeto) => {
                    if (!objeto.participation || objeto.participation < 1) {
                        throw new Error('Os beneficiários devem ter pelo menos 1% de participação.');
                    }

                    soma += parseInt(objeto.participation, 10
                    )
                });
                if (newEndosso.changes.beneficiarios.length > 0 && soma < 100) {
                    toast.warning('A soma das participações dos beneficiarios deve ser igual a 100%');
                    return;
                }
            } catch (error) {
                toast.warning(error.message);
                return;
            }

            const listaDeIds = alteracoesSelecionadas.current.map(item => item.id).join(',');;

            if (newEndosso.id) {
                try {
                    const response = await api.put(`endorsements/updateInfo/${newEndosso.id}`, {
                        client_id: cliente.id,
                        changes: newEndosso.changes,
                        insurance_id: newEndosso.insurance_id == 0 ? null : newEndosso.insurance_id,
                        lead_id: newEndosso.lead_id == 0 ? null : newEndosso.lead_id,
                        observation: listaDeIds
                    });

                    if (response.status === 200) {
                        toast.success('Endosso atualizado com sucesso');
                        await entering();
                    }
                } catch (error) {
                    if (error.response) {
                        console.log('Status do Erro:', error.response.status);
                        console.log('Dados do Erro:', error.response.data);


                    } else if (error.request) {
                        console.log('Erro de Resposta:', error.request);
                    } else {
                        console.log('Erro ao Configurar a Solicitação:', error.message);
                    }

                    toast.error("Erro ao atualizar endosso, favor sinalizar o suporte caso persista.")
                }

            } else {

                try {
                    const response = await api.post(`endorsements/save`, {
                        client_id: cliente.id,
                        changes: changesEndosso,
                        insurance_id: newEndosso.insurance_id == 0 ? null : newEndosso.insurance_id,
                        lead_id: newEndosso.lead_id == 0 ? null : newEndosso.lead_id,
                        observation: listaDeIds
                    });

                    if (response.status === 200) {
                        toast.success('Endosso gravado com sucesso');
                        await entering();
                        aparecerCamposPreenchimentoEndosso([], null, true)
                    }
                } catch (error) {
                    if (error.response) {
                        console.log('Status do Erro:', error.response.status);
                        console.log('Dados do Erro:', error.response.data);

                        if (error.response.status == 400 && error.response.data.message === 'Endorsement create not permited, one endoirsement is can active.') {
                            toast.error("Já existe um endosso aberto no momento, aguarde finalização para gravar um novo.")
                            return;

                        }
                    } else if (error.request) {
                        console.log('Erro de Resposta:', error.request);
                    } else {
                        console.log('Erro ao Configurar a Solicitação:', error.message);
                    }

                    toast.error("Erro ao gravar endosso, favor sinalizar o suporte caso persista.")
                }
            }
        } else {
            toast.error("Marque os campos de alteração para realizar o endosso.")
        }
    }

    const finalizarEndosso = async (e, val) => {

        try {
            const response = await api.put(`endorsements/finishEndorsement/${endossoAberto.id}`);

            if (response.status === 200) {
                toast.success('Endosso finalizado com sucesso.');
                await entering();
            }
        } catch (error) {
            if (error.response) {
                console.log('Status do Erro:', error.response.status);
                console.log('Dados do Erro:', error.response.data);

                if (error.response.status == 400 && error.response.data.message === 'Endorsement finish not permited, one active endoirsement is required.') {
                    toast.error("Não existe um endosso aberto no momento.")
                    return;

                }
            } else if (error.request) {
                console.log('Erro de Resposta:', error.request);
            } else {
                console.log('Erro ao Configurar a Solicitação:', error.message);
            }

            toast.error("Erro ao finalizar endosso, favor sinalizar o suporte caso persista.")
        }
    }

    const StyledBoxesHeader = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 12,
    };
    const StyledBoxesWidth = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 6,
        width: 280,
    };

    const verificarSeIdEstaNaLista = (id) => {
        if (camposAdicionaisEndosso.length > 0) {
            const verificacao = camposAdicionaisEndosso.some(option => option.id.id === id);
            return verificacao;
        }
        return false;
    }

    const NewBeneficiary = () => {

        setCamposEndosso({
            ...changesEndosso,
            beneficiarios: [...changesEndosso.beneficiarios, {
                name: '',
                cpf: '',
                rg: '',
                birth_date: '',
                parentesco: null,
                sex: null,
                maritalStatus: null,
                phone: '',
                cellphone: '',
                email: '',
                instagram: '',
                facebook: '',
                participation: changesEndosso.beneficiarios.length < 1 ? 100 : 0
            }]
        });

        setNewEndosso({
            ...newEndosso,
            changes: {
                ...newEndosso.changes,
                beneficiarios: [...newEndosso.changes.beneficiarios, {
                    name: '',
                    cpf: '',
                    rg: '',
                    birth_date: '',
                    parentesco: null,
                    sex: null,
                    maritalStatus: null,
                    phone: '',
                    cellphone: '',
                    email: '',
                    instagram: '',
                    facebook: '',
                    participation: newEndosso.changes.beneficiarios.length < 1 ? 100 : 0
                }]
            }
        })
    }

    const RemoveBeneficiary = (i) => {
        const beneficiaries = newEndosso.changes.beneficiarios;
        beneficiaries.splice(i, 1)
        var obj = []
        obj = obj.concat(obj, beneficiaries)

        setCamposEndosso({
            ...changesEndosso,
            beneficiarios: obj
        });

        setNewEndosso({
            ...newEndosso,
            changes: {
                ...newEndosso.changes,
                beneficiarios: obj
            }
        })
    }

    const CarregarBeneficiarios = () => {
        var componentes = [];

        newEndosso.changes?.beneficiarios && newEndosso.changes?.beneficiarios?.length > 0 ? (
            componentes = newEndosso.changes?.beneficiarios?.map((e, index) =>
                <>
                    <div style={{ backgroundColor: "#fff", display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px' }}>
                        <label style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '1.3rem',
                            fontWeight: '700'
                        }}>{index + 1}° Beneficiário:</label>
                        <Button style={{ backgroundColor: '#fa9c12' }} className={"button"} onClick={() => RemoveBeneficiary(index)} variant="contained" color="default">
                            <label style={{ color: '#fff', fontWeight: '800' }} >Remover</label>
                        </Button>
                    </div>
                    <Beneficiary beneficiary={e} index={index} handleChange={handleChange} />
                    <Divider style={{ marginBottom: 15 }} />
                </>
            )
        ) : (<></>
        )
        return (<>{componentes}<div style={{ backgroundColor: "#fff", display: 'flex', flexDirection: 'row-reverse', width: '100%', padding: '10px' }}>
            <Button style={{ backgroundColor: '#fa9c12' }} onClick={() => NewBeneficiary()} variant="contained" color="default"><label style={{ color: '#fff', fontWeight: '800' }}>Adicionar Beneficiário</label></Button>
        </div></>);
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', gap: 35, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', gap: 35, alignItems: 'center' }}>
                        {newEndosso?.created_at && (<TextField
                            variant="outlined"
                            value={maskDateTime(newEndosso.created_at) || dataAtual}
                            varibleName="data_endosso"
                            label="Data do Endosso:"
                            type="datetime"
                            style={{
                                width: '30%',
                                marginTop: 20
                            }}
                            disabled />)}
                        <Autocomplete
                            multiple
                            options={EndossoAlteracao}
                            getOptionLabel={(option) => option?.name}
                            value={alteracoesSelecionadas.current}
                            onChange={onChangeAlteracao}
                            style={{
                                width: 500,
                                marginTop: 11
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    variant="outlined"
                                    label='Alteração'
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Button
                            className='botoes-cliente'
                            onClick={gravarEndosso}
                            variant="contained"
                            color="default"
                            style={{
                                height: 52,
                                marginTop: 11,
                            }}>
                            {newEndosso && newEndosso?.id ? (<>Atualizar Endosso</>) : (<>Gravar Endosso</>)}
                        </Button>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: 35, alignItems: 'center', justifyContent: 'space-between' }}>
                    <AutocompleteField
                        medium
                        varibleName="seguro_ativo"
                        onChange={(e, value, variable) => {
                            const resultado = { id: 0, name: '', lead_id: 0 };
                            if (value && value != undefined) {
                                setNewEndosso({ ...newEndosso, insurance_id: value.id, lead_id: value.lead_id });
                                setSeguroAtivoEndossoSelecionado(value)
                            } else {
                                setNewEndosso({ ...newEndosso, insurance_id: resultado.id, lead_id: resultado.lead_id });
                                setSeguroAtivoEndossoSelecionado(resultado);
                            }

                        }}
                        label='Endosso em qual seguro ativo?'
                        style={{
                            width: 220,
                            marginTop: 26
                        }}
                        getOptionLabel={(option) => option?.name || ''}
                        options={optionsSegurosAdquiridos}
                        value={optionSeguroAtivoEndossoSelecionado ? optionSeguroAtivoEndossoSelecionado : undefined} />
                    {endosso && endosso[0]?.status == 'Aberto' && (
                        <Button
                            className='botoes-cliente'
                            onClick={finalizarEndosso}
                            variant="contained"
                            color="default"
                            style={{
                                height: 52,
                                marginTop: 11,
                            }}
                        >
                            Finalizar Endosso
                        </Button>
                    )}
                </div>
            </div>
            <div>
                {camposAdicionaisEndosso.length > 0 && (
                    <Paper style={{ padding: 12, marginTop: 20 }}>
                        <h3>Campos para alteração:</h3>
                        <form>
                            {verificarSeIdEstaNaLista(1) && (
                                <TextField
                                    variant='outlined'
                                    label="Nome do cliente"
                                    type="name"
                                    onChange={(e) => {
                                        setCamposEndosso({ ...changesEndosso, nome_cliente: e.target.value })
                                        setNewEndosso({ ...newEndosso, changes: { ...newEndosso.changes, nome_cliente: e.target.value } })
                                    }}
                                    style={{
                                        width: '40%', marginTop: 16,
                                        marginBottom: 8,
                                        marginRight: 6,
                                        marginLeft: 12,
                                    }}
                                    value={newEndosso.changes.nome_cliente || undefined} />
                            )}
                            {verificarSeIdEstaNaLista(2) && (
                                <>
                                    <FormGroup>
                                        <MaskedTextField
                                            mask="99999-999"
                                            variant="outlined"
                                            varibleName="cep"
                                            label="CEP"
                                            value={newEndosso?.changes?.endereco?.cep || undefined}
                                            style={{ marginTop: 16, marginBottom: 8, marginRight: 6, marginLeft: 3, width: '60%' }}
                                            onChange={(e) => {
                                                setCamposEndosso({
                                                    ...changesEndosso,
                                                    endereco: {
                                                        ...changesEndosso.endereco,
                                                        cep: e.target.value
                                                    }
                                                });
                                                setNewEndosso({
                                                    ...newEndosso,
                                                    changes: {
                                                        ...newEndosso.changes,
                                                        endereco: {
                                                            ...newEndosso.changes.endereco,
                                                            cep: e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <TextField
                                            mask=""
                                            variant="outlined"
                                            varibleName="street"
                                            label="Logradouro"
                                            value={newEndosso?.changes?.endereco?.logradouro || undefined}
                                            style={{ marginTop: 16, marginBottom: 8, marginRight: 6, marginLeft: 8, width: '98%' }}
                                            onChange={(e) => {
                                                setCamposEndosso({
                                                    ...changesEndosso,
                                                    endereco: {
                                                        ...changesEndosso.endereco,
                                                        logradouro: e.target.value
                                                    }
                                                });
                                                setNewEndosso({
                                                    ...newEndosso,
                                                    changes: {
                                                        ...newEndosso.changes,
                                                        endereco: {
                                                            ...newEndosso.changes.endereco,
                                                            logradouro: e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <TextField
                                            variant="outlined"
                                            varibleName="number"
                                            label="Número"
                                            value={newEndosso?.changes?.endereco?.numero || undefined}
                                            style={{
                                                width: '20%',
                                                marginTop: 16,
                                                marginBottom: 8,
                                                marginRight: 6,
                                                marginLeft: 12,
                                            }}
                                            onChange={(e) => {
                                                setCamposEndosso({
                                                    ...changesEndosso,
                                                    endereco: {
                                                        ...changesEndosso.endereco,
                                                        numero: e.target.value
                                                    }
                                                });
                                                setNewEndosso({
                                                    ...newEndosso,
                                                    changes: {
                                                        ...newEndosso.changes,
                                                        endereco: {
                                                            ...newEndosso.changes.endereco,
                                                            numero: e.target.value
                                                        }
                                                    }
                                                })
                                            }} />
                                        <TextField
                                            variant="outlined"
                                            varibleName="district"
                                            label="Bairro"
                                            value={newEndosso?.changes?.endereco?.bairro || undefined}
                                            style={{
                                                width: '20%',
                                                marginTop: 16,
                                                marginBottom: 8,
                                                marginRight: 6,
                                                marginLeft: 12,
                                            }}
                                            onChange={(e) => {
                                                setCamposEndosso({
                                                    ...changesEndosso,
                                                    endereco: {
                                                        ...changesEndosso.endereco,
                                                        bairro: e.target.value
                                                    }
                                                });
                                                setNewEndosso({
                                                    ...newEndosso,
                                                    changes: {
                                                        ...newEndosso.changes,
                                                        endereco: {
                                                            ...newEndosso.changes.endereco,
                                                            bairro: e.target.value
                                                        }
                                                    }
                                                })
                                            }} />
                                        <TextField
                                            variant="outlined"
                                            varibleName="comp"
                                            label="Complemento"
                                            value={newEndosso?.changes?.endereco?.complemento || undefined}
                                            style={{
                                                width: '60%',
                                                marginTop: 16,
                                                marginBottom: 8,
                                                marginRight: 6,
                                                marginLeft: 12,
                                            }}
                                            onChange={(e) => {
                                                setCamposEndosso({
                                                    ...changesEndosso,
                                                    endereco: {
                                                        ...changesEndosso.endereco,
                                                        complemento: e.target.value
                                                    }
                                                });
                                                setNewEndosso({
                                                    ...newEndosso,
                                                    changes: {
                                                        ...newEndosso.changes,
                                                        endereco: {
                                                            ...newEndosso.changes.endereco,
                                                            complemento: e.target.value
                                                        }
                                                    }
                                                })
                                            }} />
                                    </FormGroup>
                                    <FormGroup>
                                        <TextField
                                            variant="outlined"
                                            varibleName="city"
                                            label="Cidade"
                                            value={newEndosso?.changes?.endereco?.cidade || undefined}
                                            style={StyledBoxesWidth}
                                            onChange={(e) => {
                                                setCamposEndosso({
                                                    ...changesEndosso,
                                                    endereco: {
                                                        ...changesEndosso.endereco,
                                                        cidade: e.target.value
                                                    }
                                                });
                                                setNewEndosso({
                                                    ...newEndosso,
                                                    changes: {
                                                        ...newEndosso.changes,
                                                        endereco: {
                                                            ...newEndosso.changes.endereco,
                                                            cidade: e.target.value
                                                        }
                                                    }
                                                })
                                            }} />
                                        <MaskedTextField
                                            mask="**"
                                            variant="outlined"
                                            varibleName="state"
                                            label="Estado"
                                            value={newEndosso?.changes?.endereco?.estado || undefined}
                                            style={StyledBoxesWidth}
                                            onChange={(e) => {
                                                setCamposEndosso({
                                                    ...changesEndosso,
                                                    endereco: {
                                                        ...changesEndosso.endereco,
                                                        estado: e.target.value
                                                    }
                                                });
                                                setNewEndosso({
                                                    ...newEndosso,
                                                    changes: {
                                                        ...newEndosso.changes,
                                                        endereco: {
                                                            ...newEndosso.changes.endereco,
                                                            estado: e.target.value
                                                        }
                                                    }
                                                })
                                            }} />
                                    </FormGroup>
                                </>
                            )}

                            {verificarSeIdEstaNaLista(3) && (
                                <MaskedTextField
                                    mask={
                                        cliente.phone && cliente.phone.replace(/[^\d]/g, '').length < 11 ? '(99) 9999-99999' : '(99) 99999-9999'
                                    }
                                    variant="outlined"
                                    varibleName="phone"
                                    label="Telefone"
                                    value={endossoAberto.changes.telefone || undefined}
                                    style={StyledBoxesHeader}
                                    onChange={(e) => {
                                        setCamposEndosso({ ...changesEndosso, telefone: e.target.value })
                                        setNewEndosso({ ...newEndosso, changes: { ...newEndosso.changes, telefone: e.target.value } })

                                    }}
                                />
                            )}
                            {verificarSeIdEstaNaLista(4) && (

                                <MaskedTextField
                                    mask={
                                        cliente.cellphone && cliente.cellphone.replace(/[^\d]/g, '').length < 11
                                            ? '(99) 9999-99999'
                                            : '(99) 99999-9999'
                                    }
                                    variant="outlined"
                                    varibleName="cellphone"
                                    label="Celular"
                                    value={newEndosso.changes.celular || undefined}
                                    style={StyledBoxesHeader}
                                    onChange={(e) => {
                                        setCamposEndosso({ ...changesEndosso, celular: e.target.value })
                                        setNewEndosso({ ...newEndosso, changes: { ...newEndosso.changes, celular: e.target.value } })

                                    }}
                                />

                            )}
                            {verificarSeIdEstaNaLista(5) && (
                                <TextField
                                    variant="outlined"
                                    varibleName="bem_segurado"
                                    label="Bem segurado:"
                                    value={newEndosso.changes.bem_segurado || undefined}
                                    style={{
                                        width: '33.333%',
                                        marginTop: 16,
                                        marginBottom: 8,
                                        marginRight: 6,
                                        marginLeft: 12,
                                    }}
                                    onChange={(e) => {
                                        setCamposEndosso({ ...changesEndosso, bem_segurado: e.target.value })
                                        setNewEndosso({ ...newEndosso, changes: { ...newEndosso.changes, bem_segurado: e.target.value } })
                                    }}
                                />
                            )}

                            {verificarSeIdEstaNaLista(6) && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        varibleName="inicio_vigencia"
                                        type="date"
                                        label="Início de vigência:"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={newEndosso?.changes.vigencia?.inicio ? newEndosso.changes.vigencia.inicio : undefined}
                                        style={StyledBoxesHeader}
                                        onChange={(e) => {
                                            setCamposEndosso({
                                                ...changesEndosso,
                                                vigencia: {
                                                    ...changesEndosso.vigencia,
                                                    inicio: e.target.value
                                                }
                                            });
                                            setNewEndosso({
                                                ...newEndosso,
                                                changes: {
                                                    ...newEndosso.changes,
                                                    vigencia: {
                                                        ...newEndosso.changes.vigencia,
                                                        inicio: e.target.value
                                                    }
                                                }
                                            })
                                        }}

                                    />

                                    <TextField
                                        variant="outlined"
                                        varibleName="fim_vigencia"

                                        type="date"
                                        label="Final de vigência:"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={newEndosso.changes.vigencia?.fim ? newEndosso.changes?.vigencia.fim : undefined}
                                        style={StyledBoxesHeader}
                                        onChange={(e) => {
                                            setCamposEndosso({
                                                ...changesEndosso,
                                                vigencia: {
                                                    ...changesEndosso.vigencia,
                                                    fim: e.target.value
                                                }
                                            });
                                            setNewEndosso({
                                                ...newEndosso,
                                                changes: {
                                                    ...newEndosso.changes,
                                                    vigencia: {
                                                        ...newEndosso.changes.vigencia,
                                                        fim: e.target.value
                                                    }
                                                }
                                            })
                                        }} />
                                </div>
                            )}

                            {verificarSeIdEstaNaLista(7) && (CarregarBeneficiarios())}
                            {envioArquivo? 
            <div>
                <Button
                disabled={!optionSeguroAtivoEndossoSelecionado?.lead_id}
            onChange={async (event) => {
               await CustomFileUpload(
                  event,
                  setFile,
                  optionSeguroAtivoEndossoSelecionado.lead_id,
                  'Endosso',
                  'Endosso Anexado com sucesso!',
                  '/endorsements/file'
                );
            }}
            component="label"
            variant="contained"
            color="default"
            className="of-file"
            startIcon={<CloudUploadIcon />}>
            Anexar Endosso
            <input type="file" accept="application/pdf" hidden />
          </Button></div>:("")}
                        </form>
                    </Paper>
                )}
            </div>
        </>
    )
}

Endosso.propTypes = {
    cliente: P.objectOf(P.string),
    endosso: P.object,
    segurosEndosso: P.objectOf(P.string),
    entering: P.func
}

Endosso.defaultProps = {
    endosso: {},
    segurosEndosso: [],
    cliente: null
}