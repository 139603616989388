/* eslint-disable */
import { useContext, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import P, { shape } from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import { MainContext } from '../../context/ApiContext';
import UserForm from '../UserForm';
import { MaskedTextField } from '../FormFields';


const RenovationReportTableList = ({ listItem, title, states, sold, status, lostSale }) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [user, setUser] = useState(null);
  const [id, setId] = useState('');
  const [dialog, setDialog] = useState({
    msg: '',
    title: '',
  });

  function formatDate(date){
    if (date == null){ return null}
    var mydate = date?.substring(0, 10) || ""
    var year = mydate?.substring(0, 4) || ""
    var month = mydate?.substring(5,7) || ""
    var day = mydate?.substring(8, 10) || ""
    var hour = date?.substring(11, 13) || ""
    var min = date?.substring(14, 16) || ""
    var sec = date?.substring(17, 119) || ""

    var newDate = day + '/' + month + '/' + year + "\n" + hour + ':' + min + ':' + sec
    return newDate
  }

  function formatPhone(phone){
    if (phone?.length == 11) {
      var ddd = phone?.substring(0, 2) || ""
      var prefix = phone?.substring(2,7) || ""
      var sufix = phone?.substring(7, 11) || ""
    }
    if (phone?.length == 10) {
      var ddd = phone?.substring(0, 2) || ""
      var prefix = phone?.substring(2,6) || ""
      var sufix = phone?.substring(6, 10) || ""
    }
    if (phone?.length < 10 || phone?.length == undefined) {
      return "-"
    }


    var newPhone = '(' + ddd + ') ' + prefix + '-' + sufix
    return newPhone
  }

  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        msg={dialog.msg.length > 0 ? dialog.msg : 'Dialog'}
        title={dialog.title.length > 0 ? dialog.title : 'Dialog'}
      />
      <UserForm open={open} setOpen={setOpen} title={title} user={user} />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cliente</TableCell>
              <TableCell>Canal</TableCell>
              <TableCell>Capturado no prazo</TableCell>
              <TableCell>Fase</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Motivo da não venda</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItem !== null &&
              listItem.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.client_info?.client_name || '-'}</TableCell>
                  <TableCell>{item.channel?.name || '-'}</TableCell>
                  <TableCell>{item.capture_on_time == null ? "-" : item.capture_on_time == 1 ? 'Sim' : 'Não' || '-'}</TableCell>
                  <TableCell>{states.find((st) => st.var_id == item.state)?.value || "-"}</TableCell>
                  <TableCell>{item.state == 3 ? sold.find((e) => e.var_id == item.sold)?.value : (item.status == null ?  'null' : status.find((st) => st.var_id == item.status)?.value  || '-')}</TableCell>
                  <TableCell>{lostSale.find((i) => i.var_id == item.lost_sale)?.value || '-'}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

RenovationReportTableList.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
  title: P.string.isRequired,
};

RenovationReportTableList.defaultProps = {
  listItem: null,
};

export default RenovationReportTableList;
