import styled from 'styled-components';
import { Paper, FormControl } from '@material-ui/core';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  img {
    width: 90%;
    margin-bottom: 30px;
  }
`;

export const FormContainer = styled(Paper)`
  padding: 24px;
  width: 100%;
  margin: auto;

  .title-container {
    display: flex;
    justify-content: center;
    color: rgb(250 156 18);
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 10px;
    }

    .entrar {
      background-color: rgb(250 156 18);
      margin-right: 24px;

      :hover {
        background-color: rgb(203 123 6);
      }
    }
    .back {
      background-color: #888888;
      margin-right: 24px;

      :hover {
        background-color: #9f9f9f;
      }
    }
  }
`;

export const InputControl = styled(FormControl)`
  && {
    margin-bottom: 24px;
  }
`;
