/* eslint-disable arrow-body-style */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react/forbid-prop-types */
import { Box } from '@material-ui/core';
import P from 'prop-types';
import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { FunnilRowData, FunnilRowPercent } from './styled';
import { maskPercent } from '../../utils/masks';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export const FunnelData = ({ data, hData, wData }) => {
  const { width, height, ref } = useResizeDetector();
  const [label, setLabel] = useState('');
  const [anchorSide, setAnchorSide] = useState('left');

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event, l, anchor) => {
    setAnchorEl(event.currentTarget);
    setLabel(l);
    setAnchorSide(anchor);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setLabel('');
    setAnchorSide('');
  };

  const open = Boolean(anchorEl);

  const sum = (items, prop) => {
    return items.reduce((a, b) => {
      return a + b[prop];
    }, 0);
  };

  return (
    <>
      <FunnilRowData ref={ref}>
        {data.values &&
          data.values.length == 2 &&
          data.values?.map((v, index) => (
            <FunnilRowPercent
              onMouseEnter={(e) => handlePopoverOpen(e, `${v.title} - ${v.value}`, index % 2 == 0 ? 'left' : 'right')}
              onMouseLeave={handlePopoverClose}
              size={hData > 50 ? 'large' : 'small'}
              bg={index % 2 == 0 ? '#81cf93' : '#ff6161'}
              percent={(v.value / sum(data.values, 'value')) * 100}>
              {v.value > 0 && (
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                  <p>{v.value}</p>
                </Box>
              )}
            </FunnilRowPercent>
          ))}

        {data.values &&
          data.values.length == 1 &&
          data.values?.map((v) => (
            <FunnilRowPercent
              onMouseEnter={(e) => handlePopoverOpen(e, `${v.title} - ${maskPercent(v.value)}`, 'right')}
              onMouseLeave={handlePopoverClose}
              size={hData > 50 ? 'large' : 'small'}
              bg="#2ca52c">
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <p>{maskPercent(v.value)}</p>
              </Box>
            </FunnilRowPercent>
          ))}

        {!data.values && (
          <FunnilRowPercent
            onMouseEnter={(e) => handlePopoverOpen(e, `${data.title} - ${data.total}`, 'right')}
            onMouseLeave={handlePopoverClose}
            size={hData > 50 ? 'large' : 'small'}
            bg="orange">
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <p>{data.total}</p>
            </Box>
          </FunnilRowPercent>
        )}
      </FunnilRowData>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: anchorSide,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: anchorSide,
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography>{label}</Typography>
      </Popover>
    </>
  );
};

FunnelData.propTypes = {
  data: P.any.isRequired,
  hData: P.number.isRequired,
  wData: P.number,
};
