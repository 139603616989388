/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { ResidenceQuestions } from './InsuranceQuestions/insuranceResidenceQuestions';
import { BikeQuestions } from './InsuranceQuestions/insuranceBikeQuestions';
import { BusinessQuestions } from './InsuranceQuestions/insuranceBusinessQuestions';
import { CondoQuestions } from './InsuranceQuestions/insuranceCondoQuestions';
import { LifeQuestions} from './InsuranceQuestions/insuranceLifeQuestions';
import { TravelQuestions } from './InsuranceQuestions/insuranceTravelQuestions';
import { MobileQuestions } from './InsuranceQuestions/insuranceMobileQuestions';
import { PortableEquipmentQuestions } from './InsuranceQuestions/insurancePortableEquipmentQuestions';
import { BailQuestions } from './InsuranceQuestions/insuranceBailQuestions';


export function InsuranceComponents({
    selectedInsuranceId,
    handleChange,
    handleChangeAutoComplete,
    values,
    setValues
})
{
    return (
        <>
        {(selectedInsuranceId == 4) && (
            <ResidenceQuestions
                onChange={handleChange}
                onChangeAutoComplete={handleChangeAutoComplete}
                onChangeNumeric={(e) => setValues({ ...values, insurance_residence_amount: e.target.value })}
                insuranceResidenceHomeType={values.insurance_residence_homeType}
                insuranceResidenceHomeHabitation={values.insurance_residence_homeHabitation}
                insuranceResidenceAmount={values.insurance_residence_amount}
                insuranceResidenceOwner={values.insurance_residence_owner}
                insuranceResidenceNew={values.insurance_residence_new}
            />
            )}

        {(selectedInsuranceId == 8) && (
            <TravelQuestions
                onChange={handleChange}
                onChangeAutoComplete={handleChangeAutoComplete}
                insuranceTravelPassengersAmout={values.insurance_travel_passengersAmout}
                insuranceTravelAllAges={values.insurance_travel_allAges}
                insuranceTravelFromCountry={values.insurance_travel_fromCountry}
                insuranceTravelToCountry={values.insurance_travel_toCountry}
                insuranceTravelGoingDate={values.insurance_travel_goingDate}
                insuranceTravelReturnDate={values.insurance_travel_returnDate}
                insuranceTravelReason={values.insurance_travel_reason}
                insurancePracticesSports={values.insurance_practicesSports}
            />
            )}

        {(selectedInsuranceId == 9) && (
            <LifeQuestions
                onChange={handleChange}
                onChangeAutoComplete={handleChangeAutoComplete}
                onChangeNumericMonthlySalary={(e) => setValues({ ...values, insurance_life_monthlySalary: e.target.value })}
                onChangeNumericCovidVaccineAmout={(e) => setValues({ ...values, insurance_life_covidVaccineAmout: e.target.value })}
                onChangeNumericDeathCoverAmout={(e) => setValues({ ...values, insurance_life_deathCoverAmout: e.target.value })}
                insuranceLifeWork={values.insurance_life_work}
                insuranceLifeCivilStatus={values.insurance_life_civilStatus}
                insuranceLifeMonthlySalary={values.insurance_life_monthlySalary}
                insurancePracticesSports={values.insurance_practicesSports}
                insuranceLifeSmoker={values.insurance_life_smoker}
                insuranceLifeCovidVaccineAmout={values.insurance_life_covidVaccineAmout}
                insuranceLifeDeathCoverAmout={values.insurance_life_deathCoverAmout}
            />
            )}

        {(selectedInsuranceId == 10) && (
            <BikeQuestions
                onChange={handleChange}
                onChangeAutoComplete={handleChangeAutoComplete}
                onChangeNumericBikeManufactureYear={(e) => setValues({ ...values, insurance_bike_manufactureYear: e.target.value })}
                onChangeNumericBikeCost={(e) => setValues({ ...values, insurance_bike_cost: e.target.value })}
                insuranceInvoice={values.insurance_invoice}
                insuranceBrand={values.insurance_brand}
                insuranceModel={values.insurance_model}
                insuranceBikeManufactureYear={values.insurance_bike_manufactureYear}
                insuranceBikeCost={values.insurance_bike_cost}
                insuranceBikeType={values.insurance_bike_type}
                insuranceBikeUseType={values.insurance_bike_useType}
            />
            )}

        {(selectedInsuranceId == 27) && (
            <BusinessQuestions
                onChange={handleChange}
                onChangeAutoComplete={handleChangeAutoComplete}
                insuranceBusinessPersonType={values.insurance_business_personType}
                insuranceBusinessActivityPlace={values.insurance_business_activityPlace}
                insuranceAmount={values.insurance_residence_amount}
                insuranceOwner={values.insurance_residence_owner}
                insuranceNew={values.insurance_residence_new}
            />
            )}

        {(selectedInsuranceId == 28) && (
            <CondoQuestions
                onChange={handleChange}
                onChangeAutoComplete={handleChangeAutoComplete}
                onChangeNumericFireAmount={(e) => setValues({ ...values, insurance_condo_fire_amount: e.target.value })}
                onChangeNumericBlocksAmount={(e) => setValues({ ...values, insurance_condo_blocksAmount: e.target.value })}
                onChangeNumericElevatorsAmount={(e) => setValues({ ...values, insurance_condo_elevatorsAmount: e.target.value })}
                onChangeNumericFloorsAmount={(e) => setValues({ ...values, insurance_condo_floorsAmount: e.target.value })}
                onChangeNumericApartmentFloorAmount={(e) => setValues({ ...values, insurance_condo_apartmentFloorAmount: e.target.value })}
                onChangeNumericParkingSpaceAmount={(e) => setValues({ ...values, insurance_condo_parkingSpaceAmount: e.target.value })}
                insuranceCondoFireAmount={values.insurance_condo_fire_amount}
                insuranceCondoBlocksAmount={values.insurance_condo_blocksAmount}
                insuranceCondoElevatorsAmount={values.insurance_condo_elevatorsAmount}
                insuranceCondoFloorsAmount={values.insurance_condo_floorsAmount}
                insuranceCondoApartmentFloorAmount={values.insurance_condo_apartmentFloorAmount}
                insuranceCondoParkingSpaceAmount={values.insurance_condo_parkingSpaceAmount}
                insuranceNew={values.insurance_residence_new}
            />
            )}

        {(selectedInsuranceId == 29) && (
            <MobileQuestions
                onChange={handleChange}
                onChangeAutoComplete={handleChangeAutoComplete}
                insuranceInvoice={values.insurance_invoice}
                insuranceBrand={values.insurance_brand}
                insuranceModel={values.insurance_model}
                insuranceManufactureYear={values.insurance_manufactureYear}
                insuranceBuyAmount={values.insurance_buyAmount}
                insuranceBuyDate={values.insurance_buyDate}
            />
            )}

        {(selectedInsuranceId == 30) && (
            <PortableEquipmentQuestions
                onChange={handleChange}
                onChangeAutoComplete={handleChangeAutoComplete}
                insuranceInvoice={values.insurance_invoice}
                insuranceBrand={values.insurance_brand}
                insuranceModel={values.insurance_model}
                insuranceManufactureYear={values.insurance_manufactureYear}
                insuranceBuyAmount={values.insurance_buyAmount}
                insuranceBuyDate={values.insurance_buyDate}
                insuranceEquipmentType={values.insurance_equipmentType}
            />
            )}

        {(selectedInsuranceId == 31) && (
            <BailQuestions
                onChange={handleChange}
                onChangeAutoComplete={handleChangeAutoComplete}
                insuranceBailHomeHabitationType={values.insurance_bail_homeHabitationType}
                insuranceBailName={values.insurance_bail_name}
                insuranceBailRentAmount={values.insurance_bail_rentAmount}
                insuranceBailIptuAmount={values.insurance_bail_iptuAmount}
                insuranceBailCondoAmount={values.insurance_bail_condoAmount}
            />
            )}

        </>
    )
}

InsuranceComponents.propTypes = {
    selectedInsuranceId: P.number.isRequired,
    handleChange: P.objectOf(P.any).isRequired,
    handleChangeAutoComplete: P.objectOf(P.any).isRequired,
    values: P.objectOf(P.any).isRequired,
    setValues: P.objectOf(P.any).isRequired,
}