/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { FormGroup } from '../styles';
import { TextField, AutocompleteField } from '../../FormFields';
import { CommonInvoiceInsuranceQuestions,
    CommonYearValueDateInsuranceQuestions
} from './commonQuestions';


export function PortableEquipmentQuestions({
    onChange,
    insuranceInvoice,
    insuranceBrand,
    insuranceModel,
    insuranceManufactureYear,
    insuranceBuyAmount,
    insuranceBuyDate,
    insuranceEquipmentType,
})
{
    return (
        <>
        <FormGroup dark>
            <CommonInvoiceInsuranceQuestions
                onChange={onChange}
                insuranceInvoice={insuranceInvoice}
                insuranceBrand={insuranceBrand}
                insuranceModel={insuranceModel}
            />
            <TextField
                value={insuranceEquipmentType || ''}
                onChange={onChange}
                varibleName="insurance_equipmentType"
                placeholder="Equipamento"
                className="control ms"
                label="Tipo de Equipamento"
            />
        </FormGroup>
        
        <FormGroup>
            <CommonYearValueDateInsuranceQuestions
                onChange={onChange}
                insuranceManufactureYear={insuranceManufactureYear}
                insuranceBuyAmount={insuranceBuyAmount}
                insuranceBuyDate={insuranceBuyDate}
            />
        </FormGroup>
        </>
    )
}

PortableEquipmentQuestions.propTypes = {
    onChange: P.objectOf(P.any).isRequired,
    insuranceInvoice: P.string.isRequired,
    insuranceModel: P.string.isRequired,
    insuranceBrand: P.string.isRequired,
    insuranceManufactureYear: P.number.isRequired,
    insuranceBuyAmount: P.number.isRequired,
    insuranceBuyDate: P.string.isRequired,
    insuranceEquipmentType: P.string.isRequired,
}