/* eslint-disable react/prop-types */
import { Loading } from '../Loading';
import { LogoHorizontal } from '../Logo';

export const FullPageLoading = ({ transparent }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      zIndex: 100000,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: transparent ? '#22222210' : '#fff',
    }}>
    <LogoHorizontal />
    <Loading />
  </div>
);
