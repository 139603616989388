/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable */

import { Box, Button, FormGroup, Paper, useMediaQuery } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import { AutocompleteField, DateField } from '../FormFields';
import { FunnelCharts } from '../FunnelCharts';
import { FilterContainer, FunnelBox, GridContainer } from './styled';
import { getChartInformation } from '../../service/chart.service';
import { maskDate, maskPercent } from '../../utils/masks';

const empty = {};

export const ExposeCharts = () => {
  const matchesH = useMediaQuery('(max-height:800px)');
  const { data: users } = useSWR('/users', fetcher);
  const { data: channels } = useSWR('/channels', fetcher);
  const { data: insurances } = useSWR('/insurances', fetcher);
  const { data: dashboard } = useSWR('/dashboard', variantFetcher);
  const { data: percentMetasConfig } = useSWR('/getPercentMetasConfig', variantFetcher);

  const [selectChart, setSelectChart] = useState(null);
  const [hoverChart, setHoverChart] = useState(null);
  const [charts, setCharts] = useState([null, null, null, null, null, null, null, null, null, null, null, null]);
  const [filters, setFilters] = useState([null, null, null, null, null, null, null, null, null, null, null, null]);
  const [values, setValues] = useState({
    technical_teams: null,
    broker: null,
    channel: null,
    insurance: null,
    startDate: null,
    endDate: null,
  });
  const [clearHoverChart, setClearHoverChart] = useState(true);

  useEffect(() => {
    setValues({
      technical_teams: filters[selectChart]?.technical_teams || null,
      broker: filters[selectChart]?.broker || null,
      channel: filters[selectChart]?.channel || null,
      insurance: filters[selectChart]?.insurance || null,
      startDate: filters[selectChart]?.startDate || DateTime.now().toISODate(),
      endDate: filters[selectChart]?.endDate || DateTime.now().toISODate(),
    });
  }, [selectChart]);

  useEffect(() => {
    setValues({
      technical_teams: filters[selectChart]?.technical_teams || null,
      broker: filters[selectChart]?.broker || null,
      channel: filters[selectChart]?.channel || null,
      insurance: filters[selectChart]?.insurance || null,
      startDate: filters[selectChart]?.startDate || DateTime.now().toISODate(),
      endDate: filters[selectChart]?.endDate || DateTime.now().toISODate(),
    });
  }, [filters]);

  useEffect(() => {
    setValues({
      ...values,
      startDate: DateTime.now().toISODate(),
      endDate: DateTime.now().toISODate(),
    });
  }, []);

  const handleChangeAutoComplete = (event, newValue, variable) => {
    setValues({
      ...values,
      [variable]: newValue,
    });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      filters.splice(selectChart, 1, values);

      setFilters([...filters]);
      const chartData = await getChartInformation({ ...filters[selectChart] });

      charts.splice(selectChart, 1, chartData);
      setCharts([...charts]);
    } catch (error) {
      console.log(error);
    }
  };

  const LigouDentroDoPrazoPercent = () => {
    return maskPercent(charts[hoverChart]?.data && charts[hoverChart]?.data[1]?.values[0]?.value / charts[hoverChart]?.data[0]?.total);
  }

  const ConseguiuContatoPercent = () => {
    return maskPercent(charts[hoverChart]?.data && charts[hoverChart]?.data[2]?.values[0]?.value / charts[hoverChart]?.data[0]?.total);
  }

  const EnviouCotacaoPercent = () => {
    return maskPercent(charts[hoverChart]?.data && charts[hoverChart]?.data[3]?.values[0]?.value / charts[hoverChart]?.data[2]?.values[0]?.value);
  }

  const VendidosPercent = () => {
    return maskPercent(charts[hoverChart]?.data && charts[hoverChart]?.data[4]?.values[0]?.value / charts[hoverChart]?.data[2]?.values[0]?.value);
  }

  const ClearHoverChart = () => {
    if (clearHoverChart)
      setHoverChart(null);
  }


  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Paper elevation={3}>
        <FilterContainer height={matchesH ? '480px' : '550px' } width="250px" padding={matchesH ? '4px' : '12px' }>
          {hoverChart !== null &&
            <div className="hidden-modal">
              <p>
                  {!filters[hoverChart]?.broker && !filters[hoverChart]?.channe && !filters[hoverChart]?.technical_teams && ' Total '}
                  {filters[hoverChart]?.technical_teams && `Equipe técnica: ${filters[hoverChart]?.technical_teams?.description}`}
                  {filters[hoverChart]?.technical_teams && filters[hoverChart]?.channel && ' - '}
                  {filters[hoverChart]?.broker ? filters[hoverChart]?.broker.name : ''}
                  {filters[hoverChart]?.broker && filters[hoverChart]?.channel && ' - '}
                  {filters[hoverChart]?.channel ? filters[hoverChart]?.channel.name : ''}
                  {(filters[hoverChart]?.broker || filters[hoverChart]?.channel || filters[hoverChart]?.technical_teams) && filters[hoverChart]?.insurance && ' - '}
                  {filters[hoverChart]?.insurance ? filters[hoverChart]?.insurance.name : ''}
              </p>
              <p>Periodo: <span>{maskDate(filters[hoverChart]?.startDate)} - {maskDate(filters[hoverChart]?.endDate)}</span></p>
              <p>Nº de Leads: <span>{charts[hoverChart]?.data && charts[hoverChart]?.data[0]?.total}</span></p>
             {console.log(percentMetasConfig)}
              <p>Ligou dentro do prazo de 1H: <br/>
                <span>{charts[hoverChart]?.data && charts[hoverChart]?.data[1]?.values[0]?.value} / {charts[hoverChart]?.data && charts[hoverChart]?.data[0]?.total} - 
                 ({LigouDentroDoPrazoPercent()} / {percentMetasConfig?.MetaLigouDentroDoPrazo} META)</span></p>
              
              <p>Conseguiu contato: <br/>
                <span> {charts[hoverChart]?.data && charts[hoverChart]?.data[2]?.values[0]?.value} / {charts[hoverChart]?.data && charts[hoverChart]?.data[0]?.total} - 
                  ({ConseguiuContatoPercent()} / {percentMetasConfig?.MetaConseguiuContato} META)</span></p>
              
              <p>Enviou Cotação:  <br/>
                <span> {charts[hoverChart]?.data && charts[hoverChart]?.data[3]?.values[0]?.value} / {charts[hoverChart]?.data && charts[hoverChart]?.data[2]?.values[0]?.value} - 
                  ({EnviouCotacaoPercent()} / {percentMetasConfig?.MetaEnviouCotacao} META)</span></p>

              <p>Vendidos:  <br/>
              <span>  {charts[hoverChart]?.data && charts[hoverChart]?.data[4]?.values[0]?.value} / {charts[hoverChart]?.data && charts[hoverChart]?.data[3]?.values[0].value} -
                ({VendidosPercent()} / {percentMetasConfig?.MetaVendidos} META)</span></p>
                                          
              <p>Conversão:  <br/>
              <span>{maskPercent(charts[hoverChart]?.data && charts[hoverChart]?.data[5]?.values[0]?.value)}</span></p>
            </div>            
          }

          <form onSubmit={handleSubmit}>
            <FormGroup>
            <DateField
                value={values.startDate}
                onChange={handleChange}
                varibleName="startDate"
                label="Data Início de Captura"
                disabled={selectChart === null}
                required
              />
              <DateField
                value={values.endDate || ''}
                onChange={handleChange}
                varibleName="endDate"
                label="Data Fim de Captura"
                disabled={selectChart === null}
                required
              />

              <AutocompleteField
                getOptionLabel={(option) => option.description}
                className="control g"
                valueText={values.technical_teams ? values.technical_teams.name : ''}
                options={dashboard?.technical_teams}
                value={values.technical_teams || null}
                varibleName="technical_teams"
                onChange={handleChangeAutoComplete}
                label="Equipe técnica"
                disabled={values.broker || selectChart === null}
                // required
              />
              <AutocompleteField
                className="control g"
                valueText={values.broker ? values.broker.name : ''}
                options={users}
                value={values.broker || null}
                varibleName="broker"
                onChange={handleChangeAutoComplete}
                label="CORRETOR"
                disabled={values.technical_teams || selectChart === null}
                // required
              />
              <AutocompleteField
                className="control g"
                valueText={values.channel ? values.channel.name : ''}
                options={channels}
                value={values.channel || null}
                varibleName="channel"
                onChange={handleChangeAutoComplete}
                label="CANAL"
                disabled={selectChart === null}
                // required
              />
              <AutocompleteField
                className="control g"
                valueText={values.insurance ? values.insurance.name : ''}
                options={insurances}
                value={values.insurance || null}
                varibleName="insurance"
                onChange={handleChangeAutoComplete}
                label="SETOR"
                disabled={selectChart === null}
                // required
              />
            </FormGroup>
            <Box display="flex" justifyContent="space-between">
              <Button type="submit" variant="contained" color="default" disabled={selectChart === null}>
                Filtrar
              </Button>
              <Button
                type="button"
                onClick={() => {
                  charts.splice(selectChart, 1, null);
                  setCharts([...charts]);
                  filters.splice(selectChart, 1, {
                    technical_teams: null,
                    broker: null,
                    channel: null,
                    startDate: DateTime.now().toISODate(),
                    endDate: DateTime.now().toISODate(),
                  });
                  setFilters([...filters]);

                  setSelectChart(null);
                }}
                variant="contained"
                color="default"
                disabled={selectChart === null}>
                Limpar
              </Button>
            </Box>
          </form>
        </FilterContainer>
      </Paper>
      <GridContainer>
        {charts.map((c, i) => (
          <FunnelBox
            created={c != null}
            width={i === 0 ? (matchesH ? '320px' : '400px') : (matchesH ? '160px' : '200px')}
            height={i === 0 ? (matchesH ? '320px' : '400px') : (matchesH ? '160px' : '200px')}
            className={`area${i + 1}`}>

            {c != null ? (
              <>
                <div className="header" 
                  onMouseEnter={()=>setHoverChart(i)}
                  onMouseLeave={ClearHoverChart}
                  onClick={()=>setClearHoverChart(!clearHoverChart)}
                >

                  {!filters[i]?.broker && !filters[i]?.channe && !filters[i]?.technical_teams && ' Total '}
                  {filters[i]?.technical_teams && `Equipe técnica: ${filters[i]?.technical_teams?.description}`}
                  {filters[i]?.technical_teams && filters[i]?.channel && ' - '}
                  {filters[i]?.broker ? filters[i]?.broker.name : ''}
                  {filters[i]?.broker && filters[i]?.channel && ' - '}
                  {filters[i]?.channel ? filters[i]?.channel.name : ''}
                  {(filters[i]?.broker || filters[i]?.channel || filters[i]?.technical_teams) && filters[i]?.insurance && ' - '}
                  {filters[i]?.insurance ? filters[i]?.insurance.name : ''}
                </div>
                <Box
                  width="100%"
                  height="100%"
                  padding={matchesH ? '2px' : '15px'}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    if (i != 0) {
                      const first = charts.splice(0, 1, null);
                      const current = charts.splice(i, 1, ...first);
                      charts.splice(0, 1, ...current);
                      setCharts([...charts]);

                      const firstFilter = filters.splice(0, 1, null);
                      const currentFilter = filters.splice(i, 1, ...firstFilter);
                      filters.splice(0, 1, ...currentFilter);
                      setFilters([...filters]);

                    }
                    setSelectChart(0);
                  }}
                  style={{ cursor: 'pointer' }}>
                  {c.data && c.data[0]?.total > 0 ? (
                    <FunnelCharts size={i === 0 ? (matchesH ? '246px' : '311px') : (matchesH ? '125px' : '131px')} data={c.data} />
                  ) : (
                    <p style={{ textAlign: 'center' }}>
                      {!c.data && 'Selecione os filtros desejados'}
                      {c.data && (c.data[0]?.total || c.data.lenght == 0) <= 0 && 'Nenhuma Lead encontrada neste período!'}
                    </p>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                  <button
                    type="button"
                    style={{backgroundColor: 'transparent', border: 'none', cursor: 'pointer'}}
                    onClick={() => {
                      charts.splice(i, 1, empty);
                      setCharts([...charts]);
                      setSelectChart(i);
                    }}>
                    <AddCircleOutlineIcon style={{ fontSize: 40 }} />
                  </button>
                </Box>
              </>
            )}
          </FunnelBox>
        ))}
      </GridContainer>
    </Box>
  );
};
