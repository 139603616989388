/* eslint-disable no-unused-vars */
/* eslint-disable */
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import P from 'prop-types';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import useSWR from 'swr';
import { useAuth } from '../../../hooks/useAuth';
import { fetcher } from '../../../service/SWRFetcher';
import { Nav, NavbarForm, Links } from './styles';

const Navbar = ({ clickFilter, clickFilterBtn, clearFilter, clickSevenDays, checkValue, scheduler, setLockTabs, clearSelected, clickOnlySchedules, checkValueSchedules }) => {
  const { user } = useAuth();
  const { data: users, mutate: mutateUsers, isValidating } = useSWR(user.role < 3 ? `/users` : null, fetcher);
  const [filters, setFilters] = useState({
    lead_id:'',
    name: '',
    broker: '',
    cpf: '',
    start_date: '',
    end_date: '',
  });

  const [filterBtn, setFilterBtn] = useState({
    name: '',
    value: '',
    change: false,
  });


  useEffect(() => {
    if (filterBtn.change === true) {
      setFilterBtn({ ...filterBtn, change: false });
      if (typeof filterBtn.value !== 'number') setFilterBtn({ name: '', value: '' });
      else clickFilterBtn(filterBtn.name, filterBtn.value);
    }
  }, [filterBtn.change]);


  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <Nav>
      <NavbarForm>
        <div className="filter">Filtros</div>
        <div className="name">
          <input
            type="text"
            className="input-form"
            placeholder="Nome"
            name="name"
            value={filters.name}
            onChange={handleChange}
          />
        </div>
        <div className="lead_id">
          <input
            type="text"
            className="input-form"
            placeholder="Núm. do Lead"
            name="lead_id"
            value={filters.lead_id}
            onChange={handleChange} 
          />
        </div>
        <div className="cpf">
          <InputMask
            maskChar=""
            mask={filters.cpf.replace(/[^\d]/g, '').length < 12 ? '999.999.999-999' : '99.999.999/9999-99'}
            type="text"
            className="input-form"
            name="cpf"
            placeholder="CPF"
            value={filters.cpf}
            onChange={handleChange}
          />
        </div>
        <div className="broker">
            <input list="select" name="broker" className="select-form" placeholder="Corretor" 
            value={filters.broker} onChange={handleChange}
            />
            <datalist id="select">
              {user.role < 3 ? users?.map((item) => (
                <option key={item.id} value={item.name}> </option>
              ))
              :
                <option key={user.id} value={user.name}> </option>
            }
            </datalist>
        </div>
        <div className="date_ini">
          <input type="date" name="start_date" className="input-form" placeholder="Data Inicial" value={filters.start_date} onChange={handleChange} />
        </div>
        <div className="date_end">
          <input type="date" name="end_date" className="input-form" placeholder="Data Inicial" value={filters.end_date} onChange={handleChange}/>
        </div>
        <div className="filter-btn">
          <Button variant="contained" onClick={() => {setLockTabs(false), clearSelected(), clickFilter(filters.name, filters.cpf.replace(/[^0-9]/g, ''),  filters.broker, filters.start_date, filters.end_date, filters.lead_id)} }>
            Filtrar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              clearFilter();
              clearSelected();
              setFilters({
                lead_id:'',
                name: '',
                broker: '',
                cpf: '',
                start_date: '',
                end_date: '',
              });   
                  
            }}>
            Limpar Filtros
          </Button>
        </div>
      </NavbarForm>
    </Nav>
  );
};

Navbar.propTypes = {
  clickFilter: P.func,
  clearFilter: P.func,
  clickFilterBtn: P.func,
  clickSevenDays: P.func,
  scheduler: P.bool,
  checkValue: P.bool,
};
Navbar.defaultProps = {
  clickFilter: () => {},
  clickFilterBtn: () => {},
  clearFilter: () => {},
  clickSevenDays: () => {},
  scheduler: false,
  checkValue: true,
};

export default Navbar;
