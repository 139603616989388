import { Ul } from './style';

export const Loading = () => (
  <Ul>
    <li className="li li-1" />
    <li className="li li-2" />
    <li className="li li-3" />
    <li className="li li-4" />
    <li className="li li-5" />
    <li className="li li-6" />
  </Ul>
);

export const LoadingButton = () => (
  <Ul>
    <li className="liButton li-1" />
    <li className="liButton li-2" />
    <li className="liButton li-3" />
  </Ul>
);

export default Loading;
