/* eslint-disable react/prop-types */
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ListItemText, Menu, withStyles } from '@material-ui/core';
import { Logo, StyledMenuItem } from './styles';
import { logout } from '../../service/auth.service';
import { useAuth } from '../../hooks/useAuth';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function LogoUser({ user, children }) {
  const {logoutUser} = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [logoutRed, setLogoutRed] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(redirect)
    setRedirect(false);
  }, [redirect])

  return (
    <div>
      {redirect && <Redirect to="/profile" />}
      {logoutRed && <Redirect to="/login" />}
      <Logo variant="contained" onClick={handleClick}>
        <span>{children}</span>
      </Logo>
      <StyledMenu 
        id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {user?.channel_id > 1 && (
          <StyledMenuItem
            onClick={() => {
              handleClose();
              setRedirect(true);
            }}>
            <ListItemText primary="Profile" />
          </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={() => {
            logoutUser();
            setLogoutRed(true);
          }}>
          <ListItemText primary="Logout" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
