import styled from "styled-components";


export const DivSeparacao = styled.div`
display: flex;
flex-direction: row;

@media (max-width: 1024px){
    flex-direction: column ;
}
`;

export const EstornoButton = styled.button`
    min-width: fit-content;
    cursor: pointer;
    color: rgb(255, 255, 255);
    border: none;
    font-weight: 700;
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    white-space: nowrap;
    vertical-align: middle;
    background-color: rgb(255,0,0);
    text-transform: uppercase;
    font-family: "DM Sans", sans-serif;
    :hover{
      background-color: rgb(188, 40, 40);
    }
`;