/* eslint-disable no-unused-vars */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import P from 'prop-types';

export default function AlertDialog({ open, setOpen, msg, title, next, ok }) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (next) next();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {title.length > 0 ? title[0].toUpperCase() + title.substring(1) : ''}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msg.length > 0 ? msg[0].toUpperCase() + msg.substring(1) : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {ok != null && (
            <Button onClick={ok} color="primary" autoFocus>
              Ok
            </Button>
          )}
          {ok == null && (
            <>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                Continuar
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  open: P.bool.isRequired,
  setOpen: P.func,
  msg: P.string,
  title: P.string,
  next: P.func,
  ok: P.func,
};

AlertDialog.defaultProps = {
  ok: null,
  next: null,
  msg: 'Dialog',
  title: 'Dialog',
  setOpen: null,
};
