/* eslint-disable */
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import useSWR from 'swr';
import { Header, Container, ExportReportWrapper } from '../../styles/shared/styles';
import DatePeriodFilter from '../../components/AttendanceReportTableList/Filter';
import LoadingPage from '../LoadingPage';
import AttendanceReportTableList from '../../components/AttendanceReportTableList';
import { fetcher } from '../../service/SWRFetcher';
import useApp from '../../hooks/useApp';
import { excelExport } from '../../service/excel.service';

const AttendanceReport = () => {
  const { setState } = useApp();
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();

  const [filters, setFilters] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
    broker_id: [],
    partner_id: [],
    sold: null,
  });

  const brokerFilter = () => {
    if (!filters.broker_id || filters.broker_id.length === 0) return '';

    let stringBrokerFilter = '';
    filters.broker_id.map((b) => (stringBrokerFilter += `&broker_id[]=${b.id}`));
    return stringBrokerFilter;
  };

  const partnerFilter = () => {
    if (!filters.partner_id || filters.partner_id.length === 0) return '';

    let stringPartnerFilter = '';
    filters.partner_id.map((partner) => (stringPartnerFilter += `&partner_id[]=${partner.id}`));
    return stringPartnerFilter;
  };

  const { data, isValidating } = useSWR(
    `/leads/AttendanceReport?start_date=${filters.date_ini}&end_date=${
      filters.date_end
    }${brokerFilter()}${partnerFilter()}`,
    fetcher
  );

  useEffect(() => {
    setState('loading', isValidating);
  }, [isValidating]);

  return (
    <>
      <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
        <Container>
          <Header>
            <h1>Relatório de Atendimento</h1>
          </Header>
          <DatePeriodFilter setFilters={setFilters} />

          <AttendanceReportTableList listItem={data} />

          {!!data && data?.length > 0 && (
            <ExportReportWrapper>
              <button
                className="button"
                onClick={() =>
                  excelExport(
                    `/leads/AttendanceReportExport?start_date=${filters.date_ini}&end_date=${
                      filters.date_end
                    }${brokerFilter()}${partnerFilter()}`,
                    'relatorio_de_atendimento' + DateTime.now().toFormat('dd_LL_yyyy')
                  )
                }>
                Exportar
              </button>
            </ExportReportWrapper>
          )}
        </Container>
      </motion.div>
    </>
  );
};

export default AttendanceReport;
