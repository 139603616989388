import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  color: #fc986a;
`;

export const AddButton = styled(Button)`
  /* margin: 2px; */
  background-color: #fc986a;
  color: #fff;

  :hover {
    background-color: #f26522;
  }
`;

export const Container = styled.div`
  padding: 0 16px 16px 16px;
`;

export const ExportReportWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 4px;
  padding-right: 4px;
  padding: 15px 15px;

  button {
    cursor: pointer;
      color: #fff;
      border: none;
      font-weight: 700;
      padding: 8px 16px;
      text-align: center;
      border-radius: 8px;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      background-color: #f26522;
      text-transform: uppercase;
      font-family: 'DM Sans', sans-serif;
  }
`;
