import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;

  /* max-width: 800px; */

  .filter {
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    color: #777;
  }
`;

export const NavbarForm = styled.div`

  .email,
  .client {
    margin-left: 4px;
    input,
    select {
      font: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      width: 100%;
      color: #333;
      border: none;
      height: 34px;
      outline: none;
      display: block;
      padding: 6px 12px;
      border-radius: 8px;
      transition: all 0.2s ease;
      background-color: #ecf0f1;
      border-bottom: 2px solid transparent;

      :hover {
        border-color: #f26522;
      }
    }
  } 

  .is-active{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    white-space: nowrap;
    input {
      /* width: ; */
    }
  }

  .form {
    display: flex;
    > div {
      padding-left: 4px;
      padding-right: 4px;
    }

    .name {
      margin-right: 5px;
    }

    .inputs {
      display: flex;
      input,
      select {
        font: inherit;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        width: 100%;
        color: #333;
        border: none;
        height: 34px;
        outline: none;
        display: block;
        padding: 6px 12px;
        border-radius: 8px;
        transition: all 0.2s ease;
        background-color: #ecf0f1;
        border-bottom: 2px solid transparent;

        :hover {
          border-color: #f26522;
        }
      }
    }

    input,
    select {
      width: 100%;
      color: #333;
      border: none;
      outline: none;
      border-radius: 8px;
      transition: all 0.2s ease;
      border-bottom: 2px solid transparent;
    }
    .autocomplete > div {
      background-color: #ecf0f1;
      border-radius: 8px;
      ::before {
        border-radius: 8px;
      }
      ::after {
        border-bottom: none;
      }
      :hover {
        ::before {
          border-color: #f26522;
        }
      }
    }

    .button {
      cursor: pointer;
      color: #fff;
      border: none;
      font-weight: 700;
      padding: 8px 16px;
      text-align: center;
      border-radius: 8px;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      background-color: #f26522;
      text-transform: uppercase;
      font-family: 'DM Sans', sans-serif;
      margin-left: 8px;
    }
  }
`;

export const Links = styled.div`
  display: flex;
  a {
    list-style: none;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #777;
  }
`;
