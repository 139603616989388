/* eslint-disable */
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Header, Container, ExportReportWrapper } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import Box from '@material-ui/core/Box';

import DatePeriodFilter from '../../components/CallsReportTableList/Filter';
import CallsReportTableList from '../../components/CallsReportTableList';

import { DateTime } from 'luxon';
import { useAuth } from '../../hooks/useAuth';
import useApp from '../../hooks/useApp';
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import { excelExport } from '../../service/excel.service';

const CallsReport = () => {
  const { setState } = useApp();
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [checkFilter, setCheckFilter] = useState(true);
  const [filters, setFilters] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
    partner_id: [],
    broker_id: [],
    status: '',
    client: '',
    isCallReport: true,
  });

  const partnerFilter = () => {
    if (!filters.partner_id || filters.partner_id.length === 0) return '';

    let stringPartnerFilter = '';
    filters.partner_id.map((partner) => (stringPartnerFilter += `&partner_id[]=${partner.id}`));
    return stringPartnerFilter;
  };

  const brokerFilter = () => {
    if (!filters.broker_id || filters.broker_id.length === 0) return '';

    let stringBrokerFilter = '';
    filters.broker_id.map((broker) => (stringBrokerFilter += `&broker_id[]=${broker.id}`));
    return stringBrokerFilter;
  };

  const {
    data: listLeads,
    mutate,
    isValidating: validateLeds,
  } = useSWR(
    `/leads/list-broker?status=${filters.status}&date_ini=${filters.date_ini}&date_end=${
      filters.date_end
    }${partnerFilter()}${brokerFilter()}&client_name=${filters.client}&isCallReport=${
      filters.isCallReport
    }&per_page=all`,
    fetcher
  );

  const { data: callsResult, isValidating: validatecells } = useSWR(`/callsResult`, fetcher);

  useEffect(() => {
    setState('loading', validateLeds || validatecells);
  }, [validateLeds, validatecells]);

  return (
    <>
      <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
        <Container>
          <Header>
            <h1>Relatório de Ligações</h1>
          </Header>
          <DatePeriodFilter setFilters={setFilters} setCheckFilter={setCheckFilter} checkFilter={checkFilter} />
          {listLeads && callsResult && (
            <>
              <CallsReportTableList listItem={listLeads} filters={filters} callResult={callsResult} mutate={mutate} />
            </>
          )}

          {!!listLeads && listLeads.length > 0 && (
            <ExportReportWrapper>
              <button
                className="button"
                onClick={() => {
                  excelExport(
                    `/leads/callsManagerReportExport?status=${filters.status}&date_ini=${filters.date_ini}&date_end=${
                      filters.date_end
                    }${partnerFilter()}${brokerFilter()}&client_name=${filters.client}&isCallReport=${
                      filters.isCallReport
                    }&per_page=all`,
                    'relatorio_ligações_' + DateTime.now().toFormat('dd_LL_yyyy')
                  );
                }}>
                Exportar
              </button>
            </ExportReportWrapper>
          )}
        </Container>
      </motion.div>
    </>
  );
};

export default CallsReport;
