/* eslint-disable */
import { useState } from 'react';
import { Nav, NavbarForm } from './styles';
import { DateTime } from 'luxon';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import useSWR from 'swr';
import { fetcher } from '../../../service/SWRFetcher';
import useApp from '../../../hooks/useApp';

const statusList = [
  { name: 'Atendida', value: '2' },
  { name: 'Não atendida', value: '3' },
  { name: 'Ocupado', value: '0' },
  { name: 'Falha', value: '1' },
];

const DatePeriodFilter = ({ setFilters, setCheckFilter, checkFilter }) => {
  const { partners } = useApp();

  const { data: users } = useSWR(`/users`, fetcher);

  function handleSubmit(event) {
    event.preventDefault();
    setCheckFilter(false);
    let filters = {
      date_ini: event.target.elements.date_ini?.value,
      date_end: event.target.elements.date_end?.value,
      partner_id: partner?.map((partner) => ({ id: partner.id })) || null,
      broker_id: brokerId?.map((b) => ({ id: b.id })) || null,
      status: status?.value || '',
      client: client || '',
      isCallReport: true,
    };
    setFilters((f) => ({ ...f, ...filters }));
  }

  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [dates, setDates] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
  });
  const [partner, setPartner] = useState();
  const [brokerId, setBrokerId] = useState();
  const [status, setStatus] = useState();
  const [client, setClient] = useState();

  return (
    <Nav>
      <div className="filter">Filtros - Data de Criação:</div>

      <NavbarForm>
        <form className="form" onSubmit={handleSubmit}>
          <div className="inputs">
            <div class="tooltip">
              <div className="date_ini">
                <input
                  type="date"
                  name="date_ini"
                  className="input-form"
                  placeholder="Data Inicial"
                  value={dates.date_ini}
                  onChange={(e) => setDates(e)}
                />
              </div>
              <span class="tooltiptext">Data de Criação da Proposta</span>
            </div>
            <div class="tooltip">
              <div className="date_end">
                <input
                  type="date"
                  name="date_end"
                  className="input-form"
                  placeholder="Data Final"
                  value={dates.date_end}
                  onChange={(e) => setDates(e)}
                />
              </div>
              <span class="tooltiptext">Data de Criação da Proposta</span>
            </div>
          </div>

          <Autocomplete
            multiple
            options={partners ? partners : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setPartner(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Empresas" />
            )}
          />

          <Autocomplete
            multiple
            // className="input-form"
            // id="tags-standard"
            options={users ? users?.filter((u) => u.roles[0]?.id <= 3) : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setBrokerId(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Corretor" />
            )}
          />
          <TextField
            className="autocomplete"
            variant="standard"
            placeholder="Cliente"
            value={client}
            onChange={(e) => setClient(e.target.value)}
          />
          <Autocomplete
            // multiple
            // className="input-form"
            // id="tags-standard"
            options={statusList}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setStatus(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Status" />
            )}
          />
          {/* <AutocompleteField
              className="control g"
              valueText={values.broker ? values.broker.name : ''}
              options={users.filter((u) => u.roles[0]?.id <= 3)}
              value={brokerId || null}
              onChange={(e,value)=> setBrokerId(value)}
              label="CORRETOR"
              // disabled={user?.roles[0].id > 2}
              required
            /> */}

          {/* <div className="partner_id">
            <select type="select" name="partner_id" className="select-form" placeholder="Empresa" value={partner.partner_id} onChange={(e) =>setPartner(e)}>
            <option value="">Empresa</option>
              {values.partners.map((item) => (
               <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
          </div> */}
          {/* <div className="insurance_id">
            <select type="select" name="insurance_id" className="select-form" placeholder="Seguro" value={insurance.partner_id} onChange={(e) =>setInsurance(e)}>
            <option value="">Tipo Seguro</option>
              {values.insurances.map((item) => {
                if(corretora){
                  if(item.id > 3) return false
                }
                return (<option key={item.id} value={item.id}>{item.name}</option>)
              }
              )}
            </select>
          </div> */}
          <div>
            <button className="button" type="submit">
              Filtrar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
