/* eslint-disable */
import { Badge, withStyles } from '@material-ui/core';
import P from 'prop-types';
import { ButtonMenu } from './styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: '80%',
    width: '30px',
    height: '30px',
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: '50%',
    fontSize: '1.1rem',
    backgroundColor:'#f26522',
    color: '#fff',
    zIndex: 100,
    // padding: '5px',
  },
}))(Badge);

export default function MenuItemAgendamentos({count, name, onClick, className, nextSch}) {
  return (
    <div>
      <StyledBadge badgeContent={nextSch.count}>
        <ButtonMenu className={`${className} ${nextSch.expired ? 'expired' : ''}`} variant="contained" onClick={onClick}>
          {name} <span className="count">{count}</span>
        </ButtonMenu>
      </StyledBadge>
    </div>
  );
}


MenuItemAgendamentos.propTypes = {
  onClick: P.func,
  name: P.string.isRequired,
  count: P.any,
  className: P.string.isRequired,
  nextSch: P.objectOf(P.any).isRequired
};

MenuItemAgendamentos.defaultProps = {
  onClick: ()=>{},
  count: 0
};
