import { logout } from '../auth.service';
import Login from '../../pages/Login';

const SampleClient = () =>
{    
    localStorage.setItem("tenantId", "sampleclient");
    localStorage.setItem("logoVertical", "logoSampleclient");
    logout();
    
    return Login();
};

export default SampleClient;