import { Button } from '@material-ui/core';
import P from 'prop-types';
import { Nav, NavbarForm } from './styles';

const Filter = ({ clickFilter, clearFilter, children, buttonStyle }) => (
    <Nav>
      <NavbarForm>
        <span>Filtros:</span>
        {children || <></>}
        {clickFilter && (
          <Button
            style={buttonStyle}
            variant="contained"
            onClick={() => clickFilter()}>
            Filtrar
          </Button>
        )}
        {clearFilter && (
          <Button
            style={buttonStyle}
            variant="contained"
            onClick={() => clearFilter()}>
            Limpar Filtros
          </Button>
        )}
      </NavbarForm>
    </Nav>
  );

Filter.propTypes = {
  clickFilter: P.func,
  clearFilter: P.func,
  children: P.element,
  buttonStyle: P.element,
};
Filter.defaultProps = {
  clickFilter: undefined,
  clearFilter: undefined,
  children: undefined,
  buttonStyle: undefined,
};

export default Filter;
