import { useState } from 'react';
import { Checkbox, FormControlLabel, IconButton, TextField, Dialog,
  Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import P from 'prop-types';
import { editPercents } from '../../service/commissionSchema.service';


export default function CommissionPercentsRangeEdit({ open, setOpen, commissionPercents, mutate, update }) {
    const [values, setValues] = useState({
        begin: 0,
        end: 0,
        commission: 0
    });

    const handleClose = () => {
        setOpen(false);
    };
          
    const submit = async (e) => {
      e?.preventDefault();
    
      const percents = {
        commissionSchema_id: commissionPercents.commissionSchema_id,
        begin: parseFloat(values.begin),
        end: parseFloat(values.end),
        commission: parseFloat(values.commission),
      };

      try {
        await editPercents(percents, commissionPercents.id);
  
        toast.success('Comissão alterada!');
        mutate();
      } catch (error) {
        toast.error('Erro ao alterar a comissão');
      }
  
      update();
      handleClose();
    };

    const entering = () => {
      setValues({
        begin: commissionPercents.begin, 
        end: commissionPercents.end, 
        commission: commissionPercents.commission});
    }

      
    return (
        <div>
          <Dialog
            TransitionProps={{onEntering: entering}}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            
            maxWidth="md">
            <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Nova Comissão
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle >
            <form onSubmit={submit}>
              <DialogContent>

              <TextField
                autoFocus
                margin="dense"
                label="De"
                type="number"
                value={values.begin}
                fullWidth
                required
                onChange={(e) => setValues({ ...values, begin: e.target.value })}
              />

              <TextField
                margin="dense"
                label="Até"
                type="number"
                value={values.end}
                fullWidth
                onChange={(e) => setValues({ ...values, end: e.target.value })}
              />

              <TextField
                margin="dense"
                label="Comissão"
                type="number"
                value={values.commission}
                fullWidth
                onChange={(e) => setValues({ ...values, commission: e.target.value })}
              />

              </DialogContent>
              <DialogActions style={{position: 'sticky', bottom: 0, backgroundColor:'white', width:'100%',}}>
                <Button onClick={handleClose} color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Salvar
                </Button>
              </DialogActions>
    
            </form>
          </Dialog>
        
        </div>
      );
}
    
CommissionPercentsRangeEdit.propTypes = {
    open: P.bool.isRequired,
    setOpen: P.func.isRequired,
    commissionPercents: P.element.isRequired,
    mutate: P.func.isRequired,
    update: P.func.isRequired,
  };