import styled from 'styled-components';
import { Box, Button, TableRow } from '@material-ui/core';

export const SlectButton = styled(Button)`
  && {
      background-color: darkgray;
      border-radius: 25px;
      margin: 5px;
  }
`;
export const Container = styled(Box)`
  && {
      width: 100%;
      height: calc(100% - 80px);
      border: darkgray solid 2px;
      overflow-y: auto;
      position: relative;
  }
`;
export const StyledTableRow = styled(TableRow)`
  && {
    cursor: pointer;
    :hover{
      background-color: #ffad87;
    }
    :active{
      background-color: #e89771;
    }
  }
`;