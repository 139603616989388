/* eslint-disable */
import { useContext, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import P, { shape } from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import { MainContext } from '../../context/ApiContext';
import UserForm from '../UserForm';
import { MaskedTextField } from '../FormFields';


const InidicationReportGerVeicTableList = ({ listItem, title, states, sold, status }) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [user, setUser] = useState(null);
  const [id, setId] = useState('');
  const [dialog, setDialog] = useState({
    msg: '',
    title: '',
  });

  function formatDate(date){
    if (date == null){ return null}
    var mydate = date?.substring(0, 10) || ""
    var year = mydate?.substring(0, 4) || ""
    var month = mydate?.substring(5,7) || ""
    var day = mydate?.substring(8, 10) || ""
    var hour = date?.substring(11, 13) || ""
    var min = date?.substring(14, 16) || ""
    var sec = date?.substring(17, 119) || ""

    var newDate = day + '/' + month + '/' + year + "\n" + hour + ':' + min + ':' + sec
    return newDate
  }

  function formatPhone(phone){
    if (phone?.length == 11) {
      var ddd = phone?.substring(0, 2) || ""
      var prefix = phone?.substring(2,7) || ""
      var sufix = phone?.substring(7, 11) || ""
    }
    if (phone?.length == 10) {
      var ddd = phone?.substring(0, 2) || ""
      var prefix = phone?.substring(2,6) || ""
      var sufix = phone?.substring(6, 10) || ""
    }
    if (phone?.length < 10 || phone?.length == undefined) {
      return "-"
    }


    var newPhone = '(' + ddd + ') ' + prefix + '-' + sufix
    return newPhone
  }

  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        msg={dialog.msg.length > 0 ? dialog.msg : 'Dialog'}
        title={dialog.title.length > 0 ? dialog.title : 'Dialog'}
      />
      <UserForm open={open} setOpen={setOpen} title={title} user={user} />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Veículo</TableCell>
              <TableCell>Data Indicação</TableCell>
              <TableCell>Data Criação</TableCell>
              <TableCell>Indicado no Prazo</TableCell> 
              <TableCell>Fase</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Indicador</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItem !== null &&
              listItem.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell>{item.client?.name || '-'}</TableCell>
                  <TableCell>{item.product || '-'}</TableCell>
                  <TableCell>{formatDate(item.create_lead_date) || '-'}</TableCell>
                  <TableCell>{formatDate(item.proposal_date) || '-'}</TableCell>
                  <TableCell>{item.indication_on_time  == null ? "-" : item.indication_on_time  == 1 ? 'Sim' : 'Não' || '-'}</TableCell>
                  <TableCell>{states.find((st) => st.var_id == item.state)?.value || "-"}</TableCell>
                  <TableCell>{item.state == 3 ? sold.find((e) => e.var_id == item.sold)?.value : (item.status == null ?  'null' : status.find((st) => st.var_id == item.status)?.value  || '-')}</TableCell>
                  <TableCell>{item.inviter?.name || '-'}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

InidicationReportGerVeicTableList.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
  title: P.string.isRequired,
};

InidicationReportGerVeicTableList.defaultProps = {
  listItem: null,
};

export default InidicationReportGerVeicTableList;
