/* eslint-disable */
import { useContext, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import P, { shape } from 'prop-types';
import { MainContext } from '../../context/ApiContext';

import {DateTime} from 'luxon'


const SalesReportTable = ({ listItem, title, states, status, sold, lostSale }) => {

  return (
    <>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell >ID</TableCell>
              <TableCell >Data</TableCell>
              <TableCell >Proposta</TableCell>
              <TableCell >Cancelado</TableCell>
              <TableCell >Nome</TableCell>
              <TableCell >CPF</TableCell>
              <TableCell >Produto</TableCell>
              <TableCell >Resposta</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItem?.length &&
              listItem.map((item) => (                
                <TableRow key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell align="left">{item.Data ? DateTime.fromISO(item.Data).toFormat('dd/MM/yyyy') : ""}</TableCell>
                  <TableCell align="left">{item.BrokerProposalNumber}</TableCell>
                  <TableCell align="left">{item.cancelled == true ? 'Sim' : 'Não'}</TableCell>
                  <TableCell align="left">{item.insurance_holder?.name}</TableCell>
                  <TableCell align="left">{item.insurance_holder?.cpf}</TableCell>
                  <TableCell align="left">{item.product_name}</TableCell>
                  <TableCell align="left">{item.quiver?.response}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SalesReportTable.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
  title: P.string.isRequired,
};

SalesReportTable.defaultProps = {
  listItem: null,
};

export default SalesReportTable;
