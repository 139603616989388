/* eslint-disable camelcase */
import shallow from 'zustand/shallow';
import appStore from '../store/app';

function useApp() {
  const {
    channels,
    insurances,
    partners,
    lead_states,
    lead_status,
    lead_sold,
    lead_calls_result,
    lead_lost_sale,
    lead_contact_type,
    lead_service_status,
    json_response,
    competitor_brokers,
    technical_teams,
    lost_sale_report,
    first_call_on_time,
    indication_on_time,
    time_to_capture_lead,
    time_to_finish_lead,
    proposal_on_time,
    finished_leads_days_to_show,
    endorsement_leads_days_to_show,
    lost_leads_last_year_days_to_show,
    refresh_lead_seconds,
    loading,
    dashboard_check,
    setState,
  } = appStore(
    (state) => ({
      channels: state.channels,
      insurances: state.insurances,
      partners: state.partners,
      lead_states: state.lead_states,
      lead_status: state.lead_status,
      lead_sold: state.lead_sold,
      lead_calls_result: state.lead_calls_result,
      lead_lost_sale: state.lead_lost_sale,
      lead_contact_type: state.lead_contact_type,
      lead_service_status: state.lead_service_status,
      json_response: state.json_response,
      competitor_brokers: state.competitor_brokers,
      technical_teams: state.technical_teams,
      lost_sale_report: state.lost_sale_report,
      first_call_on_time: state.first_call_on_time,
      indication_on_time: state.indication_on_time,
      time_to_capture_lead: state.time_to_capture_lead,
      time_to_finish_lead: state.time_to_finish_lead,
      proposal_on_time: state.proposal_on_time,
      finished_leads_days_to_show: state.finished_leads_days_to_show,
      endorsement_leads_days_to_show: state.endorsement_leads_days_to_show,
      lost_leads_last_year_days_to_show: state.lost_leads_last_year_days_to_show,
      refresh_lead_seconds: state.refresh_lead_seconds,
      loading: state.loading,
      dashboard_check: state.dashboard_check,
      setState: state.setState,
    }),
    shallow
  );

  return {
    channels,
    insurances,
    partners,
    lead_states,
    lead_status,
    lead_sold,
    lead_calls_result,
    lead_lost_sale,
    lead_contact_type,
    lead_service_status,
    json_response,
    competitor_brokers,
    technical_teams,
    lost_sale_report,
    first_call_on_time,
    indication_on_time,
    time_to_capture_lead,
    time_to_finish_lead,
    proposal_on_time,
    finished_leads_days_to_show,
    endorsement_leads_days_to_show,
    lost_leads_last_year_days_to_show,
    refresh_lead_seconds,
    loading,
    dashboard_check,
    setState,
  };
}

export default useApp;
