/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/forbid-prop-types */
import { useState} from 'react';
import P from 'prop-types';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import { Backdrop, Box, Chip, Fade, makeStyles, Modal, Select } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Card, CardHead, Name, Info, Status, ChipStatus, ButtonBox, Progress } from './styles';
import carIcon from '../../../assets/img/car-icon.svg';
import bikeIcon from '../../../assets/img/bike-icon.svg';
import buildIcon from '../../../assets/img/build-icon.svg';
import homeIcon from '../../../assets/img/home-icon.svg';
import lifeIcon from '../../../assets/img/life-icon.svg';
import petIcon from '../../../assets/img/pet-icon.svg';
import phoneIcon from '../../../assets/img/phone-icon.svg';
import planeIcon from '../../../assets/img/plane-icon.svg';
import userIcon from '../../../assets/img/user-icon.svg';
import clockIcon from '../../../assets/img/clock-icon.svg';
import dateIcon from '../../../assets/img/date-icon.svg';
import fireIcon from '../../../assets/img/fire-icon.svg';
import ConfirmationDialog from '../../ConfirmationDialog';
import api from '../../../service/api';
import LeadView from '../../LeadView';
import BusinessTime from '../../../utils/businessTime';
import useApp from '../../../hooks/useApp';
import { editLead } from '../../../service/lead.service';

const icons = [
  { id: 1, icon: carIcon },
  { id: 2, icon: carIcon },
  { id: 3, icon: carIcon },
  { id: 4, icon: homeIcon },
  { id: 5, icon: carIcon },
  { id: 6, icon: phoneIcon },
  { id: 7, icon: petIcon },
  { id: 8, icon: planeIcon },
  { id: 9, icon: lifeIcon },
  { id: 10, icon: bikeIcon },
  { id: 11, icon: userIcon },
  { id: 12, icon: userIcon },
  { id: 13, icon: userIcon },
  { id: 14, icon: lifeIcon },
  { id: 15, icon: lifeIcon },
  { id: 16, icon: buildIcon },
  { id: 17, icon: lifeIcon },
  { id: 18, icon: carIcon },
  { id: 19, icon: buildIcon },
  { id: 20, icon: userIcon },
  { id: 21, icon: userIcon },
];

const colors = [
  { status: 1, bg: '#2980b9', color: '#fff' },
  { status: 2, bg: '#f39c12', color: '#000' },
  { status: 3, bg: '#16a085', color: '#fff' },
  { status: 4, bg: '#8e44ad', color: '#fff' },
  { status: 5, bg: '#7f8c8d', color: '#fff' },
  { status: 6, bg: '#27ae60', color: '#000' },
  { status: 7, bg: '#d35400', color: '#fff' },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '60%',
    height: '80%',
    borderRadius: 8,
    minWidth: '500px',
    minHeight: '300px',
    padding: '24px 32px',
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
  },
}));

const LeadCard = ({ lead, onClick, onClickView, user, older, mutate }) => {
  const classes = useStyles();
  const { lead_service_status, time_to_capture_lead, time_to_finish_lead, lead_status, channels, lead_sold } = useApp();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [serviceStatus, setServiceStatus] = useState(lead.service_status || null);
  const [dialog, setDialog] = useState({
    msg: '',
    title: '',
  });

  const handleCapture = (idItem, valueItem) => {
    setDialog({
      msg: `Tem certeza que deseja capturar o lead ${valueItem}?`,
      title: `Captura de Lead`,
    });
    // setId(idItem);
    setOpenConfirm(true);
  };

  const captureFunc = async () => {
    try {
      await api.post(`/leads/scheduling`, {
        lead_id: lead.id,
        scheduling_date: DateTime.now().toISO().split('.')[0],
        description: '.',
        status: 1,
      });
    } catch (e) {
      // console.log(e);
    }
    try {
      const { data } = await api.put(`capture-lead/${lead.id}`);
      // getLeads();
      onClick(data);
      toast.success('Lead capturada com sucesso');
    } catch (e) {
      toast.warning('Erro ao capturar Lead');
    }
  };

  const progress = (maxTime) => {
    const now = DateTime.now();
    const captureDate = lead.capture_date?.split(' ').join('T');
    const progressTime = BusinessTime(
      lead.status > 2 ? captureDate : lead.create_lead_date.split(' ').join('T'),
      now.toISO(),
      { timeEnd: '18:00:00' }
    );

    const percent = ((progressTime.hours + progressTime.minutes / 60) / maxTime) * 100;
    if (percent > 100) return 100;
    return percent;
  };

  const handleRecover = async (e) => {
    if (lead.quoted === 1) await editLead({ state: 2, status: 4 }, lead.id);
    else await editLead({ state: 2, status: 3 }, lead.id);
    mutate();
    // await getLeads();
  };

  const handleChangeServiceStatus = async (e) => {
    setServiceStatus(e.target.value);
    await editLead({ service_status: e.target.value }, lead.id);
    mutate();
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const expired = () => {
    if (lead.lead_scheduling) {
      const now = DateTime.now();
      const schedule_date = new Date(lead.lead_scheduling?.scheduling_date);

      if (schedule_date < now) {
        return true;
      }
      return false;
    }
    return false;
  };

  const jsonUser = JSON.parse(user);

  return (
    <Card schedule={DateTime.fromISO(lead?.create_lead_date.replace(' ', 'T')) > DateTime.now() && lead?.state == 1}>
      <ConfirmationDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        msg={dialog.msg.length > 0 ? dialog.msg : 'Dialog'}
        title={dialog.title.length > 0 ? dialog.title : 'Dialog'}
        next={captureFunc}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <LeadView lead={lead} />
          </div>
        </Fade>
      </Modal>

      <CardHead indication={lead.indication_on_time == 1} className="flex-item">
        <div className="lead-number">
        {lead.on_fire == 1 && <img src={fireIcon} alt="onFire"/>}
          <img src={icons.find((i) => i.id === lead.insurance_id)?.icon || userIcon} 
            alt="." 
            style={{marginLeft: '-2px'}}
          />
          <h6 style={{fontWeight: 100, color: '#888', position: 'absolute', top: '10%', fontSize: '10px'}}>
              {lead.insurance?.name}
          </h6>
          {lead.indication_on_time !== null && <div className="on-time" />}
          {lead.id}
        </div>
        <div className="date-time">
          <div className="date">
            <img src={dateIcon} alt="." /> {lead.create_lead_date?.split(' ')[0].split('-').reverse().join('/')}
          </div>
          <div className="time">
            <img src={clockIcon} alt="." />{' '}
            {`${lead.create_lead_date?.split(' ')[1].split(':')[0]}:${
              lead.create_lead_date?.split(' ')[1].split(':')[1]
            }`}
          </div>
        </div>
      </CardHead>

      <Name className="flex-item">
        <h5 className="name">{(lead.client ? lead.client.name : lead.client_info?.client_name) || '-'}</h5>
        <div className="cpf">
          {lead.client?.cpf ? lead.client.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4') : '-'}
        </div>
      </Name>

      <Info expired={expired()} className="flex-item">
        <ChipStatus>
          <Chip
            style={{
              backgroundColor: colors.find((c) => c.status === lead.status).bg,
              color: colors.find((c) => c.status === lead.status).color,
            }}
            className="status-chip"
            label={
              lead.state === 1
                ? `${lead_status?.find((s) => s.var_id === lead.status)?.value} - ${
                    channels?.find((c) => c.id === lead.channel_id)?.name
                  }`
                : lead_status?.find((s) => s.var_id === lead.status)?.value
            }
            variant="outlined"
          />
        </ChipStatus>

        {lead.state === 3 && (
          <Box className="finalizado">
            <strong>Status:</strong>
            <strong style={{ color: lead.sold === 1 ? '#27ae60' : '#d35400', marginLeft: '4px' }}>
              {lead_sold.find((s) => s.var_id === lead.sold)?.value}
            </strong>
          </Box>
        )}

        {lead.lead_scheduling && lead.lead_scheduling.status === 0 && (
          <>
            <div className="status agendado">
              <strong>{[27,28].some(c => c == lead.channel_id) ? 'Puxar o Lead até a data' : 'Agendado'}: </strong>
              {lead.lead_scheduling.scheduling_date.split(' ')[0].split('-').reverse().join('/')}{' '}
              {lead.lead_scheduling.scheduling_date.split(' ')[1]}
            </div>
            {![27,28].some(c => c == lead.channel_id) && (
              <div className="status">
                <strong>Status do agendamento: </strong>
                {lead.lead_scheduling?.status === 1 ? 'Contactado' : 'Pendente'}
              </div>
            )}
            <div>
              <p className="description">
                <strong>Descrição: </strong>
                {lead.lead_scheduling?.description || '-'}
              </p>
            </div>
          </>
        )}
        {[27,28].some(c => c == lead.channel_id) && (
              <div className="status">
                <strong>Vencimento do Seguro: </strong>
                {/* {DateTime.fromISO(lead?.lead_scheduling.scheduling_date.replace(' ', 'T')).plus({days: 15}).toFormat('dd/MM/yyyy : HH:mm:ss')} */}
                {lead?.end_of_validity != null ? DateTime.fromISO(lead?.end_of_validity.replace(' ', 'T')).toFormat('dd/MM/yyyy : HH:mm:ss') : '-'}
              </div>
        )}
        {[27,28].some(c => c == lead.channel_id) && (
            <div className="status">
            <strong>Seguradora: </strong>
            {/* {DateTime.fromISO(lead?.lead_scheduling.scheduling_date.replace(' ', 'T')).plus({days: 15}).toFormat('dd/MM/yyyy : HH:mm:ss')} */}
            {lead?.product_details?.nome_da_seguradora != null ? lead?.product_details?.nome_da_seguradora  : '-'}
          </div>
        )}
        <div className="chanel">
          <strong>Canal: </strong> {(lead.channel && lead.channel.name) || '-'}
        </div>
        <div className="seller">
          <strong>Indicado por </strong> {lead.inviter ? lead.inviter.name : '-'}
        </div>
        <div className="tel">
          {lead.client && lead.client.cellphone
            ? lead.client.cellphone.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')
            : '-'}
        </div>
        <div className="partner">{lead.partner.name || '-'}</div>
        {lead.broker_id && (
          <div className="seller">
            <strong>Corretor</strong> {(lead.broker && lead.broker.name) || '-'}
          </div>
        )}
      </Info>
      
      <ButtonBox>
        {
        ((!lead.broker_id && (jsonUser.role <= 3 || lead?.id == older)) 
        || (lead.state == 1 )) 
        && (
          <Button
            className="button"
            type="button"
            fullWidth
            onClick={() => {
              handleCapture(lead.id, lead.id);
            }}
            disabled={DateTime.fromISO(lead?.create_lead_date.replace(' ', 'T')) > DateTime.now()}>
            Capturar esse Lead
          </Button>
        )}

        {lead.broker_id && (
          <>
            {lead.state > 1 && lead.state !== 3 && (
              <Button className="button" type="button" fullWidth onClick={async () => {
                onClick(lead)
                }}>
                Trabalhar lead
              </Button>
            )}
          </>
        )}

        {lead.state === 3 && jsonUser.role < 3 && (
          <Button className="button" type="button" fullWidth onClick={handleRecover}>
            Recuperar Lead
          </Button>
        )}
        {((lead.state === 3 && jsonUser.role < 3) || (lead.state === 3 && lead.broker_id == jsonUser.id)) && (
          <Button className="button" type="button" fullWidth onClick={() => onClickView(lead)}>
            Ver Lead
          </Button>
        )}
      </ButtonBox>

      {lead.state > 1 && (
        <Status display="flex" alignItems="center">
          <strong>Status</strong>
          <Select
            native
            className="select-style"
            onChange={handleChangeServiceStatus}
            value={serviceStatus || ''}
            fullWidth>
            <option aria-label="None" value={null}>
              Nenhum
            </option>
            {lead_service_status?.map((ss) => (
              <option key={ss.id} value={ss.var_id}>
                {ss.value}
              </option>
            ))}
          </Select>
        </Status>
      )}

      {lead.channel_id !== 6 && lead.channel_id !== 10 && lead.state < 3 && (
        <Progress
          className="progress-container flex-item"
          Progress={progress(lead.status > 2 ? time_to_finish_lead : time_to_capture_lead)}>
          <div className="progress">
            <div
              className="progress-bar"
              style={{
                width: `${progress(lead.status > 2 ? time_to_finish_lead : time_to_capture_lead)}%`,
              }}
            />
          </div>
          <div>0 h</div>
          <div>{lead.status > 2 ? '24' : '1'} h</div>
        </Progress>
      )}
    </Card>
  );
};

LeadCard.propTypes = {
  lead: P.oneOfType([P.any]),
  user: P.object,
  onClick: P.func,
};

LeadCard.defaultProps = {
  onClick: null,
  user: null,
  lead: null,
};

export default LeadCard;
