import { logout } from '../auth.service';
import DefaultPage from '../../pages/DefaultPage';

const Default = () =>
{    
    // localStorage.setItem("tenantId", "m3tas");
    // localStorage.setItem("logoVertical", "logoM3tas");
    logout();

    return DefaultPage();
};

export default Default;