import { useEffect } from 'react';
import LoginForm from '../../components/LoginForm';
import { Container } from './style';

const Login = () => (
    <Container>
      <LoginForm />
    </Container>
  );

export default Login;
