import styled from 'styled-components';
import { AppBar, Drawer } from '@material-ui/core';

export const Container = styled.div`
  && {
    display: flex;
  }
  .MuiDrawer-paper::-webkit-scrollbar {
    width: 0;
  }
`;

export const ListItemText = styled.span`
  font-family: system-ui;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f26522;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

export const Bar = styled(AppBar)`
  && {
    z-index: 1201;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    color: #f26522;
    background-color: #fff;
    top: 0;
    left: auto;
    right: 0;
    position: absolute;
    height: 40px;
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    & .toolbar {
      @media (min-width: 600px) {
        padding: 16px;
        min-height: 40px;
      }

      .menuButton {
        margin-right: 36px;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
      .menuButtonHidden {
        display: none;
      }
    }
  }
  &&.shift {
    width: calc(100% - 240px);
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: 240px;
  }
`;

export const SideMenu = styled(Drawer)`
  && .menu-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    min-height: 40px;
  }
  max-height:100vh;
`;
