/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Container, StyledTableRow } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { grey, orange } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import { useAuth } from '../../hooks/useAuth';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { fetcher } from '../../service/SWRFetcher';
import { maskDateTime } from '../../utils/masks';
import { TextField} from '../FormFields';
import Checkbox from '@material-ui/core/Checkbox';
import { dataEdit } from '../../service/data.service';


export default function GetProposal({ open, setOpen, setFillable, inviter }) {
  const [proposal, setProposal] = useState('');
  const [checked, setChecked] = useState(false);
  const { data: user } = useSWR(`/users/me`, fetcher);
  const { data: proposals, mutate } = useSWR(`/elective_lead/list?inviter_login=${inviter?.login}&proposal=${proposal}&bypass_allowed=${checked}`, fetcher);


  useEffect(() => {
    mutate();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleBypass = (e,v, pr) => {
    const bypassForm = {bypass_allowed: v}
    dataEdit('/elective_lead/bypass', bypassForm, pr.id)
    pr.bypass_allowed = 1;
    mutate();    
  }

  const GreenCheckbox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const GrayCheckbox = withStyles({
    root: {
      color: grey[400],
      '&$checked': {
        color: grey[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <>
      <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            Propostas
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent style={{ height: '70vh' }}>
          <TextField
            value={proposal}
            onChange={(e) => setProposal(e.target.value)}
            varibleName="client_address_district"
            variant="outlined"
            label="Proposta"
          />

          {user?.roles[0]?.id === 1 && (
            <FormControlLabel
              control={<GreenCheckbox checked={checked} onChange={() => setChecked(!checked)} name="checked" />}
              label="Exibir permissão de duplicidades"
              style={{ marginTop: '20px', marginLeft: '20px' }}
            />
          )}

          <Container>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Proposta</TableCell>
                    <TableCell>Nome do Cliente</TableCell>
                    <TableCell>CEP Cliente</TableCell>
                    <TableCell>Empresa</TableCell>
                    <TableCell>Data da proposta</TableCell>
                    <TableCell>Veiculo</TableCell>
                    <TableCell>Chassi do veiculo</TableCell>
                    <TableCell>Status da proposta</TableCell>
                    {user?.roles[0]?.id === 1 && checked && <TableCell>Permite Duplicidade</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inviter && proposals && (
                    <>
                      {proposals.map((pr) => (
                        <>
                          {!checked ? (
                            <StyledTableRow
                              key={pr.id}
                              onClick={() => {
                                setFillable(pr);
                                handleClose();
                              }}>
                              <TableCell>{pr.proposal}</TableCell>
                              <TableCell>{pr.client_informations.name}</TableCell>
                              <TableCell>{pr.client_informations.address.cep}</TableCell>
                              <TableCell>{pr.partner.name}</TableCell>
                              <TableCell>{maskDateTime(pr.proposal_date)}</TableCell>
                              <TableCell>{pr.product_informations.name}</TableCell>
                              <TableCell>{pr.product_informations.chassis}</TableCell>
                              <TableCell>{pr.additional_information.split('\n')[0].split(': ')[1]}</TableCell>
                            </StyledTableRow>
                          ) : (
                            user?.roles[0]?.id === 1 &&
                            checked && (
                              <TableRow>
                                <TableCell>{pr.proposal}</TableCell>
                                <TableCell>{pr.client_informations.name}</TableCell>
                                <TableCell>{pr.client_informations.address.cep}</TableCell>
                                <TableCell>{pr.partner.name}</TableCell>
                                <TableCell>{maskDateTime(pr.proposal_date)}</TableCell>
                                <TableCell>{pr.product_informations.name}</TableCell>
                                <TableCell>{pr.product_informations.chassis}</TableCell>
                                <TableCell>{pr.additional_information.split('\n')[0].split(': ')[1]}</TableCell>
                                {pr?.bypass_allowed == null ? ( 
                                <TableCell> 
                                  <FormControlLabel
                                    control={
                                      <GreenCheckbox
                                        checked={pr?.bypass_allowed}
                                        onChange={(e,v) => handleBypass(e,v, pr)}
                                        name="checked"
                                      />
                                    }
                                    label=""
                                    style={{ marginTop: '0px', marginLeft: '0px' }}
                                  />
                                </TableCell>) 
                                : (
                                <TableCell> 
                                  <FormControlLabel
                                  control={
                                    <GrayCheckbox
                                      checked={true}
                                      name="checked"
                                      disabled={true}
                                      iconStyle={{fill: 'white'}}
                                    />
                                  }
                                  label=""
                                  style={{ marginTop: '0px', marginLeft: '0px' }}
                                />
                              </TableCell>)}

                              </TableRow>
                            )
                          )}
                        </>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#F26522' }}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
