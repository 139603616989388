import { logout } from '../auth.service';
import Login from '../../pages/Login';

const Corretora3C = () =>
{    
    localStorage.setItem("tenantId", "3ccorretora");
    localStorage.setItem("logoVertical", "logo3ccorretora");
    logout();
    
    return Login();
};

export default Corretora3C;