import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  background-color: #fff;
  border-radius: 8px;
  padding:10px;
  min-width:1020px;
  margin-top:20px;
`;

export const NavbarForm = styled.div`
  display:flex;
  gap:15px;
  width:100%;
  >span{
    height: 100%;
    padding: 6px 12px;
    font-size: 18px;
    line-height: 25px;
    color: rgb(119, 119, 119);
  }
  input{
    min-width: 80px;
    font: inherit;
    color: rgb(51, 51, 51);
    border: none;
    outline: none;
    display: block;
    padding: 6px 12px;
    border-radius: 8px;
    transition: all 0.2s ease 0s;
    background-color: rgb(236, 240, 241);
    border-bottom: 2px solid transparent;
    :hover {
      border-color: #f26522;
    }
    :focus {
      border-color: #f26522;
    }
  }
  button
  {
    min-width: fit-content;
    ursor: pointer;
    color: rgb(255, 255, 255);
    border: none;
    font-weight: 700;
    padding: 8px 16px;
    text-align: center;
    border-radius: 8px;
    white-space: nowrap;
    vertical-align: middle;
    background-color: rgb(242, 101, 34);
    text-transform: uppercase;
    font-family: "DM Sans", sans-serif;
    :hover{
      background-color: rgb(220, 75, 34);
    }
  }
  
`;
