import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';

export default function BasicFormRegister({ open, setOpen, action, title, fields, id, value, order }) {
  const [values, setValues] = useState({
    order:null,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const editing = () => {
    fields.map((f, i) => setValues((v) => ({ ...v, order, [f]: value[i] })));
  };

  const submit = async (e) => {
    e.preventDefault();
    action(values, id);
    handleClose();
  };

  return (
    <div>
      <Dialog
        onEntering={() => value && editing()}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a', width: 600 }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {id ? 'Editar' : 'Cadastrar'} {title}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={submit}>
          <DialogContent>
            {fields.map((f) => (
              <TextField
                key={f}
                autoFocus
                margin="dense"
                label={f}
                type="name"
                value={values[f] || ''}
                fullWidth
                required
                onChange={(e) => setValues((v) => ({ ...v, [f]: e.target.value }))}
              />
            ))}
          </DialogContent>
          {order &&
            <DialogContent>
              <TextField
                margin="dense"
                label='Ordem'
                type="name"
                fullWidth
                required
                onChange={(e) => setValues((v) => ({ ...v, order: e.target.value }))}
                value={values.order || ''}
              />
            </DialogContent>
          }
          <DialogActions>
            <Button type="submit" color="primary">
              Salvar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

BasicFormRegister.propTypes = {
  open: P.bool.isRequired,
  title: P.string.isRequired,
  setOpen: P.func.isRequired,
  action: P.func.isRequired,
  id: P.number,
  order: P.number,
  value: P.arrayOf(P.string),
  fields: P.arrayOf(P.string).isRequired,
};

BasicFormRegister.defaultProps = {
  id: null,
  value: null,
  order: null,
};
