/* eslint-disable no-unused-vars */
/* eslint-disable */
import { Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import P from 'prop-types';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import useSWR from 'swr';
import { useAuth } from '../../../hooks/useAuth';
import useApp from '../../../hooks/useApp';
import { fetcher } from '../../../service/SWRFetcher';
import { Nav, NavbarForm, Links } from './styles';

const Navbar = ({ clickFilter, clickFilterBtn, clearFilter, clickSevenDays, checkValue, scheduler, setLockTabs, clearSelected, clickOnlySchedules, checkValueSchedules, checkSoldLeads, clickSoldLeads }) => {
  const { user } = useAuth();
  const { data: users, mutate: mutateUsers, isValidating } = useSWR(user.role < 3 ? `/users` : null, fetcher);
  const {channels} = useApp();
  const [brokerId, setBrokerId] = useState();
  const [filters, setFilters] = useState({
    lead_id:'',
    name: '',
    broker: '',
    cpf: '',
    start_date: '',
    end_date: '',
    channel: '',
    channel_id: '',
  });

  const [filterBtn, setFilterBtn] = useState({
    name: '',
    value: '',
    change: false,
  });

  useEffect(() => {
    if (filterBtn.change === true) {
      setFilterBtn({ ...filterBtn, change: false });
      if (typeof filterBtn.value !== 'number') setFilterBtn({ name: '', value: '' });
      else clickFilterBtn(filterBtn.name, filterBtn.value);
    }
  }, [filterBtn.change]); 

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleChangeChannel = (e) => {
    const channel = channels.find((x) => x.name == e.target.value);
    
    if (channel != null) {
      setFilters({ ...filters, 
        channel: e.target.value, 
        channel_id: channel.id });
    }
    else {
      setFilters({ ...filters, 
        channel: '', 
        channel_id: null });
    }
  };

  const handleFilter = () => {
    setLockTabs(false);
    clearSelected();
    clickFilter(filters.name, 
      filters.cpf.replace(/[^0-9]/g, ''),  
      filters.broker, 
      filters.start_date, 
      filters.end_date, 
      filters.lead_id,
      filters.channel_id);
  }

  const handleFilterClear = () => {
    clearFilter();
    clearSelected();
    setFilters({
      lead_id:'',
      name: '',
      broker: '',
      cpf: '',
      start_date: '',
      end_date: '',
      channel: '',
      channel_id: null,
    });        
  }

  return (
    <>
    <Nav>
      <NavbarForm>
        <div className="filter">Filtros</div>
        <div className="broker">
          <input list="selectChannels" name="channel" className="select-form" placeholder="Canal" 
            value={filters.channel} onChange={handleChangeChannel}
          />
          <datalist id="selectChannels">
              {channels?.map((item) => (
                <option key={item.id} value={item.name}> </option>
              ))}
          </datalist>
        </div>
        <div className="name">
          <input
            type="text"
            className="input-form"
            placeholder="Nome"
            name="name"
            value={filters.name}
            onChange={handleChange}
          />
        </div>
        <div className="lead_id">
          <input
            type="text"
            className="input-form"
            placeholder="Lead"
            name="lead_id"
            value={filters.lead_id}
            onChange={handleChange}
          />
        </div>
        <div className="cpf">
          <InputMask
            maskChar=""
            mask={filters.cpf.replace(/[^\d]/g, '').length < 12 ? '999.999.999-999' : '99.999.999/9999-99'}
            type="text"
            className="input-form"
            name="cpf"
            placeholder="CPF"
            value={filters.cpf}
            onChange={handleChange}
          />
        </div>
        <div className="broker">
            <input list="select" name="broker" className="select-form" placeholder="Corretor" 
              value={filters.broker} onChange={handleChange}
            />
            <datalist id="select">
              {user.role < 3 ? users?.map((item) => (
                <option key={item.id} value={item.name}> </option>
              ))
              :
                <option key={user.id} value={user.name}> </option>
            }
            </datalist>
        </div>
        <div className="date_ini">
          <input type="date" name="start_date" className="input-form" placeholder="Data Inicial" value={filters.start_date} onChange={handleChange} />
        </div>
        <div className="date_end">
          <input type="date" name="end_date" className="input-form" placeholder="Data Inicial" value={filters.end_date} onChange={handleChange}/>
        </div>
        <div className="filter-btn">
          <Button variant="contained" onClick={handleFilter}>
            Filtrar
          </Button>
          <Button
            variant="contained"
            onClick={handleFilterClear}>
            Limpar (X)
          </Button>          
        </div>
      </NavbarForm>
    </Nav>

    <div style={{marginLeft: 'auto', height: '0px'}}>
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={<Checkbox color="default" onChange={clickOnlySchedules} checked={checkValueSchedules} name="checkedA" />}
            label={ <span><h5>Agendamentos</h5></span> }
          />

          {scheduler ? (
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={<Checkbox color="default" onChange={clickSevenDays} checked={checkValue} name="checkedA" />}
              label={<span><h5>Nos próximos 7 dias</h5></span>}
            />
          )
          :
          (
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={<Checkbox color="default" onChange={clickSoldLeads} checked={checkSoldLeads} name="checkedVendidos" />}
              label={ <span><h5>Vendidos</h5></span> }
            />
          )}
        </div>    
    </>
  );
};

Navbar.propTypes = {
  clickFilter: P.func,
  clearFilter: P.func,
  clickFilterBtn: P.func,
  clickSevenDays: P.func,
  clickSoldLeads: P.func,
  scheduler: P.bool,
  checkValue: P.bool,
  checkSoldLeads: P.bool,
};
Navbar.defaultProps = {
  clickFilter: () => {},
  clickFilterBtn: () => {},
  clearFilter: () => {},
  clickSevenDays: () => {},
  clickSoldLeads: () => {},
  scheduler: false,
  checkValue: true,
  checkSoldLeads: false,
};

export default Navbar;
