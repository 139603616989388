export const months = [
  { name: 'Janeiro', id: 1 },
  { name: 'Fevereiro', id: 2 },
  { name: 'Março', id: 3 },
  { name: 'Abril', id: 4 },
  { name: 'Maio', id: 5 },
  { name: 'Junho', id: 6 },
  { name: 'Julho', id: 7 },
  { name: 'Agosto', id: 8 },
  { name: 'Setembro', id: 9 },
  { name: 'Outubro', id: 10 },
  { name: 'Novembro', id: 11 },
  { name: 'Dezembro', id: 12 },
];
export const maritalStatus = [
  { name: 'Solteiro', id: 1 },
  { name: 'Casado', id: 2 },
  { name: 'Divorciado', id: 3 },
  { name: 'Separado', id: 4 },
  { name: 'Viúvo', id: 5 },
]

export const offer = [
  { id: 0, name: 'Não oferecido' },
  { id: 1, name: 'Oferecido / não vendido' },
  { id: 2, name: 'Oferecido/vendido' },
];
export const UF = [
{id:'AC',name:'Acre'},
{id:'AL',name:'Alagoas'},
{id:'AM',name:'Amazonas'},
{id:'AP',name:'Amapá'},
{id:'BA',name:'Bahia'},
{id:'CE',name:'Ceará'},
{id:'DF',name:'Distrito Federal'},
{id:'ES',name:'Espírito Santo'},
{id:'GO',name:'Goiás'},
{id:'MA',name:'Maranhão'},
{id:'MT',name:'Mato Grosso'},
{id:'MS',name:'Mato Grosso do Sul'},
{id:'MG',name:'Minas Gerais'},
{id:'PA',name:'Pará'},
{id:'PB',name:'Paraíba'},
{id:'PR',name:'Paraná'},
{id:'PE',name:'Pernambuco'},
{id:'PI',name:'Piauí'},
{id:'RJ',name:'Rio de Janeiro'},
{id:'RN',name:'Rio Grande do Norte'},
{id:'RS',name:'Rio Grande do Sul'},
{id:'RO',name:'Rondônia'},
{id:'RR',name:'Roraima'},
{id:'SC',name:'Santa Catarina'},
{id:'SP',name:'São Paulo'},
{id:'SE',name:'Sergipe'},
{id:'TO',name:'Tocantins'}
];
export const sexo = [
  { id: 0, name: 'Masculino' },
  { id: 1, name: 'Feminino' }
];
export const Nacionalidade = [
  { id: 0, name: 'Brasileiro' },
  { id: 1, name: 'Estrangeiro' }
];
export const optProposta = [{ id: 1, name: 'SIM, PROPOSTA E PRINT ANEXADO NO CRM' }, { id: 0, name: 'NÃO' }]
export const optCertificado = [{ id: 1, name: 'SIM, CERTIFICADO-APÓLICE E PRINT ANEXADO NO CRM' }, { id: 0, name: 'NÃO' }]
export const SN = [{ id: 1, name: 'Sim' }, { id: 0, name: 'Não' }]
export const EndossoAlteracao = [
  {
      id: 1,
      name: 'Nome'
  },
  {
      id: 2,
      name: 'Endereço'
  },
  {
      id: 3,
      name: 'Telefone'
  },
  {
      id: 4,
      name: 'Celular'
  },
  {
      id: 5,
      name: 'Bem segurado'
  },
  {
      id: 6,
      name: 'Vigência'
  },
  {
      id: 7,
      name: 'Beneficiário'
  },

];
export const instituicaoFinanceira = [
  {
    name: "BCO DO BRASIL S.A.",
    id: 1
  },
  {
    name: "BRB - BCO DE BRASILIA S.A.",
    id: 70
  },
  {
    name: "SANTINVEST S.A. - CFI",
    id: 539
  },
  {
    name: "CCR SEARA",
    id: 430
  },
  {
    name: "AGK CC S.A.",
    id: 272
  },
  {
    name: "CONF NAC COOP CENTRAIS UNICRED",
    id: 136
  },
  {
    name: "ÍNDIGO INVESTIMENTOS DTVM LTDA.",
    id: 407
  },
  {
    name: "CAIXA ECONOMICA FEDERAL",
    id: 104
  },
  {
    name: "BANCO INTER",
    id: 77
  },
  {
    name: "COLUNA S.A. DTVM",
    id: 423
  },
  {
    name: "BCO RIBEIRAO PRETO S.A.",
    id: 741
  },
  {
    name: "BANCO BARI S.A.",
    id: 330
  },
  {
    name: "BCO CETELEM S.A.",
    id: 739
  },
  {
    name: "EWALLY IP S.A.",
    id: 534
  },
  {
    name: "BANCO SEMEAR",
    id: 743
  },
  {
    name: "PLANNER CV S.A.",
    id: 100
  },
  {
    name: "FDO GARANTIDOR CRÉDITOS",
    id: 541
  },
  {
    name: "BCO B3 S.A.",
    id: 96
  },
  {
    name: "BCO RABOBANK INTL BRASIL S.A.",
    id: 747
  },
  {
    name: "CIELO IP S.A.",
    id: 362
  },
  {
    name: "CCR DE ABELARDO LUZ",
    id: 322
  },
  {
    name: "BCO COOPERATIVO SICREDI S.A.",
    id: 748
  },
  {
    name: "CREHNOR LARANJEIRAS",
    id: 350
  },
  {
    name: "BCO BNP PARIBAS BRASIL S A",
    id: 752
  },
  {
    name: "CECM COOPERFORTE",
    id: 379
  },
  {
    name: "KIRTON BANK",
    id: 399
  },
  {
    name: "BCO BRASILEIRO DE CRÉDITO S.A.",
    id: 378
  },
  {
    name: "BCO BV S.A.",
    id: 413
  },
  {
    name: "BANCO SICOOB S.A.",
    id: 756
  },
  {
    name: "TRINUS CAPITAL DTVM",
    id: 360
  },
  {
    name: "BCO KEB HANA DO BRASIL S.A.",
    id: 757
  },
  {
    name: "XP INVESTIMENTOS CCTVM S/A",
    id: 102
  },
  {
    name: "SISPRIME DO BRASIL - COOP",
    id: 84
  },
  {
    name: "CM CAPITAL MARKETS CCTVM LTDA",
    id: 180
  },
  {
    name: "BCO MORGAN STANLEY S.A.",
    id: 66
  },
  {
    name: "UBS BRASIL CCTVM S.A.",
    id: 15
  },
  {
    name: "TREVISO CC S.A.",
    id: 143
  },
  {
    name: "HIPERCARD BM S.A.",
    id: 62
  },
  {
    name: "BCO. J.SAFRA S.A.",
    id: 74
  },
  {
    name: "UNIPRIME COOPCENTRAL LTDA.",
    id: 99
  },
  {
    name: "BCO TOYOTA DO BRASIL S.A.",
    id: 387
  },
  {
    name: "PARATI - CFI S.A.",
    id: 326
  },
  {
    name: "BCO ALFA S.A.",
    id: 25
  },
  {
    name: "BCO ABN AMRO S.A.",
    id: 75
  },
  {
    name: "BCO CARGILL S.A.",
    id: 40
  },
  {
    name: "TERRA INVESTIMENTOS DTVM",
    id: 307
  },
  {
    name: "CECM DOS TRAB.PORT. DA G.VITOR",
    id: 385
  },
  {
    name: "SOCINAL S.A. CFI",
    id: 425
  },
  {
    name: "SERVICOOP",
    id: 190
  },
  {
    name: "OZ CORRETORA DE CÂMBIO S.A.",
    id: 296
  },
  {
    name: "BANCO BRADESCARD",
    id: 63
  },
  {
    name: "NOVA FUTURA CTVM LTDA.",
    id: 191
  },
  {
    name: "FIDUCIA SCMEPP LTDA",
    id: 382
  },
  {
    name: "GOLDMAN SACHS DO BRASIL BM S.A",
    id: 64
  },
  {
    name: "CCM SERV. PÚBLICOS SP",
    id: 459
  },
  {
    name: "CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.",
    id: 97
  },
  {
    name: "CCM DESP TRÂNS SC E RS",
    id: 16
  },
  {
    name: "BCO AFINZ S.A. - BM",
    id: 299
  },
  {
    name: "CECM SERV PUBL PINHÃO",
    id: 471
  },
  {
    name: "PORTOSEG S.A. CFI",
    id: 468
  },
  {
    name: "BANCO INBURSA",
    id: 12
  },
  {
    name: "BCO DA AMAZONIA S.A.",
    id: 3
  },
  {
    name: "CONFIDENCE CC S.A.",
    id: 60
  },
  {
    name: "BCO DO EST. DO PA S.A.",
    id: 37
  },
  {
    name: "VIA CERTA FINANCIADORA S.A. - CFI",
    id: 411
  },
  {
    name: "ZEMA CFI S/A",
    id: 359
  },
  {
    name: "CASA CREDITO S.A. SCM",
    id: 159
  },
  {
    name: "COOPCENTRAL AILOS",
    id: 85
  },
  {
    name: "COOP CREDITAG",
    id: 400
  },
  {
    name: "CREDIARE CFI S.A.",
    id: 429
  },
  {
    name: "PLANNER SOCIEDADE DE CRÉDITO DIRETO",
    id: 410
  },
  {
    name: "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO",
    id: 114
  },
  {
    name: "CECM FABRIC CALÇADOS SAPIRANGA",
    id: 328
  },
  {
    name: "BCO BBI S.A.",
    id: 36
  },
  {
    name: "LIGA INVEST DTVM LTDA.",
    id: 469
  },
  {
    name: "BCO BRADESCO FINANC. S.A.",
    id: 394
  },
  {
    name: "BCO DO NORDESTE DO BRASIL S.A.",
    id: 4
  },
  {
    name: "HEDGE INVESTMENTS DTVM LTDA.",
    id: 458
  },
  {
    name: "BCO CCB BRASIL S.A.",
    id: 320
  },
  {
    name: "HS FINANCEIRA",
    id: 189
  },
  {
    name: "LECCA CFI S.A.",
    id: 105
  },
  {
    name: "BCO KDB BRASIL S.A.",
    id: 76
  },
  {
    name: "BANCO TOPÁZIO S.A.",
    id: 82
  },
  {
    name: "HSCM SCMEPP LTDA.",
    id: 312
  },
  {
    name: "VALOR SCD S.A.",
    id: 195
  },
  {
    name: "POLOCRED SCMEPP LTDA.",
    id: 93
  },
  {
    name: "CCR DE IBIAM",
    id: 391
  },
  {
    name: "CCR DE SÃO MIGUEL DO OESTE",
    id: 273
  },
  {
    name: "BCO CSF S.A.",
    id: 368
  },
  {
    name: "PAGSEGURO INTERNET IP S.A.",
    id: 290
  },
  {
    name: "MONEYCORP BCO DE CÂMBIO S.A.",
    id: 259
  },
  {
    name: "F D GOLD DTVM LTDA",
    id: 395
  },
  {
    name: "EFÍ S.A. - IP",
    id: 364
  },
  {
    name: "ICAP DO BRASIL CTVM LTDA.",
    id: 157
  },
  {
    name: "SOCRED SA - SCMEPP",
    id: 183
  },
  {
    name: "STATE STREET BR S.A. BCO COMERCIAL",
    id: 14
  },
  {
    name: "CARUANA SCFI",
    id: 130
  },
  {
    name: "MIDWAY S.A. - SCFI",
    id: 358
  },
  {
    name: "CODEPE CVC S.A.",
    id: 127
  },
  {
    name: "PICPAY BANK - BANCO MÚLTIPLO S.A",
    id: 79
  },
  {
    name: "MASTER BI S.A.",
    id: 141
  },
  {
    name: "SUPERDIGITAL I.P. S.A.",
    id: 340
  },
  {
    name: "BANCOSEGURO S.A.",
    id: 81
  },
  {
    name: "BCO YAMAHA MOTOR S.A.",
    id: 475
  },
  {
    name: "CRESOL CONFEDERAÇÃO",
    id: 133
  },
  {
    name: "MERCADO PAGO IP LTDA.",
    id: 323
  },
  {
    name: "BCO AGIBANK S.A.",
    id: 121
  },
  {
    name: "BCO DA CHINA BRASIL S.A.",
    id: 83
  },
  {
    name: "GET MONEY CC LTDA",
    id: 138
  },
  {
    name: "BCO BANDEPE S.A.",
    id: 24
  },
  {
    name: "GLOBAL SCM LTDA",
    id: 384
  },
  {
    name: "NEON FINANCEIRA - CFI S.A.",
    id: 426
  },
  {
    name: "BANCO RANDON S.A.",
    id: 88
  },
  {
    name: "OM DTVM LTDA",
    id: 319
  },
  {
    name: "BMP SCMEPP LTDA",
    id: 274
  },
  {
    name: "TRAVELEX BANCO DE CÂMBIO S.A.",
    id: 95
  },
  {
    name: "BANCO FINAXIS",
    id: 94
  },
  {
    name: "GAZINCRED S.A. SCFI",
    id: 478
  },
  {
    name: "BCO SENFF S.A.",
    id: 276
  },
  {
    name: "MIRAE ASSET CCTVM LTDA",
    id: 447
  },
  {
    name: "BCO DO EST. DE SE S.A.",
    id: 47
  },
  {
    name: "BEXS BCO DE CAMBIO S.A.",
    id: 144
  },
  {
    name: "ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO",
    id: 332
  },
  {
    name: "FITBANK IP",
    id: 450
  },
  {
    name: "BR PARTNERS BI",
    id: 126
  },
  {
    name: "ÓRAMA DTVM S.A.",
    id: 325
  },
  {
    name: "DOCK IP S.A.",
    id: 301
  },
  {
    name: "BRL TRUST DTVM SA",
    id: 173
  },
  {
    name: "FRAM CAPITAL DTVM S.A.",
    id: 331
  },
  {
    name: "BCO WESTERN UNION",
    id: 119
  },
  {
    name: "HUB IP S.A.",
    id: 396
  },
  {
    name: "CELCOIN IP S.A.",
    id: 509
  },
  {
    name: "CAMBIONET CC LTDA",
    id: 309
  },
  {
    name: "PARANA BCO S.A.",
    id: 254
  },
  {
    name: "BARI CIA HIPOTECÁRIA",
    id: 268
  },
  {
    name: "IUGU IP S.A.",
    id: 401
  },
  {
    name: "BCO BOCOM BBM S.A.",
    id: 107
  },
  {
    name: "BANCO BESA S.A.",
    id: 334
  },
  {
    name: "SOCIAL BANK S/A",
    id: 412
  },
  {
    name: "BCO WOORI BANK DO BRASIL S.A.",
    id: 124
  },
  {
    name: "FACTA S.A. CFI",
    id: 149
  },
  {
    name: "STONE IP S.A.",
    id: 197
  },
  {
    name: "ID CTVM",
    id: 439
  },
  {
    name: "AMAZÔNIA CC LTDA.",
    id: 313
  },
  {
    name: "BROKER BRASIL CC LTDA.",
    id: 142
  },
  {
    name: "PINBANK IP",
    id: 529
  },
  {
    name: "BCO MERCANTIL DO BRASIL S.A.",
    id: 389
  },
  {
    name: "BCO ITAÚ BBA S.A.",
    id: 184
  },
  {
    name: "BCO TRIANGULO S.A.",
    id: 634
  },
  {
    name: "SENSO CCVM S.A.",
    id: 545
  },
  {
    name: "ICBC DO BRASIL BM S.A.",
    id: 132
  },
  {
    name: "VIPS CC LTDA.",
    id: 298
  },
  {
    name: "BMS SCD S.A.",
    id: 377
  },
  {
    name: "CREFAZ SCMEPP LTDA",
    id: 321
  },
  {
    name: "NU PAGAMENTOS - IP",
    id: 260
  },
  {
    name: "CDC SCD S.A.",
    id: 470
  },
  {
    name: "UBS BRASIL BI S.A.",
    id: 129
  },
  {
    name: "BRAZA BANK S.A. BCO DE CÂMBIO",
    id: 128
  },
  {
    name: "LAMARA SCD S.A.",
    id: 416
  },
  {
    name: "ASAAS IP S.A.",
    id: 461
  },
  {
    name: "PARMETAL DTVM LTDA",
    id: 194
  },
  {
    name: "NEON PAGAMENTOS S.A. IP",
    id: 536
  },
  {
    name: "EBANX IP LTDA.",
    id: 383
  },
  {
    name: "CARTOS SCD S.A.",
    id: 324
  },
  {
    name: "VORTX DTVM LTDA.",
    id: 310
  },
  {
    name: "PICPAY",
    id: 380
  },
  {
    name: "WILL FINANCEIRA S.A.CFI",
    id: 280
  },
  {
    name: "GUITTA CC LTDA",
    id: 146
  },
  {
    name: "FFA SCMEPP LTDA.",
    id: 343
  },
  {
    name: "COOP DE PRIMAVERA DO LESTE",
    id: 279
  },
  {
    name: "BANCO DIGIO",
    id: 335
  },
  {
    name: "AL5 S.A. CFI",
    id: 349
  },
  {
    name: "CRED-UFES",
    id: 427
  },
  {
    name: "REALIZE CFI S.A.",
    id: 374
  },
  {
    name: "GENIAL INVESTIMENTOS CVM S.A.",
    id: 278
  },
  {
    name: "IB CCTVM S.A.",
    id: 271
  },
  {
    name: "BCO BANESTES S.A.",
    id: 21
  },
  {
    name: "BCO ABC BRASIL S.A.",
    id: 246
  },
  {
    name: "BS2 DTVM S.A.",
    id: 292
  },
  {
    name: "Balcão B3",
    id: null
  },
  {
    name: "CIP SA C3",
    id: null
  },
  {
    name: "SCOTIABANK BRASIL",
    id: 751
  },
  {
    name: "TORO CTVM S.A.",
    id: 352
  },
  {
    name: "BANCO BTG PACTUAL S.A.",
    id: 208
  },
  {
    name: "NU FINANCEIRA S.A. CFI",
    id: 386
  },
  {
    name: "BCO MODAL S.A.",
    id: 746
  },
  {
    name: "U4C INSTITUIÇÃO DE PAGAMENTO S.A.",
    id: 546
  },
  {
    name: "BCO CLASSICO S.A.",
    id: 241
  },
  {
    name: "IDEAL CTVM S.A.",
    id: 398
  },
  {
    name: "BCO C6 S.A.",
    id: 336
  },
  {
    name: "BCO GUANABARA S.A.",
    id: 612
  },
  {
    name: "BCO INDUSTRIAL DO BRASIL S.A.",
    id: 604
  },
  {
    name: "BCO CREDIT SUISSE S.A.",
    id: 505
  },
  {
    name: "QI SCD S.A.",
    id: 329
  },
  {
    name: "FAIR CC S.A.",
    id: 196
  },
  {
    name: "CREDITAS SCD",
    id: 342
  },
  {
    name: "BCO LA NACION ARGENTINA",
    id: 300
  },
  {
    name: "CITIBANK N.A.",
    id: 477
  },
  {
    name: "BCO CEDULA S.A.",
    id: 266
  },
  {
    name: "BCO BRADESCO BERJ S.A.",
    id: 122
  },
  {
    name: "BCO J.P. MORGAN S.A.",
    id: 376
  },
  {
    name: "BCO XP S.A.",
    id: 348
  },
  {
    name: "BCO CAIXA GERAL BRASIL S.A.",
    id: 473
  },
  {
    name: "BCO CITIBANK S.A.",
    id: 745
  },
  {
    name: "BCO RODOBENS S.A.",
    id: 120
  },
  {
    name: "BCO FATOR S.A.",
    id: 265
  },
  {
    name: "BNDES",
    id: 7
  },
  {
    name: "ATIVA S.A. INVESTIMENTOS CCTVM",
    id: 188
  },
  {
    name: "BGC LIQUIDEZ DTVM LTDA",
    id: 134
  },
  {
    name: "BANCO ITAÚ CONSIGNADO S.A.",
    id: 29
  },
  {
    name: "MASTER S/A CCTVM",
    id: 467
  },
  {
    name: "BANCO MASTER",
    id: 243
  },
  {
    name: "LISTO SCD S.A.",
    id: 397
  },
  {
    name: "HAITONG BI DO BRASIL S.A.",
    id: 78
  },
  {
    name: "INTERCAM CC LTDA",
    id: 525
  },
  {
    name: "ÓTIMO SCD S.A.",
    id: 355
  },
  {
    name: "VITREO DTVM S.A.",
    id: 367
  },
  {
    name: "REAG DTVM S.A.",
    id: 528
  },
  {
    name: "PLANTAE CFI",
    id: 445
  },
  {
    name: "UP.P SEP S.A.",
    id: 373
  },
  {
    name: "OLIVEIRA TRUST DTVM S.A.",
    id: 111
  },
  {
    name: "FINVEST DTVM",
    id: 512
  },
  {
    name: "QISTA S.A. CFI",
    id: 516
  },
  {
    name: "BONUSPAGO SCD S.A.",
    id: 408
  },
  {
    name: "MAF DTVM SA",
    id: 484
  },
  {
    name: "COBUCCIO S.A. SCFI",
    id: 402
  },
  {
    name: "SCFI EFÍ S.A.",
    id: 507
  },
  {
    name: "SUMUP SCD S.A.",
    id: 404
  },
  {
    name: "ZIPDIN SCD S.A.",
    id: 418
  },
  {
    name: "LEND SCD S.A.",
    id: 414
  },
  {
    name: "DM",
    id: 449
  },
  {
    name: "MERCADO CRÉDITO SCFI S.A.",
    id: 518
  },
  {
    name: "ACCREDITO SCD S.A.",
    id: 406
  },
  {
    name: "CORA SCD S.A.",
    id: 403
  },
  {
    name: "NUMBRS SCD S.A.",
    id: 419
  },
  {
    name: "DELCRED SCD S.A.",
    id: 435
  },
  {
    name: "FÊNIX DTVM LTDA.",
    id: 455
  },
  {
    name: "CC LAR CREDI",
    id: 421
  },
  {
    name: "CREDIHOME SCD",
    id: 443
  },
  {
    name: "MARU SCD S.A.",
    id: 535
  },
  {
    name: "UY3 SCD S/A",
    id: 457
  },
  {
    name: "CREDSYSTEM SCD S.A.",
    id: 428
  },
  {
    name: "HEMERA DTVM LTDA.",
    id: 448
  },
  {
    name: "CREDIFIT SCD S.A.",
    id: 452
  },
  {
    name: "FFCRED SCD S.A.",
    id: 510
  },
  {
    name: "STARK SCD S.A.",
    id: 462
  },
  {
    name: "CAPITAL CONSIG SCD S.A.",
    id: 465
  },
  {
    name: "PORTOPAR DTVM LTDA",
    id: 306
  },
  {
    name: "AZUMI DTVM",
    id: 463
  },
  {
    name: "J17 - SCD S/A",
    id: 451
  },
  {
    name: "TRINUS SCD S.A.",
    id: 444
  },
  {
    name: "LIONS TRUST DTVM",
    id: 519
  },
  {
    name: "MÉRITO DTVM LTDA.",
    id: 454
  },
  {
    name: "UNAVANTI SCD S/A",
    id: 460
  },
  {
    name: "RJI",
    id: 506
  },
  {
    name: "SBCASH SCD",
    id: 482
  },
  {
    name: "BNY MELLON BCO S.A.",
    id: 17
  },
  {
    name: "PEFISA S.A. - C.F.I.",
    id: 174
  },
  {
    name: "SUPERLÓGICA SCD S.A.",
    id: 481
  },
  {
    name: "PEAK SEP S.A.",
    id: 521
  },
  {
    name: "BR-CAPITAL DTVM S.A.",
    id: 433
  },
  {
    name: "BCO LA PROVINCIA B AIRES BCE",
    id: 495
  },
  {
    name: "HR DIGITAL SCD",
    id: 523
  },
  {
    name: "ATICCA SCD S.A.",
    id: 527
  },
  {
    name: "MAGNUM SCD",
    id: 511
  },
  {
    name: "ATF CREDIT SCD S.A.",
    id: 513
  },
  {
    name: "BANCO GENIAL",
    id: 125
  },
  {
    name: "EAGLE SCD S.A.",
    id: 532
  },
  {
    name: "MICROCASH SCMEPP LTDA.",
    id: 537
  },
  {
    name: "WNT CAPITAL DTVM",
    id: 524
  },
  {
    name: "MONETARIE SCD",
    id: 526
  },
  {
    name: "JPMORGAN CHASE BANK",
    id: 488
  },
  {
    name: "RED SCD S.A.",
    id: 522
  },
  {
    name: "SER FINANCE SCD S.A.",
    id: 530
  },
  {
    name: "BCO ANDBANK S.A.",
    id: 65
  },
  {
    name: "LEVYCAM CCV LTDA",
    id: 145
  },
  {
    name: "BCV - BCO, CRÉDITO E VAREJO S.A.",
    id: 250
  },
  {
    name: "BEXS CC S.A.",
    id: 253
  },
  {
    name: "BCO HSBC S.A.",
    id: 269
  },
  {
    name: "BCO ARBI S.A.",
    id: 213
  },
  {
    name: "INTESA SANPAOLO BRASIL S.A. BM",
    id: 139
  },
  {
    name: "BCO TRICURY S.A.",
    id: 18
  },
  {
    name: "BCO SAFRA S.A.",
    id: 422
  },
  {
    name: "BCO LETSBANK S.A.",
    id: 630
  },
  {
    name: "BCO FIBRA S.A.",
    id: 224
  },
  {
    name: "BCO VOLKSWAGEN S.A",
    id: 393
  },
  {
    name: "BCO LUSO BRASILEIRO S.A.",
    id: 600
  },
  {
    name: "BCO GM S.A.",
    id: 390
  },
  {
    name: "BANCO PAN",
    id: 623
  },
  {
    name: "BCO VOTORANTIM S.A.",
    id: 655
  },
  {
    name: "BCO ITAUBANK S.A.",
    id: 479
  },
  {
    name: "BCO MUFG BRASIL S.A.",
    id: 456
  },
  {
    name: "BCO SUMITOMO MITSUI BRASIL S.A.",
    id: 464
  },
  {
    name: "ITAÚ UNIBANCO S.A.",
    id: 341
  },
  {
    name: "BCO BRADESCO S.A.",
    id: 237
  },
  {
    name: "BCO MERCEDES-BENZ S.A.",
    id: 381
  },
  {
    name: "OMNI BANCO S.A.",
    id: 613
  },
  {
    name: "BCO SOFISA S.A.",
    id: 637
  },
  {
    name: "Câmbio B3",
    id: null
  },
  {
    name: "BANCO VOITER",
    id: 653
  },
  {
    name: "BCO CREFISA S.A.",
    id: 69
  },
  {
    name: "BCO MIZUHO S.A.",
    id: 370
  },
  {
    name: "BANCO INVESTCRED UNIBANCO S.A.",
    id: 249
  },
  {
    name: "BCO BMG S.A.",
    id: 318
  },
  {
    name: "BCO C6 CONSIG",
    id: 626
  },
  {
    name: "AVENUE SECURITIES DTVM LTDA.",
    id: 508
  },
  {
    name: "SAGITUR CC",
    id: 270
  },
  {
    name: "BCO SOCIETE GENERALE BRASIL",
    id: 366
  },
  {
    name: "NEON CTVM S.A.",
    id: 113
  },
  {
    name: "TULLETT PREBON BRASIL CVC LTDA",
    id: 131
  },
  {
    name: "C.SUISSE HEDGING-GRIFFO CV S/A",
    id: 11
  },
  {
    name: "BCO PAULISTA S.A.",
    id: 611
  },
  {
    name: "BOFA MERRILL LYNCH BM S.A.",
    id: 755
  },
  {
    name: "CREDISAN CC",
    id: 89
  },
  {
    name: "BCO PINE S.A.",
    id: 643
  },
  {
    name: "NU INVEST CORRETORA DE VALORES S.A.",
    id: 140
  },
  {
    name: "BCO DAYCOVAL S.A",
    id: 707
  },
  {
    name: "CAROL DTVM LTDA.",
    id: 288
  },
  {
    name: "SINGULARE CTVM S.A.",
    id: 363
  },
  {
    name: "RENASCENCA DTVM LTDA",
    id: 101
  },
  {
    name: "DEUTSCHE BANK S.A.BCO ALEMAO",
    id: 487
  },
  {
    name: "BANCO CIFRA",
    id: 233
  },
  {
    name: "GUIDE",
    id: 177
  },
  {
    name: "TRUSTEE DTVM LTDA.",
    id: 438
  },
  {
    name: "SIMPAUL",
    id: 365
  },
  {
    name: "BCO RENDIMENTO S.A.",
    id: 633
  },
  {
    name: "BCO BS2 S.A.",
    id: 218
  },
  {
    name: "LASTRO RDV DTVM LTDA",
    id: 293
  },
  {
    name: "FRENTE CC S.A.",
    id: 285
  },
  {
    name: "B&T CC LTDA.",
    id: 80
  },
  {
    name: "NOVO BCO CONTINENTAL S.A. - BM",
    id: 753
  },
  {
    name: "BCO CRÉDIT AGRICOLE BR S.A.",
    id: 222
  },
  {
    name: "CCR COOPAVEL",
    id: 281
  },
  {
    name: "BANCO SISTEMA",
    id: 754
  },
  {
    name: "DOURADA CORRETORA",
    id: 311
  },
  {
    name: "CREDIALIANÇA CCR",
    id: 98
  },
  {
    name: "BCO VR S.A.",
    id: 610
  },
  {
    name: "BCO OURINVEST S.A.",
    id: 712
  },
  {
    name: "BCO RNX S.A.",
    id: 720
  },
  {
    name: "CREDICOAMO",
    id: 10
  },
  {
    name: "CREDIBRF COOP",
    id: 440
  },
  {
    name: "MAGNETIS - DTVM",
    id: 442
  },
  {
    name: "RB INVESTIMENTOS DTVM LTDA.",
    id: 283
  },
  {
    name: "BCO SANTANDER (BRASIL) S.A.",
    id: 33
  },
  {
    name: "BANCO JOHN DEERE S.A.",
    id: 217
  },
  {
    name: "BCO DO ESTADO DO RS S.A.",
    id: 41
  },
  {
    name: "ADVANCED CC LTDA",
    id: 117
  },
  {
    name: "BCO DIGIMAIS S.A.",
    id: 654
  },
  {
    name: "WARREN CVMC LTDA",
    id: 371
  },
  {
    name: "BANCO ORIGINAL",
    id: 212
  },
  {
    name: "EFX CC LTDA.",
    id: 289
  }
];