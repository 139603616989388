/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { FormContainer, Container, InputControl } from './styles';
import {LogoVertical} from '../Logo';
import { MainContext } from '../../context/ApiContext';
import { TextField } from '../FormFields';

const RedefineForm = () => {
  const {
    values: { users },
    mail: { sendMailRedefine },
  } = useContext(MainContext);
  const [redirect, setRedirect] = useState(false);
  const [send, setSend] = useState(false);
  const [values, setValues] = useState({
    login: null
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        sendMailRedefine(values);
        setSend(true);
    } catch (error) {
      // toast.error('Erro do servidor');
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Container>
      {redirect && <Redirect to="/login" />}
      <LogoVertical />
      <FormContainer elevation={1}>
        <div className="title-container">
          <h1>Redefinição de senha</h1>
        </div>
        {send ? (
          <div>
            <p>Você receberá um e-mail para dar continuidade</p>
            <p>no processo de redefinição de senha!</p>
          </div>
        ) : (
          <div>
            <p>Informe seu login!</p>
          </div>
        )}
        {!send ? (
          <form onSubmit={handleSubmit}>
            <InputControl fullWidth required>
              <TextField
                value={values.login || ''}
                onChange={handleChange}
                varibleName="login"
                label="Login"
                fullWidth
                required
              />
            </InputControl>
            <div className="footer">
              <Button className="back" variant="contained" component={Link} to="/login">
                Voltar
              </Button>
              <Button className="entrar" variant="contained" type="submit">
                Continuar
              </Button>
            </div>
          </form>
        ) : (
              <Button className="back" variant="contained" component={Link} to="/login">
                Voltar
              </Button>
        )}
      </FormContainer>
    </Container>
  );
};

export default RedefineForm;
