/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { Nav, NavbarForm } from './styles';
import { DateTime } from 'luxon';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useAuth } from '../../../hooks/useAuth';
import { fetcher } from '../../../service/SWRFetcher';
import useSWR from 'swr';
import SearchClientModal from '../../SearchClientModal';

const DatePeriodFilter = ({ userId, filters, setFilters }) => {
  const dateNow = DateTime.now().toISODate();
  const { data: users } = useSWR(`/users`, fetcher);
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [dates, setDates] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  const { user } = useAuth();

  function handleSubmit(event) {
    event.preventDefault();
    let filt = {
      date_ini: event.target.elements.date_ini?.value,
      date_end: event.target.elements.date_end?.value,
      channel_id: channelId?.map((b) => ({ id: b.id })) || null,
      client: client || '',
      broker_id: brokerId?.map((b) => ({ id: b.id })) || null,
      paginate: null,
    };
    setFilters({ ...filters, ...filt });
  }

  const [channelId, setChannelId] = useState();
  const [brokerId, setBrokerId] = useState();
  const [client, setClient] = useState("");

  return (
    <Nav>
      <div className="filter">Filtros</div>

      <NavbarForm>
        <form className="form" onSubmit={handleSubmit}>
          <div className="inputs">
            <div className="date_ini">
              <input
                type="date"
                name="date_ini"
                className="input-form"
                placeholder="Data Inicial"
                value={dates.date_ini}
                onChange={(e) => setDates(e)}
              />
            </div>
            <div className="date_end">
              <input
                type="date"
                name="date_end"
                className="input-form"
                placeholder="Data Final"
                value={dates.date_end}
                onChange={(e) => setDates(e)}
              />
            </div>
          </div>

          <Autocomplete
            multiple
            options={user.technical_team.channels ? user.technical_team.channels.filter((i) => i.id >= 27) : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setChannelId(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Canal" />
            )}
          />

          <TextField
            className="autocomplete"
            variant="standard"
            placeholder="Cliente"
            value={client}
            onChange={(e) => setClient(e.target.value)}
          />

          {user.role == 1 && (
            <Autocomplete
              multiple
              options={users ? users?.filter((u) => u.roles[0]?.id <= 3) : []}
              getOptionLabel={(option) => option?.name}
              onChange={(e, value) => setBrokerId(value)}
              renderInput={(params) => (
                <TextField {...params} className="autocomplete" variant="standard" placeholder="Corretor" />
              )}
            />
          )}

          {/* <SearchClientModal
            open={isModalOpen}
            title="Nome do Cliente"
            setOpen={() => {
              setIsModalOpen((oldState) => !oldState);
            }}
            fieldName="Pesquise pelo nome do cliente"
            next={(client) => {
              setClient(client);
              setIsModalOpen(false);
            }}
          /> */}

          <div>
            <button className="button" type="submit">
              Filtrar
            </button>
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
