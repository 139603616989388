/* eslint-disable no-param-reassign */
import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import { Header, Container } from '../../styles/shared/styles';
import { GetTenantRoute } from '../../router/tenantRoutes';
import { sendFile } from '../../service/lead.service';
import useApp from '../../hooks/useApp';
import { Box } from './styles';
import { AutocompleteField } from '../../components/FormFields';
import SimpleModelSheet from '../../assets/files/Modelo Simples em Planilha.xlsx';
import ExampleModelCsv from '../../assets/files/Exemplo Simples em Texto.csv';
import ConfigLeadModelSheet from '../../assets/files/Modelo com Configuracoes do Lead.xlsx';
import './styles.css';

const ImportarLeads = () => {
    const { data: users } = useSWR('/users', fetcher);
    const { insurances, partners, channels } = useApp();
    const [importFile, setImportFile] = useState(false);
    const history = useHistory();
    const [values, setValues] = useState({
        selectValues: false,
        selectedUser: null,
        selectedCity: null,
        selectedState: null,
        productName: null,
        selectedChannel: null,
        selectedInsurance: null,
    });
    const [getErrorList, setErrorList] = useState([]);
    const inputFileRef = useRef(null);

    const GetDocUrl = () => 
        `${process.env.REACT_APP_API_BASE_URL.replace("{tenantId}",GetTenantRoute())}${"/doc"}`;

    const fileUpload = async (event) => {
        setErrorList(null);
        if (event.target.files[0] == null) {
            return false;
        }
        if (event.target.files[0].type != 'text/csv' && 
            event.target.files[0].type != 'text/plain') 
        {
            toast.warning('Formato de arquivo inválido! Somente arquivos Texto');
            return false;
        }
        
        setImportFile(event.target.files[0]);
        const data = {
            file: event.target.files[0],
        };

        let queryString = '';
        if (values.selectValues) {
            queryString = 
                `selectedUser=${values.selectedUser?.id ?? ''}
                &selectedCity=${values.selectedCity ?? ''}
                &selectedState=${values.selectedState?.toUpperCase() ?? ''}
                &selectedProductName=${values.productName ?? ''}
                &selectedChannel=${values.selectedChannel?.id ?? ''} 
                &selectedInsurance=${values.selectedInsurance?.id ?? ''} 
                `;
        }

        const result = await sendFile(`/lead/importfilelead?${queryString}`, 
            data, setImportFile, 
            'importLead');

        if (inputFileRef.current.value != null)
            inputFileRef.current.value = '';

        if (result.status == 200) {
            if (result.data.data == null )
                alert('Todos os Leads foram Importados!');

            setErrorList(result.data.data);
        }
        else {
            setErrorList(result.data);
            alert(`${result.message}`);      
            return false;
        }
        return true;
    };          

    const handleCheckboxChange = (e) => {
        setValues({...values, selectValues: e.target.checked});
    }

    const handleTextChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleAutoCompleteChange = (e, newValue, variable) => {
        setValues({
            ...values,
            [variable]: newValue,
          });
    }

    const getChannelList = () => 
        (
            <select>
                {channels?.map((item) => (
                    <option>{item.name} = {item.id}</option>
                ))}
            </select>
        );
    
    const getUserList = () => 
        (
            <select>
                {users?.filter((x) => x.status == 1 && x.login != "m3tas")
                    .map((item) => (
                    <option>{item.name} = {item.id}</option>
                ))}
            </select>
        );
    
    const getInsuranceList = () => 
        (
            <select>
                {insurances?.map((item) => (
                    <option>{item.name} = {item.id}</option>
                ))}
            </select>
        );

    const getPartnerList = () => 
        (
            <select>
                {partners?.map((item) => (
                    <option>{item.name} = {item.id}</option>
                ))}
            </select>
        );
            
    const displayErrorList = () => 
    {
        const errorList = Array.isArray(getErrorList) ? getErrorList : [getErrorList];
        console.log(errorList);
        return (
            <div style={{maxHeight: '300px', overflow: 'scroll'}}>
                {errorList != null && errorList[0] != null  && 
                    <h3 style={{color: 'rgb(201 110 55)'}}>Os seguintes Leads não foram importados:</h3>
                }
                {errorList?.map((lead) => (
                    <p>{lead}</p>
                ))}
            </div>
        )
    }


    return (
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
            <Container>
                <Header>
                    <h2>Importar Leads</h2>
                </Header>

                <Box style={{border: "1px solid #ccc"}}>

                    <FormControlLabel
                        style={{ marginLeft: 0 }}
                        control={<Checkbox name="selectValues" 
                                    checked={values.selectValues} 
                                    onChange={handleCheckboxChange} />}
                        label={ <span><h5>Selecionar dados</h5></span> }
                    />
                    <p>Os dados selecionados aqui serão fixados para todos os Leads do arquivo.</p>
                    
                    <AutocompleteField
                        className="control g"
                        valueText={values.selectedChannel}
                        options={channels}
                        value={values.selectedChannel || []}
                        varibleName="selectedChannel"
                        onChange={handleAutoCompleteChange}
                        label="Canal"
                        disabled={!values.selectValues}
                        style={{width: '200px'}}                        
                    />
                    <AutocompleteField
                        className="control g"
                        valueText={values.selectedUser}
                        options={users}
                        value={values.selectedUser || null}
                        varibleName="selectedUser"
                        onChange={handleAutoCompleteChange}
                        label="Corretor"
                        disabled={!values.selectValues}
                        style={{width: '200px'}}
                    />
                    <AutocompleteField
                        className="control g"
                        valueText={values.selectedInsurance}
                        options={insurances}
                        value={values.selectedInsurance || null}
                        varibleName="selectedInsurance"
                        onChange={handleAutoCompleteChange}
                        label="Tipo do Seguro"
                        disabled={!values.selectValues}
                        style={{width: '200px'}}
                    />

                    <br/>
                    <TextField label="Cidade" 
                        name="selectedCity" 
                        checked={values.selectCity}
                        onChange={handleTextChange} 
                        disabled={!values.selectValues}
                        style={{marginLeft: '10px'}}
                    />

                    <TextField label="Estado" 
                        placeholder='Sigla'
                        name="selectedState" 
                        inputProps={{ maxLength: 2, style: { textTransform: "uppercase" } }}
                        checked={values.selectState}
                        onChange={handleTextChange} 
                        disabled={!values.selectValues}
                        style={{marginLeft: '10px', width: '50px'}}
                    />

                    <TextField label="Descrição" 
                        name="productName" 
                        checked={values.productName}
                        onChange={handleTextChange} 
                        disabled={!values.selectValues}
                        style={{marginLeft: '10px'}}
                    />

                </Box>

                <Box m={5}>
                    <br/>
                    <Button
                      onChange={fileUpload}
                      component="label"
                      variant="contained"
                      color="default"
                      className="of-file"
                      startIcon={<CloudUploadIcon />}>
                      Enviar arquivo para Importação
                      <input type="file" ref={inputFileRef} hidden />
                    </Button>
                </Box>

                <Box>
                    {displayErrorList()}
                </Box>

                <Box>
                    <br/>
                    <h4>Instruções para importação</h4>
                    <p>
                        O arquivo deve possuir um conteúdo em texto no formato CSV (Dados separados por vírgula(,) ou ponto e vírgula(;))
                        <br/>É requerido que o formato de códificação seja UTF-8 para traduzir corretamente letras com assento.
                        <br/>O cabeçalho com o nome de cada coluna é opcional.
                    </p>
                    <br/>
                    <p>
                        Modelo em planilha com configurações do Lead, para auxiliar na preparação do arquivo de importação: 
                        <a href={ConfigLeadModelSheet} title='Modelo em Planilha com Configurações do Lead'>Download da planilha</a>
                        <br/>
                        Exemplo do formato texto a ser importado:
                        <a href={ExampleModelCsv} title='Exemplo Simples em Texto'>Download do csv</a>
                        <br/>
                    </p>
                    <br/>
                    <h4>Observações:</h4>
                        <p>
                            Items com (*) são dados obrigatórios que devem ser informados! <br/>
                            Os demais itens são opcionais e podem ficam em branco, será utilizado o valor padrão. <br/>
                            Será validado na inclusão o Nome do Cliente, Telefone e o Tipo do Seguro, para evitar duplicar um mesmo cliente e Lead.<br/>
                            A ordem das colunas deve ser respeitada|
                        </p>
                    <br/>
                    <h4>As colunas devem seguir exatamente a ordem abaixo:</h4>
                    <p> 
                        <div className='colunasEstio'>
                            <div className='colunasObrigatorias'>
                                [A] Nome do Cliente (*) <br/>
                                [B] Telefone (*) <br/>
                            </div>
                            [C] e-Mail <br/>
                            [D] CPF <br/>
                            [E] RG <br/>
                            [F] Cep <br/>
                            [G] RUA <br/>
                            [H] Número <br/>
                            [I] Bairro <br/>
                            [J] Cidade <br/>
                            [K] Estado -{'>'} (Sigla)<br/>
                            [L] Complemento <br/>
                            [M] Data de Nascimento -{'>'} (dd/mm/yyyy ou Formato número Excel)<br/>
                            [N] Sexo -{'>'} (Masculino, Feminino) <br/>
                            [O] Estado Civil -{'>'} (Solteiro, Casado, Divorciado, Separado, Viúvo) <br/>
                            [P] É Servidor Público -{'>'} (0=Não 1=Sim) <br/>
                            [Q] Nacionalidade -{'>'} (Brasileiro) <br/>
                            [R] Profissão <br/>
                            [S] Descrição <br/>
                            [T] Identificador do Canal de Origem -{'>'} {getChannelList()} <br/>
                            [U] Identificador do Corretor -{'>'} {getUserList()} <br/>
                        </div>
                        <br/>
                        <div className='colunasLeads'>
                            <p>Dados de configuração do Lead</p>
                            <div className='colunasEstio'>
                                [V] Estado do Lead -{'>'} (1=Aguardando Captura)<br/>
                                [X] Status da Etapa do Lead -{'>'} (1=Aguardando Captura)<br/>
                                [Y] Identificador do Seguro -{'>'} {getInsuranceList()} <br/>
                                [Z] Identificador da Empresa -{'>'} {getPartnerList()} <br/>
                            </div>
                        </div>
                        <br/>
                        <p>Dados exclusivos para Veículos</p>
                        <div className='colunasEstio'>
                            [AA] leadChassis <br/>
                            [AB] leadRenavam <br/>
                            [AC] leadPlaca <br/>
                            [AD] leadMarca <br/>
                            [AE] leadEstado <br/>
                            [AF] leadMunicipio <br/>
                            [AG] leadDataVigencia <br/>
                            [AH] leadDataExpiracao <br/>
                            [AI] leadAnoModelo <br/>
                            [AJ] leadQtd <br/>
                            [AK] leadRaca <br/>
                            [AL] leadDependentes <br/>
                            [AM] leadDoencasGraves <br/>
                            [AN] leadEstadoCivil <br/>
                            [AO] leadContato <br/>
                        </div>
                        </p><br/>
                        
                </Box>

            </Container>
        </motion.div>
    );
};


export default ImportarLeads;