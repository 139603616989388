/* eslint-disable */
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import P from 'prop-types';

const CompTable = ({ colums, data, cellOnClick }) => {
    const accessProperty = (obj, propPath) => {
      const props = propPath.split('.');
      return props.reduce((o, p) => o && o[p], obj);
    };
  
    const renderColumnValue = (item, col) => {
        if (col.tratamento) {
          return col.tratamento(accessProperty(item, col.variavel));
        } else {
          return accessProperty(item, col.variavel);
        }
      };
  
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {colums?.map((x) => <TableCell key={x.titulo}>{x.titulo}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow key={item.id}>
                {colums?.map(x => (
                  <TableCell align="left" key={x.titulo} onClick={cellOnClick}>
                    {renderColumnValue(item, x)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  };
  
CompTable.propTypes = {
    colums: P.arrayOf(P.object),
    data: P.arrayOf(P.object),
    cellOnClick: P.objectOf(P.func)
};

CompTable.defaultProps = {
    colums: [],
    data: [],
    cellOnClick: null
};

export default CompTable;
