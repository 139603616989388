import styled from 'styled-components';

export const Header = styled.header`
  padding-bottom: 0px;
  max-height: 100%;
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .menu {
      ul {
        display: flex;
        list-style: none;
        li + li {
          margin-left: 8px;
        }
      }
    }
    .user {
      background-color: gray;
      box-sizing: border-box;
      position: relative;
      display: block;
      padding: 6px;
      margin-left: 8px;
    }
    div:last-child{
      width:150px;
    }
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  .seller {
    margin-bottom: 8px;
  }
  .button {
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    + .button {
      margin-top: 8px;
    }
  }
`;

export const LogoDiv = styled.div`
  a {
    padding: 12px;
    text-decoration: none;
    img {
      border: 0;
      vertical-align: middle;
      height: auto;
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 150px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0px;

  .leadBar {
    font-family: system-ui;
    color: rgb(203 123 6);
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 2px;
  }
`;

export const Leads = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px 12px;
`;

export const LeadsContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  max-height: calc(100vh - 259px);
`;
export const LogoMetasWarpper = styled.div`
width:100%;
display: flex;
flex-direction: row-reverse;
margin-top: 0px;

img{
  width: auto;
  max-height: 35px;
  margin-right: 20px;
}
`;
