/* eslint-disable camelcase */
/* eslint-disable */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import InputMask from 'react-input-mask';
import {
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Divider,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LaunchIcon from '@material-ui/icons/Launch';
import CloseIcon from '@material-ui/icons/Close';
import { useAuth } from '../../hooks/useAuth';
import { excelExport } from '../../service/excel.service';
import { ExportButton } from './styles';
import Filter from '../../components/Filter';
import CompTable from '../../components/CompTable';
import { maskCpf, maskDateTime, maskDate, maskCurrency } from '../../utils/masks';
import api from '../../service/api';
import { Header, Container } from '../../styles/shared/styles';
import { Autocomplete } from '@material-ui/lab';
import { GetInstallmentCalc } from '../../service/lead.service';


const PaymentReport = () => {
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();
  const [data, setData] = useState([]);
  const [getShowData, setShowData] = useState(false);

  const [filters, setFilters] = useState({
    cpf: undefined,
    name: undefined,
    lead: undefined,
    startDate: dateLastMonth,
    endDate: dateNow,
    broker: undefined,
    per_page: 10,
    filterPage: 0,
  });

  const MontarUrl = (page) => {
    console.log(filters);
    const filtros = Object.keys(filters).map((x) =>
      filters[x] != undefined && filters[x] != '' ? `${x}=${filters[x]}` : ''
    );
    let url = '';
    filtros.forEach((x) => {
      if (url != '') {
        url += '&';
      }
      url += x;
    });

    if (page) 
      url+=`page=${page}`;

    return url;
  };

  const GetPaymentList = async (page) => {
    setShowData(false);
    const response = await api.get(`/maistodos/pagamentos?${MontarUrl(page)}`);
    setData(response.data.data);
    setShowData(true);
  }

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  const Limpar = () => {
    setFilters({
      ...filters,
      cpf: '',
      name: '',
      status: '',
      tipoPagamento: '',
      responsavel: '',
      startDate: dateLastMonth,
      endDate: dateNow,
      per_page: 10,
      filterPage: 0,
      lead: null,
    });
  };

  const statusList = [
    { name: 'Pago', id: 1 },
    { name: 'Falha', id: 2 },
    { name: 'Estornado', id: 3 },
  ];

  const tipoPagamentoList = [
    { name: 'Assinatura', id: 1 },
    { name: 'Assinatura + Adesão', id: 2 },
  ];

  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, filterPage: 0, per_page: parseInt(event.target.value, 10) });
  };
  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, filterPage: newPage});
    GetPaymentList(newPage+1);
  };
  const handleAutoCompleteChange = (e, newValue, variable) => {
    setFilters({
        ...filters,
        [variable]: newValue?.name,
      });
  }

  const GetCurrentInstallment = (id) => {
    const pagamento = data.data.find((payment) => payment.id == id);
    const fatura = pagamento.fatura;
    const assinatura = fatura.assinatura_recorrente;
    const dataPagamentoStr = pagamento.created_at.split('T')[0];
    const parcelaAtual = GetInstallmentCalc(assinatura.inicioVigencia, dataPagamentoStr);    

    return `${parcelaAtual} / ${assinatura.periodoMeses}`;
  }

  const colums = [
    { titulo: 'Lead', variavel: 'fatura.assinatura_recorrente.lead.id' },
    { titulo: 'ID Pagamento', variavel: 'id' },
    { titulo: 'Cliente', variavel: 'fatura.assinatura_recorrente.lead.client.name' },
    { 
      titulo: 'CPF', 
      variavel: 'fatura.assinatura_recorrente.lead.client.cpf', 
      tratamento: (cpf) => maskCpf(cpf) 
    },
    { 
      titulo: 'Data do Pagamento', 
      variavel: 'created_at', 
      tratamento: (created_at) => maskDateTime(created_at) 
    },
    {
      titulo: 'Status',
      variavel: 'status',
      tratamento: (status) => {
        switch (status) {
          case 'paid':
            return 'Pago';
          case 'failure':
            return 'Falha';
          case 'refunded':
            return 'Estornado';
          default:
            return status;
        }
      },
    },
    { titulo: 'Responsável Financeiro', variavel: 'fatura.assinatura_recorrente.lead.financialData.nome' },
    { 
      titulo: 'Data Nascimento', 
      variavel: 'fatura.assinatura_recorrente.lead.client.birth_date', 
      tratamento: (birth_date) => maskDate(birth_date) 
    },
    { titulo: 'Sexo', variavel: 'fatura.assinatura_recorrente.lead.client.gender' },
    { titulo: 'Valor', variavel: 'valor'},
    { titulo: 'Tipo Pagamento', variavel: 'fatura.descricao'},
    { 
      titulo: 'Nº Parcela', 
      variavel: 'id', 
      tratamento: GetCurrentInstallment 
    },
    {
      titulo: 'Final Cartão',
      variavel: 'fatura.assinatura_recorrente.four_digit_cart',
    },
    {
      titulo: 'Detalhes',
      variavel: 'id',
      tratamento: (id) => CriarBotao(data?.data?.find((payment) => payment.id == id)),
    },
  ];


  return (
    <>
      <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
        <Container>
          <Header>
            <h1>Relatório de Pagamentos</h1>
          </Header>

          <Filter
            clickFilter={() => {
              if (
                filters.name ||
                filters.cpf ||
                filters.broker ||
                filters.startDate ||
                filters.endDate ||
                filters.lead
              ) {
                GetPaymentList();
              }
            }}
            clearFilter={() => {
              Limpar();
            }}>

            <Grid container spacing={2}>

            <input type="text" placeholder="Lead" name="lead" value={filters.lead} onChange={handleChange} />

            <input type="text" placeholder="Nome" name="name" value={filters.name} onChange={handleChange} />

            <InputMask
              xs={4}
              maskChar=""
              mask="999.999.999-99"
              type="text"
              name="cpf"
              placeholder="CPF"
              value={filters.cpf}
              onChange={handleChange}
            />
            <Autocomplete
              xs={4}
              options={statusList}
              getOptionLabel={(option) => option?.name}
              onChange={(event, newValue) => {
                handleAutoCompleteChange(event, newValue, 'status');
              }}
              style={{width: '200px', marginLeft: '10px'}}
              renderInput={(params) => (
                <TextField {...params} className="autocomplete" variant='standard' size='small' placeholder="Status" />
              )}
            />

            <Autocomplete
              xs={4}
              options={tipoPagamentoList}
              getOptionLabel={(option) => option?.name}
              onChange={(event, newValue) => {
                handleAutoCompleteChange(event, newValue, 'tipoPagamento');
              }}
              style={{width: '250px', marginLeft: '10px'}}
              renderInput={(params) => (
                <TextField {...params} className="autocomplete" variant='standard' size='small' placeholder="Tipo de Pagamento" />
              )}
            />

            <input
              xs={4}
              type="text"
              placeholder="Responsavel Financeiro"
              name="responsavel"
              value={filters.responsavel}
              onChange={handleChange}
            />
            <input
              xs={4}
              type="date"
              name="startDate"
              className="input-form"
              placeholder="Data Inicial"
              value={filters.startDate}
              onChange={handleChange}
            />
            <input
              xs={4}
              type="date"
              name="endDate"
              className="input-form"
              placeholder="Data Final"
              value={filters.endDate}
              onChange={handleChange}
            />
          
          </Grid>
          </Filter>

          {getShowData && 
            (
              <>
                <TablePagination
                component="div"
                count={data ? data.total : 0}
                page={filters.filterPage}
                onPageChange={handleChangePage}
                rowsPerPage={filters.per_page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />  
                <CompTable data={data?.data} colums={colums} />
              </>
            )
          }       
          <ExportButton
            style={{ float: 'right', marginTop: '20px' }}
            type="button"
            onClick={() => {
              excelExport(
                `/maistodos/pagamentos/export?${MontarUrl()}`,
                `relatorio_de_Pagamentos${DateTime.now().toFormat('dd_LL_yyyy')}`
              );
            }}>
            Exportar
          </ExportButton>
        </Container>
      </motion.div>
    </>
  );
};

export default PaymentReport;

const CriarBotao = (data) => {
  const { ...auth } = useAuth();
  const [open, setOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [payment, setPayment] = useState(data);
  const [RePayment,SetRePayment] = useState(false);
  const [getPaymentCreatedAt, setPaymentCreatedAt] = useState(null);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      setPayment(data);
      setLoading(false);
    }
  }, [data]);

  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleEstornar = () => {
    handleOpenConfirmation();
  };

  const handleConfirmEstorno = async () => {
    try {
      const response = await api.post(`maistodos/estornarpagamento/${payment.id}`);

      if (response.status == 200) {
        toast.success('Estorno realizado com sucesso.');
        handleCloseConfirmation();
      }
    } catch (error) {
      toast.error('Erro ao estornar pagamento!');
      handleCloseConfirmation();
      console.log(error);
      console.log(error.response);
    }
  };
 const ConfirmRepayment = async()=>
 {
  try {
    const response = await api.post(`/maistodos/novatentativapagamento/${payment.id}`);

    if (response.status == 200) {
      toast.success('Realizado com sucesso.');
      SetRePayment(false);
    }
  } catch (error) {
    toast.error('Erro ao realizar pagamento.');
    SetRePayment(true);
    console.log(error);
    console.log(error.response);
  }
 }
  const traduzirStatus = (status) => {
    switch (status) {
      case 'paid':
        return 'Pago';
      case 'failure':
        return 'Falha';
      case 'refunded':
        return 'Estornado';
      default:
        return status;
    }
  };

  const handleAtualizar = async () => {
    const body = {
      created_at: getPaymentCreatedAt
    };

    try {
        const response = await api.put(`maistodos/editPayment/${payment.id}`, body);
        toast.success("Pagamento Atualizado!");
    }
    catch (e) {
        toast.error(`Erro ao solicitar Atualização! ${e}`);
    }
  }

  const handlePaymentLink = () =>
  window.open(`https://assinaturas.maistodos.com.br/admin/subscriptions/attempt/?id=${payment.identificadorMaisTodos}`, 
      '_blank', 
      'noopener,noreferrer');


  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <LaunchIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <h2>Detalhes do Pagamento</h2>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <p>Carregando...</p>
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>
                  <b>Lead:</b> {payment.fatura.assinatura_recorrente.lead.id}{' '}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>
                  <b>Identificador Mais Todos:</b> {payment.identificadorMaisTodos}{' '}
                </p>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>
                  <b>Cliente:</b> {payment.fatura.assinatura_recorrente.lead.client.name}{' '}
                </p>
                {payment.fatura.assinatura_recorrente.lead.financialData && (
                  <p>
                    <b>Responsável Financeiro:</b> {payment.fatura.assinatura_recorrente.lead.financialData.nome}
                  </p>
                )}
                <h3>
                  <b>Status:</b> {traduzirStatus(payment.status)}
                </h3>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p>
                    <b>Seguro:</b> {payment.fatura.assinatura_recorrente.descricao}
                  </p>
                  <p>
                    <b>Forma de Pagamento:</b> Cartão de Crédito
                  </p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                  <h3>
                    <b>Produto:</b> {payment.fatura.descricao}
                  </h3>
                </div>
              </div>
              <Divider style={{ marginTop: 5, marginBottom: 15 }} />
              <div>
                <h2 style={{ textAlign: 'end' }}>Valor da Fatura: {maskCurrency(payment.fatura.valor)}</h2>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {(auth.isM3tasUser()) && (
            <>
            <TextField
                    margin="dense"
                    label="Created At"
                    type="paymentCreatedAt"
                    value={getPaymentCreatedAt}
                    style={{width: '200px'}}
                    onChange={(e) => setPaymentCreatedAt(e.target.value)}
                />
            
            <Button onClick={handleAtualizar} color="primary" medium>
              Atualizar
            </Button>
            </>
          )}
          {(auth.isAdminUser()) && (
            <>
            <Button onClick={handlePaymentLink} color="primary" medium>
              Abrir MaisTodos
            </Button>
            </>
          )}

          {!loading && payment.status === 'paid' && (
            <Button onClick={handleEstornar} color="secondary" medium>
              Estornar Pagamento
            </Button>
          )}
          {!loading && payment.status !== 'paid' && (
            <Button onClick={()=>SetRePayment(true)} color="secondary" medium>
             Re-Tentativa de pagamento
            </Button>
          )}
          <Button onClick={handleClose} color="primary" medium>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleCloseConfirmation}>
        <DialogTitle>Confirmação de Estorno</DialogTitle>
        <DialogContent>Tem certeza de que deseja estornar o pagamento?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmEstorno} color="secondary">
            Confirmar Estorno
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={RePayment} onClose={()=>SetRePayment(false)}>
        <DialogTitle>Confirmação de Pagamento</DialogTitle>
        <DialogContent>Tem certeza de que deseja Realizar o pagamento?</DialogContent>
        <DialogActions>
          <Button onClick={()=>SetRePayment(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={ConfirmRepayment} color="secondary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};