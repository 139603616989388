/* eslint-disable react/forbid-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { FixedSizeGrid as Grid } from 'react-window';
import P from 'prop-types';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
// import { listToMatrix } from '../../utils/array';
// import LeadCard from '../Dashboard/LeadCard';

const LeadsGridVirtualized = ({ rows, children}) => {
  const { width, height, ref } = useResizeDetector();
  const [columnNumber, setColumnNumber] = useState(null);

  useEffect(() => {
    setColumnNumber(width > 1259 ? 4 : 3);
  }, [width])

  return (
    <div ref={ref} style={{ width: '100%'
    , height: 'calc(100vh - 225px)'  
    }}>
      {width > 1 && height > 1 && rows > 0 &&(
        <Grid
          className="Grid"
          columnCount={columnNumber}
          columnWidth={(width / columnNumber) - 5}
          height={height}
          rowCount={rows}
          rowHeight={550}
          width={width}>
          {children}
        </Grid>
      )}
    </div>
  );
};

LeadsGridVirtualized.propTypes = {
  children: P.any.isRequired,
  rows: P.number,
};
LeadsGridVirtualized.defaultProps = {
  rows: 0,
};

export default LeadsGridVirtualized;
