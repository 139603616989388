
import styled from 'styled-components';
import {motion} from 'framer-motion'

export const MiniMenu = styled(motion.div)`
    left: 100%;
    /* top: calc((40px * ${props => props.clickMenu || 1}) - 55px); */
    top: -15px;
    z-index: 100;
    padding: 16px;
    min-width: 260px;
    position: absolute;
    display: block;

    .sub-menus-content{
      min-width: 200px;

      button {
        text-align: left;
        color: #333;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        padding: 6px 12px;
        border-radius: 6px;
        align-items: center;
        text-decoration: none;
        justify-content: space-between;
        transition: all 0.15s ease-in-out;
        :hover {
          background-color: #f1f1f1;
        }
        small {
          color: #ccc;
          display: block;
        }
        .count {
          width: 20px;
          color: #999;
          font-weight: 400;
          text-align: center;
          margin-left: 2px;
        }
      }
    }
`;
