/* eslint-disable no-nested-ternary */
import { Box } from '@material-ui/core';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const GridContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'area1 area1 area2 area3 area10'
    'area1 area1 area4 area5 area11'
    'area6 area7 area8 area9 area12';

  > div {
    border: solid 1px orange;
  }

  .area1 {
    grid-area: area1;
  }
  .area2 {
    grid-area: area2;
  }
  .area3 {
    grid-area: area3;
  }
  .area4 {
    grid-area: area4;
  }
  .area5 {
    grid-area: area5;
  }
  .area6 {
    grid-area: area6;
  }
  .area7 {
    grid-area: area7;
  }
  .area8 {
    grid-area: area8;
  }
  .area9 {
    grid-area: area9;
  }
  .area10 {
    grid-area: area10;
  }
  .area11 {
    grid-area: area11;
  }
  .area12 {
    grid-area: area12;
  }
  .area13 {
    grid-area: area13;
  }
`;

export const FilterContainer = styled(Box)`
    position: relative;
  .hidden-modal {
    padding: 10px;
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    /* display: ${props => props.hover ? 'block' : 'none'}; */

    span{
      font-weight: normal;

    }

    p{
      font-weight: bold;

    }
    p+p{
      margin-top: 10px;
    }
    p+span{
      margin-top: 10px;
    }
  }
`;

export const FunnelBox = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  background-color: ${(props) => (props.created ? '#f2652211' : 'transparent')};
  width: ${(props) => (props.width ? (typeof props.width === 'string' ? props.width : `${props.width}px`) : '50px')};
  height: ${(props) =>
    props.height ? (typeof props.height === 'string' ? props.height : `${props.height}px`) : '50px'};

  .header {
    width: 100%;
    background-color: #ffffff70;
    border-bottom: solid 1px black;
    text-align: center;
    max-height: 21px;
    overflow-y: hidden;
    cursor: default;
  }
`;
