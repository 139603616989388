import styled from 'styled-components';
import { FormControl } from '@material-ui/core';
import { motion } from 'framer-motion';

export const ContainerFields = styled(motion.div)`
  display: flex;
  align-items: center;

  .password-container {
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 16px;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    .input {
      margin-top: 8px;
    }
    form {
      display: flex;
      flex-direction: column;
      .btns {
        align-self: flex-end;
      }
    }
  }

  .profile-container {
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 24px;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    form {
      display: flex;
      flex-direction: column;
      .btns {
        align-self: flex-end;
      }
    }
  }
`;

export const Container = styled(motion.div)`
  max-width: 970px;
  padding-left: 16px;
  padding-right: 16px;

  .card {
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
  }

  .user-info {
    .name {
      color: #84817a;
      font-weight: 600;
    }
    .login {
      color: #aaa69d;
    }
    .phone-email {
      display: flex;
      color: #84817a;
      font-size: 12px;
      margin-top: 8px;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        svg {
          color: #f26522;
          margin-right: 8px;
        }
      }
      .phone { padding-right: 8px; }
      .email { padding-left: 8px; }
    }
  }
`;

export const InputControl = styled(FormControl)`
  && {
    margin-bottom: 24px;
  }
`;
