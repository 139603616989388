import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Logo = styled(motion.img)``;
export const LogoV = styled(motion.img)`
max-width: 700px`;
export const LogoH = styled(motion.img)`
max-width: 500px`;
export const BandeiraCartao = styled.img`
height: 10px;
margin: 0;`;
