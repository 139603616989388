/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { FormGroup } from './styles';
import { maritalStatus, sexo } from '../../mock/leadInfo.com';
import { TextField, AutocompleteField, MaskedTextField } from '../FormFields';



export function Beneficiary({ beneficiary, handleChange, index, disabledField }) {
    const hojedata = new Date();
    const ultimadata = new Date(hojedata.getFullYear(), hojedata.getMonth(), hojedata.getDate()).toISOString().slice(0, 10);
    const handleChangeAutoComplete = (event, newValue, variable) => {
        handleChange(index, event, { ...newValue, variable });
    };
    return (<>
        <FormGroup>
            <TextField
                value={beneficiary.name || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="name"
                label="Nome"
                className="control gg"
                required
                disabled={disabledField}
            />
            <MaskedTextField
                mask='999.999.999-99'
                value={beneficiary.cpf || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="cpf"
                label="CPF"
                className="control s"
                required
                disabled={disabledField}

            />
            <MaskedTextField
                mask='9999999999'
                value={beneficiary.rg || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="rg"
                label="RG"
                className="control s"
                disabled={disabledField}

            />
            <TextField
                label="Data de Nascimento"
                type="date"
                varibleName="birth_date"
                value={beneficiary.birth_date || ''}
                onChange={(e) => handleChange(index, e)}
                InputLabelProps={{
                    shrink: true,
                }}
                maxDate={ultimadata}
                disabled={disabledField}

            />
        </FormGroup>
        <FormGroup >
            <TextField
                value={beneficiary.parentesco || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="parentesco"
                label="Parentesco"
                className="control gg"
                required
                disabled={disabledField}


            />
            <AutocompleteField
                medium
                className="control m"
                valueText={beneficiary.sex ? beneficiary.sex : ''}
                options={sexo}
                value={beneficiary.sex || null}
                varibleName="sex"
                onChange={handleChangeAutoComplete}
                label="Sexo"
                disabled={disabledField}

            />
        </FormGroup>
        <FormGroup >
            <AutocompleteField
                medium
                className="control m"
                valueText={beneficiary.maritalStatus ? beneficiary.maritalStatus : ''}
                options={maritalStatus}
                value={beneficiary.maritalStatus || null}
                varibleName="maritalStatus"
                onChange={handleChangeAutoComplete}
                label="Estado Civil"
                disabled={disabledField}

            />
            <MaskedTextField
                mask={
                    beneficiary.phone && beneficiary.phone.replace(/[^\d]/g, '').length < 11
                        ? '(99)9999-99999'
                        : '(99)99999-9999'
                }
                value={beneficiary.phone || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="phone"
                label="Telefone"
                disabled={disabledField}

            />
            <MaskedTextField
                mask={
                    beneficiary.cellphone && beneficiary.cellphone.replace(/[^\d]/g, '').length < 11
                        ? '(99)9999-99999'
                        : '(99)99999-9999'
                }
                value={beneficiary.cellphone || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="cellphone"
                label="Celular"
                disabled={disabledField}

            />
        </FormGroup>
        <FormGroup >
            <TextField
                value={beneficiary.email || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="email"
                label="E-mail"
                className="control gg"
                disabled={disabledField}

            />
            <TextField
                value={beneficiary.instagram || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="instagram"
                label="Instagram"
                className="control gg"
                disabled={disabledField}

            />
            <TextField
                value={beneficiary.facebook || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="facebook"
                label="Facebook"
                className="control gg"
                disabled={disabledField}

            />
            <TextField
                required
                value={beneficiary.participation || ''}
                onChange={(e) => handleChange(index, e)}
                varibleName="participation"
                label="% Participação"
                type="number"
                className="control s"
                maxDate={100}
                minDate={1}
                step={0.1}
                disabled={disabledField}

            />
        </FormGroup>
    </>
    )
}
Beneficiary.propTypes = {
    index: P.number,
    beneficiary: P.objectOf(P.any).isRequired,
    handleChange: P.func.isRequired,
    disabledField: P.bool
}
Beneficiary.defaultProps = {
    index: 0,
    disabledField: false
};