/* eslint-disable */
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { dataEdit } from '../../service/data.service';

export default function CancelLeadModal({ title, open, setOpen, value, setOpenLeadForm }) {
  const [cancelLeadForm, setCancelLeadForm] = useState();
  const [response, setResponse] = useState();

  const ResetForm = () => {
    setCancelLeadForm({});
  };

  useEffect(() => {
    ResetForm();
  }, []);

  if (response == 'success') {
    toast.success('Lead Cancelado com sucesso!');
    setResponse(null);
    setOpen(false);
    setOpenLeadForm(false)
  } else if (response == 'fail') {
    toast.error('Houve um Problema');
    setResponse(null);
  }

  const handleClose = () => {
    setOpen(false);
  };  

  const handleSubmit = async () => {
    let resp = await dataEdit('/cancel-lead', cancelLeadForm, value?.id)
    setResponse(resp);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {title}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}>
          <DialogContent>
          <div style={{ margin: '15px' }}>
                <FormLabel component="legend" style={{ marginBottom: '5px' }}>
                  Lembre-se de salvar as alterações antes de confirmar
                </FormLabel>
          </div>   
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Sim
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
