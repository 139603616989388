import { useState, useEffect, useContext } from 'react';
import P from 'prop-types';
import { Link, Redirect, useParams } from 'react-router-dom';
import { IconButton, InputAdornment, Input, InputLabel, Button } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FormContainer, InputControl, Container } from './styles';
import {LogoImg} from '../Logo';
import { MainContext } from '../../context/ApiContext';

const VerifyForm = ({redefine}) => {
  const {
    verify: { verify, getVerifyUser },
  } = useContext(MainContext);
  const { hash } = useParams();
  const [redirect, setRedirect] = useState(false);
  // const [redirectUrl, setRedirectUrl] = useState('/login');
  const [values, setValues] = useState({
    password_confirm: '',
    password: '',
    id: null,
    hash: null,
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data },
        } = await getVerifyUser();
        const vCode = data.find((v) => v.verify_code === atob(hash));
        if (!redefine) {
          if (vCode.verified) throw new Error('Usuário já verificado');
        }
        if (vCode) setValues({ ...values, id: vCode.user_id, hash });
      } catch (error) {
        setRedirect(true);
      }
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.password === values.password_confirm) {
      const verifyData = {
        id: values.id,
        verify_code: values.hash,
        password: values.password,
      };
      try {
        await verify(verifyData);
        setRedirect(true);
      } catch (error) {
        //
      }
    }
  };

  return (
    <Container>
      {redirect && <Redirect to="/login" />}
      <LogoImg />
      <FormContainer elevation={1}>
        <div className="title-container">
          <h1>Crie uma nova senha</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <InputControl fullWidth>
            <InputLabel htmlFor="password">Senha</InputLabel>
            <Input
              id="password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </InputControl>
          <InputControl fullWidth>
            <InputLabel htmlFor="password_confirm">Confirmação de Senha</InputLabel>
            <Input
              id="password_confirm"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password_confirm}
              onChange={(e) => setValues({ ...values, password_confirm: e.target.value })}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </InputControl>
          <div className="footer">
            <Button className="entrar" variant="contained" type="submit">
              Salvar
            </Button>
            <Button className="back" variant="contained" component={Link} to="/login">
              Voltar
            </Button>
          </div>
        </form>
      </FormContainer>
    </Container>
  );
};

export default VerifyForm;


VerifyForm.propTypes = {
  redefine: P.bool,
}
VerifyForm.defaultProps = {
  redefine: false,
}
