/* eslint-disable */
import { useContext, useState } from 'react';
import { MainContext } from '../../../context/ApiContext';
import { Nav, NavbarForm } from './styles';
import { DateTime } from 'luxon';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import useSWR from 'swr';
import { fetcher } from '../../../service/SWRFetcher';
import useApp from '../../../hooks/useApp';

const DatePeriodFilter = ({ setFilters }) => {
  const { partners } = useApp();

  const { data: users } = useSWR(`/users`, fetcher);

  const [partner, setPartner] = useState();

  function handleSubmit(event) {
    event.preventDefault();
    const filters = {
      date_ini: dates.date_ini,
      date_end: dates.date_end,
      partner_id: partner?.map((partner) => ({ id: partner.id })) || null,
      broker_id: brokerId?.map((b) => ({ id: b.id })) || null,
    };
    setFilters((f) => ({ ...f, ...filters }));
  }

  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();

  const [dates, setDates] = useState({
    date_ini: dateLastMonth,
    date_end: dateNow,
  });

  const [brokerId, setBrokerId] = useState();

  const clear = () => {
    setDates({
      date_ini: dateLastMonth,
      date_end: dateNow,
    });
    setBrokerId([]);
    setPartner([]);
    const filters = {
      date_ini: dates.date_ini,
      date_end: dates.date_end,
      partner_id: partner?.map((partner) => ({ id: partner.id })) || null,
      broker_id: brokerId?.map((b) => ({ id: b.id })) || null,
    };
    setFilters((f) => ({ ...f, ...filters }));
  };

  return (
    <Nav>
      <div className="filter">Filtros - Data de Captura:</div>

      <NavbarForm>
        <form className="form" onSubmit={handleSubmit}>
          <div className="inputs">
            <div className="date_ini">
              <input
                type="date"
                name="date_ini"
                className="input-form"
                placeholder="Data Inicial"
                value={dates.date_ini}
                onChange={(e) => setDates({ ...dates, date_ini: e.target.value })}
              />
            </div>
            <div className="date_end">
              <input
                type="date"
                name="date_end"
                className="input-form"
                placeholder="Data Final"
                value={dates.date_end}
                onChange={(e) => setDates({ ...dates, date_end: e.target.value })}
              />
            </div>
          </div>

          <Autocomplete
            multiple
            options={partners ? partners : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setPartner(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Empresas" />
            )}
          />

          <Autocomplete
            multiple
            options={users ? users : []}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setBrokerId(value)}
            renderInput={(params) => (
              <TextField {...params} className="autocomplete" variant="standard" placeholder="Corretor" />
            )}
          />

          <div>
            <button className="button" type="submit">
              Filtrar
            </button>
            {/* <button className="button" type="button" onClick={clear}>
              Limpar
            </button> */}
          </div>
        </form>
      </NavbarForm>
    </Nav>
  );
};

export default DatePeriodFilter;
