/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Header, Container } from '../../styles/shared/styles';
import { MainContext } from '../../context/ApiContext';
import IndicationReportTableList from '../../components/IndicationReportTableList';
import DatePeriodFilter from '../../components/IndicationReportTableList/Filter';
import LoadingPage from '../LoadingPage';

const InidicationReport = () => {
  const [user, setUser] = useState(null);
  const { values, getters, creators } = useContext(MainContext);
  const {
    auth: { getUserInfo },
  } = useContext(MainContext);
  const [userId, setUserId] = useState();

  useEffect(() => {
    (async()=>{
      await getters.getIndicationReportLeads();
      const temp = await getUserInfo();
      setUser(temp.data);
    })()
  }, []);

  return (
    <>
      {user === null ? (
        <LoadingPage />
      ) : (
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
          <Container>
            <Header>
              <h1>Relatório de Indicações - {user?.name}</h1>
            </Header>
            <DatePeriodFilter id={userId} />
            <IndicationReportTableList
              listItem={values.leads}
              title="Relatório de Indicação (Concessionária)"
              states={values.lead_states}
              sold={values.lead_sold}
              status={values.lead_status}
              lostSale={values.lead_lost_sale}
            />
          </Container>
        </motion.div>
      )}
    </>
  );
};

export default InidicationReport;
