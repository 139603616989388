import { useEffect, useRef, useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow , makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import { DateTime } from 'luxon';
import { AutocompleteField, DateField, MaskedTextField, TextFieldAdorned } from '../FormFields';
import { Container, FormGroup, LoadingContainer, LoadingDiv } from '../LeadForm/styles';
import { maskDate, removeMask } from '../../utils/masks';
import { editClient } from '../../service/client.service';
import { DivBotoes, DivSeparacao } from './styles';
import api from '../../service/api';
import { maritalStatus } from '../../mock/leadInfo.com';



export default function ModalBeneficiarios({ title, open, setOpen, cliente }) {

    const formatDateToBrazilian = (dateString) => {
        if (dateString) {
          const [year, month, day] = dateString.split('-');
          return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
        }
        return dateString
      };

    const handleClose = () => {
        setOpen(false);
    };

    const useStyles = makeStyles({
        table: {
          minWidth: 650,
        },
        tableCell: {
          fontSize: '12px', // Defina o tamanho da fonte conforme necessário
          whiteSpace: 'nowrap', // Impede a quebra de linha
        },
      });

      const classes = useStyles();

    return (
        <div>
            <Dialog onClose={handleClose}
                aria-labelledby="form-dialog-title"
                
                open={open}
                maxWidth="md"
                style={{ zIndex: 15615 }}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <h3>{title}</h3>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>

                </DialogTitle>

                <DivSeparacao >

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                                <TableRow hover>
                                    <TableCell className={classes.tableCell} ><b>Seguro</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Nome</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>CPF</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>RG</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Data de Nascimento</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Parentesco</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Gênero</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Estado Civil</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Telefone</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Celular</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Email</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Instagram</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Facebook</b></TableCell>
                                    <TableCell className={classes.tableCell}><b>Participação (%)</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cliente.leads.map((lead) => (
                                    (lead.cliente_beneficiarios || []).map((beneficiario) => (
                                        <TableRow key={lead.id}>
                                            <TableCell className={classes.tableCell}>
                                                {lead.insurance.name}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.name || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.cpf || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.rg || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.birth_date ? formatDateToBrazilian(beneficiario.birth_date) : '' || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.parentesco || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.sex?.name || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.maritalStatus?.name || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.phone || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.cellphone || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.email || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.instagram || ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.facebook|| ''}</TableCell>
                                            <TableCell className={classes.tableCell}>{beneficiario?.participation || ''}%</TableCell>
                                        </TableRow>
                                    ))

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DivSeparacao>
            </Dialog>
        </div>
    )
}

ModalBeneficiarios.propTypes = {
    open: P.bool.isRequired,
    title: P.string.isRequired,
    setOpen: P.func.isRequired,
    cliente: P.objectOf(P.string),
};

ModalBeneficiarios.defaultProps = {
    cliente: null,
};
