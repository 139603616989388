/* eslint-disable react/forbid-prop-types */
import P from 'prop-types';
import { FormControl } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { Autocomplete } from '@material-ui/lab';
import { StyledTextField } from './styled';

export const TextField = 
  ({
    value, 
    varibleName, 
    label, 
    className, 
    maxChars,
    minChars, 
    maxDate,
    minDate,
    step,
    placeholder, 
    styleWidth,
    required,
    ...props 
  }) => (
  <FormControl className={className} style={{width: styleWidth}}>
    <StyledTextField
      name={varibleName}
      label={label}
      value={value}
      margin="normal"
      variant="outlined"
      placeholder={placeholder}
      required={required}
      inputProps={{
        autoComplete: 'nope',
        maxLength: maxChars,
        minLength: minChars,
        max: maxDate,
        min:minDate,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  </FormControl>
);

TextField.propTypes = {
  value: P.oneOfType([P.string, P.number, P.any]),
  label: P.string.isRequired,
  className: P.string,
  varibleName: P.string.isRequired,
  maxChars: P.number,
  minChars: P.number,
  maxDate: P.string,
  minDate:P.string,
  step:P.number,
  placeholder: P.string,
  styleWidth: P.string,
  required: P.bool,
};

TextField.defaultProps = {
  value: null,
  className: 'control',
  maxChars: null,
  minChars: null,
  maxDate: null,
  minDate:null,
  step:1,
  placeholder: null,
  styleWidth: null,
  required: false,
};

export const TextFieldAdorned = ({ value, varibleName, label, className, endAdornment, startAdornment, ...props }) => (
  <FormControl className={className}>
    <StyledTextField
      name={varibleName}
      label={label}
      value={value}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment,
        endAdornment,
        autoComplete: 'nope',
      }}
      {...props}
    />
  </FormControl>
);

TextFieldAdorned.propTypes = {
  value: P.oneOfType([P.string, P.number, P.any]),
  label: P.string.isRequired,
  className: P.string,
  endAdornment: P.element,
  startAdornment: P.element,
  varibleName: P.string.isRequired,
};

TextFieldAdorned.defaultProps = {
  value: null,
  className: 'control',
  startAdornment: null,
  endAdornment: null,
};

export const MaskedTextField = ({ value, mask, varibleName, label, className, disabled, fullWidth, ...props }) => (
  <FormControl className={className} fullWidth={fullWidth}>
    <InputMask
      mask={mask}
      value={value}
      maskChar=""
      name={varibleName}
      label={label}
      {...props}
      InputProps={{ disabled }}>
      {(params) => (
        <StyledTextField
          variant="outlined"
          margin="normal"
          inputProps={{
            autoComplete: 'nope',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...params}
        />
      )}
    </InputMask>
  </FormControl>
);

MaskedTextField.propTypes = {
  value: P.oneOfType([P.string, P.number, P.any]),
  label: P.string.isRequired,
  className: P.string,
  disabled: P.bool,
  fullWidth: P.bool,
  varibleName: P.string.isRequired,
  mask: P.oneOfType([P.func, P.string]),
};

MaskedTextField.defaultProps = {
  value: null,
  mask: 'input',
  className: 'control',
  disabled: false,
  fullWidth: false,
};

export const AutocompleteField = ({
  valueText,
  options,
  onChange,
  varibleName,
  label,
  required,
  className,
  getOptionLabel,
  getOptionSelected,
  medium,
  ...props
}) => (
  <FormControl className={className}>
    <Autocomplete
      {...props}
      autoSelect
      size={medium ? 'medium' : 'small'}
      // disableClearable
      options={options || ['Aguarde um momento']}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={(event, newValue) => {
        onChange(event, newValue, varibleName);
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={label}
          value={valueText}
          required={required}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  </FormControl>
);

AutocompleteField.propTypes = {
  options: P.arrayOf(P.string),
  label: P.string.isRequired,
  onChange: P.func.isRequired,
  valueText: P.oneOfType([P.string, P.number]).isRequired,
  varibleName: P.string.isRequired,
  className: P.string,
  required: P.bool,
  getOptionLabel: P.func,
  getOptionSelected: P.func,
  medium: P.bool,
};

AutocompleteField.defaultProps = {
  required: false,
  options: null,
  className: 'control',
  getOptionLabel: (option) => option.name,
  getOptionSelected: (option, value) => option.id === value.id,
  medium: false,
};

export const DateField = ({ value, onChange, mask, varibleName, label, required, ...props }) => (
  <FormControl className="control">
    <StyledTextField
      {...props}
      type="date"
      name={varibleName}
      label={label}
      value={value}
      onChange={onChange}
      required={required}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputComponent: mask,
        autoComplete: 'nope',
      }}
    />
  </FormControl>
);

DateField.propTypes = {
  value: P.oneOfType([P.string, P.number, P.any]).isRequired,
  label: P.string.isRequired,
  varibleName: P.string.isRequired,
  onChange: P.func.isRequired,
  required: P.bool,
  mask: P.oneOfType([P.func, P.string]),
};

DateField.defaultProps = {
  mask: 'input',
  required: false,
};

export const TextField2 = ({ value, varibleName, label, className, ...props }) => (
  <FormControl className={className}>
    <StyledTextField
      name={varibleName}
      label={label}
      value={value}
      margin="normal"
      variant="outlined"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  </FormControl>
);

TextField2.propTypes = {
  value: P.oneOfType([P.string, P.number, P.any]),
  label: P.string.isRequired,
  className: P.string,
  varibleName: P.string.isRequired,
};

TextField2.defaultProps = {
  value: null,
  className: 'control',
};
