/* eslint-disable */
import { toast } from 'react-toastify';
import api from '../../service/api';

export const fileUpload = async (event, setFile, leadCurrent, fileName, msg) => {
  if (event.target.files[0] == null) {
    return false;
  }

  if (event.target.files[0].size > 2000000) {
    setFile(null);
    toast.warning('Tamanho máximo suportado de 2Mb.');
  }
  
  setFile(event.target.files[0]);
  try {
    const data = {
      lead_id: leadCurrent.id,
      file: event.target.files[0],
    };
    const formData = new FormData();
    const keys = Object.keys(data);
    const values = Object.values(data);
    for (let i = 0; i < keys.length; i += 1) {
      formData.append(keys[i], values[i]);
    }

    const token = localStorage.getItem('token');
    const resp = await api.post(`/leads/file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${token}`,
        'File-Name': fileName
      }
    });
    if(msg)
    toast.success(msg);
    else
    toast.success('Arquivo enviado com sucesso');
    setFile(null);
    return true;
  } catch (error) {
    toast.error('Erro ao enviar arquivo');
    setFile(null);
  }
  
  return false;
};

export const CustomFileUpload = async (event, setFile, leadCurrent, fileName, msg,route) => {
  if (event.target.files[0] == null) {
    return false;
  }
  if (event.target.files[0].type != 'application/pdf') {
    setFile(null);
    toast.warning('Formato de arquivo inválido! Somente arquivos PDF');
  }
  else {
    setFile(event.target.files[0]);
    try {
      const data = {
        name:event.target.files[0].name,
        file: event.target.files[0],
      };
      const formData = new FormData();
      const keys = Object.keys(data);
      const values = Object.values(data);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], values[i]);
      }

      const token = localStorage.getItem('token');
      const resp = await api.post(route, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'authorization': `Bearer ${token}`,
          'File-Name': fileName,
          'identificadorLead':leadCurrent
        }
      });
      if(msg)
      toast.success(msg);
      else
      toast.success('Arquivo enviado com sucesso');
      setFile(null);
      return true;
    } catch (error) {
      toast.error('Erro ao enviar arquivo');
      setFile(null);
    }
  }
  return false;
};

export const onClickVisualizarAnexo = async (lead, fileName, checkData) => {
  try {

    const response = await api.get(`leads/fileDownloadByName?lead_id=${lead.id}
      &fileName=${fileName}
      &checkData=${checkData}`,
        {
            responseType: 'arraybuffer',
        }
    );

    const binaryData = btoa(
        new Uint8Array(response.data).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
        }, '')
    );

    if (checkData) {
      const decodeResponse = JSON.parse(atob(binaryData));
      return decodeResponse.data == 'true';
    }
    
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${binaryData}`;
    link.download = `${fileName}_${getPdfFileName(lead)}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (error) {
      console.log(error);
  }
};

export const getPdfFileName = (lead) => `${lead.client.name.split(' ')[0]}_${lead.id}`;
