/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, FormControl, FormGroup, IconButton, InputLabel, Select } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import api from '../../service/api';

export default function ScheduleModal({ open, setOpen, lead, next }) {
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const [values, setValues] = useState({
    lead_id: null,
    scheduling_date: new Date(),
    description: '',
    status: null,
  });

  const [valuesFF, setValuesFF] = useState({
    scheduling_date: '',
    scheduling_time: '',
  });

  const entering = () => {
    setValues({
      ...values,
      lead_id: lead.id || '',
      scheduling_date:
        lead.lead_scheduling?.scheduling_date.split(' ').join(['T']) || DateTime.now().toString().split('.')[0],
      description: lead.lead_scheduling?.description || '',
      status: lead.lead_scheduling?.status.toString() || null,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    const res = await api.post(`/leads/scheduling`, {
      lead_id: values.lead_id,
      scheduling_date: isFirefox ? `${valuesFF.scheduling_date}T${valuesFF.scheduling_time}` : values.scheduling_date,
      description: values.description,
      status: 0,
    });
    if (res) {
      alert('Lead agendada com sucesso!');
      next();
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        onEntering={entering}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            Agendamento de Lead
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={submit}>
          <DialogContent>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              {!isFirefox ? (
                <FormControl style={{ width: '200px' }}>
                  <TextField
                    id="datetime-local"
                    label="Data e hora do agendamento"
                    type="datetime-local"
                    value={values.scheduling_date}
                    onChange={(e) => setValues({ ...values, scheduling_date: e.target.value })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              ) : (
                <Box width="500px">
                  <FormControl style={{width: '150px'}}>
                    <TextField
                      id="date"
                      label="Data e hora do agendamento"
                      type="date"
                      value={valuesFF.scheduling_date}
                      onChange={(e) => setValuesFF({ ...valuesFF, scheduling_date: e.target.value })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                  <FormControl style={{width: '150px', marginLeft: '10px'}}>
                    <TextField
                      // width="50%"
                      id="time"
                      label="Data e hora do agendamento"
                      type="time"
                      value={valuesFF.scheduling_time}
                      onChange={(e) => setValuesFF({ ...valuesFF, scheduling_time: e.target.value })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
            <FormControl fullWidth>
              <TextField
                onChange={(e) => setValues({ ...values, description: e.target.value })}
                label="OBSERVAÇÕES"
                value={values.description}
                multiline
                rows={5}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            {values.status !== null && (
              <Button
                // variant="contained"
                color="primary"
                onClick={async () => {
                  const res = await api.post(`/leads/scheduling`, {
                    lead_id: values.lead_id,
                    scheduling_date: values.scheduling_date,
                    description: values.description,
                    status: 1,
                  });
                  if (res) {
                    alert('Agendamento encerrado com sucesso!');
                    next();
                    handleClose();
                  }
                }}>
                Encerrar Agendamento
              </Button>
            )}
            <Button type="submit" color="primary">
              Agendar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

ScheduleModal.propTypes = {
  open: P.bool.isRequired,
  setOpen: P.func.isRequired,
  lead: P.objectOf(P.string).isRequired,
  next: P.func.isRequired,
};
